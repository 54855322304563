import React, { useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import { gql } from '@apollo/client';
import Button from 'jbc-front/components/Button';
import Modal from 'jbc-front/components/CommonModal';
import { Download } from 'jbc-front/components/icons';
import { fileEncodings } from '../pages/ImportWithholdingSlips';
import { CSV_EXPORT_REQUEST_TARGET_COUNT, DownloadForm, renderRadioField } from '../components/DownloadModal';
import { Balloon, useQuery, useMutation, targetOptions, useYear } from '../components';
import { reduxForm, Field } from 'redux-form';
import { hasConditions } from '../employees/utils';
import { required } from 'jbc-front/utils/validators';
import { BaloonItem } from '../top/Cooperation';
import { Download as DownloadIcon } from 'jbc-front/components/icons';
import { saveAs } from 'file-saver';
import { useApolloClient } from '@apollo/client';
import { toBlob } from '../utils/url';
import { useNotify } from '@jbc-year-end-adj/common/hooks/useNotify';
import styles from './DownloadModalForResultCsv.scss';

// 2022年12月現在、本番で数十件のダウンロードでもALBのタイムアウトエラーになるため一旦全件バックグラウンド処理(0設定)にする
const MAX_DOWNLOAD_COUNT = 0;

const exportDirectionOptions = [
  { value: 'vertical', label: '年末調整項目を縦に並べて表示（氏名が横に並びます）' },
  { value: 'horizontal', label: '年末調整項目を横に並べて表示（氏名が縦に並びます）' }
];

const serviceTypes = snapshotCount => {
  const types = [
    { value: 'lms', label: '現時点の労務HRと比較' },
    { value: 'payroll', label: '現時点の給与計算と比較' }
  ];
  if (snapshotCount > 0) {
    types.push({ value: 'previous_snapshot', label: '前回実行時点と比較' });
  }
  return types;
};
const differenceExportTargets = [
  { value: 'employee', label: '本人情報' },
  { value: 'family', label: '家族情報' }
];

const WITHHOLDING_SLIP_CSV_EXPORT_REQUEST_RUNNING = gql`
  query withholdingSlipCsvExportRequestRunning($year: Int!) {
    client {
      id
      clientYearly(year: $year) {
        id
        withholdingSlipCsvExportRequestRunning
      }
    }
  }
`;

const WITHHOLDING_SLIP_CSV = gql`
  query withholdingSlipCsv($fileEncoding: FileEncoding, $search: EmployeeSearchInput, $year: Int!) {
    client {
      id
      clientYearly(year: $year) {
        id
        withholdingSlipCsv(fileEncoding: $fileEncoding, search: $search)
      }
    }
  }
`;

const CREATE_WITHHOLDING_SLIP_CSV_EXPORT_REQUEST = gql`
  mutation createWithholdingSlipCsvExportRequest($input: CreateCsvExportRequestInput!) {
    createCsvExportRequest(input: $input) {
      clientYearly {
        id
        withholdingSlipCsvExportRequestRunning
      }
    }
  }
`;

const YEAR_END_ADJ_CSV = gql`
  query yearEndAdjCsv($fileEncoding: FileEncoding, $search: EmployeeSearchInput, $exportDirection: ExportDirection, $year: Int!) {
    client {
      id
      clientYearly(year: $year) {
        id
        yearEndAdjCsv(fileEncoding: $fileEncoding, search: $search, exportDirection: $exportDirection)
      }
    }
  }
`;

const YEAR_END_ADJ_CSV_EXPORT_REQUEST_RUNNING = gql`
  query yearEndAdjCsvExportRequestRunning($year: Int!) {
    client {
      id
      clientYearly(year: $year) {
        id
        yearEndAdjCsvExportRequestRunning
      }
    }
  }
`;

const CREATE_YEAR_END_ADJ_CSV_EXPORT_REQUEST = gql`
  mutation createYearEndAdjCsvExportRequest($input: CreateYearEndAdjCsvExportRequestInput!) {
    createYearEndAdjCsvExportRequest(input: $input) {
      clientYearly {
        id
        yearEndAdjCsvExportRequestRunning
      }
    }
  }
`;

const DIFFERENCE_LIST_CSV_EXPORT_REQUEST_RUNNING = gql`
  query differenceListCsvExportRequestRunning($year: Int!) {
    client {
      id
      clientYearly(year: $year) {
        id
        differenceListCsvExportRequestRunning
      }
    }
  }
`;

const CREATE_DIFFERENCE_LIST_CSV_EXPORT_REQUEST = gql`
  mutation createDifferenceListCsvExportRequest($input: CreateDifferenceListCsvExportRequestInput!) {
    createDifferenceListCsvExportRequest(input: $input) {
      clientYearly {
        id
        differenceListCsvExportRequestRunning
      }
    }
  }
`;

const DIFFERENCE_LIST_SNAPSHOT_COUNT = gql`
  query differenceListSnapshotCount($year: Int!) {
    client {
      id
      clientYearly(year: $year) {
        id
        differenceListSnapshotCount
      }
    }
  }
`;

const renderCooperationButton = (toggle, title, isOpen, disabled = false) => (
  <Button
    {...(isOpen ? { className: 'ignore-react-onclickoutside' } : {})}
    onClick={toggle}
    icon={<DownloadIcon size={17} />}
    widthAuto
    disabled={disabled}
  >
    CSVデータダウンロード
  </Button>
);

const WithHoldingSlipDownloadModal = ({
  isOpen,
  hideModal,
  createCsvRequest,
  search,
  type,
  query,
  getFile,
  fileName,
  noteItem,
  hasConditions
}) => {
  const client = useApolloClient();
  const year = useYear();
  const notify = useNotify();
  return (
    <Modal isOpen={isOpen} hideModal={hideModal}>
      <Modal.Header hideModal={hideModal}>給与・賞与データダウンロード</Modal.Header>
      <DownloadForm
        {...{ search, hideModal, client, createCsvRequest, type, query, getFile, fileName, noteItem, year, notify }}
        initialValues={{
          fileEncoding: 'utf8',
          _target: hasConditions ? 'search' : 'all'
        }}
      />
    </Modal>
  );
};

const YearEndAdjDownloadModal = ({ isOpen, hideModal, createCsvRequest, search, query, getFile, fileName, noteItem, hasConditions }) => {
  const client = useApolloClient();
  const year = useYear();
  const notify = useNotify();
  return (
    <Modal isOpen={isOpen} hideModal={hideModal}>
      <Modal.Header hideModal={hideModal}>年末調整一覧表作成</Modal.Header>
      <DownloadYearEndAdjForm
        {...{ search, hideModal, client, createCsvRequest, query, getFile, fileName, noteItem, year, notify }}
        initialValues={{
          fileEncoding: 'utf8',
          _target: hasConditions ? 'search' : 'all',
          exportDirection: 'vertical'
        }}
      />
    </Modal>
  );
};

const DownloadYearEndAdjForm =
  (({ handleSubmit, submitting, hideModal, noteItem, year }) => (
    <>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <Field
            component={renderRadioField}
            label="ファイル形式選択"
            name="fileEncoding"
            options={fileEncodings}
            note="Excel2016より前のバージョンのExcelをお使いの方はShift-JISをご利用ください。"
            validate={required}
          />
          <Field
            label="ダウンロード対象選択"
            component={renderRadioField}
            name="_target"
            options={targetOptions}
            note={
              <>
                全従業員：全従業員の
                {noteItem}
                をダウンロードします。
                <br />
                検索絞込中の従業員：一覧で検索絞込した従業員の
                {noteItem}
                をダウンロードします。
              </>
            }
            validate={required}
          />
          <Field
            label="出力形式の選択"
            component={renderRadioField}
            name="exportDirection"
            options={exportDirectionOptions}
            validate={required}
          />
          <br />
          依頼一覧のステータスが「完了」になっていない従業員は以下の項目が計算されておりません。
          <br />
          ・給与所得控除後の金額
          <br />
          ・所得金額調整控除の金額
          <br />
          ・給与所得控除後の金額 所得調整後
          <br />
          ・所得控除の合計額
          <br />
          ・差引課税給与所得
          <br />
          ・算出所得年税額
          <br />
          ・年調所得税額
          <br />
          ・年調年税額
          <br />
          ・差引超過額又は不足額
          {year === 2024 && (
            <>
              <br />
              ・年末調整時点減税対象人数
              <br />
              ・年末調整時点年調減税額
              <br />
              ・年調減税額
              <br />
              ・年調減税額控除後の年調所得税額
              <br />
              ・控除外額
            </>
          )}
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Buttons>
          <Button onClick={hideModal}>キャンセル</Button>
          <Button primary as="button" onClick={handleSubmit} disabled={submitting}>
            <Download />
            ダウンロード
          </Button>
        </Modal.Buttons>
      </Modal.Footer>
    </>
  ))
  |> reduxForm({
    form: 'downloadDifferenceApply',
    onSubmit: async (
      values,
      dispatch,
      { client, search, hideModal, createCsvRequest, type, query, getFile, fileName, year, noteItem, notify }
    ) => {
      const searchParams = values._target === 'search' ? search : null;

      const { data: targetCount } = await client.query({
        query: CSV_EXPORT_REQUEST_TARGET_COUNT,
        variables: {
          search: searchParams,
          year
        },
        fetchPolicy: 'network-only'
      });
      const totalCount = _.get(targetCount, 'client.clientYearly.csvExportRequestTargetCount', 0);

      try {
        if (totalCount >= MAX_DOWNLOAD_COUNT) {
          await createCsvRequest({
            variables: {
              input: {
                year: year,
                type: 'year_end_adj',
                search: searchParams,
                exportDirection: values.exportDirection,
                fileEncoding: values.fileEncoding
              }
            }
          });
          notify(`${noteItem}はメールにてお送りします`);
        } else {
          const { data } = await client.query({
            query,
            variables: {
              fileEncoding: values.fileEncoding,
              search: searchParams,
              exportDirection: values.exportDirection,
              year
            },
            fetchPolicy: 'no-cache'
          });
          saveAs(toBlob(getFile(data), 'text/csv'), fileName);
        }
        hideModal();
      } catch (err) {
        const msg = _.get(err, 'graphQLErrors[0].message');
        notify(msg || err.message, 'error');
      }
    }
  });

const DifferenceListDownloadModal = ({ isOpen, hideModal, createCsvRequest, search, query, getFile, fileName, snapshotCount }) => {
  const year = useYear();
  const notify = useNotify();
  return (
    <Modal isOpen={isOpen} hideModal={hideModal}>
      <Modal.Header hideModal={hideModal}>差分リストのダウンロード</Modal.Header>
      <DownloadDifferenceListForm
        {...{ search, hideModal, createCsvRequest, query, getFile, fileName, year, notify, snapshotCount }}
        initialValues={{
          fileEncoding: 'utf8',
          differenceExportTarget: 'employee',
          differenceTargetService: 'lms'
        }}
      />
    </Modal>
  );
};

const DownloadDifferenceListForm =
  (({ handleSubmit, submitting, hideModal, snapshotCount, year }) => (
    <>
      <Modal.Body>
        <form onSubmit={handleSubmit} className={styles.description}>
          <b>
            ジョブカン労務HR又はジョブカン給与計算の現在の情報と年末調整実施後の情報
            <br />
            (ダウンロード実行日時点の情報)を比較し、変更があった従業員のリストを出力します。
          </b>
          <Field
            component={renderRadioField}
            label="ファイル形式選択"
            name="fileEncoding"
            options={fileEncodings}
            note="Excel2016より前のバージョンのExcelをお使いの方はShift-JISをご利用ください。"
            validate={required}
          />
          <Field
            label="比較サービス選択"
            component={renderRadioField}
            name="differenceTargetService"
            options={serviceTypes(snapshotCount)}
            validate={required}
            note={
              <>
                現時点の労務HRと比較：現時点のジョブカン労務HRの情報と比較します。
                <br />
                現時点の給与計算と比較：現時点のジョブカン給与計算の情報と比較します。
                <br />
                前回実行時点と比較：前回実行した時点の情報と比較します。
                {year >= 2024 && (
                  <>
                    <br />
                    比較サービス選択で選択した項目と出力される差分リストの内容は
                    <a
                      className="u-txt-link"
                      href="https://jobcan-payroll.zendesk.com/hc/ja/articles/12946041702169-%E5%B7%AE%E5%88%86%E3%83%AA%E3%82%B9%E3%83%88%E3%82%92%E3%83%80%E3%82%A6%E3%83%B3%E3%83%AD%E3%83%BC%E3%83%89%E3%81%99%E3%82%8B"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      こちら
                    </a>
                    をご確認ください。
                  </>
                )}
              </>
            }
          />
          <Field
            label="対象者選択"
            component={renderRadioField}
            name="differenceExportTarget"
            options={differenceExportTargets}
            validate={required}
            note={
              <>
                本人情報：従業員本人の差分をダウンロードします。
                <br />
                家族情報：配偶者・被扶養者情報の差分をダウンロードします。
              </>
            }
          />
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Buttons>
          <Button onClick={hideModal}>キャンセル</Button>
          <Button primary as="button" onClick={handleSubmit} disabled={submitting}>
            <Download />
            ダウンロード
          </Button>
        </Modal.Buttons>
      </Modal.Footer>
    </>
  ))
  |> reduxForm({
    form: 'downloadDifferenceList',
    onSubmit: async (values, dispatch, { search, hideModal, createCsvRequest, year, noteItem, notify }) => {
      // apiのエラーからnotifyの通知は内部的にやってみるたいなのでtry-ctachしない
      // try-ctachしてnotifyするとflashメッセージが2重に表示される
      await createCsvRequest({
        variables: {
          input: {
            year,
            fileEncoding: values.fileEncoding,
            differenceTargetService: values.differenceTargetService,
            differenceExportTarget: values.differenceExportTarget
          }
        }
      });
      notify('差分リストはメールにてお送りします');
      hideModal();
    }
  });

export const DownloadModalForResultCsv = ({ search, formValues, disabled }) => {
  const year = useYear();

  const [isOpenWithHoldingSlipModal, setIsOpenWithHoldingSlipModal] = useState(false);
  const [createWithholdingSlipCsvRequest] = useMutation(CREATE_WITHHOLDING_SLIP_CSV_EXPORT_REQUEST);
  const { data: withholdingSlipData, startPolling: startWithholdingSlipPolling, stopPolling: stopWithholdingSlipPolling } = useQuery(
    WITHHOLDING_SLIP_CSV_EXPORT_REQUEST_RUNNING,
    {
      variables: { year },
      fetchPolicy: 'network-only'
    }
  );
  const exportWithholdingSlipRequest = withholdingSlipData?.client?.clientYearly || {};
  const withholdingSlipPolling = useMemo(() => {
    return exportWithholdingSlipRequest?.withholdingSlipCsvExportRequestRunning;
  }, [exportWithholdingSlipRequest]);

  const [isOpenYearEndAdjModal, setIsOpenYearEndAdjModal] = useState(false);
  const [isOpenDifferenceListModal, setIsOpenDifferenceListModal] = useState(false);
  const [createYearEndAdjCsvRequest] = useMutation(CREATE_YEAR_END_ADJ_CSV_EXPORT_REQUEST);
  const [createDifferenceListCsvRequest] = useMutation(CREATE_DIFFERENCE_LIST_CSV_EXPORT_REQUEST);
  const { data: snapshotInfo, refetch: refetchSnapshotCount } = useQuery(DIFFERENCE_LIST_SNAPSHOT_COUNT, {
    variables: { year },
    fetchPolicy: 'network-only'
  });

  const { data: yearEndAdjData, startPolling: startYearEndAdjPolling, stopPolling: stopYearEndAdjPolling } = useQuery(
    YEAR_END_ADJ_CSV_EXPORT_REQUEST_RUNNING,
    {
      variables: { year },
      fetchPolicy: 'network-only'
    }
  );
  const exportYearEndAdjRequest = yearEndAdjData?.client?.clientYearly || {};
  const yearEndAdjPolling = useMemo(() => {
    return exportYearEndAdjRequest?.yearEndAdjCsvExportRequestRunning;
  }, [exportYearEndAdjRequest]);

  const { data: differenceListData, startPolling: startDifferenceListPolling, stopPolling: stopDifferenceListPolling } = useQuery(
    DIFFERENCE_LIST_CSV_EXPORT_REQUEST_RUNNING,
    {
      variables: { year },
      fetchPolicy: 'network-only'
    }
  );
  const exportDifferenceListRequest = differenceListData?.client?.clientYearly || {};
  const differenceListPolling = useMemo(() => {
    return exportDifferenceListRequest?.differenceListCsvExportRequestRunning;
  }, [exportDifferenceListRequest]);

  useEffect(() => {
    if (withholdingSlipPolling) {
      startWithholdingSlipPolling(3000);
      return () => {
        stopWithholdingSlipPolling();
      };
    }
    return undefined;
  });
  useEffect(() => {
    if (yearEndAdjPolling) {
      startYearEndAdjPolling(3000);
      return () => {
        stopYearEndAdjPolling();
      };
    }
    return undefined;
  });
  useEffect(() => {
    if (differenceListPolling) {
      startDifferenceListPolling(3000);
      return () => {
        stopDifferenceListPolling();
      };
    }
    return undefined;
  });

  return (
    <span className="u-ml10">
      <Balloon center switchRender={renderCooperationButton} title="CSVデータダウンロード" disabled={disabled}>
        {hide => (
          <>
            <Balloon.Ul>
              <BaloonItem
                name="給与・賞与データダウンロード"
                onClick={() => {
                  setIsOpenWithHoldingSlipModal(true);
                  hide();
                }}
                disabled={withholdingSlipPolling}
              />
              <BaloonItem
                name="年末調整一覧表"
                onClick={() => {
                  setIsOpenYearEndAdjModal(true);
                  hide();
                }}
                disabled={yearEndAdjPolling}
              />
              <BaloonItem
                name="差分リスト"
                onClick={() => {
                  // モーダル開いた時にスナップショットの確認
                  refetchSnapshotCount();
                  setIsOpenDifferenceListModal(true);
                  hide();
                }}
                disabled={yearEndAdjPolling}
              />
            </Balloon.Ul>
          </>
        )}
      </Balloon>
      <WithHoldingSlipDownloadModal
        isOpen={isOpenWithHoldingSlipModal}
        hideModal={() => setIsOpenWithHoldingSlipModal(false)}
        query={WITHHOLDING_SLIP_CSV}
        getFile={withholdingSlipData => withholdingSlipData.client.clientYearly.withholdingSlipCsv}
        createCsvRequest={createWithholdingSlipCsvRequest}
        fileName="給与・賞与データ.csv"
        noteItem="従業員の給与・賞与情報"
        type="withholding_slip"
        search={search}
        hasConditions={hasConditions(formValues)}
      />
      <YearEndAdjDownloadModal
        isOpen={isOpenYearEndAdjModal}
        hideModal={() => setIsOpenYearEndAdjModal(false)}
        query={YEAR_END_ADJ_CSV}
        getFile={yearEndAdjData => yearEndAdjData.client.clientYearly.yearEndAdjCsv}
        createCsvRequest={createYearEndAdjCsvRequest}
        fileName="年末調整一覧表.csv"
        noteItem="年末調整一覧表"
        search={search}
        hasConditions={hasConditions(formValues)}
      />
      <DifferenceListDownloadModal
        isOpen={isOpenDifferenceListModal}
        hideModal={() => setIsOpenDifferenceListModal(false)}
        createCsvRequest={createDifferenceListCsvRequest}
        snapshotCount={snapshotInfo?.client?.clientYearly?.differenceListSnapshotCount}
        hasConditions={hasConditions(formValues)}
      />
    </span>
  );
};

export default DownloadModalForResultCsv;
