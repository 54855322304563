import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import compose from 'lodash/fp/compose';
import _ from 'lodash';
import { Loading } from 'jbc-front/components/presenters/ui/Loading';
import Modal, { withModal } from 'jbc-front/components/CommonModal';
import { TextAreaField } from 'jbc-front/components/Form';
import Button from 'jbc-front/components/Button';
import { EditSquare, DeleteSquare, Close } from 'jbc-front/components/icons';
import ActionButton from 'jbc-front/components/ActionButton';
import { Postit, PostitOn } from 'jbc-front/components/icons';
import classnames from 'classnames';
import { Rnd } from 'react-rnd';

import { toFormValues } from '@jbc-year-end-adj/2024/utils/form';
import { onClickOutside } from '@jbc-year-end-adj/2024/components/onClickOutside';
import { DateField, RadioField } from '@jbc-year-end-adj/2024/components/FieldWithDiff';
import { useAdminQuery as useQuery } from '@jbc-year-end-adj/2024/hooks/graphql/useQuery';
import { useAdminMutation as useMutation } from '@jbc-year-end-adj/2024/hooks/graphql/useMutation';
import { useMediaQuery } from '@jbc-year-end-adj/2024/hooks/useMediaQuery';
import { useNotify } from '@jbc-year-end-adj/common/hooks/useNotify';
import { reduxForm } from '@jbc-year-end-adj/2024/features/FormName/FormNameProvider';
import { MEMO_COLOR_OPTIONS } from '../MemoColor';
import { FETCH_MEMOS } from './query';
import { CREATE_MEMO, UPDATE_MEMO, DELETE_MEMO } from './mutation';
import styles from './Memo.scss';

const initialState = { isOpen: false, currentMemo: null };

const setInitialValues = memo => (memo ? _.pick(toFormValues(memo), ['id', 'body', 'alertAt', 'color']) : { color: 'blue' });

export const MemoEdit = compose(
  Child => props => {
    const [createMemo] = useMutation(CREATE_MEMO);
    const [updateMemo] = useMutation(UPDATE_MEMO);
    const notify = useNotify();
    return (
      <Child
        {...props}
        initialValues={setInitialValues(props.memo)}
        onSubmit={async values => {
          const { memo, employeeId, hideModal } = props;
          if (memo) {
            await updateMemo({
              variables: {
                input: {
                  memoId: values.id,
                  attributes: {
                    body: values.body,
                    alertAt: values.alertAt,
                    color: values.color
                  }
                }
              }
            });
          } else {
            await createMemo({ variables: { input: { employeeId, attributes: { ...values } } } });
          }
          hideModal();
        }}
      />
    );
  },
  reduxForm({ form: 'memoEdit' })
)(({ handleSubmit, hideModal, memo, submitting }) => {
  return (
    <Modal isOpen portalClassName="ReactModalPortal">
      <Modal.Header hideModal={hideModal}>{memo ? 'メモを更新' : 'メモを新規作成'}</Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <RadioField name="color" options={MEMO_COLOR_OPTIONS} label="メモカラー" />
          <TextAreaField name="body" />
          <div className={styles.dateWrap}>
            <DateField name="alertAt" label="アラート設定" />
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Buttons>
          <Button onClick={hideModal}>キャンセル</Button>
          <Button primary onClick={handleSubmit} disabled={submitting}>
            保存する
          </Button>
        </Modal.Buttons>
      </Modal.Footer>
    </Modal>
  );
});

const DeleteModal = withModal(({ isModalOpen, hideModal, showModal, children, memo }) => {
  const [deleteMemo, { loading }] = useMutation(DELETE_MEMO);

  return (
    <>
      {children({ showModal })}
      {isModalOpen && (
        <Modal isOpen portalClassName="ReactModalPortal ignore-react-onclickoutside">
          <Modal.Header hideModal={hideModal}>削除の確認</Modal.Header>
          <Modal.Body>メモを削除しますか</Modal.Body>
          <Modal.Footer>
            <Modal.Buttons>
              <Button onClick={hideModal}>いいえ</Button>
              <Button
                primary
                onClick={async () => {
                  await deleteMemo({ variables: { input: { id: memo.id } } });
                  hideModal();
                }}
                disabled={loading}
              >
                はい
              </Button>
            </Modal.Buttons>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
});

const MemoList = ({ edit, memos }) => (
  <div className={styles.list}>
    {memos.map(memo => (
      <div key={memo.id} className={classnames(styles.row, styles[`memo_border_BG_default_${memo.color}`])}>
        <div className={styles.memoHead}>
          <div className={styles.user}>{memo.createdUser?.name}</div>
          <div className={styles.action}>
            <div className={styles.tools}>
              <div role="button" className={styles.tool} onClick={edit(memo)} onKeyPress={edit(memo)} tabIndex={0}>
                <EditSquare />
              </div>
              <DeleteModal memo={memo}>
                {({ showModal }) => (
                  <div role="button" onClick={showModal} onKeyPress={showModal} className={styles.tool} tabIndex={0}>
                    <DeleteSquare />
                  </div>
                )}
              </DeleteModal>
            </div>
          </div>
        </div>
        <div className={styles.memoBody}>{memo.body}</div>
        <div className={styles.memoFooter}>
          <div className={styles.info}>アラート予定日: {memo.alertAt ? moment(memo.alertAt).format('YYYY/MM/DD') : '未設定'}</div>
          <div className={styles.info}>最終更新日時: {moment(memo.updatedAt).format('YYYY/MM/DD HH:mm')}</div>
          <div className={styles.info}>最終更新者: {memo.lastUpdatedUser ? memo.lastUpdatedUser.name : 'ー'}</div>
        </div>
      </div>
    ))}
  </div>
);

const MemoDetailContent = ({ employee, setIsOpen, setMemoEdit, className }) => {
  const [minHeight, setMinHeight] = useState(230);

  const contentRef = useRef(null);
  const rndRef = useRef(null);

  const edit = memo => () => {
    setMemoEdit({ currentMemo: memo, isOpen: true });
  };

  const handleResize = () => {
    const contentElement = contentRef.current;
    if (contentElement) {
      const computedStyle = window.getComputedStyle(contentElement);
      const padding = parseInt(computedStyle.paddingTop) + parseInt(computedStyle.paddingBottom);
      const newHeight = contentElement.scrollHeight + padding;
      setMinHeight(newHeight);
    }
  };

  const { loading, data } = useQuery(FETCH_MEMOS, { variables: { employeeId: employee.id } });
  const memos = _.get(data, 'client.clientYearly.employee.memos', []);
  const oneMemos = memos.length < 2;
  const mq = useMediaQuery();
  const maxWidth = mq.isMobile ? 350 : 1220;

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (!loading && memos.length > 0 && rndRef.current) {
      let widthSet = '620px';
      if (memos.length === 1) {
        widthSet = '340px';
      }
      rndRef.current.updateSize({ width: widthSet });
    }
  }, [loading, memos]);

  const memoDetailContentClassNames = classnames(
    styles.memoRndContent,
    {
      [styles.oneMemos]: oneMemos,
      [styles.draggable]: true
    },
    className
  );

  const commonContent = (
    <div className={mq.isMobile ? styles.memoContentWrap : null}>
      <div className={styles.title}>
        <span>メモ</span>
        <Close className="u-cur-pointer cancel" onClick={() => setIsOpen(false)} />
      </div>
      <div ref={contentRef} className={classnames(styles.content, 'cancel')}>
        {loading ? (
          <Loading />
        ) : (
          memos.length > 0 && (
            <>
              <MemoList edit={edit} memos={memos} />
              <div className={`l-flex ${styles.footer}`}>
                <ActionButton onClick={() => setMemoEdit({ isOpen: true, currentMemo: null })}>メモを追加</ActionButton>
              </div>
            </>
          )
        )}
      </div>
    </div>
  );

  return mq.isMobile ? (
    commonContent
  ) : (
    <Rnd
      ref={rndRef}
      default={{
        x: 40,
        y: 30,
        width: '340px',
        height: 'auto'
      }}
      className={memoDetailContentClassNames}
      minWidth={340}
      maxWidth={maxWidth}
      minHeight={minHeight}
      onResize={handleResize}
      bounds="#root"
      cancel=".cancel"
    >
      {commonContent}
    </Rnd>
  );
};

const MemoDetail = onClickOutside()(MemoDetailContent);

export const Memo = ({ employee, size, className, onClickOutside }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [memoEdit, setMemoEdit] = useState(initialState);
  const postit =
    employee.memos.length > 0 ? (
      <PostitOn className={styles[`memo_${employee.memos.slice(-1)[0].color}`]} size={size} />
    ) : (
      <Postit size={size} />
    );
  const open = e => {
    e.stopPropagation();
    if (employee.memos.length > 0) {
      setIsOpen(!isOpen);
    } else {
      setIsOpen(true);
      setMemoEdit({ isOpen: true, currentMemo: null, employeeId: employee.id });
    }
  };

  const handleClickOutside = () => {
    if (onClickOutside === false) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  };

  return (
    <div className={styles.comments}>
      <div role="button" className={isOpen ? 'ignore-react-onclickoutside' : ''} onClick={open} onKeyPress={open} tabIndex={0}>
        <span className={styles.postit}>{postit}</span>
      </div>
      {isOpen &&
        employee.memos.length > 0 &&
        (memoEdit.isOpen ? (
          <MemoDetailContent {...{ employee, setIsOpen, setMemoEdit, className }} />
        ) : (
          <MemoDetail {...{ employee, setIsOpen, setMemoEdit, className, handleClickOutside }} />
        ))}
      {memoEdit.isOpen && (
        <MemoEdit
          employeeId={employee.id}
          memo={memoEdit.currentMemo}
          hideModal={() => {
            setMemoEdit(initialState);
          }}
        />
      )}
    </div>
  );
};

export default Memo;
