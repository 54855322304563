import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { Employee } from 'adminPages/employees/show/query';
import { FLAT_TAX_REDUCTION_PER_PERSON } from 'adminPages/employees/show/consts';
import { InputWithText } from 'components/form/InputWithText';
import { Section } from 'components/ui/Section';
import { Schema } from './schema';
import { amountFormat, displayFormat } from '@jbc-year-end-adj/common/utils/formatter';
import styles from './ReductionAmount.scss';
import { HoverDescriptionContent } from '../Preview/ReductionAmount';

type ReductionAmountProps = {
  employee: Employee;
};

const validAmount = (value: number | string): boolean => {
  value = Number(value);
  return Number.isInteger(value) && value >= 0 && value <= 99;
};

export const ReductionAmount: FC<ReductionAmountProps> = ({ employee }) => {
  const {
    formState: { errors },
    register,
    watch
  } = useFormContext<Schema>();
  const hasReduceTargetCountFromPayroll = employee.reduceTargetCountFromPayroll !== null;
  const reduceTargetCount = watch('reduceTargetCount');
  const amount = (() => {
    if (validAmount(reduceTargetCount)) {
      return amountFormat(String(reduceTargetCount * FLAT_TAX_REDUCTION_PER_PERSON)) + '円';
    } else {
      return displayFormat();
    }
  })();

  return (
    <Section>
      <Section.Header>年調減税額</Section.Header>
      <Section.Body>
        <div className="l-overflow-scroll">
          <table className="m-table-list">
            <thead>
              <tr className={styles.titleTh}>
                <th className={styles.invisible}></th>
                <th>ジョブカン給与計算の確定情報</th>
                <th>年末調整時点</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th className={styles.targetTh}>減税対象人数</th>
                {hasReduceTargetCountFromPayroll ? (
                  <td>{employee.reduceTargetCountFromPayroll}名</td>
                ) : (
                  <td className={styles.noPayroll}>
                    <HoverDescriptionContent />
                  </td>
                )}
                <td>
                  <InputWithText
                    className={styles.input}
                    error={errors.reduceTargetCount && errors.reduceTargetCount?.message}
                    isError={errors.reduceTargetCount && !!errors.reduceTargetCount?.message}
                    {...register('reduceTargetCount')}
                    text="名"
                  />
                </td>
              </tr>
              <tr>
                <th className={styles.amountTh}>
                  月次減税額
                  <br />
                  年調減税額
                </th>
                {hasReduceTargetCountFromPayroll ? (
                  <td>{amountFormat(String(employee.reduceTargetCountFromPayroll! * FLAT_TAX_REDUCTION_PER_PERSON))}円</td>
                ) : (
                  <td className={styles.noPayroll}>
                    <HoverDescriptionContent />
                  </td>
                )}
                <td>{amount}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </Section.Body>
    </Section>
  );
};
