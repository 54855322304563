import { FC } from 'react';
import { dateFormat, amountFormat } from '@jbc-year-end-adj/common/utils/formatter';
import { Dl, Dt, Dd, FileDd, FileDl } from '../../../components/DefinitionList';
import { Grid } from '../../../components/Grid';
import { PreviewSubSection } from '../../../components/PreviewSubSection';
import { WITHHOLDING_SLIP_STATUS } from '../../../consts';
import { useEmployeeInfo } from '../EmployeeInfoProvider';
import { Hint } from 'jbc-front/components/presenters/ui/Hint';
import styles from './Preview.scss';

export const Preview: FC = () => {
  const { employee } = useEmployeeInfo();
  const profile = employee.profile;

  return (
    <Grid colGap>
      {profile.formerJobs.length > 0 ? (
        profile.formerJobs.map((formerJob, index) => (
          <PreviewSubSection key={index}>
            <PreviewSubSection.Header>前職（{index + 1}）</PreviewSubSection.Header>
            <PreviewSubSection.Body>
              <Dl>
                <Dt>会社名</Dt>
                <Dd>{formerJob.companyName}</Dd>
              </Dl>

              <Dl>
                <Dt>住所</Dt>
                <Dd>{formerJob.address}</Dd>
              </Dl>

              <Dl>
                <Dt>退職日</Dt>
                <Dd>{dateFormat(formerJob.resignedOn, 'L')}</Dd>
              </Dl>

              <Dl>
                <Dt>最終給与支払日</Dt>
                <Dd>{dateFormat(formerJob.lastSalaryOn, 'L')}</Dd>
              </Dl>

              <Dl>
                <Dt>源泉徴収票</Dt>
                <Dd>{WITHHOLDING_SLIP_STATUS[formerJob.withholdingSlipStatus]}</Dd>
              </Dl>

              <FileDl>
                <Dt>源泉徴収票画像</Dt>
                <FileDd file={formerJob.withholdingSlipImage} />
              </FileDl>

              <Dl>
                <Dt className={styles.amountHeader}>
                  支払金額
                  <Hint>
                    この項目に入力した金額は源泉徴収票に反映されます。
                    <br />
                    源泉徴収票編集画面で前職分の課税支払額の情報を登録した場合はこの項目に反映されません。
                  </Hint>
                </Dt>
                <Dd>{amountFormat(formerJob.paymentAmount)}</Dd>
              </Dl>

              <Dl>
                <Dt className={styles.amountHeader}>
                  社会保険料等の金額
                  <Hint>
                    この項目に入力した金額は源泉徴収票に反映されます。
                    <br />
                    源泉徴収票編集画面で前職分の社会保険料控除額の情報を登録した場合はこの項目に反映されません。
                  </Hint>
                </Dt>
                <Dd>{amountFormat(formerJob.deductionAmount)}</Dd>
              </Dl>

              <Dl>
                <Dt className={styles.amountHeader}>
                  源泉徴収税額
                  <Hint>
                    この項目に入力した金額は源泉徴収票に反映されます。
                    <br />
                    源泉徴収票編集画面で前職分の源泉徴収額（所得税）の情報を登録した場合はこの項目に反映されません。
                  </Hint>
                </Dt>
                <Dd>{amountFormat(formerJob.taxAmount)}</Dd>
              </Dl>
            </PreviewSubSection.Body>
          </PreviewSubSection>
        ))
      ) : (
        <p>前職情報が存在しません。</p>
      )}
    </Grid>
  );
};
