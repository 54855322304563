import { FC, useEffect, useState } from 'react';
import useRouter from 'use-react-router';

// @ts-ignore
import { Redirect } from 'react-router';
// @ts-ignore
import { parse } from 'query-string';

import { year } from '@jbc-year-end-adj/2024/consts';
import { useAdminQuery as useQuery } from '@jbc-year-end-adj/2024/hooks/graphql/useQuery';
import { Sidebar } from '@jbc-year-end-adj/2024/features/questions/components/Sidebar';
import { findQuestion } from '@jbc-year-end-adj/2024/features/questions/utils';
import { Container, Caption, Progress } from '@jbc-year-end-adj/2024/features/questions/components/ProgressBar';
import { currentQuestionName, totalQuestionsCount } from '@jbc-year-end-adj/2024/features/questions/components/ProgressBar/utils';

import { Question } from './parts/Question';
import { FETCH_QUESTION_GROUPS, QueryResult } from './query';
import { Loading } from 'jbc-front/components/presenters/ui/Loading';
import styles from './QuestionDescription.scss';

export const QuestionDescription: FC = () => {
  const {
    location: { search }
  } = useRouter();
  const { current } = parse(search);

  const { data, loading } = useQuery<QueryResult>(FETCH_QUESTION_GROUPS, { fetchPolicy: 'network-only' });
  const [currentCode, setCurrentCode] = useState<string>();

  useEffect(() => {
    if (typeof current === 'string') {
      setCurrentCode(current);
    }
  }, [current]);

  if (loading) return <Loading />;
  if (!currentCode) return <Redirect to={`/${year}/settings/question_descriptions?current=start`} />;
  if (data?.questionGroups === undefined) throw Error;

  const {
    questionGroups,
    client: {
      clientYearly: { office }
    }
  } = data;
  const total = totalQuestionsCount(questionGroups);
  const currentQuestion = findQuestion(questionGroups, currentCode);

  if (currentQuestion === undefined) throw Error;

  const name = currentQuestionName(questionGroups, currentQuestion);
  const answeredCount = questionGroups
    .map(group => group.questions)
    .flatMap(questions => questions)
    .findIndex(question => question.code === currentQuestion.code);

  const handleChangeCode = (code: string) => {
    setCurrentCode(code);
    window.scrollTo(0, 0);
  };

  return (
    <div>
      <div className="l-main-title-wrap">
        <h2 className="m-title-main">説明文カスタマイズ</h2>
        <p className="m-title-main-note">1画面ごとに自動保存されており入力途中から再開が可能です</p>
      </div>

      <div className="l-contents-wrap">
        <div className="l-wrap-xl">
          {currentQuestion && (
            <div className={styles.progress}>
              <Container>
                <Caption name={name} answeredCount={answeredCount} total={total} />
                <Progress answeredCount={answeredCount} total={total} />
              </Container>
            </div>
          )}

          <div className={styles.contentContainer}>
            <div className={styles.aside}>
              <Sidebar
                currentQuestionCode={currentQuestion.code}
                questionGroups={data.questionGroups}
                onChangeCode={handleChangeCode}
                forceOpen
                forceClickable
                allActive
              />
            </div>

            <div className={styles.main}>
              <Question questionGroups={questionGroups} code={currentCode} office={office} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
