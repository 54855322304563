import { FC } from 'react';
import { yup } from '@jbc-year-end-adj/common/yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler } from 'react-hook-form';

import { Modal } from 'jbc-front/components/presenters/ui/Modal';
import Button from 'jbc-front/components/Button';
import { Spacer } from 'jbc-front/components/presenters/Spacer';

import { Textarea } from 'components/form/Textarea';
import { REJECT } from './mutation';
import { useResult } from '../ResultProvider';
import { FETCH_EMPLOYEE } from '../../../query';

import { useNotify } from '@jbc-year-end-adj/common/hooks/useNotify';
// @ts-ignore
import { useMutation } from 'components/Graphql';

type RejectModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

type Schema = {
  comment?: string;
};

export const RejectModal: FC<RejectModalProps> = ({ isOpen, onClose }) => {
  const { employee } = useResult();
  const [reflect, { loading }] = useMutation(REJECT);
  const notify = useNotify();

  const schema = yup.object({
    comment: yup.string().max(2000, 'コメントは2000文字以内で入力してください')
  });

  const {
    formState: { errors },
    handleSubmit,
    register
  } = useForm<Schema>({
    defaultValues: { comment: '' },
    resolver: yupResolver(schema),
    mode: 'onChange'
  });

  const onSubmit: SubmitHandler<Schema> = async (data: Schema) => {
    await reflect({
      variables: {
        input: {
          employeeId: String(employee.id),
          comment: data.comment
        }
      },
      refetchQueries: [FETCH_EMPLOYEE]
    });
    onClose();
    notify('修正依頼が完了しました', 'success');
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal isOpen={isOpen} onClose={onClose}>
        <Modal.Header onClose={onClose}>修正依頼</Modal.Header>
        <Modal.Body>
          <Textarea label="コメント" error={errors.comment?.message} isError={!!errors.comment?.message} {...register('comment')} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onClose} disabled={loading}>
            キャンセル
          </Button>
          <Spacer direction="x" size={20} />
          <Button primary disabled={loading} onClick={handleSubmit(onSubmit)}>
            修正依頼
          </Button>
        </Modal.Footer>
      </Modal>
    </form>
  );
};
