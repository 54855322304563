import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { Row } from 'components/form/Row';
import { Input } from 'components/form/Input';
import { Radio } from 'components/form/Radio';
import { FileField } from 'components/react-hook-form/FileField';
import { DeletableSection } from '../../../../components/DeletableSection';
import { FormSection } from 'components/form/FormSection';
import { Schema } from './schema';
import { FilePreview } from 'components/feature/FilePreview';
import { AmountField } from 'components/react-hook-form/AmountField';
import { useYear } from 'hooks/useYear';

type EarthquakeInsuranceType = {
  index: number;
  onDelete: () => void;
};

export const EarthquakeInsurance: FC<EarthquakeInsuranceType> = ({ index, onDelete }) => {
  const year = useYear();
  const {
    formState: { errors },
    watch,
    register,
    setValue
  } = useFormContext<Schema>();

  const image = watch(`earthquakeInsurances.${index}.image`);

  return (
    <DeletableSection>
      <DeletableSection.Header onDelete={onDelete}>地震保険（{index + 1}）</DeletableSection.Header>
      <DeletableSection.Body>
        <FormSection>
          <Row>
            <Input
              label="保険会社等の名称"
              required
              placeholder="例：××火災、××損保"
              {...register(`earthquakeInsurances.${index}.name`)}
              error={errors.earthquakeInsurances && errors.earthquakeInsurances[index]?.name?.message}
              isError={errors.earthquakeInsurances && !!errors.earthquakeInsurances[index]?.name?.message}
            />
            <Input
              label="保険等の種類（目的）"
              required
              note="保険料控除証明書に記載された保険種類を記入してください"
              noteMaxLength={8}
              placeholder="例：地震、積立傷害　等"
              {...register(`earthquakeInsurances.${index}.insuranceType`)}
              error={errors.earthquakeInsurances && errors.earthquakeInsurances[index]?.insuranceType?.message}
              isError={errors.earthquakeInsurances && !!errors.earthquakeInsurances[index]?.insuranceType?.message}
            />
          </Row>
          <Row>
            <Input
              label="保険期間"
              required
              note="保険料控除証明書に記載された保険期間を記入してください"
              noteMaxLength={8}
              placeholder="例：1年、5年　等"
              {...register(`earthquakeInsurances.${index}.insuranceDuration`)}
              error={errors.earthquakeInsurances && errors.earthquakeInsurances[index]?.insuranceDuration?.message}
              isError={errors.earthquakeInsurances && !!errors.earthquakeInsurances[index]?.insuranceDuration?.message}
            />
            <Input
              label="保険等の契約者の氏名"
              required
              note="保険料控除証明書に記載された契約者の氏名を記入してください"
              noteMaxLength={8}
              placeholder="例：ジョブカン　太朗"
              {...register(`earthquakeInsurances.${index}.nameOfContractor`)}
              error={errors.earthquakeInsurances && errors.earthquakeInsurances[index]?.nameOfContractor?.message}
              isError={errors.earthquakeInsurances && !!errors.earthquakeInsurances[index]?.nameOfContractor?.message}
            />
          </Row>
          <Row>
            <Input
              label="保険等の受取人 氏名"
              required
              note="保険料控除証明書に記載された受給人の氏名を記入してください"
              noteMaxLength={8}
              placeholder="例：ジョブカン　花子"
              {...register(`earthquakeInsurances.${index}.nameOfRecipient`)}
              error={errors.earthquakeInsurances && errors.earthquakeInsurances[index]?.nameOfRecipient?.message}
              isError={errors.earthquakeInsurances && !!errors.earthquakeInsurances[index]?.nameOfRecipient?.message}
            />
            {year <= 2023 ? (
              <Input
                label="保険等の受取人 続柄"
                required
                note="保険料控除証明書に記載された受給人と本人の関係を記入してください。受給人が本人の場合、「本人」を記入してください。"
                noteMaxLength={8}
                placeholder="例：本人、妻　等"
                {...register(`earthquakeInsurances.${index}.relationshipOfRecipient`)}
                error={errors.earthquakeInsurances && errors.earthquakeInsurances[index]?.relationshipOfRecipient?.message}
                isError={errors.earthquakeInsurances && !!errors.earthquakeInsurances[index]?.relationshipOfRecipient?.message}
              />
            ) : (
              <div style={{ width: '100%' }} />
            )}
          </Row>
          <Radio.Container
            label="保険料区分"
            required
            note="保険料控除証明書の表記をご確認ください"
            error={errors.earthquakeInsurances && errors.earthquakeInsurances[index]?.newOrOld?.message}
          >
            <Radio {...register(`earthquakeInsurances.${index}.newOrOld`)} value="new_type">
              地震
            </Radio>
            <Radio {...register(`earthquakeInsurances.${index}.newOrOld`)} value="old_type">
              旧長期
            </Radio>
          </Radio.Container>
          <AmountField
            label="保険料金額"
            required
            placeholder="例：100000"
            {...register(`earthquakeInsurances.${index}.amount`)}
            error={errors.earthquakeInsurances && errors.earthquakeInsurances[index]?.amount?.message}
            isError={errors.earthquakeInsurances && !!errors.earthquakeInsurances[index]?.amount?.message}
          />
          <FileField
            label="証明書画像"
            note="管理者が確認できるよう保険料控除証明書の画像（対応可能形式：jpg, jpeg, gif, png, pdf）をアップロードしてください。原本は必ず会社に提出してください。"
            noteMaxLength={19}
            name={`earthquakeInsurances.${index}.image`}
          >
            {image && (
              <FileField.FileContainer>
                <FileField.File file={image} onRemove={() => setValue(`earthquakeInsurances.${index}.image`, null)} />
                <FilePreview file={image} />
              </FileField.FileContainer>
            )}
          </FileField>
        </FormSection>
      </DeletableSection.Body>
    </DeletableSection>
  );
};
