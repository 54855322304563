import { FC, ComponentProps } from 'react';
import { Grid } from '../../../components/Grid';
import { Diff, FileDiff } from '../../../components/Diff';
import { Dl, Dt, Dd, FileDd, FileDl } from '../../../components/DefinitionList';
import { useResult } from '../ResultProvider';
import { FormerJob } from '../../../query';
import { amountFormat, dateFormat } from '@jbc-year-end-adj/common/utils/formatter';
import { WITHHOLDING_SLIP_STATUS } from '../../../consts';
import { PreviewSubSection } from '../../../components/PreviewSubSection';
import { Tag } from '../../../components/Tag';
import styles from './Preview.scss';
import { Hint } from 'jbc-front/components/presenters/ui/Hint';

type DiffType = {
  type: 'add' | 'update' | 'delete';
  source: FormerJob | undefined;
  input: FormerJob | undefined;
};

export const Preview: FC = () => {
  const { employee } = useResult();
  const profile = employee.profile;
  const requestProfile = employee.request?.profile;

  // compareKeyが一致するもの || profileにしかないもの
  const formerJobDiff = profile.formerJobs.reduce<DiffType[]>((diff, source) => {
    const input = requestProfile?.formerJobs.find(formerJob => formerJob.compareKey === source.compareKey);
    const type = input ? 'update' : 'delete';
    diff.push({ type, source, input });

    return diff;
  }, []);

  // requestにしかないもの
  requestProfile?.formerJobs.forEach(requestFormerJob => {
    const index = profile.formerJobs.findIndex(formerJob => formerJob.compareKey === requestFormerJob.compareKey);
    if (index === -1) {
      formerJobDiff.push({
        type: 'add',
        source: undefined,
        input: requestFormerJob
      });
    }
  });

  if (formerJobDiff.length === 0) {
    return <p>前職情報は存在しません。</p>;
  }

  return (
    <Grid>
      {formerJobDiff.map((diff, index) => (
        <>
          {diff.type === 'add' && <CreateOrDeleteFormerJobSection key={index} index={index + 1} formerJob={diff.input!} type={'add'} />}
          {diff.type === 'delete' && (
            <CreateOrDeleteFormerJobSection key={index} index={index + 1} formerJob={diff.source!} type={'delete'} />
          )}
          {diff.type === 'update' && <UpdateFormerJobSection key={index} index={index + 1} diff={diff} />}
        </>
      ))}
    </Grid>
  );
};

type UpdateFormerJobSectionProps = {
  index: number;
  diff: DiffType;
};

const UpdateFormerJobSection: FC<UpdateFormerJobSectionProps> = ({ index, diff }) => {
  const withholdingSlipStatusDiffProps = (diff: DiffType) => {
    const props: ComponentProps<typeof Diff> = {
      title: '源泉徴収票',
      source: undefined,
      input: undefined
    };

    if (diff.source?.withholdingSlipStatus) {
      props.source = WITHHOLDING_SLIP_STATUS[diff.source.withholdingSlipStatus];
    }

    if (diff.input?.withholdingSlipStatus) {
      props.input = WITHHOLDING_SLIP_STATUS[diff.input.withholdingSlipStatus];
    }

    return props;
  };

  return (
    <PreviewSubSection key={index}>
      <PreviewSubSection.Header>前職（{index}）</PreviewSubSection.Header>
      <PreviewSubSection.Body>
        <Diff title="会社名" source={diff.source?.companyName} input={diff.input?.companyName} />

        <Diff title="住所" source={diff.source?.address} input={diff.input?.address} />

        <Diff title="退職日" source={dateFormat(diff.source?.resignedOn, 'L')} input={dateFormat(diff.input?.resignedOn, 'L')} />

        <Diff
          title="最終給与支払日"
          source={dateFormat(diff.source?.lastSalaryOn, 'L')}
          input={dateFormat(diff.input?.lastSalaryOn, 'L')}
        />

        <Diff {...withholdingSlipStatusDiffProps(diff)} />

        <FileDiff title="源泉徴収票画像" source={diff.source?.withholdingSlipImage} input={diff.input?.withholdingSlipImage} />

        <Diff
          title={
            <div className={styles.updateAmountHeader}>
              支払金額
              <Hint>
                この項目に入力した金額は源泉徴収票に反映されます。
                <br />
                源泉徴収票編集画面で前職分の課税支払額の情報を登録した場合はこの項目に反映されません。
              </Hint>
            </div>
          }
          source={amountFormat(diff.source?.paymentAmount)}
          input={amountFormat(diff.input?.paymentAmount)}
        />

        <Diff
          title={
            <div className={styles.updateAmountHeader}>
              社会保険料等の金額
              <Hint>
                この項目に入力した金額は源泉徴収票に反映されます。
                <br />
                源泉徴収票編集画面で前職分の社会保険料控除額の情報を登録した場合はこの項目に反映されません。
              </Hint>
            </div>
          }
          source={amountFormat(diff.source?.deductionAmount)}
          input={amountFormat(diff.input?.deductionAmount)}
        />

        <Diff
          title={
            <div className={styles.updateAmountHeader}>
              源泉徴収税額
              <Hint>
                この項目に入力した金額は源泉徴収票に反映されます。
                <br />
                源泉徴収票編集画面で前職分の源泉徴収額（所得税）の情報を登録した場合はこの項目に反映されません。
              </Hint>
            </div>
          }
          source={amountFormat(diff.source?.taxAmount)}
          input={amountFormat(diff.input?.taxAmount)}
        />
      </PreviewSubSection.Body>
    </PreviewSubSection>
  );
};

type AddFormerJobProps = {
  index: number;
  formerJob: FormerJob;
  type: 'add' | 'delete';
};

const CreateOrDeleteFormerJobSection: FC<AddFormerJobProps> = ({ index, formerJob, type }) => {
  return (
    <PreviewSubSection>
      <PreviewSubSection.Header>
        前職（{index}）{type === 'add' && <Tag color="blue">追加</Tag>}
        {type === 'delete' && <Tag color="red">削除</Tag>}
      </PreviewSubSection.Header>
      <PreviewSubSection.Body>
        <Dl>
          <Dt>会社名</Dt>
          <Dd>{formerJob.companyName}</Dd>
        </Dl>

        <Dl>
          <Dt>住所</Dt>
          <Dd>{formerJob.address}</Dd>
        </Dl>

        <Dl>
          <Dt>退職日</Dt>
          <Dd>{dateFormat(formerJob.resignedOn, 'L')}</Dd>
        </Dl>

        <Dl>
          <Dt>最終給与支払日</Dt>
          <Dd>{dateFormat(formerJob.lastSalaryOn, 'L')}</Dd>
        </Dl>

        <Dl>
          <Dt>源泉徴収票</Dt>
          <Dd>{WITHHOLDING_SLIP_STATUS[formerJob.withholdingSlipStatus]}</Dd>
        </Dl>

        <FileDl>
          <Dt>源泉徴収票画像</Dt>
          <FileDd file={formerJob.withholdingSlipImage} />
        </FileDl>

        <Dl>
          <Dt className={styles.createAmountHeader}>
            支払金額
            <Hint>
              この項目に入力した金額は源泉徴収票に反映されます。
              <br />
              源泉徴収票編集画面で前職分の課税支払額の情報を登録した場合はこの項目に反映されません。
            </Hint>
          </Dt>
          <Dd>{amountFormat(formerJob.paymentAmount)}</Dd>
        </Dl>

        <Dl>
          <Dt className={styles.createAmountHeader}>
            社会保険料等の金額
            <Hint>
              この項目に入力した金額は源泉徴収票に反映されます。
              <br />
              源泉徴収票編集画面で前職分の社会保険料控除額の情報を登録した場合はこの項目に反映されません。
            </Hint>
          </Dt>
          <Dd>{amountFormat(formerJob.deductionAmount)}</Dd>
        </Dl>

        <Dl>
          <Dt className={styles.createAmountHeader}>
            源泉徴収税額
            <Hint>
              この項目に入力した金額は源泉徴収票に反映されます。
              <br />
              源泉徴収票編集画面で前職分の源泉徴収額（所得税）の情報を登録した場合はこの項目に反映されません。
            </Hint>
          </Dt>
          <Dd>{amountFormat(formerJob.taxAmount)}</Dd>
        </Dl>
      </PreviewSubSection.Body>
    </PreviewSubSection>
  );
};
