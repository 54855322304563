import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { autofill, getFormValues, reset, getFormMeta } from 'redux-form';
import AdditionalSearchFields from './AdditionalSearchFields';
import SortingFields from './SortingFields';
import styles from './SearchForm.scss';
import { MainFormControls } from 'jbc-front/components/SearchForm';
import {
  convertFormToQueryString,
  convertQueryToForm,
  getCurrentQueryFromLocation,
  saveDisplayEmployeeLimit,
  hasConditions
} from './utils';
import compose from 'lodash/fp/compose';
import moment from 'moment';
import { useFormName, reduxForm, useSession, searchDetector } from '../components';
import useReactRouter from 'use-react-router';

const SearchForm = ({ handleSubmit, statusField }) => {
  const [isExpand, setIsExpand] = useState(false);
  const dispatch = useDispatch();
  const { formName } = useFormName();
  const { location, history } = useReactRouter();
  const clearForm = () => {
    dispatch(reset(formName));
    history.push({ ...location, search: '' });
  };
  const formValues = useSelector(getFormValues(formName));
  const {
    location: { pathname }
  } = useReactRouter();
  const { me } = useSession();

  return (
    <div className={styles.searchForm}>
      <form onSubmit={handleSubmit}>
        <div className={styles.mainSearchFields}>
          {statusField ? statusField(handleSubmit) : <div />}
          <MainFormControls
            isExpand={isExpand}
            hasConditions={hasConditions(formValues)}
            handleClear={clearForm}
            onToggleClick={() => {
              setIsExpand(isExpand => !isExpand);
            }}
          />
        </div>
        {isExpand && <AdditionalSearchFields />}
        <div className={styles.sortingFieldsContainer}>
          <SortingFields
            onChangeLimit={newLimit => {
              saveDisplayEmployeeLimit(pathname, me, newLimit);
              handleSubmit();
            }}
            onChangeSortType={handleSubmit}
            sortName="sortType"
          />
        </div>
      </form>
    </div>
  );
};

const validate = values => {
  const errors = {};
  if (values.age_from && values.age_to && +values.age_from > +values.age_to) {
    errors.age_to = errors.age_from = 'の範囲が正しくありません';
  }
  ['joined_at', 'retired_at', 'birthday'].forEach(prefix => {
    if (
      [values[`${prefix}_start`], values[`${prefix}_end`]].every(date => date && moment(date, 'YYYY/MM/DD').isValid()) &&
      moment(values[`${prefix}_start`], 'YYYY/MM/DD').diff(moment(values[`${prefix}_end`], 'YYYY/MM/DD'), 'days') > 0
    ) {
      errors[`${prefix}_start`] = errors[`${prefix}_end`] = 'の範囲が正しくありません';
    }
  });
  return errors;
};

export default formName =>
  compose(
    searchDetector({ convertFormToQueryString, convertQueryToForm, getCurrentQueryFromLocation }),
    reduxForm({
      form: formName,
      enableReinitialize: true,
      validate,
      onSubmit(values, dispatch, { handleSearch }) {
        dispatch((dispatch, getState) => {
          const autofilled = getFormMeta(formName)(getState())?.page?.autofilled;
          if (!autofilled) {
            dispatch(autofill(formName, 'page', 1));
          }
          handleSearch(getFormValues(formName)(getState()));
        });
      }
    })
  )(SearchForm);
