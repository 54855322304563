import { FC } from 'react';
import { QuestionGroup, Office } from '@jbc-year-end-adj/2024/features/questions/types';
import { findQuestion } from '@jbc-year-end-adj/2024/features/questions/utils';

import {
  Start,
  ResignInYear,
  MultiCompanies,
  SalaryMore,
  SalaryMoreNext,
  BasicInfos,
  Householder,
  FormerJobs,
  Income,
  SalaryIncome,
  OtherIncomeType,
  AllIncome,
  WorkingStudent,
  Handicap,
  Widow,
  Spouse,
  HasDependent,
  Dependents,
  HasInsurances,
  LifeInsurances,
  EarthquakeInsurances,
  SocialInsurances,
  Premium,
  HousingLoan,
  Attachments,
  SpouseLivingStatus
} from './Questions';

type QuestionProps = {
  code: string;
  questionGroups: QuestionGroup[];
  office: Office;
};

export const Question: FC<QuestionProps> = ({ questionGroups, code, office }) => {
  const question = findQuestion(questionGroups, code);

  if (!question) throw Error;

  switch (code) {
    case 'start':
      return <Start question={question} />;
    case 'resign_in_year':
      return <ResignInYear question={question} />;
    case 'multi_companies':
      return <MultiCompanies question={question} office={office} />;
    case 'salary_more':
      return <SalaryMore question={question} office={office} />;
    case 'salary_more_next':
      return <SalaryMoreNext question={question} office={office} />;
    case 'basic_infos':
      return <BasicInfos question={question} />;
    case 'householder':
      return <Householder question={question} />;
    case 'former_jobs':
      return <FormerJobs question={question} />;
    case 'income':
      return <Income question={question} />;
    case 'salary_income':
      return <SalaryIncome question={question} />;
    case 'other_income_type':
      return <OtherIncomeType question={question} />;
    case 'all_income':
      return <AllIncome question={question} />;
    case 'working_student':
      return <WorkingStudent question={question} />;
    case 'handicap':
      return <Handicap question={question} />;
    case 'widow':
      return <Widow question={question} />;
    case 'spouse_living_status':
      return <SpouseLivingStatus question={question} />;
    case 'spouse':
      return <Spouse question={question} />;
    case 'has_dependent':
      return <HasDependent question={question} />;
    case 'dependents':
      return <Dependents question={question} />;
    case 'has_insurances':
      return <HasInsurances question={question} />;
    case 'life_insurances':
      return <LifeInsurances question={question} />;
    case 'earthquake_insurances':
      return <EarthquakeInsurances question={question} />;
    case 'social_insurances':
      return <SocialInsurances question={question} />;
    case 'premium':
      return <Premium question={question} />;
    case 'housing_loan':
      return <HousingLoan question={question} />;
    case 'attachments':
      return <Attachments question={question} />;
    default:
      return null;
  }
};
