import { FC } from 'react';
import { gql, useQuery } from '@apollo/client';

import { Note } from 'jbc-front/components/presenters/ui/Note';

import { Section } from 'components/ui/Section';
import { FormSection } from 'components/form/FormSection';
import { Radio } from 'components/form/Radio';
import { Textarea } from 'components/form/Textarea';
import { FileField } from 'components/form/FileField';
import { DifferentAddress } from './DifferentAddress';
import { DifferentAndForeignAddress } from './DifferentAndForeignAddress';
import { Income } from './Income';
import { FilePreview } from 'components/feature/FilePreview';
import { useYear } from 'hooks/useYear';
import { Spouse } from '../../../../query';

const IMAGE_SETTINGS = gql`
  query imageSettings($year: Int!) {
    client {
      clientYearly(year: $year) {
        clientSetting {
          id
          requestImageUploadSettings {
            handicap
          }
        }
      }
    }
  }
`;

type ThisYearProps = {
  spouse?: Spouse;
  isSameHousehold?: boolean;
};

export const ThisYear: FC<ThisYearProps> = ({ spouse, isSameHousehold }) => {
  const year = useYear();

  const { data, loading } = useQuery(IMAGE_SETTINGS, { variables: { year } });

  if (loading) return null;

  const thisYear = spouse?.thisYear;
  const income = spouse?.income;
  const hasSpouseRetirementIncome = income?.retirementIncome ? Number(income.retirementIncome) > 0 : false;

  const {
    client: {
      clientYearly: {
        clientSetting: {
          requestImageUploadSettings: { handicap: handicapImageSetting }
        }
      }
    }
  } = data;

  return (
    <>
      <Section>
        <Section.Header>今年の扶養情報</Section.Header>
        <Section.Body>
          <FormSection>
            <Radio.Container label="今年の源泉控除対象、配偶者特別控除対象、または障害者控除対象" required>
              <Radio checked={thisYear && thisYear.dependentTaxLaw} disabled>
                対象
              </Radio>
              <Radio checked={thisYear && !thisYear.dependentTaxLaw} disabled>
                対象外
              </Radio>
            </Radio.Container>

            {thisYear && !thisYear.dependentTaxLaw && (
              <>
                <Radio.Container label="退職手当を有する配偶者" required>
                  <Radio checked={thisYear && hasSpouseRetirementIncome} disabled>
                    対象
                  </Radio>
                  <Radio checked={thisYear && !hasSpouseRetirementIncome} disabled>
                    対象外
                  </Radio>
                </Radio.Container>
                <Note>
                  扶養控除等(異動)申告書の「退職手当等を有する配偶者・扶養親族」に反映されます。
                  <br />
                  所得税の計算上では扶養対象外でも住民税の計算上は扶養対象となる場合があります。
                </Note>
              </>
            )}

            {thisYear && (thisYear.dependentTaxLaw || hasSpouseRetirementIncome || isSameHousehold) && (
              <>
                <Radio.Container label="同居・別居" required>
                  <Radio checked={thisYear.residenceStatus === 'same_address'} disabled>
                    同居
                  </Radio>
                  <Radio checked={thisYear.residenceStatus === 'different_address'} disabled>
                    別居（国内）
                  </Radio>
                  <Radio checked={thisYear.residenceStatus === 'different_and_foreign_address'} disabled>
                    別居（国外）
                  </Radio>
                </Radio.Container>

                {thisYear.residenceStatus === 'different_address' && <DifferentAddress yearlyInfo={thisYear} />}
                {thisYear.residenceStatus === 'different_and_foreign_address' && <DifferentAndForeignAddress yearlyInfo={thisYear} />}

                <Radio.Container label="障害者区分" required>
                  <Radio checked={thisYear.handicapType === 'none'} disabled>
                    対象外
                  </Radio>
                  <Radio checked={thisYear.handicapType === 'normal'} disabled>
                    一般障害者
                  </Radio>
                  <Radio checked={thisYear.handicapType === 'special'} disabled>
                    特別障害者
                  </Radio>
                </Radio.Container>

                {(thisYear.handicapType === 'normal' || thisYear.handicapType === 'special') && (
                  <>
                    <Textarea
                      label="詳細"
                      note="障害の状態又は交付を受けている手帳などの種類と交付年月日、障害の等級等を記入してください。"
                      value={thisYear.handicapDetail}
                      disabled
                    />

                    {handicapImageSetting !== 'none' && (
                      <FileField label="障害者手帳画像" required={handicapImageSetting === 'required'} disabled>
                        {thisYear.handicapImage && (
                          <FileField.FileContainer>
                            <FileField.File file={thisYear.handicapImage} />
                            <FilePreview file={thisYear.handicapImage} />
                          </FileField.FileContainer>
                        )}
                      </FileField>
                    )}
                  </>
                )}
              </>
            )}
          </FormSection>
        </Section.Body>
      </Section>
      {thisYear && (thisYear.dependentTaxLaw || hasSpouseRetirementIncome || isSameHousehold) && year && (
        <Income spouse={spouse} thisYear={thisYear} />
      )}
    </>
  );
};
