import { FC, useState } from 'react';
import { Employee } from 'adminPages/employees/show/query';
import { FLAT_TAX_REDUCTION_PER_PERSON } from 'adminPages/employees/show/consts';
import { DescriptionContainer, HoverDescription } from 'jbc-front/components/presenters/ui/HoverDescription';
import { Edit as EditIcon } from 'jbc-front/components/icons';
import RollbackActionButton from 'components/RollbackActionButton';
import { Section } from 'components/ui/Section';
import { Variables, DELETE_FLAT_TAX_REDUCTION, convertToVariables } from './mutation';
import { FETCH_EMPLOYEE } from 'adminPages/employees/show/query';
import { amountFormat } from '@jbc-year-end-adj/common/utils/formatter';
import styles from './ReductionAmount.scss';

// @ts-ignore
import { useMutation } from 'components/Graphql';
// @ts-ignore
import { useNotify } from 'actions';

type ReductionAmountProps = {
  employee: Employee;
};

export const ReductionAmount: FC<ReductionAmountProps> = ({ employee }) => {
  const hasReduceTargetCountFromPayroll = employee.reduceTargetCountFromPayroll !== null;
  const shouldCalculate = ['accepted', 'not_need_adj'].includes(employee.status) || employee.isManualTargetCount;
  const reduceTargetCount = shouldCalculate ? employee.reduceTargetCount : 0;
  const [mutation] = useMutation(DELETE_FLAT_TAX_REDUCTION);
  const notify = useNotify();

  const handleRollback = async () => {
    if (!employee.isManualTargetCount) {
      return;
    }

    const variables: Variables = convertToVariables(String(employee.id));
    await mutation({
      variables,
      refetchQueries: [FETCH_EMPLOYEE]
    });
    notify('手入力前に戻しました。', 'success');
  };

  return (
    <Section>
      <Section.Header>年調減税額</Section.Header>
      <Section.Body className={styles.sectionBody}>
        <div className={styles.rollback}>
          <RollbackActionButton onClick={handleRollback} />
        </div>
        <div className="l-overflow-scroll">
          <table className="m-table-list">
            <thead>
              <tr className={styles.titleTh}>
                <th className={styles.invisible}></th>
                <th>ジョブカン給与計算の確定情報</th>
                <th>年末調整時点</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th className={styles.targetTh}>減税対象人数</th>
                {hasReduceTargetCountFromPayroll ? (
                  <td>{employee.reduceTargetCountFromPayroll}名</td>
                ) : (
                  <td className={styles.noPayroll}>
                    <HoverDescriptionContent />
                  </td>
                )}

                <td className={styles.targetTd}>
                  <span>{reduceTargetCount}名</span>
                  <span className={styles.editIcon}>{employee.isManualTargetCount ? <EditIcon /> : undefined}</span>
                </td>
              </tr>
              <tr>
                <th className={styles.amountTh}>
                  月次減税額
                  <br />
                  年調減税額
                </th>
                {hasReduceTargetCountFromPayroll ? (
                  <td>{amountFormat(String(employee.reduceTargetCountFromPayroll! * FLAT_TAX_REDUCTION_PER_PERSON))}円</td>
                ) : (
                  <td className={styles.noPayroll}>
                    <HoverDescriptionContent />
                  </td>
                )}
                <td>{amountFormat(String(reduceTargetCount * FLAT_TAX_REDUCTION_PER_PERSON))}円</td>
              </tr>
            </tbody>
          </table>
        </div>
      </Section.Body>
    </Section>
  );
};

export const HoverDescriptionContent: FC = () => {
  const [isHover, setIsHover] = useState<boolean>(false);

  return (
    <DescriptionContainer
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
      className={styles.description}
    >
      <HoverDescription isHover={isHover}>
        <div className={styles.text}>
          ジョブカン労務HRから従業員情報を連携した場合、
          <br />
          またはジョブカン給与計算で定額減税の確定処理を
          <br />
          していない状態で従業員情報を連携した場合、
          <br />
          2024年6月1日時点の対象人数、月次減税額は表示されません。
        </div>
      </HoverDescription>
    </DescriptionContainer>
  );
};
