import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Field } from 'redux-form';
import useReactRouter from 'use-react-router';
import _ from 'lodash';
import Button from 'jbc-front/components/Button';
import { SearchSelectBox } from 'jbc-front/components/SearchForm';
import { Loading } from 'jbc-front/components/presenters/ui/Loading';
import classNames from 'classnames';

// hooks
import { useAdminQuery as useQuery } from '@jbc-year-end-adj/2024/hooks/graphql/useQuery';

// features
import { useAsyncTask } from '@jbc-year-end-adj/2024/features/AsyncTask/AsyncTaskProvider';
import { AsyncTaskError } from '@jbc-year-end-adj/2024/features/AsyncTask/AsyncTaskError';
import { useSession } from '@jbc-year-end-adj/2024/features/AdminSessionProvider';
import { FormNameProvider } from '@jbc-year-end-adj/2024/features/FormName/FormNameProvider';
import searchForm from '@jbc-year-end-adj/2024/features/SearchForm/SearchForm';
import PaginatorWithResult from '@jbc-year-end-adj/2024/features/SearchForm/PaginatorWithResult';
import { MemoAlertList } from '@jbc-year-end-adj/2024/features/Memo/MemoAlertList';
import { Memo } from '@jbc-year-end-adj/2024/features/Memo/Memo';

// components
import { SearchEmploymentStatusField } from '@jbc-year-end-adj/2024/components/SearchForm';
import { Title } from '@jbc-year-end-adj/2024/components/ui/Title';

// utils
import { makeBackUrl } from '@jbc-year-end-adj/2024/utils/url';
import recordDisplay from '@jbc-year-end-adj/2024/utils/recordDisplay';
import {
  getCurrentQueryFromLocation,
  convertQueryToForm,
  convertFormToGraphQl,
  hasConditions
} from '@jbc-year-end-adj/2024/utils/employeeForm';

// consts
import { year } from '@jbc-year-end-adj/2024/consts';

// page parts
import { DELETE_MODE, REQUEST_MODE, CHANGE_STATUS_MODE } from './consts';
import { SelectList, SelectedResult } from './parts/SelectList';
import { Cooperation } from './parts/Cooperation';
import { DownloadEmployeesCsv } from './parts/DownloadEmployeesCsv';
import { FloatingButtonArea } from './parts/FloatingButtonArea';
import { SetNotNeedAdj } from './parts/SetNotNeedAdj';
import { EMPLOYEES } from './parts/query';
import styles from './Top.scss';

const ImportButton = ({ fixed, to }) => {
  const props = fixed
    ? { disabled: true, disabledReason: '年末調整情報が確定していますので従業員データ一括更新はできません' }
    : { as: Link, to };
  return (
    <Button primary {...props}>
      従業員データ一括更新
    </Button>
  );
};

export const Top = ({ location: { search, pathname } }) => {
  const { me, isCurrentYear, clientYearly } = useSession();
  const [mode, setMode] = useState(REQUEST_MODE);
  const formValues = convertQueryToForm(getCurrentQueryFromLocation({ search, pathname }, me));
  const variables = { year, ...convertFormToGraphQl(formValues), mode };
  const { data, loading: loadingEmployees, refetch: refetchEmployees } = useQuery(EMPLOYEES, {
    variables,
    fetchPolicy: 'network-only'
  });
  const countByStatus = data?.client?.clientYearly?.employeeCountByStatus;

  return (
    <div>
      <div className="l-main-title-wrap">
        <Title>年末調整の依頼一覧</Title>
        {!isCurrentYear && (
          <p className={styles.errorMessage}>過去の年末調整では、従業員への年末調整依頼、ステータス一括変更を行うことはできません。</p>
        )}
      </div>
      <div className="l-contents-wrap">
        <div className={styles.yeaContentsWrap}>
          <MemoAlertList />
          <div className={styles.buttonArea}>
            <div className={styles.buttonList}>
              <DownloadEmployeesCsv search={variables.search} hasConditions={hasConditions(formValues)} />
              <div className={styles.buttonRight}>
                {isCurrentYear && (
                  <>
                    <Cooperation refetchEmployees={refetchEmployees} fixed={!!clientYearly?.fixed} />
                    <ImportButton to={`/${year}/employee/import_update`} fixed={!!clientYearly?.fixed} />
                  </>
                )}
              </div>
            </div>
          </div>
          <div className={styles.searchArea}>
            <SearchForm
              query={EMPLOYEES}
              variables={variables}
              statusField={handleSubmit => (
                <Field
                  name="status"
                  component={SearchEmploymentStatusField}
                  employmentStatuses={requestStatuses}
                  onEmploymentStatusChange={handleSubmit}
                  countByStatus={countByStatus}
                />
              )}
            />
            <AsyncTaskError
              displayTypes={[
                'employee_import',
                'withholding_slip_csv_import',
                'employee_import_from_prev_year_job',
                'employees_csv_import',
                'employees_export_to_service',
                'send_request',
                'bulk_delete_employees',
                'update_status'
              ]}
            />
            <Employees
              mode={mode}
              onChangeMode={setMode}
              variables={variables}
              data={data}
              loadingEmployees={loadingEmployees}
              refetchEmployees={refetchEmployees}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const MODE_OPTIONS = [
  { label: '一括依頼', value: REQUEST_MODE },
  { label: 'ステータス一括変更', value: CHANGE_STATUS_MODE },
  { label: '従業員一括削除', value: DELETE_MODE }
];

const requestStatuses = [
  { id: 'all', name: 'すべて', color: styles.blueTag, active: styles.active },
  { id: 'not_requested', name: '未依頼', color: styles.redTag, active: styles.active },
  { id: 'in_progress', name: '依頼中', color: styles.blueTag, active: styles.active },
  { id: 'applying', name: '入力済', color: styles.yellowTag, active: styles.active },
  { id: 'rejected', name: '再依頼中', color: styles.blueTag, active: styles.active },
  { id: 'fixed', name: '修正済', color: styles.yellowTag, active: styles.active },
  { id: 'accepted', name: '完了', color: styles.greenTag, active: styles.active },
  { id: 'not_need_adj', name: '対象外', color: styles.greyTag, active: styles.active }
];

const searchFormName = 'employeeSearchTop';
const SearchForm = searchForm(searchFormName);

export const statuses = {
  not_requested: '未依頼',
  not_need_adj: '対象外',
  in_progress: '依頼中',
  applying: '入力済',
  rejected: '再依頼中',
  fixed: '修正済',
  accepted: '完了'
};

const ModeSelectField = ({ onChange, value, disabled = false }) => {
  const input = {
    value,
    onChange,
    onBlur: () => {},
    disabled: disabled
  };

  const meta = {
    error: [],
    touched: false
  };

  return <SearchSelectBox input={input} meta={meta} options={MODE_OPTIONS} />;
};

const Employees = ({ mode, onChangeMode, variables, data, loadingEmployees, refetchEmployees }) => {
  const {
    history,
    location: { search, pathname }
  } = useReactRouter();
  const isDeleteMode = mode === DELETE_MODE;
  const { isCurrentYear } = useSession();
  const { taskRunningProps } = useAsyncTask();
  const totalCount = _.get(data, 'client.clientYearly.employees.totalCount', 0);
  const employees = _.get(data, 'client.clientYearly.employees.list', []);
  const selectableCount = _.get(data, 'client.clientYearly.selectableEmployeesCount', 0);
  const fixed = _.get(data, 'client.clientYearly.fixed', false);
  const canBulkDelete = isCurrentYear || isDeleteMode;

  const selectedInfo = ({ staffCode, profile, status }) => {
    const prefix = staffCode ? staffCode + ' ' : '';
    const name = `${prefix}${profile.fullName}`;

    return { name, status: statuses[status] };
  };

  const disabledMessage = employee => {
    if (isDeleteMode || mode === CHANGE_STATUS_MODE) return;

    return employee.status === 'not_need_adj'
      ? '対象外です'
      : employee.status === 'applying' || employee.status === 'fixed'
      ? '既に依頼済みです'
      : !employee.email || !_.trim(employee.email)
      ? `従業員のメールアドレスが未入力のため依頼できません。
                        従業員への依頼が必要な場合、労務HR・給与計算から従業員メールアドレスを入力して再度連携してください。`
      : null;
  };

  useEffect(() => {
    if (_.isUndefined(taskRunningProps)) {
      return;
    }

    if (!taskRunningProps.disabled) {
      refetchEmployees();
    }
  }, [refetchEmployees, taskRunningProps]);

  const moveEmployeeDetail = employee_id => {
    history.push(
      `/${year}/employee/${employee_id}?back_to=${makeBackUrl({
        pathname,
        search
      })}`
    );
  };

  const EmployeeList = ({ list, selected, allSelectMode, isDisabledSubmit, selectedResultCount, reset, th }) => {
    if (loadingEmployees) return <Loading />;

    const selectedTotalCount = selectedResultCount();

    return (
      <>
        <div className={classNames(styles.selectedEmployeeRow, 'u-mb10')}>
          <div className={styles.modeSelectContainer}>
            <ModeSelectField className={styles.modeSelect} value={mode} onChange={value => onChangeMode(value)} disabled={fixed} />
          </div>
          {list.length > 0 && <SelectedResult selectedTotalCount={selectedTotalCount} />}
        </div>

        <div className="l-overflow-scroll">
          <table className={styles.table}>
            <thead>
              <tr>
                {canBulkDelete && th}
                <th className={styles.noteColumn}>メモ</th>
                <th className={styles.staffcodeColumn}>スタッフコード</th>
                <th className={styles.nameColumn}>氏名</th>
                <th className={styles.employmentStatusColumn}>雇用形態</th>
                <th className={styles.positionColumn}>役職</th>
                <th className={styles.emailColumn}>メールアドレス</th>
                <th className={styles.statusColumn}>ステータス</th>
              </tr>
            </thead>
            <tbody>
              {list.length > 0 ? (
                list.map(({ item: employee, td }) => (
                  <tr key={employee.id} onClick={() => moveEmployeeDetail(employee.id)} className="table-hover">
                    {canBulkDelete && td}
                    <td onClick={e => e.stopPropagation()} className={styles.memo}>
                      <Memo employee={employee} size={30} />
                    </td>
                    <td>{employee.staffCode}</td>
                    <td>{employee.profile.fullName}</td>
                    <td>{recordDisplay(employee.employmentType)}</td>
                    <td>{recordDisplay(employee.position)}</td>
                    <td>{recordDisplay(employee.email)}</td>
                    <SetNotNeedAdj employeeId={employee.id} employeeStatus={employee.status} disabled={taskRunningProps?.disabled} />
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={canBulkDelete ? 8 : 7}>該当の従業員は見つかりませんでした</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <FormNameProvider formName={searchFormName}>
          <div className={styles.paginator}>
            <PaginatorWithResult count={totalCount} />
          </div>
        </FormNameProvider>
        <FloatingButtonArea
          mode={mode}
          onChangeMode={onChangeMode}
          selected={selected}
          allSelectMode={allSelectMode}
          isDisabledSubmit={isDisabledSubmit}
          selectedTotalCount={selectedTotalCount}
          reset={reset}
        />
      </>
    );
  };

  return (
    <SelectList
      mode={mode}
      variables={variables}
      list={employees}
      totalCount={totalCount}
      selectableCount={selectableCount}
      disabledMessage={disabledMessage}
      selectedInfo={selectedInfo}
    >
      {props => <EmployeeList {...props} />}
    </SelectList>
  );
};
