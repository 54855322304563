import React, { useEffect, useState } from 'react';
import { gql } from '@apollo/client';
import classnames from 'classnames';
import { reduxForm, Field } from 'redux-form';
import Button from 'jbc-front/components/Button';
import { Link as LinkIcon, Pulldown } from 'jbc-front/components/icons';
import Modal from 'jbc-front/components/CommonModal';
import { required } from 'jbc-front/utils/validators';
import { ASYNC_TASK_FRAGMENT, useAsyncTask } from '../components/AsyncTask';
import { useNotify } from '@jbc-year-end-adj/common/hooks/useNotify';
import { Balloon, useQuery, useMutation, useYear, useSession, renderRadioField } from '../components';
import { IMPORT_EMPLOYEES, APPLICATIONS } from '../pages/ClientSetting';
import styles from './Cooperation.scss';
import _ from 'lodash';

const IMPORT_FROM_PREV_YEAR = gql`
  mutation importFromPrevYear($year: Int!) {
    importFromPrevYear(input: { year: $year }) {
      clientYearly {
        id
        asyncTask {
          ...AsyncTaskFields
        }
      }
    }
  }
  ${ASYNC_TASK_FRAGMENT}
`;

const OFFICE = gql`
  query Office($year: Int!) {
    client {
      id
      clientYearly(year: $year) {
        id
        office {
          id
          name
        }
      }
    }
  }
`;

const ImportModal = ({ isOpen, hideModal, onSubmit, submitting }) => (
  <Modal isOpen={isOpen} hideModal={hideModal}>
    <Modal.Header hideModal={hideModal}>昨年の年末調整データから保険・ローン情報を取得</Modal.Header>
    <Modal.Body>
      <p>スタッフコードをもとに、昨年の年末調整から「保険・ローン」の情報を取得します。</p>
      <p>現在入力されている「保険・ローン」の情報は上書きされます。</p>
      <p>※ステータスが「未依頼」のみ取得が出来ます。</p>
      <p>※昨年の添付ファイルは取得されません</p>
      <p className={`${styles.warning} u-mt15`}>本当に取得しますか。</p>
    </Modal.Body>
    <Modal.Footer>
      <Modal.Buttons>
        <Button onClick={hideModal} disabled={submitting}>
          キャンセル
        </Button>
        <Button primary onClick={onSubmit} disabled={submitting}>
          取得
        </Button>
      </Modal.Buttons>
    </Modal.Footer>
  </Modal>
);

const LmsModalBody = () => (
  <>
    スタッフコードを持たない従業員は取得できません。
    <br />
    スタッフコードを変更しますと別従業員として認識されますのでご注意ください。
    <br />
    <span className={styles.warning}>
      <b>
        ステータスが「未依頼」「依頼中」「入力済」「再依頼中」「修正済」の場合は、
        <br />
        再度データのインポートを行うと従業員情報を上書きできます。
      </b>
    </span>
    <br />
    結果承認タブで従業員から提出された情報は上書きされません。
    <br />
    ※メールアドレスはステータスに関係なく上書きされます。
    <br />
  </>
);

const PayrollModalBody = () => (
  <>
    スタッフコードを持たない従業員は取得できません。
    <br />
    スタッフコードを変更しますと別従業員として認識されますのでご注意ください。
    <br />
    <br />
    また、給与額・賞与額の連携は、年内支給の全ての給与・賞与が確定されている場合にのみ実行されます。
    <br />
    従業員の支給金額が連携されない場合は給与・賞与の確定状態をご確認ください。
    <br />
  </>
);

const PayrollModalBodyForEmployeeInfo = () => (
  <>
    スタッフコードを持たない従業員は取得できません。
    <br />
    スタッフコードを変更しますと別従業員として認識されますのでご注意ください。
    <br />
    <span className={styles.warning}>
      <b>
        ステータスが「未依頼」「依頼中」「入力済」「再依頼中」「修正済」の場合は、
        <br />
        再度データのインポートを行うと従業員情報を上書きできます。
      </b>
    </span>
    <br />
    結果承認タブで従業員から提出された情報は上書きされません。
    <br />
    ※メールアドレスはステータスに関係なく上書きされます。
    <br />
  </>
);

const importTargetOptions = [
  { value: 'all', label: '全従業員' },
  { value: 'selection', label: 'インポート設定されている従業員' }
];

const importTargetSettingUrl = {
  ジョブカン労務HR: 'https://lms.jobcan.jp/employees/year_end_adj_coordination_targets',
  ジョブカン給与計算: 'https://payroll.jobcan.jp/employees/choose_coordination_yea'
};

const ApplicationImportOptions = ({ application }) => (
  <div className={styles.importOptions}>
    <Field
      component={renderRadioField}
      label="インポート対象選択"
      name="target"
      options={importTargetOptions}
      validate={required}
      note={
        <>
          全従業員：{application.name}に登録されている従業員の情報を取得します。（インポートされる従業員の条件は
          <a
            className="u-txt-link"
            href="https://jobcan-payroll.zendesk.com/hc/ja/articles/900002422883"
            target="_blank"
            rel="noopener noreferrer"
          >
            こちら
          </a>
          ）
          <br />
          インポート設定されている従業員：
          <a className="u-txt-link" href={importTargetSettingUrl[application.name]} target="_blank" rel="noopener noreferrer">
            対象従業員選択（年末調整）
          </a>
          で選択されている従業員の情報を取得します。
        </>
      }
    />
  </div>
);

const importOfficeInfo = async ({ year, application, importEmployees }) => {
  await importEmployees({
    variables: {
      service: application.name,
      year,
      needsOffice: true,
      needsEmployee: false,
      needsWithholdingSlip: false,
      filterBySelection: false
    }
  });
};

export const ApplicationImportModal =
  (({ body, handleSubmit, submitting, loading, application, isOpen, hideModal }) => {
    const Body = body || React.Fragment;
    return (
      <Modal isOpen={isOpen} hideModal={hideModal}>
        <Modal.Header hideModal={hideModal}>{application.name}から取得</Modal.Header>
        <Modal.Body>
          <Body />
          <ApplicationImportOptions application={application} />
        </Modal.Body>
        <Modal.Footer>
          <Modal.Buttons>
            <Button onClick={() => hideModal(false)}>キャンセル</Button>
            <Button primary onClick={handleSubmit} disabled={loading || !application.connected || submitting}>
              取得
            </Button>
          </Modal.Buttons>
        </Modal.Footer>
      </Modal>
    );
  })
  |> reduxForm({
    onSubmit: async (
      { target },
      _dispatch,
      {
        year,
        application,
        importEmployees,
        refetchApplications,
        refetchEmployees,
        hideModal,
        needsOffice,
        needsEmployee,
        needsWithholdingSlip
      }
    ) => {
      await importEmployees({
        variables: {
          service: application.name,
          year,
          filterBySelection: target === 'selection',
          needsOffice,
          needsEmployee,
          needsWithholdingSlip
        }
      });
      refetchApplications && refetchApplications();
      refetchEmployees && refetchEmployees();
      hideModal(false);
    }
  });

export const BaloonItem = ({ name, disabled, ...rest }) => (
  <Balloon.Li>
    <a className={classnames({ [styles.disabledAnchor]: disabled })} {...rest}>
      <Pulldown transform="rotate(-90)" />
      {name}
    </a>
  </Balloon.Li>
);

export const BaloonListItem = ({ name, disabled, disabledReason, ...rest }) => (
  <Balloon.Li>
    <a className={classnames({ [styles.disabledAnchor]: disabled })} {...rest}>
      {name}
    </a>
  </Balloon.Li>
);

export const BaloonItemCooperation = ({
  name,
  year,
  importEmployees,
  application,
  refetchEmployees,
  refetchApplications,
  setNeedsEmployee,
  setIsOpenAppModal,
  hide,
  isOfficeNotYetImported,
  ...rest
}) => (
  <Balloon.Li>
    <span>
      <Pulldown transform="rotate(-90)" />
      {name}
    </span>
    <div className={styles.list}>
      <BaloonListItem
        {...rest}
        key={application.id}
        name="「事業所情報」を取得"
        onClick={() => {
          importOfficeInfo({
            year,
            application,
            importEmployees
          });
          hide();
        }}
      ></BaloonListItem>
    </div>
    <div className={styles.list}>
      <BaloonListItem
        {...rest}
        name="「従業員情報」を取得"
        disabled={isOfficeNotYetImported}
        onClick={() => {
          setNeedsEmployee(true);
          setIsOpenAppModal(application.name, true);
          hide();
        }}
      ></BaloonListItem>
    </div>
    {application.name === 'ジョブカン給与計算' && (
      <div className={styles.list}>
      <BaloonListItem // eslint-disable-line
          {...rest}
          name="「給与データ」を取得"
          disabled={isOfficeNotYetImported}
          onClick={() => {
            setNeedsEmployee(false);
            setIsOpenAppModal(application.name, true);
            hide();
          }}
        ></BaloonListItem>
      </div>
    )}
  </Balloon.Li>
);

export const renderCooperationButton = (toggle, title, isOpen, disabled = false, disabledReason = '反映させる従業員が存在していません') => (
  <Button
    {...(isOpen ? { className: 'ignore-react-onclickoutside' } : {})}
    onClick={toggle}
    icon={<LinkIcon size={17} />}
    widthAuto
    disabled={disabled}
    disabledReason={disabledReason}
  >
    {title}
    <span className={styles.triangle} />
  </Button>
);

export const Cooperation = ({ refetchEmployees, fixed }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenPayrollModal, setIsOpenPayrollModal] = useState(false);
  const [isOpenLmsModal, setIsOpenLmsModal] = useState(false);
  const [needsEmployee, setNeedsEmployee] = useState(false);
  const { data, loading: loadingQuery, refetch: refetchApplications } = useQuery(APPLICATIONS);
  const [importEmployees, { loading }] = useMutation(IMPORT_EMPLOYEES);
  const [importFromPrevYear, { loading: loadingImportLastYear }] = useMutation(IMPORT_FROM_PREV_YEAR);
  const year = useYear();
  const notify = useNotify();
  const { clientYearlies } = useSession();
  const prevYear = clientYearlies.find(clientYearly => clientYearly.year === year - 1);
  const { taskRunningProps } = useAsyncTask();
  const { refetch: refetchOffice, data: dataOffice } = useQuery(OFFICE, { variables: { year } });
  const isOfficeNotYetImported = !dataOffice?.client?.clientYearly?.office;

  useEffect(() => {
    if (taskRunningProps === undefined) {
      return;
    }

    if (!taskRunningProps.disabled) {
      refetchOffice();
    }
  }, [taskRunningProps, refetchOffice]);

  const isOpenAppModal = app => (app === 'ジョブカン給与計算' ? isOpenPayrollModal : isOpenLmsModal);
  const setIsOpenAppModal = (app, bool) => (app === 'ジョブカン給与計算' ? setIsOpenPayrollModal(bool) : setIsOpenLmsModal(bool));
  if (loadingQuery) return null;
  const {
    client: { applications }
  } = data;
  const handleImportLastYear = async () => {
    try {
      await importFromPrevYear({ variables: { year } });
      setIsOpenModal(false);
    } catch (err) {
      const msg = _.get(err, 'graphQLErrors[0].message');
      notify(msg || err.message, 'error');
    }
  };
  return (
    <>
      <Balloon
        center
        switchRender={renderCooperationButton}
        title="連携（取得）"
        disabled={fixed}
        disabledReason={'年末調整情報が確定していますので連携（取得）はできません。'}
      >
        {hide => (
          <>
            <Balloon.Ul>
              <BaloonItem
                name="昨年の保険・ローン情報を取得"
                disabled={!prevYear}
                onClick={() => {
                  setIsOpenModal(true);
                  hide();
                }}
              />
              {applications.map(application => (
                <>
                  <BaloonItemCooperation
                    key={application.id}
                    name={`${application.name}から取得`}
                    year={year}
                    importEmployees={importEmployees}
                    application={application}
                    refetchEmployees={refetchEmployees}
                    refetchApplications={refetchApplications}
                    setNeedsEmployee={setNeedsEmployee}
                    setIsOpenAppModal={setIsOpenAppModal}
                    hide={hide}
                    isOfficeNotYetImported={isOfficeNotYetImported}
                  />
                </>
              ))}
              <BaloonItem
                name="連携項目を確認する"
                href="https://jobcan-payroll.zendesk.com/hc/ja/articles/900003088906"
                target="_blank"
                rel="noopener noreferrer"
              />
            </Balloon.Ul>
          </>
        )}
      </Balloon>
      <ImportModal
        isOpen={isOpenModal}
        submitting={loadingImportLastYear}
        hideModal={() => setIsOpenModal(false)}
        onSubmit={handleImportLastYear}
      />
      {applications.map(application => (
        <ApplicationImportModal
          form="applicationImport"
          year={year}
          key={application.id}
          refetchEmployees={refetchEmployees}
          refetchApplications={refetchApplications}
          loading={loading}
          importEmployees={importEmployees}
          application={application}
          isOpen={isOpenAppModal(application.name)}
          hideModal={() => setIsOpenAppModal(application.name)}
          body={
            application.name === 'ジョブカン給与計算' ? (needsEmployee ? PayrollModalBodyForEmployeeInfo : PayrollModalBody) : LmsModalBody
          }
          initialValues={{ target: 'selection' }}
          needsOffice={false}
          needsEmployee={needsEmployee}
          needsWithholdingSlip={!needsEmployee}
        />
      ))}
    </>
  );
};

export default Cooperation;
