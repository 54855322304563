import { useEffect, useLayoutEffect } from 'react';
import useReactRouter from 'use-react-router';
import { parse } from 'query-string';
import { usePrevious } from '@jbc-year-end-adj/common/hooks/usePrevious';

// NOTE: 本当はこっちの実装を使いたい。全体的なUXを改善するまで、現状の挙動を維持するためScrollToTopOnAnyNavigationを使うことにしたので今は不使用。
//       https://github.com/d-o-n-u-t-s/roumu-server/issues/8637#issuecomment-1952037151
export const ScrollToTopOnNavigation = () => {
  const {
    location: { pathname, search }
  } = useReactRouter();
  const oldSearch = usePrevious(search) ?? '';

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // クエリパラメータの page, _page が変わった時 (同一ページ内のページネーション操作) にスクロール
  useEffect(() => {
    const oldQuery = parse(oldSearch, { arrayFormat: 'bracket' });
    const newQuery = parse(search, { arrayFormat: 'bracket' });
    if (oldQuery['page'] !== newQuery['page'] || oldQuery['_page'] !== newQuery['_page']) {
      window.scrollTo(0, 0);
    }
  }, [oldSearch, search]);

  return null;
};

export const ScrollToTopOnAnyNavigation = () => {
  const { history } = useReactRouter();
  useLayoutEffect(() => {
    return history.listen(() => {
      window.scrollTo(0, 0);
    });
  }, [history]);
  return null;
};
