import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { gql, useApolloClient } from '@apollo/client';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import Button from 'jbc-front/components/Button';
import { Error } from 'jbc-front/components/Form';
import { Download } from 'jbc-front/components/icons';
import { required } from 'jbc-front/utils/validators';
import { useNotify } from '@jbc-year-end-adj/common/hooks/useNotify';
import { useYear, Modal } from '../components';
import { fileEncodings } from '../pages/ImportWithholdingSlips';
import { toBlob } from '../utils/url';
import styles from './DownloadModal.scss';

export const MAX_DOWNLOAD_COUNT = 400;

export const CSV_EXPORT_REQUEST_TARGET_COUNT = gql`
  query csvExportRequestTargetCount($year: Int!, $search: EmployeeSearchInput) {
    client {
      id
      clientYearly(year: $year) {
        id
        fixed
        csvExportRequestTargetCount(search: $search)
      }
    }
  }
`;

export const targetOptions = [
  { value: 'all', label: '全従業員' },
  { value: 'search', label: '検索絞込中の従業員' }
];

const isAsyncCreatable = type => ['employee', 'withholding_slip', 'difference_to_apply'].includes(type);

export const DownloadModal = ({
  button,
  header,
  fileName,
  noteItem,
  type,
  query,
  getFile,
  createCsvRequest,
  search,
  hasConditions,
  disabled = false,
  disabledReason = '作成に時間がかかるため、完了しましたらメールにてお送りいたします。',
  primary = true
}) => {
  const client = useApolloClient();
  const year = useYear();
  const notify = useNotify();

  return (
    <Modal>
      {({ isOpen, showModal, hideModal }) => (
        <>
          <Button icon={<Download size={16} />} onClick={showModal} disabled={disabled} disabledReason={disabledReason}>
            {button}
          </Button>
          {isOpen && (
            <Modal.Modal>
              <Modal.Header>{header}</Modal.Header>
              <DownloadForm
                {...{
                  search,
                  hideModal,
                  client,
                  createCsvRequest,
                  type,
                  query,
                  getFile,
                  fileName,
                  noteItem,
                  year,
                  notify
                }}
                initialValues={{
                  fileEncoding: 'utf8',
                  _target: hasConditions ? 'search' : 'all'
                }}
              />
            </Modal.Modal>
          )}
        </>
      )}
    </Modal>
  );
};

export const DownloadForm =
  (({ handleSubmit, submitting, hideModal, noteItem }) => (
    <>
      <Modal.Body>
        <p className={styles.text}>
          <span className={styles.important}>
            ※ステータスが「入力済」「修正済」「再依頼中」（従業員回答後のステータス）の場合は、従業員データダウンロードから出力される情報に従業員が回答したものは含まれません。
          </span>
          <br />
          従業員が回答した内容は個別に画面から確認していただきますようお願いします。
        </p>
        <p className={styles.text}>
          なお、従業員が回答した内容を承認後（「完了」「対象外」ステータス）は、従業員が回答した内容が反映されたCSVを出力することが可能です。
        </p>
        <form onSubmit={handleSubmit}>
          <Field
            component={renderRadioField}
            label="ファイル形式選択"
            name="fileEncoding"
            options={fileEncodings}
            note="Excel2016より前のバージョンのExcelをお使いの方はShift-JISをご利用ください。"
            validate={required}
          />
          <Field
            label="ダウンロード対象選択"
            component={renderRadioField}
            name="_target"
            options={targetOptions}
            note={
              <>
                全従業員：全従業員の
                {noteItem}
                をダウンロードします。
                <br />
                検索絞込中の従業員：一覧で検索絞込した従業員の
                {noteItem}
                をダウンロードします。
              </>
            }
            validate={required}
          />
          <br />
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Buttons>
          <Button onClick={hideModal}>キャンセル</Button>
          <Button primary as="button" onClick={handleSubmit} disabled={submitting}>
            <Download />
            ダウンロード
          </Button>
        </Modal.Buttons>
      </Modal.Footer>
    </>
  ))
  |> reduxForm({
    form: 'downloadDifferenceApply',
    onSubmit: async (
      values,
      dispatch,
      { client, search, hideModal, createCsvRequest, type, query, getFile, fileName, year, noteItem, notify }
    ) => {
      const searchParams = values._target === 'search' ? search : null;

      const { data: targetCount } = await client.query({
        query: CSV_EXPORT_REQUEST_TARGET_COUNT,
        variables: {
          search: searchParams,
          year
        },
        fetchPolicy: 'network-only'
      });
      const totalCount = _.get(targetCount, 'client.clientYearly.csvExportRequestTargetCount', 0);

      try {
        if (totalCount >= MAX_DOWNLOAD_COUNT && isAsyncCreatable(type)) {
          await createCsvRequest({
            variables: {
              input: {
                year: year,
                type: type,
                search: searchParams,
                fileEncoding: values.fileEncoding
              }
            }
          });
          notify(`${noteItem}はメールにてお送りします`);
        } else {
          const { data } = await client.query({
            query,
            variables: {
              fileEncoding: values.fileEncoding,
              search: searchParams,
              year
            },
            fetchPolicy: 'no-cache'
          });
          saveAs(toBlob(getFile(data), 'text/csv'), fileName);
        }
        hideModal();
      } catch (err) {
        const msg = _.get(err, 'graphQLErrors[0].message');
        notify(msg || err.message, 'error');
      }
    }
  });

export const renderRadioField = ({ label, input, options, disabled, note, meta }) => (
  <div className={meta.error && meta.touched ? styles.invalidField : styles.field}>
    <div className={styles.label}>{label}</div>
    <div>
      {options.map(({ label, value }) => (
        <div style={{ display: 'inline-block' }} className="input-label" key={value}>
          <label>
            <input
              {..._.omit(input, 'value')}
              type="radio"
              value={value}
              checked={value === input.value}
              disabled={disabled}
              className={`m-radio-input${disabled ? ' disabled' : ''}`}
            />
            <span className={`m-radio-parts${disabled ? ' disabled' : ''}`}>{label}</span>
          </label>
        </div>
      ))}
    </div>
    {note && <div className={styles.note}>{note}</div>}
    <Error meta={meta} label={label} />
  </div>
);

export default DownloadModal;
