import { FC, useState, useEffect } from 'react';
// @ts-ignore
import { saveAs } from 'file-saver';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Button from 'jbc-front/components/Button';
import { Download } from 'jbc-front/components/icons';
import { Modal } from 'jbc-front/components/presenters/ui/Modal';
import { Radio, RadioContainer } from 'jbc-front/components/presenters/form/Radio';
import { Note } from 'jbc-front/components/presenters/ui/Note';

import { SearchParams } from '@jbc-year-end-adj/2024/features/SearchForm/types';
import { useSession } from '@jbc-year-end-adj/2024/features/AdminSessionProvider';
import { FormField } from '@jbc-year-end-adj/2024/components/form/FormField';
import { Label } from '@jbc-year-end-adj/2024/components/form/Label';
import { FormSection } from '@jbc-year-end-adj/2024/components/form/FormSection';
import { useNotify } from '@jbc-year-end-adj/common/hooks/useNotify';
import { useAdminLazyQuery as useLazyQuery } from '@jbc-year-end-adj/2024/hooks/graphql/useQuery';
import { useAdminMutation as useMutation } from '@jbc-year-end-adj/2024/hooks/graphql/useMutation';
import { useApolloClient } from '@apollo/client';

import { toBlob } from '@jbc-year-end-adj/2024/utils/url';

import { ADMIN_ENDPOINT } from '@jbc-year-end-adj/2024/hooks/graphql/consts';
import { CSV_EXPORT_REQUEST_TARGET_COUNT, EMPLOYEES_CSV } from './query';
import { CREATE_EMPLOYEE_CSV_EXPORT_REQUEST } from './mutation';
import { schema, Schema, buildDefaultValues } from './schema';
import styles from './DownloadModal.scss';

// MEMO: 仕様を揃える場合はyears/2024/consts.tsに移動する
const MAX_DOWNLOAD_COUNT = 400;

type CsvDownloadModal = {
  isOpen: boolean;
  onClose: () => void;
  search: SearchParams;
  hasConditions: boolean;
};

export const CsvDownloadModal: FC<CsvDownloadModal> = ({ isOpen, onClose, search, hasConditions }) => {
  const { clientYearly } = useSession();
  const notify = useNotify();
  const client = useApolloClient();
  const defaultValues = buildDefaultValues(hasConditions);
  const { register, handleSubmit, reset, watch } = useForm<Schema>({
    resolver: yupResolver(schema),
    defaultValues
  });
  const targetData = watch('targetData');
  const [submitting, setSubmitting] = useState(false);

  const [createCsvRequest] = useMutation(CREATE_EMPLOYEE_CSV_EXPORT_REQUEST);
  const [fetchEmployeeCsv] = useLazyQuery(EMPLOYEES_CSV, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      const blob = toBlob(data.client.clientYearly.exportEmployeeCsv, 'text/csv');
      if (targetData === 'employee') {
        saveAs(blob, '従業員情報データ.csv');
      } else {
        saveAs(blob, '従業員の回答（承認前）データ.csv');
      }
    }
  });

  useEffect(() => {
    if (isOpen) {
      reset(buildDefaultValues(hasConditions));
    }
  }, [isOpen, hasConditions]);

  const onSubmit: SubmitHandler<Schema> = async (data: Schema) => {
    setSubmitting(true);

    if (clientYearly?.fixed && data.targetData === 'employee_request') {
      notify('年末調整確定後は「従業員の回答（承認前）」を選択することはできません。', 'error');
      return;
    }

    const searchParams = data.downloadTarget === 'search' ? search : null;

    // csvの対象従業員数を取得
    const { data: csvExportTargetData } = await client.query({
      query: CSV_EXPORT_REQUEST_TARGET_COUNT,
      variables: {
        search: searchParams
      },
      fetchPolicy: 'network-only',
      context: {
        uri: ADMIN_ENDPOINT
      }
    });

    const totalCount = csvExportTargetData?.client?.clientYearly?.csvExportRequestTargetCount || 0;

    try {
      if (totalCount >= MAX_DOWNLOAD_COUNT) {
        await createCsvRequest({
          variables: {
            input: {
              type: 'employee',
              targetData: data.targetData,
              search: searchParams,
              fileEncoding: data.fileEncoding
            }
          }
        });
        notify('年末調整データはメールにてお送りします。', 'success');
      } else {
        await fetchEmployeeCsv({
          variables: {
            fileEncoding: data.fileEncoding,
            targetData: data.targetData,
            search: searchParams
          }
        });
      }

      onClose();
    } catch (err) {
      notify(err.graphQLErrors?.[0]?.message || err.message, 'error');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Header onClose={onClose}>従業員データダウンロード</Modal.Header>
      <Modal.Body>
        <form>
          <FormSection>
            <FormField>
              <FormField.LabelContainer>
                <Label className={styles.label}>ファイル形式</Label>
              </FormField.LabelContainer>
              <RadioContainer className={styles.radioContainer}>
                <Radio {...register('fileEncoding')} value="utf8">
                  UTF-8(推奨)
                </Radio>
                <Radio {...register('fileEncoding')} value="sjis">
                  Shift-JIS
                </Radio>
              </RadioContainer>
              <FormField.NoteContainer>
                <Note>Excel2016より前のバージョンのExcelをお使いの方はShift-JISをご利用ください</Note>
              </FormField.NoteContainer>
            </FormField>

            <FormField>
              <FormField.LabelContainer>
                <Label className={styles.label}>対象データ</Label>
              </FormField.LabelContainer>
              <RadioContainer className={styles.radioContainer}>
                <Radio {...register('targetData')} value="employee">
                  従業員情報
                </Radio>
                <Radio {...register('targetData')} value="employee_request" disabled={clientYearly?.fixed}>
                  従業員の回答(承認前)
                </Radio>
              </RadioContainer>
              <FormField.NoteContainer>
                <Note>
                  従業員情報：登録されている従業員情報をダウンロードします。
                  <br />
                  従業員の回答(承認前)：従業員が提出した未承認の情報をダウンロードします。
                  <br />
                  <span className={styles.description}>ステータスが「入力済」「再依頼中」「修正済」以外の従業員は空欄で出力されます。</span>
                </Note>
              </FormField.NoteContainer>
            </FormField>

            <FormField>
              <FormField.LabelContainer>
                <Label className={styles.label}>ダウンロード対象選択</Label>
              </FormField.LabelContainer>
              <RadioContainer className={styles.radioContainer}>
                <Radio {...register('downloadTarget')} value="all">
                  全従業員
                </Radio>
                <Radio {...register('downloadTarget')} value="search">
                  検索絞込中の従業員
                </Radio>
              </RadioContainer>
              <FormField.NoteContainer>
                <Note>
                  全従業員：全従業員の年末調整データをダウンロードします。
                  <br />
                  検索絞込中の従業員：一覧で検索絞込した従業員の年末調整データをダウンロードします。
                </Note>
              </FormField.NoteContainer>
            </FormField>
          </FormSection>
        </form>
      </Modal.Body>
      <Modal.Footer className={styles.modalFooter}>
        <Button onClick={onClose}>キャンセル</Button>
        <Button primary as="button" onClick={handleSubmit(onSubmit)} disabled={submitting}>
          <Download />
          ダウンロード
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
