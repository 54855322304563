import { FC } from 'react';
import { Diff, FileDiff } from '../../../../../components/Diff';
import { Dl, Dt, Dd, FileDd, FileDl } from '../../../../../components/DefinitionList';
import { PreviewSubSection } from '../../../../../components/PreviewSubSection';
import { Tag } from '../../../../../components/Tag';
import { LifeInsurance } from '../../../../../query';
import { amountFormat, displayFormat } from '@jbc-year-end-adj/common/utils/formatter';
import { FilePreview } from 'components/feature/FilePreview';
import { useYear } from 'hooks/useYear';

type DiffType = {
  type: 'add' | 'update' | 'delete';
  source: LifeInsurance | undefined;
  input: LifeInsurance | undefined;
};

type UpdateNursingMedicalInsuranceDiffProps = {
  index: number;
  diff: DiffType;
};

export const UpdateNursingMedicalInsuranceDiff: FC<UpdateNursingMedicalInsuranceDiffProps> = ({ index, diff }) => {
  const year = useYear();
  return (
    <PreviewSubSection key={index}>
      <PreviewSubSection.Header>介護医療保険（{index}）</PreviewSubSection.Header>
      <PreviewSubSection.Body>
        <Diff title="保険会社等の名称" source={diff.source?.name} input={diff.input?.name} />
        <Diff title="保険等の種類" source={diff.source?.insuranceType} input={diff.input?.insuranceType} />
        <Diff title="保険期間" source={diff.source?.insuranceDuration} input={diff.input?.insuranceDuration} />
        <Diff title="契約者氏名" source={diff.source?.nameOfContractor} input={diff.input?.nameOfContractor} />
        <Diff
          title={year <= 2023 ? '受取人・氏名' : '受取人の氏名'}
          source={diff.source?.nameOfRecipient}
          input={diff.input?.nameOfRecipient}
        />
        {year <= 2023 && (
          <Diff title="受取人・続柄" source={diff.source?.relationshipOfRecipient} input={diff.input?.relationshipOfRecipient} />
        )}
        <Diff title="保険料金額" source={amountFormat(diff.source?.amount)} input={amountFormat(diff.input?.amount)} />
        <FileDiff title="証明書画像" source={diff.source?.image} input={diff.input?.image} />
      </PreviewSubSection.Body>
    </PreviewSubSection>
  );
};

type CreateOrDeleteNursintMedicalInsuranceDiffProps = {
  index: number;
  lifeInsurance: LifeInsurance | undefined;
  type: 'add' | 'delete';
};

export const CreateOrDeleteNursingMedicalInsuranceDiff: FC<CreateOrDeleteNursintMedicalInsuranceDiffProps> = ({
  index,
  lifeInsurance,
  type
}) => {
  const year = useYear();
  return (
    <PreviewSubSection key={index}>
      <PreviewSubSection.Header>
        介護医療保険（{index}）{type === 'add' && <Tag color="blue">追加</Tag>}
        {type === 'delete' && <Tag color="red">削除</Tag>}
      </PreviewSubSection.Header>
      <PreviewSubSection.Body>
        <Dl>
          <Dt>保険会社等の名称</Dt>
          <Dd>{displayFormat(lifeInsurance?.name)}</Dd>
        </Dl>
        <Dl>
          <Dt>保険等の種類</Dt>
          <Dd>{displayFormat(lifeInsurance?.insuranceType)}</Dd>
        </Dl>
        <Dl>
          <Dt>保険期間</Dt>
          <Dd>{displayFormat(lifeInsurance?.insuranceDuration)}</Dd>
        </Dl>
        <Dl>
          <Dt>契約者氏名</Dt>
          <Dd>{displayFormat(lifeInsurance?.nameOfContractor)}</Dd>
        </Dl>
        <Dl>
          {year <= 2023 ? <Dt>受取人・氏名</Dt> : <Dt>受取人の氏名</Dt>}
          <Dd>{displayFormat(lifeInsurance?.nameOfRecipient)}</Dd>
        </Dl>
        {year <= 2023 && (
          <Dl>
            <Dt>受取人・続柄</Dt>
            <Dd>{displayFormat(lifeInsurance?.relationshipOfRecipient)}</Dd>
          </Dl>
        )}
        <Dl>
          <Dt>保険料金額</Dt>
          <Dd>{amountFormat(lifeInsurance?.amount)}</Dd>
        </Dl>
        <FileDl>
          <Dt>証明書画像</Dt>
          <FileDd file={lifeInsurance?.image}>{lifeInsurance?.image && <FilePreview file={lifeInsurance?.image} />}</FileDd>
        </FileDl>
      </PreviewSubSection.Body>
    </PreviewSubSection>
  );
};
