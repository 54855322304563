import React, { useState } from 'react';
import { gql } from '@apollo/client';
import { useQuery, useMutation, Modal, useYear } from '.';
import Button from 'jbc-front/components/Button';
import Download from 'jbc-front/components/icons/Download';
import Loading from './Loading';
import { PollingController } from '../city_report/Report';
import styles from './DownloadWithholdSlips.scss';
import moment from 'moment';
import { renderRadioField as RadioField } from 'jbc-front/components/Form';
import _ from 'lodash';
import useReactRouter from 'use-react-router';
import { parse, stringify } from 'query-string';
import { useNotify } from '@jbc-year-end-adj/common/hooks/useNotify';

const BULK_CLIENT_CITY_REPORT = gql`
  query bulkClientCityReport($year: Int!) {
    client {
      id
      clientYearly(year: $year) {
        id
        clientCityReport {
          id
          status
          generatedAt
        }
        bulkClientCityReport {
          id
          status
          generatedAt
        }
      }
    }
  }
`;

const MAKE_CLIENT_CITY_REPORT = gql`
  mutation makeClientCityReport($input: MakeClientCityReportInput!) {
    makeClientCityReport(input: $input) {
      clientYearly {
        id
        clientCityReport {
          id
          status
          generatedAt
        }
        bulkClientCityReport {
          id
          status
          generatedAt
        }
      }
    }
  }
`;

const DownloadFormat = ({ downloadFormat, setDownloadFormat }) => (
  <div className="u-mb10">
    <RadioField
      meta={{}}
      input={{
        value: downloadFormat,
        onChange(e) {
          setDownloadFormat(e.target.value);
        }
      }}
      name="downloadFormat"
      label="作成形式"
      required
      options={[
        { value: 'pdf', label: 'PDF形式(複数人1ファイル)' },
        { value: 'zip', label: 'ZIP形式(市区町村別)' }
      ]}
    />
  </div>
);

export const DownloadClientCity = ({ hasMyNumberPermission, inProgress, showMyNumber }) => {
  const year = useYear();
  const notify = useNotify();
  const { loading: loadingQuery, data, startPolling, stopPolling } = useQuery(BULK_CLIENT_CITY_REPORT, {
    fetchPolicy: 'network-only',
    variables: { year }
  });
  const {
    history,
    location: { pathname, search: querySearch }
  } = useReactRouter();
  const { format } = parse(querySearch);
  const [downloadFormat, setDownloadFormat] = useState(format || 'pdf');
  const [isOpen, setIsOpen] = useState(!!format);
  const hideModal = () => {
    setIsOpen(false);
    if (format) {
      history.replace({ pathname, search: stringify(_.omit(parse(querySearch), 'format')) });
    }
  };
  const [makeReport, { loading }] = useMutation(MAKE_CLIENT_CITY_REPORT);
  if (loadingQuery) return null;
  const zipReport = data.client.clientYearly.clientCityReport;
  const pdfReport = data.client.clientYearly.bulkClientCityReport;
  const [report, reportType] = downloadFormat === 'zip' ? [zipReport, 'client_city_report'] : [pdfReport, 'bulk_client_city_report'];
  const makingReport = inProgress || (report && (report.status === 'in_process' || report.status === 'waiting'));
  const hasReport = report && (report.status === 'success' || report.status === 'failed');

  return (
    <Modal>
      {() => (
        <>
          <Modal.Modal isOpen={isOpen}>
            <Modal.Header hideModal={hideModal}>給与支払報告書一括作成</Modal.Header>
            <Modal.Body style={{ maxWidth: '480px' }}>
              <DownloadFormat downloadFormat={downloadFormat} setDownloadFormat={setDownloadFormat} />
              {hasReport ? (
                <>
                  作成済みの給与支払報告書が存在します。
                  <div className={styles.file}>
                    {report.status === 'failed' ? (
                      <>作成が失敗しました</>
                    ) : (
                      <>
                        {showMyNumber && !hasMyNumberPermission ? (
                          <>給与支払報告書ダウンロード</>
                        ) : (
                          <a
                            href={`/report?report_type=${reportType}&disposition=attachment${year ? '&year=' + year : ''}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="u-txt-link"
                          >
                            給与支払報告書ダウンロード
                          </a>
                        )}
                        （{moment(report.generatedAt).format('YYYY/MM/DD HH:mm')}
                        作成）
                        {showMyNumber && !hasMyNumberPermission && (
                          <>
                            <br />
                            ※マイナンバーが出力された帳票を閲覧する権限がありません。
                          </>
                        )}
                      </>
                    )}
                  </div>
                  再作成を行うと既存の給与支払報告書は削除されます。再作成が完了しましたらメールにてお送りいたします。
                  <br />
                  <br />
                  ※従業員数によって時間がかかる場合がございます。予めご了承ください。
                </>
              ) : (
                <>
                  給与支払報告書を作成します。作成が完了しましたらメールにてお送りいたします。
                  <br />
                  ※従業員数によって時間がかかる場合がございます。予めご了承ください。
                </>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Modal.Buttons>
                <Button onClick={hideModal}>キャンセル</Button>
                <Button
                  primary
                  disabled={loading}
                  onClick={async () => {
                    await makeReport({
                      variables: {
                        input: {
                          downloadFormat,
                          year
                        }
                      }
                    });
                    notify('給与支払報告書はメールにてお送りします');
                    hideModal();
                  }}
                >
                  {hasReport ? '再作成' : '作成'}
                </Button>
              </Modal.Buttons>
            </Modal.Footer>
          </Modal.Modal>
          <Button
            onClick={() => setIsOpen(true)}
            disabled={makingReport}
            disabledReason={makingReport ? '給与支払報告書作成中です' : ''}
            icon={<Download />}
          >
            {makingReport && <Loading className={styles.loading} />}
            PDF一括ダウンロード
          </Button>
          {report && <PollingController reportStatus={report.status} {...{ startPolling, stopPolling }} />}
        </>
      )}
    </Modal>
  );
};

export default DownloadClientCity;
