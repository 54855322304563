import { FC, useState } from 'react';
import { useYear } from 'hooks/useYear';
import { useRightExpansionContainer } from 'components/feature/RightExpansionContainer';
import { Dt, Dd, Dl, FileDd, FileDl } from '../../../../components/DefinitionList';
import { File } from '../../../../components/Diff';
import { Grid } from '../../../../components/Grid';
import { PreviewSubSection } from '../../../../components/PreviewSubSection';
import { DependentYearlyInfo, OtherFamily } from '../../../../query';
import { dateFormat, displayFormat, amountFormat, fullNameFormat, booleanFormat } from '@jbc-year-end-adj/common/utils/formatter';
import { GENDER, RESIDENT_STATUS, HANDICAP_CLASSIFICATION } from '../../../../consts';
import { Pulldown, Income as IncomeIcon } from 'jbc-front/components/icons';
import { Income } from './Income';
import styles from './Preview.scss';
import classnames from 'classnames';

type OtherFamilyRowProps = {
  otherFamily: OtherFamily;
};

export const OtherFamilyRow: FC<OtherFamilyRowProps> = ({ otherFamily }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const year = useYear();
  const { setContent } = useRightExpansionContainer();

  const handleClickIncome = () => {
    setContent(<Income income={otherFamily.income} year={year} />, 'spouseIncomeDiff', 400);
  };

  const fullAddress = (yearlyInfo?: DependentYearlyInfo) => {
    if (!yearlyInfo) return undefined;

    return `${yearlyInfo.postcode0}-${yearlyInfo.postcode1} ${yearlyInfo.fullAddress}`;
  };

  return (
    <>
      <tr onClick={() => setIsOpen(!isOpen)} className={classnames(styles.row, isOpen && styles.expand)}>
        <td>
          <div className={styles.toggleColumn}>
            <Pulldown className={styles.icon} transform={isOpen ? 'rotate(0)' : 'rotate(-90)'} />
            {otherFamily.relationOther}
          </div>
        </td>
        <td className={styles.fullNameTd}>
          {`${fullNameFormat(otherFamily.lastName, otherFamily.firstName)}（${fullNameFormat(
            otherFamily.lastNameKana,
            otherFamily.firstNameKana
          )}）`}
        </td>
        <td>{dateFormat(otherFamily.birthday, 'L')}</td>
        <td className={styles.genderTd}>{otherFamily.gender ? GENDER[otherFamily.gender] : displayFormat()}</td>
        <td>{otherFamily.thisYear?.dependentTaxLaw ? '○' : '×'}</td>
        <td>{otherFamily.nextYear?.dependentTaxLaw ? '○' : '×'}</td>
      </tr>

      {isOpen && (
        <tr>
          <td colSpan={6}>
            <div className={styles.expandableRow}>
              <Grid colGap col2>
                <PreviewSubSection>
                  <PreviewSubSection.Header>今年の税扶養</PreviewSubSection.Header>
                  <PreviewSubSection.Body>
                    <Dl>
                      <Dt>今年の源泉控除対象</Dt>
                      <Dd>
                        {otherFamily?.thisYear?.dependentTaxLaw === undefined
                          ? displayFormat()
                          : otherFamily?.thisYear?.dependentTaxLaw
                          ? '対象'
                          : '対象外'}
                      </Dd>
                    </Dl>

                    {!otherFamily?.thisYear?.dependentTaxLaw && (
                      <Dl>
                        <Dt>今年の税の扶養対象ではないが、所得金額調整控除の対象、退職手当等を有する扶養親族の対象</Dt>
                        <Dd>{otherFamily?.thisYear?.incomeAdjustmentDeduction ? '対象' : '対象外'}</Dd>
                      </Dl>
                    )}

                    <Dl>
                      <Dt>同居・別居</Dt>
                      <Dd>
                        {otherFamily?.thisYear?.residenceStatus ? RESIDENT_STATUS[otherFamily.thisYear.residenceStatus] : displayFormat()}
                      </Dd>
                    </Dl>

                    {otherFamily.thisYear?.residenceStatus === 'different_address' && (
                      <Dl>
                        <Dt>住所</Dt>
                        <Dd>{fullAddress(otherFamily.thisYear)}</Dd>
                      </Dl>
                    )}

                    {otherFamily.thisYear?.residenceStatus === 'different_and_foreign_address' && (
                      <>
                        <Dl>
                          <Dt>Address</Dt>
                          <Dd>{displayFormat(otherFamily.thisYear?.addressForeign)}</Dd>
                        </Dl>

                        <Dl>
                          <Dt>非居住者</Dt>
                          <Dd>
                            {booleanFormat({
                              value: otherFamily.thisYear?.isNonResident ?? false,
                              truthyString: '非居住者',
                              falsyString: '対象外'
                            })}
                          </Dd>
                        </Dl>

                        <Dl>
                          <Dt>留学</Dt>
                          <Dd>
                            {booleanFormat({
                              value: otherFamily.thisYear?.isStudyAbroad ?? false,
                              truthyString: '留学',
                              falsyString: '対象外'
                            })}
                          </Dd>
                        </Dl>

                        <Dl>
                          <Dt>国外居住親族への送金額</Dt>
                          <Dd>{amountFormat(otherFamily.thisYear?.remittance)}</Dd>
                        </Dl>

                        <Dl>
                          <Dt>親族関係書類</Dt>
                          <File file={otherFamily.thisYear?.relatedToRelativesDocument} />
                        </Dl>

                        <FileDl>
                          <Dt>送金関係書類</Dt>
                          <FileDd file={otherFamily.thisYear?.relatedToRemittanceDocument} />
                        </FileDl>

                        <FileDl>
                          <Dt>留学関係書類</Dt>
                          <FileDd file={otherFamily.thisYear?.provingStudyAbroadDocument} />
                        </FileDl>
                      </>
                    )}

                    <Dl>
                      <Dt>障害者区分</Dt>
                      <Dd>
                        {otherFamily.thisYear?.handicapType ? HANDICAP_CLASSIFICATION[otherFamily.thisYear.handicapType] : displayFormat()}
                      </Dd>
                    </Dl>

                    <Dl>
                      <Dt>障害者詳細</Dt>
                      <Dd>{otherFamily.thisYear?.handicapDetail}</Dd>
                    </Dl>

                    <FileDl>
                      <Dt>障害者手帳画像</Dt>
                      <File file={otherFamily.thisYear?.handicapImage} />
                    </FileDl>

                    <Dl>
                      <Dt>
                        <div className={styles.incomeDetailHeader}>
                          所得見積額
                          <i onClick={handleClickIncome}>
                            <IncomeIcon />
                          </i>
                        </div>
                      </Dt>
                      <Dd>{amountFormat(otherFamily.thisYear?.earnings) || '0'}</Dd>
                    </Dl>
                  </PreviewSubSection.Body>
                </PreviewSubSection>

                <PreviewSubSection>
                  <PreviewSubSection.Header>来年の扶養情報</PreviewSubSection.Header>
                  <PreviewSubSection.Body>
                    <Dl>
                      <Dt>来年の源泉控除対象</Dt>
                      <Dd>
                        {otherFamily?.nextYear?.dependentTaxLaw === undefined
                          ? displayFormat()
                          : otherFamily?.nextYear?.dependentTaxLaw
                          ? '対象'
                          : '対象外'}
                      </Dd>
                    </Dl>

                    <Dl>
                      <Dt>同居・別居</Dt>
                      <Dd>
                        {otherFamily?.nextYear?.residenceStatus ? RESIDENT_STATUS[otherFamily.nextYear.residenceStatus] : displayFormat()}
                      </Dd>
                    </Dl>

                    {otherFamily.nextYear?.residenceStatus === 'different_address' && (
                      <Dl>
                        <Dt>住所</Dt>
                        <Dd>{fullAddress(otherFamily.nextYear)}</Dd>
                      </Dl>
                    )}

                    {otherFamily.nextYear?.residenceStatus === 'different_and_foreign_address' && (
                      <>
                        <Dl>
                          <Dt>Address</Dt>
                          <Dd>{displayFormat(otherFamily.nextYear?.addressForeign)}</Dd>
                        </Dl>

                        <Dl>
                          <Dt>非居住者</Dt>
                          <Dd>
                            {booleanFormat({
                              value: otherFamily.nextYear?.isNonResident ?? false,
                              truthyString: '非居住者',
                              falsyString: '対象外'
                            })}
                          </Dd>
                        </Dl>

                        <Dl>
                          <Dt>留学</Dt>
                          <Dd>
                            {booleanFormat({
                              value: otherFamily.nextYear?.isStudyAbroad ?? false,
                              truthyString: '留学',
                              falsyString: '対象外'
                            })}
                          </Dd>
                        </Dl>

                        <Dl>
                          <Dt>国外居住親族への送金額</Dt>
                          <Dd>{amountFormat(otherFamily.nextYear?.remittance)}</Dd>
                        </Dl>

                        <FileDl>
                          <Dt>親族関係書類</Dt>
                          <File file={otherFamily.nextYear.relatedToRelativesDocument} />
                        </FileDl>

                        <FileDl>
                          <Dt>送金関係書類</Dt>
                          <File file={otherFamily.nextYear.relatedToRemittanceDocument} />
                        </FileDl>

                        <FileDl>
                          <Dt>留学関係書類</Dt>
                          <File file={otherFamily.nextYear.provingStudyAbroadDocument} />
                        </FileDl>
                      </>
                    )}

                    <Dl>
                      <Dt>障害者区分</Dt>
                      <Dd>
                        {otherFamily.nextYear?.handicapType ? HANDICAP_CLASSIFICATION[otherFamily.nextYear.handicapType] : displayFormat()}
                      </Dd>
                    </Dl>

                    <Dl>
                      <Dt>障害者詳細</Dt>
                      <Dd>{otherFamily.nextYear?.handicapDetail}</Dd>
                    </Dl>

                    <FileDl>
                      <Dt>障害者手帳画像</Dt>
                      <File file={otherFamily.nextYear?.handicapImage} />
                    </FileDl>

                    <Dl>
                      <Dt>所得見積額</Dt>
                      <Dd>{amountFormat(otherFamily.nextYear?.earnings) || '0'}</Dd>
                    </Dl>
                  </PreviewSubSection.Body>
                </PreviewSubSection>
              </Grid>

              <Grid colGap>
                <Dl>
                  <Dt>税法上の扶養家族になった日</Dt>
                  <Dd>{dateFormat(otherFamily.dependentFrom, 'L')}</Dd>
                </Dl>
                <Dl>
                  <Dt>税法上の扶養に追加された理由</Dt>
                  <Dd>{displayFormat(otherFamily.dependentReason)}</Dd>
                </Dl>
                <Dl>
                  <Dt>死亡日</Dt>
                  <Dd>{dateFormat(otherFamily.diedOn, 'L')}</Dd>
                </Dl>
              </Grid>
            </div>
          </td>
        </tr>
      )}
    </>
  );
};
