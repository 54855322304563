import { gql } from '@apollo/client';
import { ASYNC_TASK_FRAGMENT } from '../components';

export const FINISHED_EMPLOYEES = gql`
  query result($per: Int, $page: Int, $search: EmployeeSearchInput, $year: Int!) {
    client {
      id
      clientYearly(year: $year) {
        id
        fixing
        fixed
        withholdingSlipCountByStatus(search: $search) {
          totalCount
          notFinished
          finished
          notCovered
        }
        employees(per: $per, page: $page, search: $search) {
          totalCount
          list {
            id
            staffCode
            email
            employmentType
            position
            requiredFieldsFilled
            hasNoPayment
            isNonResident
            taxSchedule
            profile {
              id
              fullName
              nextYearOnly
              hasSpouseDeductionThisYear
              resignBeforeYearEnd
              isNotCoveredByNextYear
              other
              isDisasterSufferer
            }
            status
            withholdingSlip {
              id
            }
            memos {
              id
              color
            }
            salaryGreaterThan20Million
          }
        }
      }
    }
  }
`;

export const FIX_DATA = gql`
  mutation fixData($year: Int!) {
    fixData(input: { year: $year }) {
      clientYearly {
        id
        fixed
        fixedAt
      }
    }
  }
`;

export const FIX_DATA_ASYNC = gql`
  mutation fixDataAsync($year: Int!) {
    fixDataAsync(input: { year: $year }) {
      clientYearly {
        id
        fixing
        asyncTask {
          ...AsyncTaskFields
        }
      }
    }
  }
  ${ASYNC_TASK_FRAGMENT}
`;

export const UNFIX_DATA = gql`
  mutation unfixData($year: Int!) {
    unfixData(input: { year: $year }) {
      clientYearly {
        id
        fixed
        fixedAt
      }
    }
  }
`;
