import { FC } from 'react';
import { Hint } from 'jbc-front/components/presenters/ui/Hint';
import { dateFormat, displayFormat, fullNameFormat } from '@jbc-year-end-adj/common/utils/formatter';
import { GENDER } from 'adminPages/employees/show/consts';
import { OtherFamily, Employee } from 'adminPages/employees/show/query';
import { Section } from 'components/ui/Section';
import styles from './ReductionTarget.scss';

const displaySymbol = (isDisplay: boolean, value: boolean) => {
  if (!isDisplay) {
    return '－';
  }
  return value ? '○' : '×';
};

type OtherFamilyRowProps = {
  otherFamily: OtherFamily;
  isDisplay: boolean;
};

const OtherFamilyRow: FC<OtherFamilyRowProps> = ({ otherFamily, isDisplay }) => {
  return (
    <tr>
      <td>{otherFamily.relationOther}</td>
      <td>
        {fullNameFormat(otherFamily.lastName, otherFamily.firstName)}（{fullNameFormat(otherFamily.lastNameKana, otherFamily.firstNameKana)}
        ）
      </td>
      <td>{dateFormat(otherFamily.birthday, 'L')}</td>
      <td>{otherFamily.gender ? GENDER[otherFamily.gender] : displayFormat()}</td>
      <td>{displaySymbol(isDisplay, otherFamily.isReductionTarget)}</td>
    </tr>
  );
};

type ReductionTargetProps = {
  employee: Employee;
};

export const ReductionTarget: FC<ReductionTargetProps> = ({ employee }) => {
  const profile = employee.profile;
  const spouse = profile.spouse;
  const isDisplay = ['accepted', 'not_need_adj'].includes(employee.status);

  return (
    <Section>
      <Section.Header>
        定額減税対象者
        <Hint className={styles.hint}>
          <HintContent />
        </Hint>
      </Section.Header>
      <Section.Body>
        <div className="l-overflow-scroll">
          <table className="m-table-list">
            <thead>
              <tr className={styles.radiusTh}>
                <th>続柄</th>
                <th>氏名</th>
                <th>生年月日</th>
                <th>性別</th>
                <th>定額減税対象</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>本人</td>
                <td className={styles.fullName}>
                  {fullNameFormat(profile.lastName, profile.firstName)}（{fullNameFormat(profile.lastNameKana, profile.firstNameKana)}）
                </td>
                <td>{dateFormat(profile.birthday, 'L')}</td>
                <td>{profile?.gender ? GENDER[profile.gender] : displayFormat()}</td>
                <td>{displaySymbol(isDisplay, employee.isReductionTarget)}</td>
              </tr>
              {spouse && (
                <tr>
                  <td>配偶者</td>
                  <td>
                    {spouse
                      ? `${fullNameFormat(spouse.lastName, spouse.firstName)}（${fullNameFormat(
                          spouse.lastNameKana,
                          spouse.firstNameKana
                        )}）`
                      : displayFormat()}
                  </td>
                  <td>{dateFormat(spouse?.birthday, 'L')}</td>
                  <td>{spouse?.gender ? GENDER[spouse.gender] : displayFormat()}</td>
                  <td>{displaySymbol(isDisplay, spouse.isReductionTarget)}</td>
                </tr>
              )}
              {profile.otherFamilies.map(otherFamily => (
                <OtherFamilyRow key={otherFamily.id} otherFamily={otherFamily} isDisplay={isDisplay} />
              ))}
            </tbody>
          </table>
        </div>
      </Section.Body>
    </Section>
  );
};

const HintContent: FC = () => {
  return (
    <div className={styles.text}>
      定額減税対象としてカウントされるのは以下のすべての条件を満たした場合です。
      <br />
      <br />
      【従業員本人】
      <br />
      ① 年末調整の対象
      <br />
      ② 税額表区分が「甲欄」
      <br />
      ③ 基本情報＞非居住者区分が「対象外」
      <br />
      ④ 合計所得金額が1,805万円以下
      <br />
      <br />
      【配偶者】
      <br />
      ① 今年の扶養情報＞同一生計配偶者が「対象」
      <br />
      ② 今年の扶養情報＞同居・別居が「同居」、「別居（国内）」、「別居（国外）」で非居住者にチェックなし
      <br />
      ③ 今年の扶養情報＞配偶者の給与収入＞合計所得金額が「48万円以下」
      <br />
      <br />
      【配偶者以外の扶養親族】
      <br />① 今年の税扶養＞今年の税の扶養対象が「対象」
      <div className={styles.indent}>
        ※今年の税扶養を「対象」とするためには、今年の税扶養＞扶養親族の給与収入＞所得見積額が「48万円以下」となっている必要があります。
      </div>
      ② 今年の税扶養＞同居・別居が「同居」、「別居（国内）」、「別居（国外）」で非居住者にチェックなし
      <br />
      <br />
      なお、従業員本人が定額減税対象としてカウントされない場合、【配偶者・配偶者以外扶養親族】が定額減税対象の要件を満たしていた場合であっても定額減税対象とはなりません。
    </div>
  );
};
