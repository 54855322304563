import { AdminSessionProvider } from '@jbc-year-end-adj/2024/features/AdminSessionProvider';
import { AsyncTaskProvider } from '@jbc-year-end-adj/2024/features/AsyncTask';
import { RightExpansionContainerProvider } from '@jbc-year-end-adj/2024/features/RightExpansionContainer';
import { AdminLayout } from '@jbc-year-end-adj/2024/components/layout/AdminLayout';
import { FixRunningGuard, NotFixedGuard, fixRunningGuard, fixedGuard } from '@jbc-year-end-adj/2024/features/Guard';
import { Show as EmployeeShow } from '../../adminPages/employees/show';

import { Settings } from '@jbc-year-end-adj/2024/adminPages/settings/index';
import { CustomMails } from '@jbc-year-end-adj/2024/adminPages/settings/customMails/index';
import { CustomMailsEdit } from '@jbc-year-end-adj/2024/adminPages/settings/customMails/edit';
import { ResidentTaxPaymentAddress } from '@jbc-year-end-adj/2024/adminPages/settings/residentTaxPaymentAddress/edit';
import { ClientSetting } from '@jbc-year-end-adj/2024/adminPages/settings/clientSetting/edit';
import { QuestionDescription } from '@jbc-year-end-adj/2024/adminPages/settings/questionDescription/edit';
import { ImportUpdate } from '@jbc-year-end-adj/2024/adminPages/importUpdate/show';

// @ts-ignore
import { Top } from '@jbc-year-end-adj/2024/adminPages/employees/index/Top';

// @ts-ignore
import * as pages from '../../pages';

// @ts-ignore;
import { Route, Switch } from 'react-router';

export const Routes = () => {
  return (
    <Switch>
      <Route path="/:year(\d+)?">
        <AdminSessionProvider>
          <AsyncTaskProvider>
            <RightExpansionContainerProvider>
              <AdminLayout>
                <Switch>
                  {/* new page */}
                  <Route path="/:year(\d+)?" exact component={fixRunningGuard(Top)} />
                  <Route path="/:year(\d+)?/settings" exact>
                    <FixRunningGuard>
                      <Settings />
                    </FixRunningGuard>
                  </Route>

                  <Route path="/:year(\d+)?/settings/custom_mails" exact>
                    <FixRunningGuard>
                      <CustomMails />
                    </FixRunningGuard>
                  </Route>

                  <Route path="/:year(\d+)?/settings/custom_mails/:email_type" exact>
                    <FixRunningGuard>
                      <CustomMailsEdit />
                    </FixRunningGuard>
                  </Route>

                  <Route path="/:year(\d+)?/resident_tax_payment_address" exact>
                    <FixRunningGuard>
                      <ResidentTaxPaymentAddress />
                    </FixRunningGuard>
                  </Route>

                  <Route path="/:year(\d+)?/client_setting" exact>
                    <FixRunningGuard>
                      <ClientSetting />
                    </FixRunningGuard>
                  </Route>

                  <Route path="/:year(\d+)?/settings/question_descriptions" exact>
                    <FixRunningGuard>
                      <QuestionDescription />
                    </FixRunningGuard>
                  </Route>

                  <Route path="/:year(\d+)?/employee/import_update" exact>
                    <NotFixedGuard>
                      <ImportUpdate />
                    </NotFixedGuard>
                  </Route>

                  {/* old page */}
                  <Route path="/:year(\d+)?/office" exact component={fixRunningGuard(pages.Office)} />
                  <Route path="/:year(\d+)?/employee/:id" exact>
                    <FixRunningGuard>
                      <EmployeeShow />
                    </FixRunningGuard>
                  </Route>
                  <Route path="/:year(\d+)?/employee/:id/edit" exact component={fixRunningGuard(pages.EmployeeEdit)} />
                  <Route path="/:year(\d+)?/employee/:id/withholding_slip" exact component={fixRunningGuard(pages.WithholdingSlip)} />
                  <Route path="/:year(\d+)?/csv_export_request" exact component={pages.DownloadFile} />
                  <Route path="/:year(\d+)?/result" exact component={pages.Result} />
                  <Route
                    path="/:year(\d+)/result/import_withholding_slip"
                    exact
                    component={fixRunningGuard(pages.ImportWithholdingSlips)}
                  />
                  <Route path="/:year(\d+)/result/difference_apply" exact component={fixedGuard(pages.DifferenceApply)} />
                  <Route path="/:year(\d+)/result/difference_apply/:id" exact component={fixedGuard(pages.DifferenceApplyDetail)} />
                  <Route path="/:year(\d+)/result/city_report" exact component={fixedGuard(pages.CityReport)} />
                  <Route path="/:year(\d+)/result/city_report_setting" exact component={fixedGuard(pages.CityReportSetting)} />
                  <Route path="/:year(\d+)/result/tax_office" exact component={fixedGuard(pages.TaxOffice)} />
                  <Route path="/:year(\d+)/result/tax_office/legal_records" exact component={fixedGuard(pages.LegalRecords)} />
                  <Route path="/:year(\d+)/result/withholding_slip_deliver" exact component={fixedGuard(pages.WithholdingSlipDeliver)} />
                  <Route component={pages.NotFound} />
                </Switch>
              </AdminLayout>
            </RightExpansionContainerProvider>
          </AsyncTaskProvider>
        </AdminSessionProvider>
      </Route>
    </Switch>
  );
};
