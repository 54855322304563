import { FC, ReactNode } from 'react';
import { Section } from '@jbc-year-end-adj/2024/components/ui/Section';
import { Separator } from '@jbc-year-end-adj/2024/components/ui/Separator';
import { Question } from '../types';

import { Lead } from './parts/Lead';
import { Description } from './parts/Description';
import { Note } from './parts/Note';
import { Content } from './parts/Content';

import { Hint } from 'jbc-front/components/presenters/ui/Hint';

type QuestionProps = {
  children: ReactNode;
};

const MainSection: FC<QuestionProps> = ({ children }) => {
  return (
    <Section>
      <Section.Header>{TITLE}</Section.Header>

      <Section.Body>
        <Content>{children}</Content>
      </Section.Body>
    </Section>
  );
};

export const TITLE = '配偶者情報';

type CheckSpouseProps = {
  question: Question;
  children: ReactNode;
};

export const CheckSpouse: FC<CheckSpouseProps> = ({ question, children }) => {
  const description = question.questionDescriptions.find(description => description.field === 'top')?.description;

  return (
    <>
      <Lead>配偶者について、該当するものを選んでください。</Lead>
      <Description>{description}</Description>
      <Note>
        <p>ひとり親とは、婚姻をしていないこと又は配偶者の生死の明らかでない一定の人のうち、次の三つの要件の全てに当てはまる人です。</p>
        <ul>
          <li>・その人と事実上婚姻関係と同様の事情にあると認められる一定の人がいないこと。</li>
          <li>
            ・生計を一にする子がいること。
            <Hint>
              「生計を一にする子」とは、総所得金額等が48万円以下で、他の人の控除対象配偶者や扶養親族になっていない人のことです。
              「合計所得金額」とは、各種損失の繰越控除を適用する前の総所得金額、譲渡所得、配当所得、雑所得、山林所得、退職所得の合計額をいいます。
              詳しくは
              <a href="https://www.nta.go.jp/taxes/shiraberu/taxanswer/shotoku/1180_qa.htm#q1" target="_blank" rel="noopener noreferrer">
                こちら
              </a>
            </Hint>
          </li>
          <li>
            ・合計所得金額が500万円以下であること。
            <Hint>
              「合計所得金額」とは、各種損失の繰越控除を適用する前の総所得金額、譲渡所得、配当所得、雑所得、山林所得、退職所得の合計額をいいます。詳しくは
              <a href="https://www.nta.go.jp/taxes/shiraberu/taxanswer/yogo/senmon.htm#word2" target="_blank" rel="noopener noreferrer">
                こちら
              </a>
            </Hint>
          </li>
        </ul>

        <div className="u-mt20">
          <p>
            寡婦とは、「ひとり親」に該当せず、次のいずれかに当てはまる人です。納税者と事実上婚姻関係と同様の事情にあると認められる一定の人がいる場合は対象となりません。
          </p>
          <ul className="m-list-notes u-mt20">
            <li>・夫と離婚した後婚姻をしておらず、扶養親族がいる人で、合計所得金額が500万円以下の人</li>
            <li>・夫と死別した後婚姻をしていない人又は夫の生死が明らかでない一定の人で、合計所得金額が500万円以下の人</li>
          </ul>
        </div>
      </Note>

      <Description>
        <p>※ 下記条件のいずれかに当てはまる方は、「配偶者なし （未婚・寡婦対象外）」を選択してください</p>
        <ul className="u-mt20">
          <li>・未婚</li>
          <li>・離婚した後婚姻をしておらず、寡婦に該当しない</li>
        </ul>
        <br />
        <p>※ 下記条件のいずれかに当てはまる方は、「配偶者なし（その他） 」を選択してください</p>
        <ul className="u-mt20">
          <li>・今年配偶者と死別</li>
          <li>・離婚した後婚姻をしておらず、寡婦に該当する</li>
        </ul>
      </Description>

      {children}
    </>
  );
};

type CheckWidowProps = {
  children: ReactNode;
};

export const CheckWidow: FC<CheckWidowProps> = ({ children }) => {
  return (
    <>
      <Separator />
      <Lead>あなたは以下のいずれかに該当しますか？</Lead>
      {children}
    </>
  );
};

export const Widow = Object.assign(MainSection, { CheckSpouse, CheckWidow });
