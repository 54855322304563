import React, { useState } from 'react';
import Button from 'jbc-front/components/Button';
import { renderSelectField as SelectField } from 'jbc-front/components/Form';
import _ from 'lodash';
import { useNotify } from '@jbc-year-end-adj/common/hooks/useNotify';
import { useMutation, convertSelectedIds, Modal } from '../components';
import styles from './BulkStatusUpdateModal.scss';
import { UPDATE_STATUS, UPDATE_STATUS_ASYNC } from './query';

const MAX_CHANGE_STATUS_COUNT = 100;
const STATUS_OPTIONS = [
  {
    label: '入力済（または 修正済）',
    value: 'applying'
  },
  {
    label: '完了（または 対象外）',
    value: 'accepted'
  }
];

export const statuses = {
  not_requested: '未依頼',
  not_need_adj: '対象外',
  in_progress: '依頼中',
  applying: '入力済',
  rejected: '再依頼中',
  fixed: '修正済',
  accepted: '完了'
};

export const BulkStatusUpdateModal = ({
  selectedTotalCount,
  allSelectMode,
  selected,
  isDisabledSubmit,
  isCurrentYear,
  taskRunningProps,
  reset,
  year
}) => {
  const [isConfirm, setIsConfirm] = useState(false);
  const [status, setStatus] = useState(null);
  const [failureEmployees, setFailureEmployees] = useState([]);
  const [updateStatusAsync, { loading: loadingUpdateStatusAsync }] = useMutation(UPDATE_STATUS_ASYNC);
  const [updateStatus, { loading: loadingUpdateStatus }] = useMutation(UPDATE_STATUS);
  const notify = useNotify();

  const handleConfirm = async hideModal => {
    if (isConfirm) {
      await handleSubmit(hideModal);
    } else {
      setIsConfirm(true);
    }
  };

  const handleSubmit = async hideModal => {
    const { employeeIds, exceptEmployeeIds } = convertSelectedIds(allSelectMode, Object.keys(_.pickBy(selected)));

    const variables = {
      input: {
        year,
        allSelectMode,
        employeeIds,
        exceptEmployeeIds,
        status
      }
    };

    if (selectedTotalCount > MAX_CHANGE_STATUS_COUNT) {
      await handleAsyncSubmit(variables, hideModal);
    } else {
      await handleSyncSubmit(variables, hideModal);
    }
  };

  const handleAsyncSubmit = async (variables, hideModal) => {
    await updateStatusAsync({ variables });

    notify('ステータスの更新を開始しました');
    setIsConfirm(false);
    setStatus(null);
    reset();
    hideModal();
  };

  const handleSyncSubmit = async (variables, hideModal) => {
    const result = await updateStatus({ variables });

    if (result.data.updateStatus.failureEmployees.length > 0) {
      setFailureEmployees(result.data.updateStatus.failureEmployees);
    } else {
      notify('ステータスを変更しました');
      setIsConfirm(false);
      setStatus(null);
      reset();
      hideModal();
    }
  };

  const handleCancel = hideModal => {
    setFailureEmployees([]);
    setIsConfirm(false);
    setStatus(null);
    reset();
    hideModal();
  };

  const ConfirmBody = () => {
    const getStatusLabel = status => {
      const option = STATUS_OPTIONS.find(option => option.value === status);
      return option.label;
    };

    return (
      <Modal.Body>
        <p>
          「依頼中」「再依頼中」から「入力済（修正済）」「完了（対象外）」のステータスに変更する場合は、
          <br />
          <span className={styles.warning}>提出が完了となり従業員が入力できなくなります。</span>
          <br />
          ステータスを一括変更してもよろしいですか？
          <br />
          <br />
          変更後のステータス「{getStatusLabel(status)}」
        </p>
      </Modal.Body>
    );
  };

  const InConfirmBody = () => {
    const input = {
      value: status,
      onChange: value => setStatus(value),
      onBlur: () => {}
    };

    const meta = {
      error: [],
      touched: false
    };

    return (
      <Modal.Body className={styles.modalBody}>
        <div className={styles.modalContent}>
          <p>選択された従業員のステータスを以下に変更します。</p>
          <p className={styles.annotation}>※「再依頼中」の従業員は「入力済」ではなく「修正済」に変更となります。</p>
          <p className={styles.annotation}>※年末調整の対象外に該当する従業員は「完了」ではなく「対象外」となります。</p>
          <SelectField input={input} meta={meta} options={STATUS_OPTIONS} addBlankOption={true} />
        </div>
      </Modal.Body>
    );
  };

  const UpdateFailureBody = () => {
    const option = STATUS_OPTIONS.find(option => option.value === status);
    return (
      <Modal.Body>
        <div className={styles.modalContent}>
          <p>以下の従業員のステータス更新に失敗しました</p>
          <p className={styles.warning}>従業員情報の必須項目が全て入力されているかご確認ください。</p>
          <br />
          <p>
            変更予定のステータス：<span className={styles.warning}>{option.label}</span>
          </p>
          <br />
          <ul>
            {failureEmployees.map(failureEmployee => {
              const {
                id,
                staffCode,
                status,
                profile: { fullName }
              } = failureEmployee;
              return <li key={id}>{`${staffCode} ${fullName}（${statuses[status]}）`}</li>;
            })}
          </ul>
        </div>
      </Modal.Body>
    );
  };

  const ModalBody = () => {
    if (failureEmployees.length > 0) {
      return <UpdateFailureBody failureEmployees={failureEmployees} />;
    } else if (isConfirm) {
      return <ConfirmBody />;
    } else {
      return <InConfirmBody />;
    }
  };

  const ModalFooterButtons = ({ hideModal }) => {
    if (failureEmployees.length > 0) {
      return <Button onClick={() => handleCancel(hideModal)}>閉じる</Button>;
    } else {
      return (
        <>
          <Button
            primary
            disabled={status == null || !isCurrentYear || isDisabledSubmit() || loadingUpdateStatus || loadingUpdateStatusAsync}
            onClick={async () => await handleConfirm(hideModal)}
            {...taskRunningProps}
          >
            一括変更する
          </Button>
          <Button onClick={() => handleCancel(hideModal)}>キャンセル</Button>
        </>
      );
    }
  };

  return (
    <Modal>
      {({ hideModal, showModal, isOpen }) => (
        <>
          <Button
            disabled={!isCurrentYear || isDisabledSubmit() || loadingUpdateStatus || loadingUpdateStatusAsync}
            onClick={async () => showModal()}
            {...taskRunningProps}
            primary
          >
            一括変更する
          </Button>
          {isOpen && (
            <Modal.Modal>
              <Modal.Header hideModal={() => handleCancel(hideModal)}>ステータス一括変更</Modal.Header>
              <ModalBody />
              <Modal.Footer>
                <Modal.Buttons>
                  <ModalFooterButtons hideModal={hideModal} />
                </Modal.Buttons>
              </Modal.Footer>
            </Modal.Modal>
          )}
        </>
      )}
    </Modal>
  );
};
