import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import useReactRouter from 'use-react-router';
import { parse } from 'query-string';
import { useNotify } from '@jbc-year-end-adj/common/hooks/useNotify';
import { useYear } from '../components';
import { default as _downloadFile } from '../downloadFile';
import styles from './DownloadFile.scss';

const makeErrorData = year => ({
  header: 'ダウンロードに失敗しました',
  redirect: `/:${year}(\\d+)?`,
  message: '依頼一覧へ移動する'
});

const makeErrorMessage = status => {
  switch (status) {
    case 403:
      return '該当操作を行う権限がありません';
    case 404:
      return 'このファイルは存在しないか、権限がありません';
    default:
      return 'ダウンロードに失敗しました';
  }
};

const DownloadFile = () => {
  const {
    location: { search }
  } = useReactRouter();
  const { type, token } = parse(search);
  const year = useYear();
  const notify = useNotify();
  const [errorData, setErrorData] = useState({ header: '', redirect: '', message: '' });
  const downloadFile = useCallback(() =>
    _downloadFile(
      '/csv_export_requests',
      err => {
        const { status } = err.response || {};
        if (status !== 401) {
          notify(makeErrorMessage(status), 'error');
        }
        setErrorData(makeErrorData(year));
      },
      { type, token, year },
      true
    )
  );

  useEffect(() => {
    downloadFile();
  }, [downloadFile]);

  return (
    <div className="l-flex">
      <div className={styles.cardWrap}>
        {errorData.header ? (
          <>
            {errorData.header && <h2>{errorData.header}</h2>}
            <Link to={errorData.redirect} className="u-txt-link">
              {errorData.message}
            </Link>
          </>
        ) : (
          <>
            <h2>ダウンロードは自動で実行されます</h2>
            <p className={styles.description}>
              ダウンロードが行われない場合は
              <span role="button" className="u-txt-link u-cur-pointer " onClick={() => downloadFile()}>
                こちら
              </span>
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default DownloadFile;
