import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { AnchorTarget } from 'jbc-front/components/ScrollSpy/v2';

import { Section } from '@jbc-year-end-adj/2024/components/ui/Section';
import { ToggleButton } from '@jbc-year-end-adj/2024/components/form/ToggleButton';
import styles from './Form.scss';

export const ImageUploadSection: FC = () => {
  const { register } = useFormContext();

  return (
    <Section>
      <AnchorTarget name="request_image_upload" />
      <Section.Header>添付データ設定</Section.Header>
      <Section.Body>
        <p>従業員に生命保険等の証明書の画像データを添付依頼できます</p>
        <p>※ ただし、画像データの有無に関係なく、生命保険等の証明書の原本は保管する義務がありますのでご注意ください。</p>
        <div className={styles.listContainer}>
          <h3 className={styles.list}>・生命保険料</h3>
          <ToggleButton.Container>
            <ToggleButton {...register('requestImageUploadSettings.lifeInsurance')} value="none">
              添付させない
            </ToggleButton>
            <ToggleButton {...register('requestImageUploadSettings.lifeInsurance')} value="optional">
              任意で添付させる
            </ToggleButton>
            <ToggleButton {...register('requestImageUploadSettings.lifeInsurance')} value="required">
              必須で添付させる
            </ToggleButton>
          </ToggleButton.Container>

          <h3 className={styles.list}>・地震保険料</h3>
          <ToggleButton.Container>
            <ToggleButton {...register('requestImageUploadSettings.earthquakeInsurance')} value="none">
              添付させない
            </ToggleButton>
            <ToggleButton {...register('requestImageUploadSettings.earthquakeInsurance')} value="optional">
              任意で添付させる
            </ToggleButton>
            <ToggleButton {...register('requestImageUploadSettings.earthquakeInsurance')} value="required">
              必須で添付させる
            </ToggleButton>
          </ToggleButton.Container>

          <h3 className={styles.list}>・社会保険料（国民年金・国民年金基金）</h3>
          <ToggleButton.Container>
            <ToggleButton {...register('requestImageUploadSettings.socialInsurance')} value="none">
              添付させない
            </ToggleButton>
            <ToggleButton {...register('requestImageUploadSettings.socialInsurance')} value="optional">
              任意で添付させる
            </ToggleButton>
            <ToggleButton {...register('requestImageUploadSettings.socialInsurance')} value="required">
              必須で添付させる
            </ToggleButton>
          </ToggleButton.Container>

          <h3 className={styles.list}>・社会保険料（上記以外）</h3>
          <ToggleButton.Container>
            <ToggleButton {...register('requestImageUploadSettings.pensionInsurance')} value="none">
              添付させない
            </ToggleButton>
            <ToggleButton {...register('requestImageUploadSettings.pensionInsurance')} value="optional">
              任意で添付させる
            </ToggleButton>
            <ToggleButton {...register('requestImageUploadSettings.pensionInsurance')} value="required">
              必須で添付させる
            </ToggleButton>
          </ToggleButton.Container>

          <h3 className={styles.list}>・小規模企業共済等掛金</h3>
          <ToggleButton.Container>
            <ToggleButton {...register('requestImageUploadSettings.premium')} value="none">
              添付させない
            </ToggleButton>
            <ToggleButton {...register('requestImageUploadSettings.premium')} value="optional">
              任意で添付させる
            </ToggleButton>
            <ToggleButton {...register('requestImageUploadSettings.premium')} value="required">
              必須で添付させる
            </ToggleButton>
          </ToggleButton.Container>

          <h3 className={styles.list}>・住宅ローン</h3>
          <ToggleButton.Container>
            <ToggleButton {...register('requestImageUploadSettings.housingLoan')} value="none">
              添付させない
            </ToggleButton>
            <ToggleButton {...register('requestImageUploadSettings.housingLoan')} value="optional">
              任意で添付させる
            </ToggleButton>
            <ToggleButton {...register('requestImageUploadSettings.housingLoan')} value="required">
              必須で添付させる
            </ToggleButton>
          </ToggleButton.Container>

          <h3 className={styles.list}>・学生証</h3>
          <ToggleButton.Container>
            <ToggleButton {...register('requestImageUploadSettings.studentIdCard')} value="none">
              添付させない
            </ToggleButton>
            <ToggleButton {...register('requestImageUploadSettings.studentIdCard')} value="optional">
              任意で添付させる
            </ToggleButton>
            <ToggleButton {...register('requestImageUploadSettings.studentIdCard')} value="required">
              必須で添付させる
            </ToggleButton>
          </ToggleButton.Container>

          <h3 className={styles.list}>・障害者手帳</h3>
          <ToggleButton.Container>
            <ToggleButton {...register('requestImageUploadSettings.handicap')} value="none">
              添付させない
            </ToggleButton>
            <ToggleButton {...register('requestImageUploadSettings.handicap')} value="optional">
              任意で添付させる
            </ToggleButton>
            <ToggleButton {...register('requestImageUploadSettings.handicap')} value="required">
              必須で添付させる
            </ToggleButton>
          </ToggleButton.Container>

          <h3 className={styles.list}>・非居住者・留学関連書類</h3>
          <ToggleButton.Container>
            <ToggleButton {...register('requestImageUploadSettings.nonResidentAndStudyAbroad')} value="none">
              添付させない
            </ToggleButton>
            <ToggleButton {...register('requestImageUploadSettings.nonResidentAndStudyAbroad')} value="optional">
              任意で添付させる
            </ToggleButton>
            <ToggleButton {...register('requestImageUploadSettings.nonResidentAndStudyAbroad')} value="required">
              必須で添付させる
            </ToggleButton>
          </ToggleButton.Container>

          <h3 className={styles.list}>・前職源泉徴収票</h3>
          <ToggleButton.Container>
            <ToggleButton {...register('requestImageUploadSettings.previousWithholdingSlip')} value="none">
              添付させない
            </ToggleButton>
            <ToggleButton {...register('requestImageUploadSettings.previousWithholdingSlip')} value="optional">
              任意で添付させる
            </ToggleButton>
            <ToggleButton {...register('requestImageUploadSettings.previousWithholdingSlip')} value="required">
              必須で添付させる
            </ToggleButton>
          </ToggleButton.Container>
        </div>
      </Section.Body>
    </Section>
  );
};
