import { FC, useState } from 'react';

import { Spacer } from 'jbc-front/components/presenters/Spacer';
import Button from 'jbc-front/components/Button';
import { FloatingContainer } from 'components/ui/FloatingContainer';
import { Navigator } from 'components/ui/Navigator';
import { Employee, EmployeeAddressClassification } from '../../query';
import { Anchor, Target, AnchorProvider } from '../../components/Anchor';
import { EmployeeInfoProvider } from './EmployeeInfoProvider';
import { BasicInformation } from './BasicInformation';
import { ResidentCardAddress } from './ResidentCardAddress';
import { TaxClassification } from './TaxClassification';
import { ResidentTax } from './ResidentTax';
import { FormerJobs } from './FormerJobs';
import { EmployeeIncome } from './EmployeeIncome';
import { Spouse } from './Spouse';
import { OtherFamilies } from './OtherFamilies';
import { AttachedFiles } from './AttachedFiles';
import { FlatTaxReduction } from './FlatTaxReduction';
import { Insurances } from './Insurances';
import { Premium } from './Premium';
import { HousingLoan } from './HousingLoan';
import { AcceptModal } from './AcceptModal';
import { ReRequestModal } from './RejectModal';
import { MoveApplyingModal } from './MoveApplyingModal';
import { useYear } from 'hooks/useYear';

// @ts-ignore
import { useSession } from 'components/SessionProvider';

import styles from './EmployeeInfo.scss';
import { ViewType } from '../../components/ToggleSection';

type SectionMode = {
  basicInformation: ViewType;
  residentCardAddress: ViewType;
  residentTax: ViewType;
  taxClassification: ViewType;
  formerJobs: ViewType;
  employeeIncome: ViewType;
  spouse: ViewType;
  otherFamilies: ViewType;
  flatTaxReduction: ViewType;
  insurances: ViewType;
  premium: ViewType;
  housingLoan: ViewType;
  attachedFiles: ViewType;
};

type MainContentProps = {
  employee: Employee;
  employeeAddressClassification: EmployeeAddressClassification;
};

const MainContent: FC<MainContentProps> = ({ employee, employeeAddressClassification }) => {
  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  const [isReRequestModalOpen, setIsReRequestModalOpen] = useState(false);
  const [isMoveApplyingModalOpen, setIsMoveApplyingModalOpen] = useState(false);
  const [sectionModeManager, setSectionModeManager] = useState<SectionMode>({
    basicInformation: 'preview',
    residentCardAddress: 'preview',
    residentTax: 'preview',
    taxClassification: 'preview',
    formerJobs: 'preview',
    employeeIncome: 'preview',
    spouse: 'preview',
    otherFamilies: 'preview',
    flatTaxReduction: 'preview',
    insurances: 'preview',
    premium: 'preview',
    housingLoan: 'preview',
    attachedFiles: 'preview'
  });
  const { clientYearly, isCurrentYear } = useSession();
  const year = useYear();

  const handleSetMode = (key: string, viewType: ViewType) => {
    setSectionModeManager(prev => ({ ...prev, [key]: viewType }));
  };

  return (
    <EmployeeInfoProvider employee={employee} employeeAddressClassification={employeeAddressClassification}>
      <Target id="basic-info">
        <BasicInformation setMode={viewType => handleSetMode('basicInformation', viewType)} />
      </Target>
      <Target id="resident-card-address">
        <ResidentCardAddress setMode={viewType => handleSetMode('residentCardAddress', viewType)} />
      </Target>
      <Target id="resident-tax">
        <ResidentTax setMode={viewType => handleSetMode('residentTax', viewType)} />
      </Target>
      <Target id="tax-classification">
        <TaxClassification setMode={viewType => handleSetMode('taxClassification', viewType)} />
      </Target>
      <Target id="former-jobs">
        <FormerJobs setMode={viewType => handleSetMode('formerJobs', viewType)} />
      </Target>
      <Target id="employee-income">
        <EmployeeIncome setMode={viewType => handleSetMode('employeeIncome', viewType)} />
      </Target>
      <Target id="spouse">
        <Spouse setMode={viewType => handleSetMode('spouse', viewType)} />
      </Target>
      <Target id="other-families">
        <OtherFamilies setMode={viewType => handleSetMode('otherFamilies', viewType)} />
      </Target>
      {year === 2024 && (
        <Target id="flat-tax-reduction">
          <FlatTaxReduction setMode={viewType => handleSetMode('flatTaxReduction', viewType)} />
        </Target>
      )}
      <Target id="insurances">
        <Insurances setMode={viewType => handleSetMode('insurances', viewType)} />
      </Target>
      <Target id="premium">
        <Premium setMode={viewType => handleSetMode('premium', viewType)} />
      </Target>
      <Target id="housing-loan">
        <HousingLoan setMode={viewType => handleSetMode('housingLoan', viewType)} />
      </Target>
      <Target id="attached-files">
        <AttachedFiles setMode={viewType => handleSetMode('attachedFiles', viewType)} />
      </Target>

      <Spacer direction="y" size={20} />

      {!clientYearly.fixed && Object.values(sectionModeManager).every((mode: ViewType) => mode === 'preview') && (
        <>
          {employee.status === 'accepted' && (
            <FloatingContainer>
              <Button onClick={() => setIsMoveApplyingModalOpen(true)}>入力済に戻す</Button>
              {isCurrentYear && <Button onClick={() => setIsReRequestModalOpen(true)}>再依頼</Button>}
            </FloatingContainer>
          )}
          {!['accepted', 'applying', 'fixed'].includes(employee.status) && (
            <FloatingContainer>
              <Button primary onClick={() => setIsAcceptModalOpen(true)}>
                完了
              </Button>
            </FloatingContainer>
          )}
        </>
      )}

      <AcceptModal onClose={() => setIsAcceptModalOpen(false)} isOpen={isAcceptModalOpen} />
      <ReRequestModal onClose={() => setIsReRequestModalOpen(false)} isOpen={isReRequestModalOpen} />
      <MoveApplyingModal onClose={() => setIsMoveApplyingModalOpen(false)} isOpen={isMoveApplyingModalOpen} />
    </EmployeeInfoProvider>
  );
};

const SideContent: FC = () => {
  const year = useYear();

  return (
    <AnchorProvider initialAnchor="basic-info">
      <Navigator className={styles.employeeNavigator}>
        <Navigator.Body>
          <Navigator.Item className={styles.item}>
            <Anchor id="basic-info">基本情報</Anchor>
          </Navigator.Item>
          <Navigator.Item className={styles.item}>
            <Anchor id="resident-card-address">住民票住所</Anchor>
          </Navigator.Item>
          <Navigator.Item className={styles.item}>
            <Anchor id="resident-tax">住民税</Anchor>
          </Navigator.Item>
          <Navigator.Item className={styles.item}>
            <Anchor id="tax-classification">税区分情報</Anchor>
          </Navigator.Item>
          <Navigator.Item className={styles.item}>
            <Anchor id="former-jobs">前職情報</Anchor>
          </Navigator.Item>
          <Navigator.Item className={styles.item}>
            <Anchor id="employee-income">給与収入等</Anchor>
          </Navigator.Item>
          <Navigator.Item className={styles.item}>
            <Anchor id="spouse">配偶者情報</Anchor>
          </Navigator.Item>
          <Navigator.Item className={styles.item}>
            <Anchor id="other-families">扶養親族情報</Anchor>
          </Navigator.Item>
          {year === 2024 && (
            <Navigator.Item className={styles.item}>
              <Anchor id="flat-tax-reduction">定額減税情報</Anchor>
            </Navigator.Item>
          )}
          <Navigator.Item className={styles.item}>
            <Anchor id="insurances">保険情報</Anchor>
          </Navigator.Item>
          <Navigator.Item className={styles.item}>
            <Anchor id="premium">小規模企業共済等掛金</Anchor>
          </Navigator.Item>
          <Navigator.Item className={styles.item}>
            <Anchor id="housing-loan">住宅ローン</Anchor>
          </Navigator.Item>
          <Navigator.Item className={styles.item}>
            <Anchor id="attached-files">添付ファイル</Anchor>
          </Navigator.Item>
        </Navigator.Body>
      </Navigator>
    </AnchorProvider>
  );
};

export const EmployeeInfo = {
  MainContent,
  SideContent
};
