import { useMemo } from 'react';
import {
  salaryEarnings as calcSalaryEarnings,
  generalEarningsAllowMinus,
  generalEarnings,
  miscellaneousEarnings as calcMiscellaneousEarnings,
  retirementIncomeDeductionAmount as calcRetirementIncomeDeductionAmount,
  retirementEarnings as calcRetirementEarnings,
  totalEarnings as calcTotalEarnings
  // @ts-ignore
} from 'utils/income';

type Income = {
  salaryIncome?: string;
  businessIncome?: string;
  businessExpense?: string;
  miscellaneousIncomeOfficialPension?: string;
  miscellaneousIncomeOther?: string;
  miscellaneousExpense?: string;
  dividendIncome?: string;
  dividendExpense?: string;
  realEstateIncome?: string;
  realEstateExpense?: string;
  retirementIncome?: string;
  lengthOfService?: string;
  retireForDisablity?: boolean;
  isOfficerRetirementAllowance?: boolean;
  otherIncome?: string;
  otherExpense?: string;
  otherExpenseSpecialDeduction?: string;
  otherEarnings?: string;
};

export const useCalcEarnings = (year: number, income: Income, birthday?: string) => {
  const {
    salaryIncome,
    businessIncome,
    businessExpense,
    miscellaneousIncomeOfficialPension,
    miscellaneousIncomeOther,
    miscellaneousExpense,
    dividendIncome,
    dividendExpense,
    realEstateIncome,
    realEstateExpense,
    retirementIncome,
    lengthOfService,
    retireForDisablity,
    isOfficerRetirementAllowance,
    otherEarnings
  } = income;

  const salaryEarnings = useMemo(() => {
    return calcSalaryEarnings(salaryIncome, year);
  }, [salaryIncome, year]);

  const businessEarnings = useMemo(() => {
    return generalEarningsAllowMinus(businessIncome, businessExpense);
  }, [businessIncome, businessExpense]);

  const dividendEarnings = useMemo(() => {
    return generalEarnings(dividendIncome, dividendExpense);
  }, [dividendIncome, dividendExpense]);

  const realEstateEarnings = useMemo(() => {
    return generalEarningsAllowMinus(realEstateIncome, realEstateExpense);
  }, [realEstateIncome, realEstateExpense]);

  const retirementIncomeDeductionAmount = useMemo(() => {
    return calcRetirementIncomeDeductionAmount(lengthOfService, retireForDisablity, retirementIncome);
  }, [lengthOfService, retireForDisablity, retirementIncome]);

  const retirementEarnings = useMemo(() => {
    return calcRetirementEarnings(retirementIncome, retirementIncomeDeductionAmount, isOfficerRetirementAllowance, lengthOfService, year);
  }, [retirementIncome, retirementIncomeDeductionAmount, isOfficerRetirementAllowance, lengthOfService]);

  const miscellaneousEarnings = useMemo(() => {
    const earnings = {
      salary: salaryEarnings,
      business: businessEarnings,
      dividend: dividendEarnings,
      realEstate: realEstateEarnings,
      retirement: retirementEarnings,
      other: otherEarnings
    };

    return calcMiscellaneousEarnings(
      miscellaneousIncomeOfficialPension,
      miscellaneousIncomeOther,
      miscellaneousExpense,
      birthday,
      year,
      earnings
    );
  }, [
    miscellaneousIncomeOfficialPension,
    miscellaneousIncomeOther,
    miscellaneousExpense,
    birthday,
    year,
    salaryEarnings,
    businessEarnings,
    dividendEarnings,
    realEstateEarnings,
    retirementEarnings,
    otherEarnings
  ]);

  const totalEarnings = useMemo(() => {
    return calcTotalEarnings(
      salaryEarnings,
      businessEarnings,
      miscellaneousEarnings,
      dividendEarnings,
      realEstateEarnings,
      retirementEarnings,
      otherEarnings
    );
  }, [salaryEarnings, businessEarnings, miscellaneousEarnings, dividendEarnings, realEstateEarnings, retirementEarnings, otherEarnings]);

  return {
    salaryEarnings,
    businessEarnings,
    miscellaneousEarnings,
    dividendEarnings,
    realEstateEarnings,
    retirementIncomeDeductionAmount,
    retirementEarnings,
    totalEarnings
  };
};
