import { FC } from 'react';
import { Dl, Dt, Dd } from '../../../components/DefinitionList';
import { Grid } from '../../../components/Grid';
import { PreviewSubSection } from '../../../components/PreviewSubSection';
import { Section } from 'components/ui/Section';
import { useYear } from 'hooks/useYear';
import { useEmployeeInfo } from '../EmployeeInfoProvider';
import { FormerJob } from '../../../query';
import styles from './Preview.scss';
import { amountFormat } from '@jbc-year-end-adj/common/utils/formatter';
import { Hint } from 'jbc-front/components/presenters/ui/Hint';

import {
  calcTotalSalaryIncome,
  salaryEarnings as calcSalaryEarnings,
  salaryEarningsFrom2024 as calcSalaryEarningsFrom2024,
  totalEarnings as calcTotalEarnings,
  miscellaneousEarnings as calcMiscellaneousEarnings
  // @ts-ignore
} from 'utils/income';
import { applicableForEmployee, applicableForSpouse, applicableForOtherFamily } from '@jbc-year-end-adj/ancient/utils/incomeAdjustment';

export const Preview: FC = () => {
  const year = useYear();
  const {
    employee: { profile }
  } = useEmployeeInfo();
  const income = profile.income;

  const calcFormerJobsPaymentAmount = (formerJobs: FormerJob[] | undefined) => {
    if (!formerJobs) return 0;

    return formerJobs.reduce((totalIncome, formerJob) => {
      if (formerJob.paymentAmount) {
        return totalIncome + Number(formerJob.paymentAmount);
      } else {
        return totalIncome;
      }
    }, 0);
  };

  const incomeAdjustmentDeduction = (() => {
    const handicapClassification = profile.handicapClassification?.classification;
    const spouseHandicapType = profile.spouse?.thisYear?.handicapType;
    const spouseTotalEarnings = profile.spouse?.income?.totalEarnings;
    const otherFamilies = profile.otherFamilies;

    return (
      applicableForEmployee(handicapClassification) ||
      applicableForSpouse(spouseHandicapType, spouseTotalEarnings) ||
      (otherFamilies || []).some(otherFamily => applicableForOtherFamily(otherFamily, year))
    );
  })();

  const formerJobsPaymentAmount = String(calcFormerJobsPaymentAmount(profile.formerJobs));
  const totalSalaryIncome = String(calcTotalSalaryIncome(income?.mainJobIncome, income?.sideJobIncome, formerJobsPaymentAmount));
  const salaryEarnings =
    year >= 2024
      ? calcSalaryEarningsFrom2024(income?.mainJobIncome, income?.sideJobIncome, formerJobsPaymentAmount, incomeAdjustmentDeduction)
      : calcSalaryEarnings(totalSalaryIncome, year, incomeAdjustmentDeduction);

  const miscellaneousEarnings = calcMiscellaneousEarnings(
    income?.miscellaneousIncomeOfficalPension,
    income?.miscellaneousIncomeOther,
    income?.miscellaneousExpense,
    profile.birthday,
    year,
    {
      salary: salaryEarnings,
      business: income?.businessEarnings,
      dividend: income?.dividendEarnings,
      realEstate: income?.realEstateEarnings,
      retirement: income?.retirementEarnings,
      other: income?.otherEarings
    }
  );

  const totalEarnings = calcTotalEarnings(
    salaryEarnings,
    income?.businessEarnings,
    miscellaneousEarnings,
    income?.dividendEarnings,
    income?.realEstateEarnings,
    income?.retirementEarnings,
    income?.otherEarings
  );

  return (
    <div className={styles.preview}>
      <Grid>
        <Dl>
          <Dt>合計所得金額</Dt>
          <Dd>{amountFormat(totalEarnings)}</Dd>
        </Dl>
      </Grid>

      <Section>
        <Section.Header>本人の給与収入</Section.Header>
        <Section.Body>
          <Grid colGap>
            <PreviewSubSection>
              <PreviewSubSection.Header>給与所得</PreviewSubSection.Header>
              <PreviewSubSection.Body>
                <Dl>
                  <Dt className={styles.mainJobIncomeHeader}>
                    収入金額（当社）
                    <Hint>
                      この項目に入力した金額は源泉徴収票に反映されません。
                      <br />
                      また、源泉徴収票編集画面で課税支払額を登録した場合はこの項目に反映されません。
                      <br />
                      源泉徴収票に反映される金額の登録は源泉徴収票編集画面より行ってください。
                    </Hint>
                  </Dt>
                  <Dd>{amountFormat(income?.mainJobIncome)}</Dd>
                </Dl>
                <Dl>
                  <Dt>収入金額（他社）</Dt>
                  <Dd>{amountFormat(income?.sideJobIncome)}</Dd>
                </Dl>
                <Dl>
                  <Dt>収入金額（前職）</Dt>
                  <Dd>{amountFormat(formerJobsPaymentAmount)}</Dd>
                </Dl>
                <Dl>
                  <Dt>収入金額（合計）</Dt>
                  <Dd>{amountFormat(totalSalaryIncome)}</Dd>
                </Dl>
                <Dl>
                  <Dt>所得金額</Dt>
                  <Dd>{amountFormat(salaryEarnings)}</Dd>
                </Dl>
              </PreviewSubSection.Body>
            </PreviewSubSection>
          </Grid>
        </Section.Body>
      </Section>

      <Section>
        <Section.Header>本人の給与収入以外の収入</Section.Header>
        <Section.Body>
          <Grid colGap>
            <PreviewSubSection>
              <PreviewSubSection.Header>事業所得</PreviewSubSection.Header>
              <PreviewSubSection.Body>
                <Dl>
                  <Dt>収入金額</Dt>
                  <Dd>{amountFormat(income?.businessIncome)}</Dd>
                </Dl>
                <Dl>
                  <Dt>必要経費</Dt>
                  <Dd>{amountFormat(income?.businessExpense)}</Dd>
                </Dl>
                <Dl>
                  <Dt>所得金額</Dt>
                  <Dd>{amountFormat(income?.businessEarnings)}</Dd>
                </Dl>
              </PreviewSubSection.Body>
            </PreviewSubSection>

            <PreviewSubSection>
              <PreviewSubSection.Header>雑所得</PreviewSubSection.Header>
              <PreviewSubSection.Body>
                <Dl>
                  <Dt>収入金額（公的年金等）</Dt>
                  <Dd>{amountFormat(income?.miscellaneousIncomeOfficalPension)}</Dd>
                </Dl>
                <Dl>
                  <Dt>収入金額（公的年金等以外）</Dt>
                  <Dd>{amountFormat(income?.miscellaneousIncomeOther)}</Dd>
                </Dl>
                <Dl>
                  <Dt>必要経費</Dt>
                  <Dd>{amountFormat(income?.miscellaneousExpense)}</Dd>
                </Dl>
                <Dl>
                  <Dt>所得金額</Dt>
                  <Dd>{amountFormat(miscellaneousEarnings)}</Dd>
                </Dl>
              </PreviewSubSection.Body>
            </PreviewSubSection>

            <PreviewSubSection>
              <PreviewSubSection.Header>配当所得</PreviewSubSection.Header>
              <PreviewSubSection.Body>
                <Dl>
                  <Dt>収入金額</Dt>
                  <Dd>{amountFormat(income?.dividendIncome)}</Dd>
                </Dl>
                <Dl>
                  <Dt>必要経費</Dt>
                  <Dd>{amountFormat(income?.dividendExpense)}</Dd>
                </Dl>
                <Dl>
                  <Dt>所得金額</Dt>
                  <Dd>{amountFormat(income?.dividendEarnings)}</Dd>
                </Dl>
              </PreviewSubSection.Body>
            </PreviewSubSection>

            <PreviewSubSection>
              <PreviewSubSection.Header>不動産所得</PreviewSubSection.Header>
              <PreviewSubSection.Body>
                <Dl>
                  <Dt>収入金額</Dt>
                  <Dd>{amountFormat(income?.realEstateIncome)}</Dd>
                </Dl>
                <Dl>
                  <Dt>必要経費</Dt>
                  <Dd>{amountFormat(income?.realEstateExpense)}</Dd>
                </Dl>
                <Dl>
                  <Dt>所得金額</Dt>
                  <Dd>{amountFormat(income?.realEstateEarnings)}</Dd>
                </Dl>
              </PreviewSubSection.Body>
            </PreviewSubSection>

            <PreviewSubSection>
              <PreviewSubSection.Header>退職所得</PreviewSubSection.Header>
              <PreviewSubSection.Body>
                <Dl>
                  <Dt>収入金額</Dt>
                  <Dd>{amountFormat(income?.retirementIncome)}</Dd>
                </Dl>
                {income?.retirementIncome && Number(income.retirementIncome.split(',').join()) > 0 && (
                  <>
                    <Dl>
                      <Dt>勤続年数</Dt>
                      <Dd>{income?.lengthOfService}</Dd>
                    </Dl>
                    <Dl>
                      <Dt>障害者になったことに直接基因して退職した</Dt>
                      <Dd>{income?.retireForDisablity ? 'はい' : 'いいえ'}</Dd>
                    </Dl>
                    <Dl>
                      <Dt>退職手当等が特定役員退職手当等に該当する</Dt>
                      <Dd>{income?.isOfficerRetirementAllowance ? 'はい' : 'いいえ'}</Dd>
                    </Dl>
                  </>
                )}
                <Dl>
                  <Dt>控除額</Dt>
                  <Dd>{amountFormat(income?.retirementIncomeDeductionAmount)}</Dd>
                </Dl>
                <Dl>
                  <Dt>所得金額</Dt>
                  <Dd>{amountFormat(income?.retirementEarnings)}</Dd>
                </Dl>
              </PreviewSubSection.Body>
            </PreviewSubSection>

            <PreviewSubSection>
              <PreviewSubSection.Header>その他所得</PreviewSubSection.Header>
              <PreviewSubSection.Body>
                <Dl>
                  <Dt>その他所得 収入金額</Dt>
                  <Dd>{amountFormat(income?.otherIncome)}</Dd>
                </Dl>
                <Dl>
                  <Dt>その他所得 必要経費</Dt>
                  <Dd>{amountFormat(income?.otherExpense)}</Dd>
                </Dl>
                <Dl>
                  <Dt>その他所得 必要経費 うち特別控除額</Dt>
                  <Dd>{amountFormat(income?.otherExpenseSpecialDeduction)}</Dd>
                </Dl>
                <Dl>
                  <Dt>その他所得 所得金額</Dt>
                  <Dd>{amountFormat(income?.otherEarings)}</Dd>
                </Dl>
              </PreviewSubSection.Body>
            </PreviewSubSection>
          </Grid>
        </Section.Body>
      </Section>
    </div>
  );
};
