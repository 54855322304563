import { gql } from '@apollo/client';
import { Schema } from './schema';

export type Variables = {
  input: {
    employeeId: string;
    attributes: {
      reduceTargetCount: number;
    };
  };
};

export const convertToVariables = (data: Schema, employeeId: string) => {
  return {
    input: {
      employeeId,
      attributes: {
        reduceTargetCount: data.reduceTargetCount
      }
    }
  };
};

export const UPDATE_FLAT_TAX_REDUCTION = gql`
  mutation UpdateFlatTaxReduction($input: UpdateFlatTaxReductionInput!) {
    updateFlatTaxReduction(input: $input) {
      employee {
        id
        reduceTargetCount
      }
    }
  }
`;
