import { yup } from '@jbc-year-end-adj/common/yup';

export type Schema = {
  reduceTargetCount: number;
};

const errorMessage = '減税対象人数は半角数字「0~99」で入力してください。';

export const schema = yup.object({
  reduceTargetCount: yup
    .number()
    .typeError(errorMessage)
    .integer(errorMessage)
    .min(0, errorMessage)
    .max(99, errorMessage)
});

export const generateDefaultValues = (reduceTargetCount: number): Schema => {
  return {
    reduceTargetCount: reduceTargetCount
  };
};
