import { FC, ReactNode, ComponentType } from 'react';
import { useSession } from '../AdminSessionProvider';

// @ts-ignore
import { Redirect } from 'react-router';

type GuardProps = {
  children: ReactNode;
};

export const FixedGuard: FC<GuardProps> = ({ children }) => {
  const { clientYearly } = useSession();
  return clientYearly?.fixed ? <>{children}</> : <Redirect to={`/${clientYearly?.year}`} />;
};

export const FixRunningGuard: FC<GuardProps> = ({ children }) => {
  const { clientYearly } = useSession();
  return clientYearly?.fixing ? <Redirect to={`/${clientYearly?.year}/result`} /> : <>{children}</>;
};

export const NotFixedGuard: FC<GuardProps> = ({ children }) => {
  const { clientYearly } = useSession();
  return clientYearly?.fixed || clientYearly?.fixing ? <Redirect to={`/${clientYearly?.year}`} /> : <>{children}</>;
};

export const fixedGuard = <WrappedComponentProps extends object>(WrappedComponent: ComponentType<WrappedComponentProps>) => {
  const ComponentWithFixedGuard = (props: WrappedComponentProps) => {
    const { clientYearly } = useSession();
    return clientYearly?.fixed ? <WrappedComponent {...props} /> : <Redirect to={`/${clientYearly?.year}`} />;
  };

  return ComponentWithFixedGuard;
};

export const fixRunningGuard = <WrappedComponentProps extends object>(WrappedComponent: ComponentType<WrappedComponentProps>) => {
  const ComponentWithFixRunningGuard = (props: WrappedComponentProps) => {
    const { clientYearly } = useSession();
    return clientYearly?.fixing ? <Redirect to={`/${clientYearly?.year}/result`} /> : <WrappedComponent {...props} />;
  };

  return ComponentWithFixRunningGuard;
};
