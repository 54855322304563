import * as yup from 'yup';
import { Maybe } from 'yup';
import { FileType } from '@jbc-year-end-adj/types';
import {
  kanaValidator,
  dateValidator,
  numberValidator,
  positiveAmountValidator,
  maxAmountValidator,
  maxFileSizeValidator
} from './utils/validators';
import { amountFormat } from './utils/formatter';

yup.setLocale({
  mixed: {
    required: ({ path, spec: { meta } }) => {
      if (meta?.file || meta?.select) {
        return `${path}を選択してください`;
      } else {
        return `${path}を入力してください`;
      }
    }
  }
});

yup.addMethod<yup.StringSchema>(yup.string, 'kana', function zenkakuKanaSpace() {
  return this.test(
    'kana',
    ({ label }) => `${label}を全角カタカナとスペースで入力してください`,
    (value?: string) => {
      if (!value) return true;
      return kanaValidator(value);
    }
  );
});

yup.addMethod<yup.StringSchema>(yup.string, 'dateFormat', function dateFormat() {
  return this.test(
    'dateFormat',
    ({ label }) => `${label}の入力形式が正しくありません`,
    (value?: string) => {
      if (!value) return true;
      return dateValidator(value);
    }
  );
});

yup.addMethod<yup.StringSchema>(yup.string, 'numberFormat', function numberFormat() {
  return this.test(
    'numberFormat',
    ({ label }) => `${label}の入力形式が正しくありません`,
    (value?: string) => {
      if (!value) return true;
      return numberValidator(value);
    }
  );
});

yup.addMethod<yup.StringSchema>(yup.string, 'positiveAmountFormat', function positiveAmountFormat() {
  return this.test(
    'positiveAmountFormat',
    ({ label }) => `${label}は半角数値と「,」で正の数を入力してください`,
    (value?: string) => {
      if (!value) return true;
      return positiveAmountValidator(value);
    }
  );
});

yup.addMethod<yup.StringSchema>(yup.string, 'maxAmount', function maxAmount(max: number) {
  return this.test(
    'maxAmount',
    ({ label }) => `${label}は${amountFormat(String(max))}円以下で入力してください`,
    (value?: string) => {
      if (!value) return true;
      return maxAmountValidator(value, max);
    }
  );
});

const MAX_FILE_SIZE = 1024 * 1024 * 10;

yup.addMethod<yup.MixedSchema>(yup.mixed, 'fileSize', function fileSize() {
  return this.file().test(
    'fileSize',
    ({ label }) => `${label}にアップロードできるファイル容量は10MB迄です。ご確認の上、再度アップロードください。`,
    (value?: FileType | File) => {
      if (!value) return true;
      if (!(value instanceof File)) return true;

      return maxFileSizeValidator(value, MAX_FILE_SIZE);
    }
  );
});

yup.addMethod<yup.MixedSchema>(yup.mixed, 'file', function file() {
  return this.meta({ file: true });
});

declare module 'yup' {
  interface StringSchema<TType extends Maybe<string> = string | undefined> {
    kana(): StringSchema<TType>;
    dateFormat(): StringSchema<TType>;
    numberFormat(): StringSchema<TType>;
    positiveAmountFormat(): StringSchema<TType>;
    maxAmount(max: number): StringSchema<TType>;
  }
  interface MixedSchema {
    fileSize(): MixedSchema<File | FileType>;
    file(): MixedSchema;
  }
}

export { yup };
