import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { ReadMoreReadLess } from 'jbc-front/components/presenters/ui/ReadMoreReadLess';
import { Note } from 'jbc-front/components/presenters/ui/Note';
import { ActionButton } from 'jbc-front/components/ActionButton';
import { Copy } from 'jbc-front/components/icons';

import { Section } from 'components/ui/Section';
import { FormSection } from 'components/form/FormSection';
import { Radio } from 'components/form/Radio';
import { Textarea } from 'components/form/Textarea';
import { FileField } from 'components/react-hook-form/FileField';
import { FormField } from 'components/form/FormField';
import { Row } from 'components/form/Row';
import { useYear } from 'hooks/useYear';

import { DifferentAddress } from './DifferentAddress';
import { DifferentAndForeignAddress } from './DifferentAndForeignAddress';
import { Schema } from '../../schema';
import { FormSeparator } from '../../../../../../components/FormSeparator';
import { Grid } from '../../../../../../components/Grid';
import styles from './NextYear.scss';
import { FilePreview } from 'components/feature/FilePreview';
import { AmountField } from 'components/react-hook-form/AmountField';

// @ts-ignore
import { salaryEarnings as calcSalaryEarnings } from 'utils/income';

export const NextYear: FC = () => {
  const {
    formState: { errors, isDirty },
    register,
    watch,
    setValue,
    getValues
  } = useFormContext<Schema>();

  const year = useYear();
  const thisYearDependentTaxLaw = watch('spouse.thisYear.dependentTaxLaw');
  const dependentTaxLaw = watch('spouse.nextYear.dependentTaxLaw');
  const residenceStatus = watch('spouse.nextYear.yearlyInfo.residenceStatus');
  const handicapType = watch('spouse.nextYear.yearlyInfo.handicapType');
  const handicapImage = watch('spouse.nextYear.yearlyInfo.handicapImage');
  const salaryIncome = watch('spouse.nextYear.yearlyInfo.income');

  const copyThisYear = () => {
    const thisYear = getValues(`spouse.thisYear`);
    setValue(`spouse.nextYear.dependentTaxLaw`, thisYear?.dependentTaxLaw || 'false');
    setValue(`spouse.nextYear.yearlyInfo.residenceStatus`, thisYear?.yearlyInfo?.residenceStatus || 'same_address');
    setValue(`spouse.nextYear.yearlyInfo.postcode0`, thisYear?.yearlyInfo?.postcode0);
    setValue(`spouse.nextYear.yearlyInfo.postcode1`, thisYear?.yearlyInfo?.postcode1);
    setValue(`spouse.nextYear.yearlyInfo.prefectureId`, thisYear?.yearlyInfo?.prefectureId);
    setValue(`spouse.nextYear.yearlyInfo.city`, thisYear?.yearlyInfo?.city);
    setValue(`spouse.nextYear.yearlyInfo.street`, thisYear?.yearlyInfo?.street);
    setValue(`spouse.nextYear.yearlyInfo.building`, thisYear?.yearlyInfo?.building);
    setValue(`spouse.nextYear.yearlyInfo.addressForeign`, thisYear?.yearlyInfo?.addressForeign);
    setValue(`spouse.nextYear.yearlyInfo.isNonResident`, thisYear?.yearlyInfo?.isNonResident);
    setValue(`spouse.nextYear.yearlyInfo.remittance`, thisYear?.yearlyInfo?.remittance);
    setValue(`spouse.nextYear.yearlyInfo.handicapType`, thisYear?.yearlyInfo?.handicapType || 'none');
    setValue(`spouse.nextYear.yearlyInfo.handicapDetail`, thisYear?.yearlyInfo?.handicapDetail);
    if (year >= 2024) {
      setValue(`spouse.nextYear.yearlyInfo.income`, thisYear?.yearlyInfo?.income?.salaryIncome, { shouldDirty: true });
    } else {
      setValue(`spouse.nextYear.yearlyInfo.earnings`, thisYear?.yearlyInfo?.earnings);
    }
  };

  useEffect(() => {
    if ((year >= 2024, isDirty)) {
      const salaryEarnings = calcSalaryEarnings(salaryIncome, year);
      setValue('spouse.nextYear.yearlyInfo.earnings', salaryEarnings);
    }
  }, [salaryIncome]);

  return (
    <Section>
      <Section.Header>来年の扶養情報</Section.Header>
      <Section.Body>
        <FormSection>
          {thisYearDependentTaxLaw === 'true' && (
            <Grid>
              <ActionButton className={styles.copyThisYear} icon={<Copy size={13} />} onClick={copyThisYear}>
                今年の扶養情報をコピーする
              </ActionButton>
            </Grid>
          )}

          <Grid>
            <FormField>
              <Radio.Container
                required
                label="来年の源泉控除対象、または障害者控除対象"
                error={errors.spouse?.nextYear?.dependentTaxLaw?.message}
              >
                <Radio value="true" {...register('spouse.nextYear.dependentTaxLaw')}>
                  対象
                </Radio>
                <Radio value="false" {...register('spouse.nextYear.dependentTaxLaw')}>
                  対象外
                </Radio>
              </Radio.Container>

              {year >= 2024 && (
                <FormField.NoteContainer>
                  <Note>
                    <ReadMoreReadLess maxLength={19}>
                      従業員本人の所得が「900万円以下（給与収入のみの場合は1,095万円以下）」で配偶者の所得が「95万以下（給与収入のみの場合は150万円以下）の場合に源泉控除対象配偶者となります。
                      <br />
                      年金収入のみの場合は108万円（65歳未満）、158万円（65歳以上）を超える場合は源泉控除対象配偶者に該当しません。
                    </ReadMoreReadLess>
                  </Note>
                </FormField.NoteContainer>
              )}
            </FormField>
          </Grid>

          {dependentTaxLaw === 'true' && (
            <>
              <FormSeparator />
              <Grid>
                <Radio.Container label="同居・別居" required error={errors.spouse?.nextYear?.yearlyInfo?.residenceStatus?.message}>
                  <Radio value="same_address" {...register('spouse.nextYear.yearlyInfo.residenceStatus')}>
                    同居
                  </Radio>
                  <Radio value="different_address" {...register('spouse.nextYear.yearlyInfo.residenceStatus')}>
                    別居（国内）
                  </Radio>
                  <Radio value="different_and_foreign_address" {...register('spouse.nextYear.yearlyInfo.residenceStatus')}>
                    別居（国外）
                  </Radio>
                </Radio.Container>
              </Grid>

              {residenceStatus === 'different_address' && <DifferentAddress />}
              {residenceStatus === 'different_and_foreign_address' && <DifferentAndForeignAddress />}

              <FormSeparator />

              <Grid>
                <Radio.Container label="障害者区分" required error={errors.spouse?.nextYear?.yearlyInfo?.handicapType?.message}>
                  <Radio value="none" {...register('spouse.nextYear.yearlyInfo.handicapType')}>
                    対象外
                  </Radio>
                  <Radio value="normal" {...register('spouse.nextYear.yearlyInfo.handicapType')}>
                    一般障害者
                  </Radio>
                  <Radio value="special" {...register('spouse.nextYear.yearlyInfo.handicapType')}>
                    特別障害者
                  </Radio>
                </Radio.Container>
                {['normal', 'special'].includes(handicapType) && (
                  <>
                    <Textarea label="詳細" rows={3} {...register('spouse.nextYear.yearlyInfo.handicapDetail')} />

                    <FileField label="障害者手帳画像" name={'spouse.nextYear.yearlyInfo.handicapImage'}>
                      {handicapImage && (
                        <FileField.FileContainer>
                          <FileField.File
                            file={handicapImage}
                            onRemove={() => setValue('spouse.nextYear.yearlyInfo.handicapImage', undefined)}
                          />
                          <FilePreview file={handicapImage} />
                        </FileField.FileContainer>
                      )}
                    </FileField>
                  </>
                )}
              </Grid>

              <FormSeparator />

              <Grid>
                <FormField>
                  {year >= 2024 ? (
                    <>
                      <Row>
                        <AmountField
                          label="給与収入等"
                          error={errors.spouse?.nextYear?.yearlyInfo?.income?.message}
                          isError={!!errors.spouse?.nextYear?.yearlyInfo?.income?.message}
                          {...register('spouse.nextYear.yearlyInfo.income')}
                        />
                        <AmountField
                          label="所得見積額"
                          error={errors.spouse?.nextYear?.yearlyInfo?.earnings?.message}
                          isError={!!errors.spouse?.nextYear?.yearlyInfo?.earnings?.message}
                          {...register('spouse.nextYear.yearlyInfo.earnings')}
                        />
                      </Row>

                      <FormField.NoteContainer>
                        <Note>
                          <ReadMoreReadLess maxLength={19}>
                            「今年の扶養情報をコピーする」ボタンを使用した場合、給与収入金額と給与所得金額が
                            「給与収入等」に反映されます。また、「給与収入等」に手入力された金額は、すべて給与
                            収入と判定し、「所得見積額」が自動計算されます。給与収入以外の収入がある場合は、
                            「給与収入等」と「所得見積額」を手入力してください。
                          </ReadMoreReadLess>
                        </Note>
                      </FormField.NoteContainer>
                    </>
                  ) : (
                    <>
                      <AmountField
                        label="所得見積額"
                        error={errors.spouse?.nextYear?.yearlyInfo?.earnings?.message}
                        isError={!!errors.spouse?.nextYear?.yearlyInfo?.earnings?.message}
                        {...register('spouse.nextYear.yearlyInfo.earnings')}
                      />
                      <FormField.NoteContainer>
                        <Note>
                          <ReadMoreReadLess maxLength={19}>
                            従業員本人の所得が「900万円以下（給与収入のみの場合は1,095万円以下）」で配偶者の所得が「95万以下（給与収入のみの場合は150万円以下）の場合に源泉控除対象配偶者となります。
                            <br />
                            年金収入のみの場合は108万円（65歳未満）、158万円（65歳以上）を超える場合は源泉控除対象配偶者に該当しません。
                          </ReadMoreReadLess>
                        </Note>
                      </FormField.NoteContainer>
                    </>
                  )}
                </FormField>
              </Grid>
            </>
          )}
        </FormSection>
      </Section.Body>
    </Section>
  );
};
