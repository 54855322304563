import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Field } from 'redux-form';
import useReactRouter from 'use-react-router';
import _ from 'lodash';
import Button from 'jbc-front/components/Button';
import {
  useAsyncTask,
  useQuery,
  useSession,
  useYear,
  AsyncTaskError,
  FormNameProvider,
  LoadingPage,
  Memo,
  SelectList,
  SelectedResult,
  Title
} from '../components';
import MemoAlertList from '../components/MemoAlertList';
import { SearchEmploymentStatusField } from '../components/SearchForm';
import searchForm from '../employees/SearchForm';
import { SearchSelectBox } from 'jbc-front/components/SearchForm';
import PaginatorWithResult from '../employees/PaginatorWithResult';
import { getCurrentQueryFromLocation, convertQueryToForm, convertFormToGraphQl, hasConditions } from '../employees/utils';
import { makeBackUrl } from '../utils/url';
import recordDisplay from '../utils/recordDisplay';
import Cooperation from '../top/Cooperation';
import DownloadEmployeesCsv from '../top/DownloadEmployeesCsv';
import FloatingButtonArea from '../top/FloatingButtonArea';
import SetNotNeedAdj from '../top/SetNotNeedAdj';
import { EMPLOYEES } from '../top/query';
import styles from './Top.scss';
import classNames from 'classnames';

export const DELETE_MODE = 'delete';
export const REQUEST_MODE = 'request';
export const CHANGE_STATUS_MODE = 'change_status';
const MODE_OPTIONS = [
  { label: '一括依頼', value: REQUEST_MODE },
  { label: 'ステータス一括変更', value: CHANGE_STATUS_MODE },
  { label: '従業員一括削除', value: DELETE_MODE }
];

const requestStatuses = [
  { id: 'all', name: 'すべて', color: styles.blueTag, active: styles.active },
  { id: 'not_requested', name: '未依頼', color: styles.redTag, active: styles.active },
  { id: 'in_progress', name: '依頼中', color: styles.blueTag, active: styles.active },
  { id: 'applying', name: '入力済', color: styles.yellowTag, active: styles.active },
  { id: 'rejected', name: '再依頼中', color: styles.blueTag, active: styles.active },
  { id: 'fixed', name: '修正済', color: styles.yellowTag, active: styles.active },
  { id: 'accepted', name: '完了', color: styles.greenTag, active: styles.active },
  { id: 'not_need_adj', name: '対象外', color: styles.greyTag, active: styles.active }
];

const searchFormName = 'employeeSearchTop';

const SearchForm = searchForm(searchFormName);

export const statuses = {
  not_requested: '未依頼',
  not_need_adj: '対象外',
  in_progress: '依頼中',
  applying: '入力済',
  rejected: '再依頼中',
  fixed: '修正済',
  accepted: '完了'
};

const ModeSelectField = ({ onChange, value, disabled = false }) => {
  const input = {
    value,
    onChange,
    onBlur: () => {},
    disabled: disabled
  };

  const meta = {
    error: [],
    touched: false
  };

  return <SearchSelectBox input={input} meta={meta} options={MODE_OPTIONS} />;
};

const Employees = ({ mode, onChangeMode, variables, data, loadingEmployees, refetchEmployees }) => {
  const {
    history,
    location: { search, pathname }
  } = useReactRouter();
  const year = useYear();
  const isDeleteMode = mode === DELETE_MODE;
  const { isCurrentYear } = useSession();
  const { taskRunningProps } = useAsyncTask();
  const totalCount = _.get(data, 'client.clientYearly.employees.totalCount', 0);
  const employees = _.get(data, 'client.clientYearly.employees.list', []);
  const selectableCount = _.get(data, 'client.clientYearly.selectableEmployeesCount', 0);
  const fixed = _.get(data, 'client.clientYearly.fixed', false);
  const canBulkDelete = isCurrentYear || isDeleteMode;
  const selectedInfo = ({ staffCode, profile, status }) => {
    const prefix = staffCode ? staffCode + ' ' : '';
    const name = `${prefix}${profile.fullName}`;

    return { name, status: statuses[status] };
  };

  const disabledMessage = employee => {
    if (isDeleteMode || mode === CHANGE_STATUS_MODE) return;

    return employee.status === 'not_need_adj'
      ? '対象外です'
      : employee.status === 'applying' || employee.status === 'fixed'
      ? '既に依頼済みです'
      : !employee.email || !_.trim(employee.email)
      ? `従業員のメールアドレスが未入力のため依頼できません。
                        従業員への依頼が必要な場合、労務HR・給与計算から従業員メールアドレスを入力して再度連携してください。`
      : null;
  };

  useEffect(() => {
    if (_.isUndefined(taskRunningProps)) {
      return;
    }

    if (!taskRunningProps.disabled) {
      refetchEmployees();
    }
  }, [refetchEmployees, taskRunningProps]);

  const moveEmployeeDetail = employee_id => {
    history.push(
      `/${year}/employee/${employee_id}?back_to=${makeBackUrl({
        pathname,
        search
      })}`
    );
  };

  return (
    <SelectList
      mode={mode}
      variables={variables}
      list={employees}
      totalCount={totalCount}
      selectableCount={selectableCount}
      disabledMessage={disabledMessage}
      selectedInfo={selectedInfo}
    >
      {({ list, selected, allSelectMode, isDisabledSubmit, selectedResultCount, reset, th }) => {
        const selectedTotalCount = selectedResultCount();

        if (loadingEmployees) {
          return <LoadingPage />;
        }

        return (
          <>
            <div className={classNames(styles.selectedEmployeeRow, 'u-mb10')}>
              <div className={styles.modeSelectContainer}>
                <ModeSelectField className={styles.modeSelect} value={mode} onChange={value => onChangeMode(value)} disabled={fixed} />
              </div>
              {list.length > 0 && <SelectedResult selectedTotalCount={selectedTotalCount} />}
            </div>

            <div className="l-overflow-scroll">
              <table className={styles.table}>
                <thead>
                  <tr>
                    {canBulkDelete && th}
                    <th className={styles.noteColumn}>メモ</th>
                    <th className={styles.staffcodeColumn}>スタッフコード</th>
                    <th className={styles.nameColumn}>氏名</th>
                    <th className={styles.employmentStatusColumn}>雇用形態</th>
                    <th className={styles.positionColumn}>役職</th>
                    <th className={styles.emailColumn}>メールアドレス</th>
                    <th className={styles.statusColumn}>ステータス</th>
                  </tr>
                </thead>
                <tbody>
                  {list.length > 0 ? (
                    list.map(({ item: employee, td }) => (
                      <tr key={employee.id} onClick={() => moveEmployeeDetail(employee.id)} className="table-hover">
                        {canBulkDelete && td}
                        <td onClick={e => e.stopPropagation()} className={styles.memo}>
                          <Memo employee={employee} size={30} />
                        </td>
                        <td>{employee.staffCode}</td>
                        <td>{employee.profile.fullName}</td>
                        <td>{recordDisplay(employee.employmentType)}</td>
                        <td>{recordDisplay(employee.position)}</td>
                        <td>{recordDisplay(employee.email)}</td>
                        <SetNotNeedAdj employee={employee} disabled={taskRunningProps?.disabled} />
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={canBulkDelete ? 8 : 7}>該当の従業員は見つかりませんでした</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <FormNameProvider formName={searchFormName}>
              <div className={styles.paginator}>
                <PaginatorWithResult count={totalCount} />
              </div>
            </FormNameProvider>
            <FloatingButtonArea
              mode={mode}
              onChangeMode={onChangeMode}
              variables={variables}
              selected={selected}
              allSelectMode={allSelectMode}
              isDisabledSubmit={isDisabledSubmit}
              selectedTotalCount={selectedTotalCount}
              selectedInfo={selectedInfo}
              reset={reset}
            />
          </>
        );
      }}
    </SelectList>
  );
};

const Top = ({ location: { search, pathname }, history, memoEdit, setMemoEdit }) => {
  const year = useYear();
  const { me, isCurrentYear } = useSession();
  const [mode, setMode] = useState(REQUEST_MODE);
  const formValues = convertQueryToForm(getCurrentQueryFromLocation({ search, pathname }, me));
  const variables = { year, ...convertFormToGraphQl(formValues), mode };
  const { data, loading: loadingEmployees, refetch: refetchEmployees } = useQuery(EMPLOYEES, {
    variables,
    fetchPolicy: 'network-only'
  });
  const countByStatus = _.get(data, 'client.clientYearly.employeeCountByStatus');

  return (
    <div>
      <div className="l-main-title-wrap">
        <>
          <Title>年末調整の依頼一覧</Title>
          {!isCurrentYear && (
            <p className={styles.errorMessage}>過去の年末調整では、従業員への年末調整依頼、ステータス一括変更を行うことはできません。</p>
          )}
        </>
      </div>
      <div className="l-contents-wrap">
        <div className={styles.yeaContentsWrap}>
          <MemoAlertList year={year} />
          <div className={styles.buttonArea}>
            <div className={styles.buttonList}>
              <DownloadEmployeesCsv search={variables.search} hasConditions={hasConditions(formValues)} />
              <div className={styles.buttonRight}>
                {isCurrentYear && (
                  <>
                    <Cooperation refetchEmployees={refetchEmployees} fixed={_.get(data, 'client.clientYearly.fixed', false)} />
                    <Link to={`/${year}/employee/import_update`}>
                      <Button primary widthWide>
                        従業員データ一括更新
                      </Button>
                    </Link>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className={styles.searchArea}>
            <SearchForm
              query={EMPLOYEES}
              variables={variables}
              statusField={handleSubmit => (
                <Field
                  name="status"
                  component={SearchEmploymentStatusField}
                  employmentStatuses={requestStatuses}
                  onEmploymentStatusChange={handleSubmit}
                  countByStatus={countByStatus}
                />
              )}
            />
            <AsyncTaskError
              displayTypes={[
                'employee_import',
                'withholding_slip_csv_import',
                'employee_import_from_prev_year_job',
                'employees_csv_import',
                'employees_export_to_service',
                'send_request',
                'bulk_delete_employees',
                'update_status'
              ]}
            />
            <Employees
              mode={mode}
              onChangeMode={setMode}
              variables={variables}
              data={data}
              loadingEmployees={loadingEmployees}
              refetchEmployees={refetchEmployees}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Top;
