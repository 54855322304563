import React from 'react';
import { gql, useApolloClient } from '@apollo/client';
import { useQuery, useYear, useSession, Memo, LoadingPage, PreviewPdf, useReportList } from '../components';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import Button from 'jbc-front/components/Button';
import { makeBackUrl, parseBackUrl } from '../utils/url';
import styles from './DifferenceApplyDetail.scss';
import { parse } from 'query-string';
import { ArrowDoublePrev, ArrowDoubleNext } from 'jbc-front/components/icons';
import { useNotify } from '@jbc-year-end-adj/common/hooks/useNotify';
import useReactRouter from 'use-react-router';
import { numberWithSplit } from '../utils';

const WITHHOLDING_SLIP_DETAIL = gql`
  query withholdingSlipDetail($id: ID!, $search: EmployeeSearchInput, $year: Int!) {
    client {
      id
      clientYearly(year: $year) {
        id
        employeeWithAround(id: $id, search: $search) {
          current {
            id
            staffCode
            insuranceAmountFinalSum {
              id
              premium
              socialInsurance
              lifeInsuranceDeduction
              earthquakeInsuranceAmountCalcTotal
              housingLoanDeductionApplicableAmount
            }
            withholdingSlip {
              id
              payPaymentAmount
              bonusPaymentAmount
              formerJobsPaymentAmount
              totalAmountPaid
              amountAfterDeduction
              adjustmentPaymentAmount
              incomeAdjustmentDeduction
              amountAfterDeductionAndAdjust

              payDeductionAmount
              bonusDeductionAmount
              formerJobsDeductionAmount
              handicapDeduction
              adjustmentDeductionAmount

              spouseDeduction
              spouseSpecialDeduction
              dependentDeduction
              basicDeduction
              incomeDeductionAmount

              taxableIncome
              incomeTaxCalculated
              housingLoanDeductionAmount
              withholdingTax
              paidIncomeTax
              differenceToApply
              smallMutualAidPremiumSum
            }
            profile {
              id
              fullName
              widowClassification {
                id
                deductionAmount
              }
              workingStudentClassification {
                id
                deductionAmount
              }
            }
            memos {
              id
            }
          }
          next {
            id
            profile {
              id
              fullName
            }
          }
          prev {
            id
            profile {
              id
              fullName
            }
          }
        }
      }
    }
  }
`;

const Item = ({ label, amount, sub, sublabel }) => (
  <>
    <dt className={sublabel && styles.sublabel}>
      {sub && '- '}
      {label}
    </dt>
    {!sublabel && <dd>{numberWithSplit(amount)}円</dd>}
  </>
);

const DifferenceApplyDetail = ({
  location,
  match: {
    params: { id }
  }
}) => {
  const { search: searchStr, back_to } = parse(location.search);
  const search = searchStr && JSON.parse(searchStr);
  const year = useYear();
  const { data, loading } = useQuery(WITHHOLDING_SLIP_DETAIL, {
    fetchPolicy: 'network-only',
    variables: { id, search, year }
  });
  const {
    history,
    location: { searchUrl, pathname }
  } = useReactRouter();
  const {
    clientYearly,
    me: { hasMyNumberPermission }
  } = useSession();
  const notify = useNotify();
  const apolloClient = useApolloClient();
  const reports = useReportList(year);
  if (loading) return <LoadingPage />;
  const {
    client: {
      clientYearly: {
        employeeWithAround: {
          current: employee,
          current: {
            withholdingSlip,
            insuranceAmountFinalSum,
            staffCode,
            profile: { widowClassification, workingStudentClassification, fullName }
          },
          next,
          prev
        }
      }
    }
  } = data;
  return (
    <div>
      <div className="l-main-title-wrap">
        <h1 className="m-title-main">過不足額の精算</h1>
      </div>
      <div className="l-contents-wrap">
        <div className="l-wrap-xl">
          <div>
            <div className={styles.topNavi}>
              <div className="l-breadcrumb">
                <Link to={`/${year}/result/difference_apply`} className="l-breadcrumb-link">
                  過不足額の精算
                </Link>
                <span className="l-breadcrumb-here">従業員情報</span>
              </div>
              <div>
                {prev && (
                  <Link to={`/${year}/result/difference_apply/${prev.id}/?back_to=${back_to}&search=${encodeURIComponent(searchStr)}`}>
                    <ArrowDoublePrev className={styles.arrowIcon} />
                    {prev.profile.fullName}
                  </Link>
                )}
                {prev && next && <span className={styles.arrowSplit}>|</span>}
                {next && (
                  <Link to={`/${year}/result/difference_apply/${next.id}/?back_to=${back_to}&search=${encodeURIComponent(searchStr)}`}>
                    {next.profile.fullName}
                    <ArrowDoubleNext className={styles.arrowIcon} />
                  </Link>
                )}
              </div>
            </div>
            <div className={styles.name}>
              <Memo employee={employee} />
              <span className="u-ml20">
                {staffCode} {fullName}
              </span>
            </div>

            <PreviewPdf>
              {({ show, isOpen, setPdf }) => {
                const reportOnClick = (report, employee, showMyNumber) => e => {
                  e.stopPropagation();
                  reports[report].show({
                    client: apolloClient,
                    setPdf,
                    show,
                    year,
                    notify,
                    employeeId: employee.id,
                    edit: null,
                    showMyNumber,
                    hasMyNumberPermission,
                    onEditClick: () => {
                      history.push(
                        `/${year}/employee/${employee.id}?back_to=${makeBackUrl({
                          searchUrl,
                          pathname
                        })}`
                      );
                    }
                  });
                };
                return (
                  <div className={styles.buttonArea}>
                    {year >= 2019 && (
                      <div className={styles.csvButtonControl}>
                        <Button onClick={reportOnClick('withholdingBook', employee)} className={styles.reportButton}>
                          源泉徴収簿
                        </Button>
                      </div>
                    )}
                  </div>
                );
              }}
            </PreviewPdf>

            <div className={styles.wrap}>
              <div>
                <div className={styles.category}>支給額</div>
                <dl className={styles.item}>
                  <Item
                    label="給与の支払金額"
                    amount={+withholdingSlip.payPaymentAmount + +(withholdingSlip.adjustmentPaymentAmount || 0)}
                  />
                  <Item label="賞与の支払金額" amount={withholdingSlip.bonusPaymentAmount} />
                  <Item label="前職の支払金額" amount={withholdingSlip.formerJobsPaymentAmount} />
                  <Item label="支払金額の合計" amount={withholdingSlip.totalAmountPaid} />
                  <Item label="給与所得控除後の金額" amount={withholdingSlip.amountAfterDeduction} />
                  {year >= 2020 && (
                    <>
                      <Item label="所得金額調整控除額" amount={withholdingSlip.incomeAdjustmentDeduction} />
                      <Item label="所得金額調整控除後の金額" amount={withholdingSlip.amountAfterDeductionAndAdjust} />
                    </>
                  )}
                </dl>
              </div>
              <div>
                <div className={styles.category}>控除額</div>
                <dl className={styles.item}>
                  <Item
                    label="給与の社会保険料等控除額（天引き）"
                    amount={+withholdingSlip.payDeductionAmount + +(withholdingSlip.adjustmentDeductionAmount || 0)}
                  />
                  <Item label="賞与の社会保険料等控除額（天引き）" amount={withholdingSlip.bonusDeductionAmount} />
                  <Item label="前職の社会保険料等控除額（天引き）" amount={withholdingSlip.formerJobsDeductionAmount} />
                  <Item label="生命保険料控除額" amount={insuranceAmountFinalSum.lifeInsuranceDeduction} />
                  <Item label="地震保険料控除額" amount={insuranceAmountFinalSum.earthquakeInsuranceAmountCalcTotal} />
                  <Item label="社会保険料控除額" amount={insuranceAmountFinalSum.socialInsurance} />
                  <Item label="小規模企業共済等掛金控除額" amount={withholdingSlip.smallMutualAidPremiumSum} />
                  <Item label="扶養控除" sublabel />
                  <Item label="障害者控除" amount={withholdingSlip.handicapDeduction} sub />
                  <Item label="寡婦・ひとり親控除" amount={widowClassification?.deductionAmount || 0} sub />
                  <Item label="勤労学生控除" amount={workingStudentClassification?.deductionAmount || 0} sub />
                  <Item label="配偶者控除" amount={withholdingSlip.spouseDeduction} sub />
                  <Item label="配偶者特別控除" amount={withholdingSlip.spouseSpecialDeduction} sub />
                  <Item label="扶養控除" amount={withholdingSlip.dependentDeduction} sub />
                  <Item label="基礎控除" amount={withholdingSlip.basicDeduction} sub />
                  <Item
                    label="合計"
                    amount={
                      [
                        withholdingSlip.handicapDeduction,
                        widowClassification?.deductionAmount || 0,
                        workingStudentClassification?.deductionAmount || 0,
                        withholdingSlip.spouseDeduction,
                        withholdingSlip.spouseSpecialDeduction,
                        withholdingSlip.dependentDeduction,
                        withholdingSlip.basicDeduction
                      ].map(x => +x) |> _.sum
                    }
                    sub
                  />
                  <Item label="所得控除の額の合計額" amount={withholdingSlip.incomeDeductionAmount} sub />
                </dl>
              </div>
              <div>
                <div className={styles.category}>税額</div>
                <dl className={styles.item}>
                  <Item label="課税所得" amount={withholdingSlip.taxableIncome} />
                  <Item label="算出所得税額" amount={withholdingSlip.incomeTaxCalculated} />
                  <Item label="住宅借入金等特別控除の額" amount={insuranceAmountFinalSum.housingLoanDeductionApplicableAmount} />
                  <Item
                    label="年調所得税額"
                    amount={Math.max(0, withholdingSlip.incomeTaxCalculated - withholdingSlip.housingLoanDeductionAmount)}
                  />
                  <Item label="年調年税額" amount={withholdingSlip.withholdingTax} />
                  <Item label="源泉徴収した所得税の額" amount={withholdingSlip.paidIncomeTax} />
                  <Item label="差引超過又は不足額" amount={withholdingSlip.differenceToApply} />
                </dl>
              </div>
            </div>

            <p className={styles.note}>
              入力された情報に誤りがある場合、年末調整確定を解除した後情報を修正してください。
              <br />
              計算システムに誤りがある場合、ジョブカン労務HR・給与計算サポート窓口までお問い合わせください。
            </p>
            <div className={styles.button}>
              <Button widthWide as={Link} to={parseBackUrl(location, `/${year}/result/difference_apply`)}>
                一覧に戻る
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DifferenceApplyDetail;
