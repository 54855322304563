import { FC, useState } from 'react';
import Button from 'jbc-front/components/Button';

import { Section } from '@jbc-year-end-adj/2024/components/ui/Section';
import { Question } from '@jbc-year-end-adj/2024/features/questions/types';
import { TITLE, CheckWidow, CheckSpouse } from '@jbc-year-end-adj/2024/features/questions/templates/Widow';
import { ButtonContainer } from '@jbc-year-end-adj/2024/features/questions/components/ButtonContainer';
import { Content } from '@jbc-year-end-adj/2024/features/questions/templates/parts/Content';

import { EditModal } from '../EditModal';
import { EmptyBody } from '../EmptyBody';
import { filteredDescritpions } from './utils';
import styles from './Questions.scss';

type MaritalStatus = 'unmarried' | 'married' | 'divorced_or_bereavement';
type WidowType = 'none' | 'normal' | 'single_parent';

type WidowProps = {
  question: Question;
};

export const Widow: FC<WidowProps> = ({ question }) => {
  const [maritalStatus, setMaritalStatus] = useState<MaritalStatus>('unmarried');
  const [widowType, setWidowType] = useState<WidowType>('none');
  const descriptions = filteredDescritpions(question, ['top']);

  return (
    <Section>
      <Section.Header className={styles.header}>
        {TITLE}
        <EditModal questionId={String(question.id)} descriptions={descriptions} />
      </Section.Header>
      <Section.Body>
        <Content>
          <CheckSpouse question={question}>
            <ButtonContainer>
              <Button huge primary={maritalStatus === 'unmarried'} onClick={() => setMaritalStatus('unmarried')}>
                配偶者有り
              </Button>
              <Button huge primary={maritalStatus === 'married'} onClick={() => setMaritalStatus('married')}>
                配偶者なし
                <br />
                （未婚・寡婦対象外）
              </Button>
              <Button
                huge
                primary={maritalStatus === 'divorced_or_bereavement'}
                onClick={() => setMaritalStatus('divorced_or_bereavement')}
              >
                配偶者なし
                <br />
                （その他）
              </Button>
            </ButtonContainer>
          </CheckSpouse>

          {['married', 'divorced_or_bereavement'].includes(maritalStatus) && (
            <>
              <CheckWidow>
                <ButtonContainer>
                  <Button huge primary={widowType === 'none'} onClick={() => setWidowType('none')}>
                    対象外
                  </Button>
                  <Button huge primary={widowType === 'single_parent'} onClick={() => setWidowType('single_parent')}>
                    ひとり親
                  </Button>
                  <Button huge primary={widowType === 'normal'} onClick={() => setWidowType('normal')}>
                    寡婦
                  </Button>
                </ButtonContainer>

                {['single_parent', 'normal'].includes(widowType) && (
                  <>
                    <EmptyBody />
                    <ButtonContainer>
                      <Button primary disabled>
                        次へ
                      </Button>
                    </ButtonContainer>
                  </>
                )}
              </CheckWidow>
            </>
          )}
        </Content>
      </Section.Body>
    </Section>
  );
};
