import { FC } from 'react';
import { Form } from './Form';
import { Preview } from './Preview';
import { ToggleSection, useToggleSection, ViewType } from 'adminPages/employees/show/components/ToggleSection';

type FlatTaxReductionProps = {
  setMode: (viewType: ViewType) => void;
};

export const FlatTaxReduction: FC<FlatTaxReductionProps> = ({ setMode }) => {
  const { viewType, setViewType } = useToggleSection();
  const handleSetViewType = (viewType: ViewType) => {
    setMode(viewType);
    setViewType(viewType);
  };

  const handleClose = () => {
    handleSetViewType('preview');
    window.location.hash = '';
    window.location.hash = '#flat-tax-reduction';
  };

  return (
    <ToggleSection viewType={viewType} setViewType={handleSetViewType}>
      <ToggleSection.Header>定額減税情報</ToggleSection.Header>
      <ToggleSection.Body>
        {viewType === 'preview' && <Preview />}
        {viewType === 'form' && <Form onCancel={handleClose} />}
      </ToggleSection.Body>
    </ToggleSection>
  );
};
