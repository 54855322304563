import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import { reducer as formErrors } from 'jbc-front/components/FormErrors';

const rootReducer = combineReducers({
  form,
  formErrors
});

export default rootReducer;
