import { FC } from 'react';
import { EarthquakeInsurances as EarthquakeInsurancesTemplate } from 'features/questions/templates/EarthquakeInsurances';
import { useWizard } from '../WizardProvider';
import { EarthquakeInsurance } from '../../../query';
import { Section } from 'components/ui/Section';
import { Input } from 'components/form/Input';
import { InputWithText } from 'components/form/InputWithText';
import { Row } from 'components/form/Row';
import { FormSection } from 'components/form/FormSection';
import { Radio } from 'components/form/Radio';
import { FileField } from 'components/form/FileField';
import { FilePreview } from 'components/feature/FilePreview';
import { amountFormat } from '@jbc-year-end-adj/common/utils/formatter';
import { useYear } from 'hooks/useYear';

export const EarthquakeInsurances: FC = () => {
  const { question, request } = useWizard();
  const year = useYear();

  return (
    <EarthquakeInsurancesTemplate question={question}>
      {request.profile?.earthquakeInsurances.map((insurance, index) => (
        <EarthquakeInsuranceForm key={insurance.id} insurance={insurance} index={index + 1} year={year} />
      ))}
    </EarthquakeInsurancesTemplate>
  );
};

type EarthquakeInsuranceFormProps = {
  insurance: EarthquakeInsurance;
  index: number;
  year: number;
};

const EarthquakeInsuranceForm: FC<EarthquakeInsuranceFormProps> = ({ insurance, index, year }) => {
  return (
    <Section>
      <Section.Header>地震保険({index})</Section.Header>
      <Section.Body>
        <FormSection>
          <Row>
            <Input label="保険会社等の名称" required value={insurance.name} disabled />
            <Input
              label="保険等の種類（目的）"
              required
              note="保険料控除証明書に記載された保険種類を記入してください"
              value={insurance.insuranceType}
              disabled
            />
          </Row>
          <Row>
            <Input
              label="保険期間"
              required
              note="保険料控除証明書に記載された保険期間を記入してください"
              value={insurance.insuranceDuration}
              disabled
            />
            <Input
              label="保険等の契約者の氏名"
              required
              note="保険料控除証明書に記載された契約者の氏名を記入してください"
              value={insurance.nameOfContractor}
              disabled
            />
          </Row>
          <Row>
            <Input
              label="保険等の受取人 氏名"
              required
              value={insurance.nameOfRecipient}
              note="保険料控除証明書に記載された受給人の氏名を記入してください"
              disabled
            />
            {year <= 2023 ? (
              <Input
                label="保険等の受取人 続柄"
                required
                note="保険料控除証明書に記載された受給人と本人の関係を記入してください。"
                value={insurance.relationshipOfRecipient}
                disabled
              />
            ) : (
              <div style={{ width: '100%' }} />
            )}
          </Row>

          <Radio.Container label="保険料区分" required note="保険料控除証明書の表記をご確認ください">
            <Radio checked={insurance.newOrOld === 'new_type'} disabled>
              地震
            </Radio>
            <Radio checked={insurance.newOrOld === 'old_type'} disabled>
              旧長期
            </Radio>
          </Radio.Container>

          <InputWithText label="保険料金額" text="円" required value={amountFormat(insurance.amount)} disabled />

          <FileField
            label="証明書画像"
            note="管理者が確認できるよう保険料控除証明書の画像（対応可能形式：jpg, jpeg, gif, png, pdf）をアップロードしてください。原本は必ず会社に提出してください。"
            disabled
          >
            {insurance.image && (
              <FileField.FileContainer>
                <FileField.File file={insurance.image} />
                <FilePreview file={insurance.image} />
              </FileField.FileContainer>
            )}
          </FileField>
        </FormSection>
      </Section.Body>
    </Section>
  );
};
