import { FC } from 'react';
import { Grid } from '../../../components/Grid';
import { dateFormat, fullNameFormat, optionalBooleanFormat } from '@jbc-year-end-adj/common/utils/formatter';
import { useResult } from '../ResultProvider';
import { Diff } from '../../../components/Diff';
import { NATIONAL_TYPE, GENDER } from '../../../consts';

export const Preview: FC = () => {
  const { employee } = useResult();
  const profile = employee.profile;
  const requestProfile = employee.request?.profile;
  const formattedSourceAddress = profile?.postcode0 ? `${profile.postcode0}-${profile.postcode1}\n${profile.fullAddress}` : '―';
  const formattedInputAddress = requestProfile?.postcode0
    ? `${requestProfile.postcode0}-${requestProfile.postcode1}\n${requestProfile.fullAddress}`
    : '―';

  return (
    <Grid>
      <Diff
        title="氏名"
        source={fullNameFormat(profile.lastName, profile.firstName)}
        input={fullNameFormat(requestProfile?.lastName, requestProfile?.firstName)}
      />

      <Diff
        title="氏名（カナ）"
        source={fullNameFormat(profile.lastNameKana, profile.firstNameKana)}
        input={fullNameFormat(requestProfile?.lastNameKana, requestProfile?.firstNameKana)}
      />

      <Diff title="生年月日" source={dateFormat(profile.birthday, 'L')} input={dateFormat(requestProfile?.birthday, 'L')} />

      <Diff title="性別" source={GENDER[profile.gender]} input={requestProfile?.gender ? GENDER[requestProfile.gender] : undefined} />

      <Diff title="住所" source={formattedSourceAddress} input={formattedInputAddress} />

      <Diff title="世帯主の続柄" source={profile.householder?.relationship} input={requestProfile?.householder?.relationship} />

      <Diff title="世帯主の氏名" source={profile.householder?.name} input={requestProfile?.householder?.name} />

      <Diff
        title="外国人区分"
        source={NATIONAL_TYPE[profile.nationalType]}
        input={requestProfile ? NATIONAL_TYPE[requestProfile?.nationalType] : undefined}
      />
      <Diff
        title="退職予定"
        source={optionalBooleanFormat(profile.resignBeforeYearEnd, 'はい', 'いいえ')}
        input={optionalBooleanFormat(requestProfile?.resignBeforeYearEnd, 'はい', 'いいえ')}
      />
    </Grid>
  );
};
