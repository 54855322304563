import { FC, ReactNode } from 'react';
import { Section } from '@jbc-year-end-adj/2024/components/ui/Section';

import { Question } from '../types';
import { findDescriptions } from '../utils';

import { Lead } from './parts/Lead';
import { Note } from './parts/Note';
import { Description } from './parts/Description';
import { Content as ContentContainer } from './parts/Content';

import styles from './templates.scss';

type SpouseLivingStatusProps = {
  question: Question;
  children: ReactNode;
};

export const SpouseLivingStatus: FC<SpouseLivingStatusProps> = ({ question, children }) => {
  return (
    <Section>
      <Section.Header className={styles.sectionHeader}>{TITLE}</Section.Header>

      <Section.Body>
        <Content question={question}>{children}</Content>
      </Section.Body>
    </Section>
  );
};

export const TITLE = '同一生計配偶者の判定';

type ContentProps = {
  question: Question;
  children: ReactNode;
  initiallyOpen?: boolean;
};

export const Content: FC<ContentProps> = ({ question, children }) => {
  const questionDescriptions = findDescriptions(question, ['top']);

  return (
    <ContentContainer>
      <Lead>あなたの配偶者は同一生計配偶者に該当しますか？</Lead>

      <Description>{questionDescriptions['top']?.description}</Description>

      <Note>
        <ul className="u-mb20">
          <li>・民法上の配偶者であること</li>
          <li>・12月31日時点（年の途中で死亡した場合には、その死亡の日）の現況において、従業員本人と生計を一にしている</li>
          <li>・合計所得金額が48万円以下である</li>
          <li>・青色申告の事業専従者として給与の支払を受けていない</li>
          <li>・白色申告者の事業専従者でない</li>
        </ul>
      </Note>

      {children}
    </ContentContainer>
  );
};
