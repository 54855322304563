import { FC, ReactNode } from 'react';
import { Section } from '@jbc-year-end-adj/2024/components/ui/Section';
import { Separator } from '@jbc-year-end-adj/2024/components/ui/Separator';

import { Question } from '../types';

import { Lead } from './parts/Lead';
import { Description } from './parts/Description';
import { Content } from './parts/Content';

type HasInsurancesProps = {
  children: ReactNode;
};

const MainSection: FC<HasInsurancesProps> = ({ children }) => {
  return (
    <Section>
      <Section.Header>{TITLE}</Section.Header>

      <Section.Body>
        <Content>{children}</Content>
      </Section.Body>
    </Section>
  );
};

export const TITLE = '保険・ローンの有無';

type LifeInsuranceProps = {
  question: Question;
  children: ReactNode;
};

export const LifeInsurance: FC<LifeInsuranceProps> = ({ question, children }) => {
  const description = question.questionDescriptions.find(description => description.field == 'life_insurance')?.description;

  return (
    <>
      <Lead>今年生命保険料を支払いましたか？</Lead>
      <Description>{description}</Description>
      {children}
    </>
  );
};

type EarthquakeInsuranceProps = {
  question: Question;
  children: ReactNode;
};

export const EarthquakeInsurance: FC<EarthquakeInsuranceProps> = ({ question, children }) => {
  const description = question.questionDescriptions.find(description => description.field == 'earthquake_insurance')?.description;

  return (
    <>
      <Separator />
      <Lead>今年地震保険料を支払いましたか？</Lead>
      <Description>{description}</Description>
      {children}
    </>
  );
};

type SocialInsuranceProps = {
  question: Question;
  children: ReactNode;
};

export const SocialInsurance: FC<SocialInsuranceProps> = ({ question, children }) => {
  const description = question.questionDescriptions.find(description => description.field == 'social_insurance')?.description;

  return (
    <>
      <Separator />
      <Lead>今年国民年金・国民健康保険料を支払いましたか？</Lead>
      <Description>{description}</Description>
      {children}
    </>
  );
};

type PremiumProps = {
  question: Question;
  children: ReactNode;
};

export const Premium: FC<PremiumProps> = ({ question, children }) => {
  const description = question.questionDescriptions.find(description => description.field == 'premium')?.description;

  return (
    <>
      <Separator />
      <Lead>今年小規模企業共済等掛金を支払いましたか？</Lead>
      <Description>{description}</Description>
      {children}
    </>
  );
};

type HousingLoanProps = {
  question: Question;
  children: ReactNode;
};

export const HousingLoan: FC<HousingLoanProps> = ({ question, children }) => {
  const description = question.questionDescriptions.find(description => description.field == 'housing_loan')?.description;

  return (
    <>
      <Separator />
      <Lead>住宅ローン控除の申告書がありますか？</Lead>
      <Description>{description}</Description>
      {children}
    </>
  );
};

export const HasInsurances = Object.assign(MainSection, {
  LifeInsurance,
  EarthquakeInsurance,
  SocialInsurance,
  Premium,
  HousingLoan
});
