import { useState, useCallback, ReactNode } from 'react';

export const useDlState = () => {
  const [isEmpty, setIsEmpty] = useState(true);
  const [isRequired, setIsRequired] = useState(false);

  const registerDt = useCallback((isRequiredField: boolean) => {
    setIsRequired(prev => prev || isRequiredField);
  }, []);

  const registerDd = useCallback((value: ReactNode) => {
    if (value != null && value !== '' && value !== '―') {
      setIsEmpty(false);
    }
  }, []);

  return {
    isEmpty,
    isRequired,
    registerDt,
    registerDd
  };
};
