import { FC, ReactNode } from 'react';
import { Section } from 'components/ui/Section';

import { Question } from '../type';

import { Lead } from './parts/Lead';
import { Description } from './parts/Description';
import { Content } from './parts/Content';

// @ts-ignore
import { useSession } from 'components/SessionProvider';

type MultiCompaniesProps = {
  question: Question;
  children: ReactNode;
};

export const MultiCompanies: FC<MultiCompaniesProps> = ({ question, children }) => {
  const description = question.questionDescriptions.find(description => description.field == 'top')?.description;
  const { office } = useSession();

  return (
    <Section>
      <Section.Header>2社以上勤務</Section.Header>

      <Section.Body>
        <Content>
          <Lead>現在、{office.name}以外で勤務していますか？</Lead>

          <Description>{description}</Description>

          {children}
        </Content>
      </Section.Body>
    </Section>
  );
};
