import React, { useEffect, useState } from 'react';
import isEqual from 'lodash/isEqual';
import uniqWith from 'lodash/uniqWith';
import { useNotify } from '@jbc-year-end-adj/common/hooks/useNotify';
import SubmissionDeadline from '../client_setting_forms/SubmissionDeadline';
import { useMutation, useQuery, LoadingPage, useYear, useSession } from '../components';
import { gql } from '@apollo/client';
import { reduxForm } from 'redux-form';
import classnames from 'classnames';
import { Section } from 'jbc-front/components/Form';
import FormErrors, { onSubmitFail } from 'jbc-front/components/FormErrors';
import Button from 'jbc-front/components/Button';
import { Import } from 'jbc-front/components/icons';
import ListGroup from 'jbc-front/components/ListGroup';
import scrollSpy, { AnchorLink, AnchorTarget } from 'jbc-front/components/ScrollSpy';
import { toFormValues, toBooleanProps } from '../utils/form';
import { useAsyncTask, ASYNC_TASK_FRAGMENT } from '../components/AsyncTask';
import { Buttons } from '../components/PrimaryPanel';
import { ImageSettingToggleButton } from './ImageSettingToggleButton';
import ToggleButton from '../components/ToggleButton';
import styles from './ClientSetting.scss';
import { ApplicationImportModal } from '../top/Cooperation';
import { DisabledMessage } from '../components/DisabledMessage';

const Anchor = ({ current, name, children }) => {
  return (
    <ListGroup.Item as={AnchorLink} name={name} style={current === name ? { backgroundColor: '#3498DB' } : { backgroundColor: '' }}>
      <div>
        {current === name ? <strong className={styles.naviLink}>{children}</strong> : <span className={styles.naviLink}>{children}</span>}
      </div>
      {current === name && <ListGroup.Icon />}
    </ListGroup.Item>
  );
};

export const APPLICATIONS = gql`
  query applications {
    client {
      id
      applications {
        id
        name
        connected
      }
    }
  }
`;

const CLIENT_SETTING = gql`
  query clientSetting($year: Int!) {
    client {
      id
      clientYearly(year: $year) {
        id
        fixed
        clientSetting {
          id
          requestPrint
          requestImageUploadSettings {
            lifeInsurance
            earthquakeInsurance
            pensionInsurance
            socialInsurance
            premium
            housingLoan
            studentIdCard
            handicap
            nonResidentAndStudyAbroad
            previousWithholdingSlip
          }
          employeeAddressClassification
          sendAcceptedEmail
          hasSubmissionDeadline
          submissionDeadline {
            id
            submissionDeadlineDate
            submissionDeadlineHour
            useReminder
            reminders {
              id
              remindDaysBefore
              remindHour
            }
          }
        }
      }
    }
  }
`;

const SAVE_CLIENT_SETTING = gql`
  mutation saveClientSetting($input: SaveClientSettingInput!) {
    saveClientSetting(input: $input) {
      clientYearly {
        id
        clientSetting {
          id
          requestPrint
          requestImageUploadSettings {
            lifeInsurance
            earthquakeInsurance
            pensionInsurance
            socialInsurance
            premium
            housingLoan
            studentIdCard
            handicap
            nonResidentAndStudyAbroad
            previousWithholdingSlip
          }
          employeeAddressClassification
          sendAcceptedEmail
          hasSubmissionDeadline
          submissionDeadline {
            id
            submissionDeadlineDate
            submissionDeadlineHour
            useReminder
            reminders {
              id
              remindDaysBefore
              remindHour
            }
          }
        }
      }
    }
  }
`;

export const IMPORT_EMPLOYEES = gql`
  mutation importEmployees(
    $service: String!
    $year: Int!
    $filterBySelection: Boolean
    $needsOffice: Boolean
    $needsEmployee: Boolean
    $needsWithholdingSlip: Boolean
  ) {
    importEmployees(
      input: {
        service: $service
        year: $year
        filterBySelection: $filterBySelection
        needsOffice: $needsOffice
        needsEmployee: $needsEmployee
        needsWithholdingSlip: $needsWithholdingSlip
      }
    ) {
      clientYearly {
        id
        asyncTask {
          ...AsyncTaskFields
        }
      }
    }
  }
  ${ASYNC_TASK_FRAGMENT}
`;

export const formName = 'clientSetting';

const requestPrints = [
  { value: 'true', label: '紙の提出を依頼する' },
  { value: 'false', label: '紙の提出を依頼しない' }
];

const requestImageUpload = [
  { value: 'none', label: '添付させない' },
  { value: 'optional', label: '任意で添付させる' },
  { value: 'required', label: '必須で添付させる' }
];

const employeeAddressClassifications = [
  { value: 'resident_card', label: '住民票住所を使う' },
  { value: 'profile', label: '現住所を使う' }
];

const sendAcceptedEmails = [
  { value: 'true', label: '送信する' },
  { value: 'false', label: '送信しない' }
];

const PayrollCooperationModalBody = () => (
  <>
    給与額・賞与額の連携は、年内支給の全ての給与・賞与が確定されている場合にのみ実行されます。
    <br />
    従業員の支給金額が連携されない場合は給与・賞与の確定状態をご確認ください。
    <br />
  </>
);

const CooperationSection = ({ applications, handleSubmit }) => {
  const { taskRunningProps } = useAsyncTask();
  const [importEmployees, { loading }] = useMutation(IMPORT_EMPLOYEES);
  const year = useYear();
  const [visibleModal, setVisibleModal] = useState(null);
  const { data } = useQuery(CLIENT_SETTING, { variables: { year } });
  const {
    client: { clientYearly }
  } = data;
  const disabledReason = clientYearly => clientYearly?.fixed && <DisabledMessage />;
  return (
    <>
      <p className="u-mb20">
        ジョブカン労務HR・給与計算の事業所情報・従業員情報・給与データを取得し、最新の年末調整に反映します。
        <br />
        年末調整開始後の再インポートは依頼一覧の「連携（取得）」ボタンより行うことができます。
      </p>
      <p className={styles.red}>
        スタッフコードを持たない従業員は取得できません。
        <br />
        スタッフコードを変更しますと別従業員として認識されますのでご注意ください。
        <br />
        ステータスが「未依頼」「依頼中」「入力済」「再依頼中」「修正済」の場合は、再度データのインポートを行うと従業員情報を上書きできます。
        <br />
        <br />
        労務HR・給与計算を両方利用されている場合、労務HRと給与計算の間のデータ連携を行ってデータを一致させた後、両方の取得ボタンを押してください。
      </p>

      <Buttons>
        {applications.map(application => (
          <Button
            key={application.id}
            className={styles.button}
            disabled={loading || !application.connected || clientYearly.fixed}
            disabledReason={disabledReason(clientYearly)}
            {...taskRunningProps}
            onClick={() => setVisibleModal(application.id)}
          >
            <div className={styles.icon}>
              <Import size={21} />
            </div>
            {application.name}
            から
            <br />
            インポート
          </Button>
        ))}
      </Buttons>

      {applications.map(application => (
        <ApplicationImportModal
          key={application.id}
          form="applicationImportInClientSetting"
          year={year}
          loading={loading || taskRunningProps?.disabled}
          importEmployees={(...args) => handleSubmit().then(() => importEmployees(...args))}
          application={application}
          isOpen={visibleModal === application.id}
          hideModal={() => setVisibleModal(null)}
          body={application.name === 'ジョブカン給与計算' && PayrollCooperationModalBody}
          initialValues={{ target: 'selection' }}
        />
      ))}
    </>
  );
};

const Form =
  (({ submitting, handleSubmit, applications, history, clientYearly, current }) => {
    const year = useYear();
    const { isCurrentYear, office } = useSession();
    const { taskRunningProps } = useAsyncTask();
    const [isNotYetImported, setIsNotYetImported] = useState(true);
    const disabledMessage = () => {
      if (isNotYetImported) {
        return 'ジョブカン労務HRまたはジョブカン給与計算からインポートを行ってください';
      }
      if (submitting) {
        return '保存中';
      }
    };

    useEffect(() => {
      if (taskRunningProps?.disabled || office) {
        setIsNotYetImported(false);
      }
    }, [taskRunningProps]);

    return (
      <div className={styles.mainGrid}>
        <div className={styles.side}>
          <AnchorMenu current={current} />
        </div>
        <form onSubmit={handleSubmit} className={styles.form}>
          <FormErrors />
          <div className={styles.setting}>
            <div className={styles.wrap}>
              <AnchorTarget name="request_print" />
              <Section title="印刷依頼設定">
                <p>
                  給与所得者の扶養控除等（異動）申告書、給与所得者の保険料控除申告書、給与所得者の基礎控除申告書 兼
                  給与所得者の配偶者控除等申告書 兼 所得金額調整控除申告書を印刷するように依頼できます。
                </p>
                <ToggleButton {...toBooleanProps} options={requestPrints} name="requestPrint" />
              </Section>
            </div>

            <div className={styles.wrap}>
              <AnchorTarget name="request_image_upload" />
              <Section title="添付データ設定">
                <p>従業員に生命保険等の証明書の画像データを添付依頼できます</p>
                <p>※ ただし、画像データの有無に関係なく、生命保険等の証明書の原本は保管する義務がありますのでご注意ください。</p>
                <div className={styles.listContainer}>
                  <h3 className={styles.list}>・生命保険料</h3>
                  <ImageSettingToggleButton
                    className={styles.toggles}
                    options={requestImageUpload}
                    name="requestImageUploadSettings.lifeInsurance"
                  />
                  <h3 className={styles.list}>・地震保険料</h3>
                  <ImageSettingToggleButton options={requestImageUpload} name="requestImageUploadSettings.earthquakeInsurance" />
                  <h3 className={styles.list}>・社会保険料（国民年金・国民年金基金）</h3>
                  <ImageSettingToggleButton options={requestImageUpload} name="requestImageUploadSettings.pensionInsurance" />
                  <h3 className={styles.list}>・社会保険料（上記以外）</h3>
                  <ImageSettingToggleButton options={requestImageUpload} name="requestImageUploadSettings.socialInsurance" />
                  <h3 className={styles.list}>・小規模企業共済等掛金</h3>
                  <ImageSettingToggleButton options={requestImageUpload} name="requestImageUploadSettings.premium" />
                  <h3 className={styles.list}>・住宅ローン</h3>
                  <ImageSettingToggleButton options={requestImageUpload} name="requestImageUploadSettings.housingLoan" />
                  <h3 className={styles.list}>・学生証</h3>
                  <ImageSettingToggleButton options={requestImageUpload} name="requestImageUploadSettings.studentIdCard" />
                  <h3 className={styles.list}>・障害者手帳</h3>
                  <ImageSettingToggleButton options={requestImageUpload} name="requestImageUploadSettings.handicap" />
                  <h3 className={styles.list}>・非居住者・留学関連書類</h3>
                  <ImageSettingToggleButton options={requestImageUpload} name="requestImageUploadSettings.nonResidentAndStudyAbroad" />
                  <h3 className={styles.list}>・前職源泉徴収票</h3>
                  <ImageSettingToggleButton options={requestImageUpload} name="requestImageUploadSettings.previousWithholdingSlip" />
                </div>
              </Section>
            </div>

            <div className={styles.wrap}>
              <AnchorTarget name="employee_address_classification" />
              <Section title="出力住所設定">
                <p>
                  給与所得者の扶養控除等（異動）申告書、給与所得者の保険料控除申告書、給与所得者の基礎控除申告書 兼
                  給与所得者の配偶者控除等申告書 兼 所得金額調整控除申告書、源泉徴収票、給与支払報告書に出力する従業員住所を選択できます。
                  <br />
                  給与支払報告書を提出する市区町村を判定するときにもここで選択された住所を使用します。
                </p>
                <ToggleButton options={employeeAddressClassifications} name="employeeAddressClassification" />
              </Section>
            </div>

            <div className={styles.wrap}>
              <AnchorTarget name="accepted_email_status" />
              <Section title="承認メール設定">
                <p>
                  管理者が承認時に従業員へのメール送信の有無を選択できます。
                  <br />
                  「送信しない」を選択した場合も承認時に「承認時にコメントを送信する」にチェックを入れて承認することによりメッセージを送信することが可能です。
                </p>
                <ToggleButton {...toBooleanProps} options={sendAcceptedEmails} name="sendAcceptedEmail" />
              </Section>
            </div>

            <div className={styles.wrap}>
              <AnchorTarget name="submission_deadline_settings" />
              <Section title="提出期日設定">
                <SubmissionDeadline />
              </Section>
            </div>

            <div className={styles.wrap}>
              <AnchorTarget name="request_data" />
              <Section title="データ取得">
                {!year || isCurrentYear ? (
                  <CooperationSection applications={applications} handleSubmit={handleSubmit} />
                ) : (
                  <div className={styles.wrap}>
                    <p>過去の年末調整には、ジョブカン労務HR・給与計算の事業所情報・従業員情報・給与データを反映させることはできません。</p>
                  </div>
                )}
              </Section>
            </div>
          </div>
          <div className="u-ta-c u-mt20">
            <Button
              primary
              onClick={async () => {
                const isSuccess = await handleSubmit();
                if (isSuccess === true) {
                  history.push('/' + year);
                }
              }}
              disabled={submitting || isNotYetImported}
              disabledReason={disabledMessage()}
            >
              保存
            </Button>
          </div>
        </form>
      </div>
    );
  })
  |> reduxForm({
    form: formName,
    onSubmitFail
  })
  |> scrollSpy;

const AnchorMenu = ({ current }) => {
  return (
    <ListGroup>
      <div className={styles.navi}>
        <Anchor name="request_print" current={current}>
          印刷依頼設定
        </Anchor>
        <Anchor name="request_image_upload" current={current}>
          添付データ設定
        </Anchor>
        <Anchor name="employee_address_classification" current={current}>
          出力住所設定
        </Anchor>
        <Anchor name="accepted_email_status" current={current}>
          承認メール設定
        </Anchor>
        <Anchor name="submission_deadline_settings" current={current}>
          提出期日設定
        </Anchor>
        <Anchor name="request_data" current={current}>
          データ取得
        </Anchor>
      </div>
    </ListGroup>
  );
};

// 階層構造になっているオブジェクトを平坦化してマージする
const getMergedValuesForClientSetting = (clientSetting, defaultValues) => {
  const { submissionDeadline, ...clientSettingWithoutSubmissionDeadline } = clientSetting || {};
  const { reminders, ...submissionDeadlineWithoutReminders } = submissionDeadline || {};
  return {
    ...defaultValues,
    ...clientSettingWithoutSubmissionDeadline,
    ...submissionDeadlineWithoutReminders,
    reminders: reminders?.length ? reminders : defaultValues.reminders
  };
};

// toFormValuesが配列の中身を消すようになっているので配列の項目は要素ごとにtoFormValuesする
const getFormValuesForClientSetting = mergedValues => {
  const withoutArrayValues = toFormValues(mergedValues, true, true);
  const arrayValues = {
    reminders: mergedValues.reminders.map(toFormValues)
  };
  return { withoutArrayValues, arrayValues };
};

const ClientSetting = ({ history }) => {
  const year = useYear();
  const notify = useNotify();
  const { loading, data } = useQuery(CLIENT_SETTING, { variables: { year } });
  const { data: applicationsData, loading: applicationsLoading } = useQuery(APPLICATIONS);
  const [saveClientSetting] = useMutation(SAVE_CLIENT_SETTING);
  if (loading || applicationsLoading) return <LoadingPage />;
  const {
    client: { clientYearly }
  } = data;
  const {
    client: { applications }
  } = applicationsData;
  const clientSetting = clientYearly?.clientSetting;
  const defaultValues = {
    requestPrint: true,
    requestImageUploadSettings: {
      lifeInsurance: 'optional',
      earthquakeInsurance: 'optional',
      pensionInsurance: 'optional',
      socialInsurance: 'optional',
      premium: 'optional',
      housingLoan: 'optional',
      studentIdCard: 'optional',
      handicap: 'optional',
      nonResidentAndStudyAbroad: 'optional',
      previousWithholdingSlip: 'optional'
    },
    employeeAddressClassification: 'resident_card',
    sendAcceptedEmail: true,
    hasSubmissionDeadline: false,
    reminders: [{ remindDaysBefore: '1', remindHour: '9' }]
  };
  const mergedValues = getMergedValuesForClientSetting(clientSetting, defaultValues);
  const { withoutArrayValues, arrayValues } = getFormValuesForClientSetting(mergedValues);
  const handleSubmit = async values => {
    if (values.hasSubmissionDeadline && values.useReminder) {
      const reminders = values.reminders.map(item => ({
        remindDaysBefore: item.remindDaysBefore,
        remindHour: item.remindHour
      }));
      const distinctReminders = uniqWith(reminders, isEqual);
      if (reminders.length !== distinctReminders.length) {
        notify('提出期日設定のリマインド日時が重複しています', 'error');
        return;
      }
    }

    await saveClientSetting({
      variables: {
        input: {
          ...values,
          reminders: values.reminders.filter(reminder => reminder.remindDaysBefore && reminder.remindHour),
          year
        }
      }
    });
    return true;
  };

  return (
    <div>
      <div className="l-main-title-wrap">
        <h1 className="m-title-main">年末調整基本設定</h1>
      </div>
      <div className={classnames('l-contents-wrap', styles.mainFlex)}>
        <Form
          initialValues={{ ...withoutArrayValues, ...arrayValues }}
          onSubmit={handleSubmit}
          applications={applications}
          history={history}
          clientYearly={clientYearly}
        />
      </div>
    </div>
  );
};

export default ClientSetting;
