import { FC } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler, useFieldArray, FormProvider } from 'react-hook-form';

import Button from 'jbc-front/components/Button';
import { ButtonRow } from 'jbc-front/components/presenters/layout/ButtonRow';

import { FormSection } from 'components/form/FormSection';
import { FormAddButton } from 'components/form/FormAddButton';
import { InputWithText } from 'components/form/InputWithText';
import { Section } from 'components/ui/Section';

import { Grid } from '../../../../components/Grid';
import { FETCH_EMPLOYEE } from '../../../../query';
import { FormSeparator } from '../../../../components/FormSeparator';
import { FloatingButton } from '../../../../components/FloatingButton';
import { useResult } from '../../ResultProvider';
import {
  schema,
  Schema,
  LifeInsuranceSchema,
  LifeInsuranceType,
  generateDefaultValues,
  lifeInsuranceObj,
  earthquakeInsuranceObj,
  socialInsuranceObj,
  NewOrOldType
} from './schema';
import { UPDATE_REQUEST_INSURANCES, convertFormDataToQueryVariables } from './mutation';
import { LifeInsurance } from './LifeInsurance';
import { EarthquakeInsurance } from './EarthquakeInsurance';
import { SocialInsurance } from './SocialInsurance';
import { calcNewTypeDeduction, calcOldTypeDeduction, calcDeduction, calcTotalDeduction } from 'utils/calcurators/lifeInsurance';
import { amountFormat } from '@jbc-year-end-adj/common/utils/formatter';

import { useNotify } from '@jbc-year-end-adj/common/hooks/useNotify';
// @ts-ignore
import { useMutation } from 'components/Graphql';
import { useYear } from 'hooks/useYear';

type FormProps = {
  onCancel: () => void;
};

export const Form: FC<FormProps> = ({ onCancel }) => {
  const year = useYear();
  const { employee } = useResult();
  const requestProfile = employee.request?.profile;
  const [update, { loading }] = useMutation(UPDATE_REQUEST_INSURANCES);
  const notify = useNotify();

  const methods = useForm<Schema>({
    defaultValues: generateDefaultValues(requestProfile),
    resolver: yupResolver(schema),
    context: { year },
    mode: 'onSubmit'
  });

  const { handleSubmit, control, watch } = methods;

  const { fields: lifeInsuranceFields, append: appendLifeInsuranceField, remove: removeLifeInsuranceField } = useFieldArray({
    control,
    name: 'lifeInsurances'
  });

  const {
    fields: earthquakeInsuranceFields,
    append: appendEarthquakeInsuranceField,
    remove: removeEarthquakeInsuranceField
  } = useFieldArray({
    control,
    name: 'earthquakeInsurances'
  });

  const { fields: socialInsuranceFields, append: appendSocialInsuranceField, remove: removeSocialInsuranceField } = useFieldArray({
    control,
    name: 'socialInsurances'
  });

  const onSubmit: SubmitHandler<Schema> = async (data: Schema) => {
    const variables = convertFormDataToQueryVariables(data, String(employee.id));
    await update({
      variables,
      refetchQueries: [FETCH_EMPLOYEE]
    });
    onCancel();
    notify('保険情報を保存しました。', 'success');
  };

  const insurances = watch('lifeInsurances');

  const calcTotalAmount = (insuranceType: LifeInsuranceType, newOrOld: NewOrOldType) => {
    const targetInsurances = insurances.filter((insurance: LifeInsuranceSchema) => insurance.lifeInsuranceType === insuranceType);
    return targetInsurances.reduce((totalAmount, insurance) => {
      if (insurance.newOrOld === newOrOld) {
        return totalAmount + Number(insurance.amount.split(',').join(''));
      } else {
        return totalAmount;
      }
    }, 0);
  };

  const calcAmount = (insuranceType: LifeInsuranceType, newOrOld: NewOrOldType, index: number) => {
    const targetInsurances = insurances
      .slice(0, index)
      .filter((insurance: LifeInsuranceSchema) => insurance.lifeInsuranceType === insuranceType);

    if (targetInsurances.length === 0) return 0;

    return targetInsurances.reduce((totalAmount, insurance) => {
      if (insurance.newOrOld === newOrOld) {
        return totalAmount + Number(insurance.amount.split(',').join(''));
      } else {
        return totalAmount;
      }
    }, 0);
  };

  const totalAmountNewTypeGeneralLifeInsurance = calcTotalAmount('GeneralLifeInsurance', 'new_type');
  const totalAmountOldTypeGeneralLifeInsurance = calcTotalAmount('GeneralLifeInsurance', 'old_type');
  const totalAmountNewTypeNursingMedicalInsurance = calcTotalAmount('NursingMedicalInsurance', 'new_type');
  const totalAmountNewTypeIndividualAnnuityInsurance = calcTotalAmount('IndividualAnnuityInsurance', 'new_type');
  const totalAmountOldTypeIndividualAnnuityInsurance = calcTotalAmount('IndividualAnnuityInsurance', 'old_type');

  const generalLifeInsuranceNewTypeDeduction = calcNewTypeDeduction(totalAmountNewTypeGeneralLifeInsurance, year);
  const generalLifeInsuranceOldTypeDeduction = calcOldTypeDeduction(totalAmountOldTypeGeneralLifeInsurance, year);
  const nursingMedicalInsuranceNewTypeDeduction = calcNewTypeDeduction(totalAmountNewTypeNursingMedicalInsurance, year);
  const individualAnnuityInsuranceNewTypeDecution = calcNewTypeDeduction(totalAmountNewTypeIndividualAnnuityInsurance, year);
  const individualAnnuityInsuranceOldTypeDecution = calcOldTypeDeduction(totalAmountOldTypeIndividualAnnuityInsurance, year);

  const generalLifeInsuranceDeduction = calcDeduction(generalLifeInsuranceNewTypeDeduction, generalLifeInsuranceOldTypeDeduction);
  const individualAnnuityInsuranceDeduction = calcDeduction(
    individualAnnuityInsuranceNewTypeDecution,
    individualAnnuityInsuranceOldTypeDecution
  );

  const totalDeduction = calcTotalDeduction(
    generalLifeInsuranceDeduction,
    nursingMedicalInsuranceNewTypeDeduction,
    individualAnnuityInsuranceDeduction,
    year
  );

  return (
    <FormProvider {...methods}>
      <form>
        <FormSection>
          <Section>
            <Section.Header>生命保険</Section.Header>
            <Section.Body>
              <FormSection>
                <Grid colGap>
                  {lifeInsuranceFields.map((field, index) => (
                    <LifeInsurance
                      key={field.id}
                      index={index}
                      onDelete={() => removeLifeInsuranceField(index)}
                      generalLifeInsuranceNewTypeAmount={calcAmount('GeneralLifeInsurance', 'new_type', index)}
                      generalLifeInsuranceOldTypeAmount={calcAmount('GeneralLifeInsurance', 'old_type', index)}
                      individualAnnuityInsuranceNewTypeAmount={calcAmount('IndividualAnnuityInsurance', 'new_type', index)}
                      individualAnnuityInsuranceOldTypeAmount={calcAmount('IndividualAnnuityInsurance', 'old_type', index)}
                      nursingMedicalInsuranceNewTypeAmount={calcAmount('NursingMedicalInsurance', 'new_type', index)}
                    />
                  ))}
                  <FormAddButton onClick={() => appendLifeInsuranceField(lifeInsuranceObj)}>生命保険情報を追加</FormAddButton>
                </Grid>

                <Section>
                  <Section.Header>控除額</Section.Header>
                  <Section.Body>
                    <FormSection>
                      <Grid>
                        <InputWithText
                          label="生命保険料控除額合計（イ + ロ + ハ）"
                          text="円"
                          disabled
                          value={amountFormat(String(totalDeduction))}
                        />
                      </Grid>

                      <Grid colGap>
                        <Section>
                          <Section.Header>一般の生命保険料の控除額（イ）</Section.Header>
                          <Section.Body>
                            <InputWithText label="控除額" text="円" disabled value={amountFormat(String(generalLifeInsuranceDeduction))} />
                          </Section.Body>
                        </Section>

                        <Section>
                          <Section.Header>介護医療保険料の控除額（ロ）</Section.Header>
                          <Section.Body>
                            <InputWithText
                              label="控除額"
                              text="円"
                              disabled
                              value={amountFormat(String(nursingMedicalInsuranceNewTypeDeduction))}
                            />
                          </Section.Body>
                        </Section>

                        <Section>
                          <Section.Header>個人年金保険料の控除額（ハ）</Section.Header>
                          <Section.Body>
                            <InputWithText
                              label="控除額"
                              text="円"
                              disabled
                              value={amountFormat(String(individualAnnuityInsuranceDeduction))}
                            />
                          </Section.Body>
                        </Section>
                      </Grid>
                    </FormSection>
                  </Section.Body>
                </Section>
              </FormSection>
            </Section.Body>
          </Section>

          <Section>
            <Section.Header>地震保険</Section.Header>
            <Section.Body>
              <Grid colGap>
                {earthquakeInsuranceFields.map((field, index) => (
                  <EarthquakeInsurance key={field.id} index={index} onDelete={() => removeEarthquakeInsuranceField(index)} />
                ))}
                <FormAddButton onClick={() => appendEarthquakeInsuranceField(earthquakeInsuranceObj)}>地震保険情報を追加</FormAddButton>
              </Grid>
            </Section.Body>
          </Section>

          <Section>
            <Section.Header>社会保険</Section.Header>
            <Section.Body>
              <Grid colGap>
                {socialInsuranceFields.map((field, index) => (
                  <SocialInsurance key={field.id} index={index} onDelete={() => removeSocialInsuranceField(index)} />
                ))}
                <FormAddButton onClick={() => appendSocialInsuranceField(socialInsuranceObj)}>社会保険情報を追加</FormAddButton>
              </Grid>
            </Section.Body>
          </Section>

          <FormSeparator />

          <FloatingButton>
            <ButtonRow>
              <Button onClick={onCancel} disabled={loading}>
                キャンセル
              </Button>
              <Button primary onClick={handleSubmit(onSubmit)} disabled={loading}>
                保存
              </Button>
            </ButtonRow>
          </FloatingButton>
        </FormSection>
      </form>
    </FormProvider>
  );
};
