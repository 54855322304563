import { FC, ReactNode } from 'react';
import { Section } from '@jbc-year-end-adj/2024/components/ui/Section';

import { Question } from '../types';

import { Lead } from './parts/Lead';
import { Description } from './parts/Description';
import { Content as ContentContainer } from './parts/Content';
import { findDescriptions } from '../utils';

type MultiCompaniesProps = {
  officeName: string;
  question: Question;
  children: ReactNode;
};

export const MultiCompanies: FC<MultiCompaniesProps> = ({ question, officeName, children }) => {
  return (
    <Section>
      <Section.Header>{TITLE}</Section.Header>

      <Section.Body>
        <Content question={question} officeName={officeName}>
          {children}
        </Content>
      </Section.Body>
    </Section>
  );
};

export const TITLE = '2社以上勤務';

type ContentProps = {
  question: Question;
  officeName: string;
  children: ReactNode;
};

export const Content: FC<ContentProps> = ({ question, officeName, children }) => {
  const questionDescriptions = findDescriptions(question, ['top']);

  return (
    <ContentContainer>
      <Lead>現在、{officeName}以外で勤務していますか？</Lead>

      <Description>{questionDescriptions['top']?.description}</Description>

      {children}
    </ContentContainer>
  );
};
