import { FC, ReactNode } from 'react';
import { Section } from '@jbc-year-end-adj/2024/components/ui/Section';

import { Question } from '../types';
import { findDescriptions } from '../utils';

import { ToggleImageContainer } from './parts/ToggleImageContainer';
import { Lead } from './parts/Lead';
import { Description } from './parts/Description';
import { Content as ContentContainer } from './parts/Content';

// @ts-ignore
import image from '@jbc-year-end-adj/2024/images/img-Premium_Deduction_Small_Business_Insurance.png';

type PremiumProps = {
  question: Question;
  children: ReactNode;
  initiallyOpen?: boolean;
};

export const Premium: FC<PremiumProps> = ({ question, children, initiallyOpen = false }) => {
  return (
    <Section>
      <Section.Header>{TITLE}</Section.Header>

      <Section.Body>
        <Content question={question} initiallyOpen={initiallyOpen}>
          {children}
        </Content>
      </Section.Body>
    </Section>
  );
};

export const TITLE = '小規模企業共済等掛金';

type ContentProps = {
  question: Question;
  children: ReactNode;
  initiallyOpen?: boolean;
};

export const Content: FC<ContentProps> = ({ question, children, initiallyOpen = false }) => {
  const questionDescriptions = findDescriptions(question, ['top']);

  return (
    <ContentContainer>
      <Lead>今年支払った小規模企業共済等掛金情報を記入してください。</Lead>

      <Description>{questionDescriptions['top']?.description}</Description>

      <ToggleImageContainer initiallyOpen={initiallyOpen}>
        <ToggleImageContainer.Header>※赤枠に関する設問です。</ToggleImageContainer.Header>
        <ToggleImageContainer.Body>
          <img src={image} alt="保険料控除_小規模企業共済" />
        </ToggleImageContainer.Body>
      </ToggleImageContainer>

      {children}
    </ContentContainer>
  );
};
