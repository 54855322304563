import { FC } from 'react';
import { useEmployeeInfo } from '../../EmployeeInfoProvider';
import { ReductionTarget } from './ReductionTarget';
import { ReductionAmount } from './ReductionAmount';
import styles from './Preview.scss';

export const Preview: FC = () => {
  const { employee } = useEmployeeInfo();

  return (
    <div className={styles.preview}>
      <ReductionTarget employee={employee} />
      <ReductionAmount employee={employee} />
    </div>
  );
};
