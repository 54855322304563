import { FC } from 'react';
import { Modal } from 'jbc-front/components/presenters/ui/Modal';
import Button from 'jbc-front/components/Button';
import { Spacer } from 'jbc-front/components/presenters/Spacer';
import { useEmployeeInfo } from '../EmployeeInfoProvider';
import { MOVE_APPLYING } from './mutation';
import { FETCH_EMPLOYEE } from '../../../query';

import { useNotify } from '@jbc-year-end-adj/common/hooks/useNotify';
// @ts-ignore
import { useMutation } from 'components/Graphql';

type MoveApplyingModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const MoveApplyingModal: FC<MoveApplyingModalProps> = ({ isOpen, onClose }) => {
  const { employee } = useEmployeeInfo();
  const [moveApplying, { loading }] = useMutation(MOVE_APPLYING);
  const notify = useNotify();

  const handleAccept = async () => {
    try {
      await moveApplying({
        variables: {
          input: {
            employeeId: String(employee.id)
          }
        },
        refetchQueries: [FETCH_EMPLOYEE]
      });
      notify('入力済に戻しました', 'success');
    } finally {
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Header onClose={onClose}>入力済に戻す</Modal.Header>
      <Modal.Body>
        <p>
          入力済に戻すと、従業員情報から新たにアンケート結果を作成します。
          <br />
          入力済に戻しますか？
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose} disabled={loading}>
          キャンセル
        </Button>
        <Spacer direction="x" size={20} />
        <Button primary onClick={handleAccept} disabled={loading}>
          入力済に戻す
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
