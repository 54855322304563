import { FC } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';

import Button from 'jbc-front/components/Button';
import { ButtonRow } from 'jbc-front/components/presenters/layout/ButtonRow';
import { Checkbox } from 'jbc-front/components/presenters/form/Checkbox';

import { Section } from 'components/ui/Section';
import { FormSection } from 'components/form/FormSection';
import { InputWithText } from 'components/form/InputWithText';
import { Input } from 'components/form/Input';
import { AmountField } from 'components/react-hook-form/AmountField';
import { useYear } from 'hooks/useYear';
import { amountFormat } from '@jbc-year-end-adj/common/utils/formatter';

import { Grid } from '../../../../components/Grid';
import { FormSeparator } from '../../../../components/FormSeparator';
import { FloatingButton } from '../../../../components/FloatingButton';
import { FETCH_EMPLOYEE } from '../../../../query';
import { useEmployeeInfo } from '../../EmployeeInfoProvider';
import { schema, Schema, generateDefaultValues } from './schema';
import { UPDATE_REQUEST_INCOME, convertFormDataToQueryVariables } from './mutation';

import { useNotify } from '@jbc-year-end-adj/common/hooks/useNotify';
// @ts-ignore
import { useMutation } from 'components/Graphql';
import {
  salaryEarnings as calcSalaryEarnings,
  salaryEarningsFrom2024 as calcSalaryEarningsFrom2024,
  calcTotalSalaryIncome,
  generalEarningsAllowMinus,
  generalEarnings,
  miscellaneousEarnings as calcMiscellaneousEarnings,
  retirementIncomeDeductionAmount as calcRetirementIncomeDeductionAmount,
  retirementEarnings as calcRetirementEarnings,
  totalEarnings as calcTotalEarnings
  // @ts-ignore
} from 'utils/income';
import { applicableForEmployee, applicableForSpouse, applicableForOtherFamily } from '@jbc-year-end-adj/ancient/utils/incomeAdjustment';

type FormProps = {
  onCancel: () => void;
};

export const EmployeeIncomeForm: FC<FormProps> = ({ onCancel }) => {
  const year = useYear();
  const { employee } = useEmployeeInfo();
  const profile = employee.profile;
  const income = profile.income;

  const [update, { loading }] = useMutation(UPDATE_REQUEST_INCOME);
  const notify = useNotify();

  const methods = useForm<Schema>({
    defaultValues: generateDefaultValues(income),
    resolver: yupResolver(schema),
    mode: 'onBlur'
  });

  const {
    handleSubmit,
    formState: { errors },
    register,
    watch
  } = methods;

  const onSubmit: SubmitHandler<Schema> = async (data: Schema) => {
    const variables = convertFormDataToQueryVariables(String(employee.id), data);

    await update({
      variables,
      refetchQueries: [FETCH_EMPLOYEE]
    });
    onCancel();
    notify('給与収入等を保存しました。', 'success');
  };

  const incomeAdjustmentDeduction = (() => {
    const handicapClassification = profile.handicapClassification;
    const spouseHandicapType = profile.spouse?.thisYear?.handicapType;
    const spouseTotalEarnings = profile.spouse?.income?.totalEarnings;
    const otherFamilies = profile.otherFamilies;

    return (
      applicableForEmployee(handicapClassification?.classification) ||
      applicableForSpouse(spouseHandicapType, spouseTotalEarnings) ||
      (otherFamilies || []).some(otherFamily => applicableForOtherFamily(otherFamily, year))
    );
  })();

  const formerJobsPaymentAmount = profile.formerJobs.reduce((totalIncome, formerJob) => {
    if (formerJob.paymentAmount) {
      return totalIncome + Number(formerJob.paymentAmount);
    } else {
      return totalIncome;
    }
  }, 0);

  const mainJobIncome = watch('mainJobIncome');
  const sideJobIncome = watch('sideJobIncome');
  const businessIncome = watch('businessIncome');
  const businessExpense = watch('businessExpense');
  const dividendIncome = watch('dividendIncome');
  const dividendExpense = watch('dividendExpense');
  const realEstateIncome = watch('realEstateIncome');
  const realEstateExpense = watch('realEstateExpense');
  const retirementIncome = watch('retirementIncome');
  const lengthOfService = watch('lengthOfService');
  const retireForDisablity = watch('retireForDisablity');
  const isOfficerRetirementAllowance = watch('isOfficerRetirementAllowance');
  const miscellaneousIncomeOfficalPension = watch('miscellaneousIncomeOfficalPension');
  const miscellaneousIncomeOther = watch('miscellaneousIncomeOther');
  const miscellaneousExpense = watch('miscellaneousExpense');
  const otherEarnings = watch('otherEarings');

  const totalSalaryIncome = calcTotalSalaryIncome(mainJobIncome, sideJobIncome, String(formerJobsPaymentAmount || 0));
  const salaryEarnings =
    year >= 2024
      ? calcSalaryEarningsFrom2024(mainJobIncome, sideJobIncome, formerJobsPaymentAmount, incomeAdjustmentDeduction)
      : calcSalaryEarnings(totalSalaryIncome, year, incomeAdjustmentDeduction);
  const businessEarnings = generalEarningsAllowMinus(businessIncome, businessExpense);
  const dividendEarnings = generalEarnings(dividendIncome, dividendExpense);
  const realEstateEarnings = generalEarningsAllowMinus(realEstateIncome, realEstateExpense);
  const retirementIncomeDeductionAmount = calcRetirementIncomeDeductionAmount(lengthOfService, retireForDisablity, retirementIncome);
  const retirementEarnings = calcRetirementEarnings(
    retirementIncome,
    retirementIncomeDeductionAmount,
    isOfficerRetirementAllowance,
    lengthOfService,
    year
  );
  const miscellaneousEarnings = calcMiscellaneousEarnings(
    miscellaneousIncomeOfficalPension,
    miscellaneousIncomeOther,
    miscellaneousExpense,
    profile.birthday,
    year,
    {
      salary: salaryEarnings,
      business: businessEarnings,
      dividend: dividendEarnings,
      realEstate: realEstateEarnings,
      retirement: retirementEarnings,
      other: otherEarnings
    }
  );

  const totalEarnings = calcTotalEarnings(
    salaryEarnings,
    businessEarnings,
    miscellaneousEarnings,
    dividendEarnings,
    realEstateEarnings,
    retirementEarnings,
    otherEarnings
  );

  return (
    <FormProvider {...methods}>
      <form>
        <FormSection>
          <Grid>
            <InputWithText text="円" label="合計所得金額" value={totalEarnings} disabled />
          </Grid>

          <Section>
            <Section.Header>本人の給与収入</Section.Header>
            <Section.Body>
              <Grid colGap>
                <Section>
                  <Section.Header>給与所得</Section.Header>
                  <Section.Body>
                    <FormSection>
                      <AmountField
                        label="収入金額（当社）"
                        note="前職情報は含みません"
                        hint={
                          <>
                            この項目に入力した金額は源泉徴収票に反映されません。
                            <br />
                            また、源泉徴収票編集画面で課税支払額を登録した場合はこの項目に反映されません。
                            <br />
                            源泉徴収票に反映される金額の登録は源泉徴収票編集画面より行ってください。
                          </>
                        }
                        error={errors.mainJobIncome?.message}
                        isError={!!errors.mainJobIncome?.message}
                        {...register('mainJobIncome')}
                      />
                      <AmountField
                        label="収入金額（他社）"
                        note="前職情報は含みません"
                        error={errors.sideJobIncome?.message}
                        isError={!!errors.sideJobIncome?.message}
                        {...register('sideJobIncome')}
                      />
                      <InputWithText label="収入金額（前職）" text="円" value={amountFormat(String(formerJobsPaymentAmount))} disabled />
                      <InputWithText label="収入金額（合計）" text="円" value={amountFormat(String(totalSalaryIncome))} disabled />
                      <InputWithText label="所得金額" text="円" value={amountFormat(String(salaryEarnings))} disabled />
                    </FormSection>
                  </Section.Body>
                </Section>
              </Grid>
            </Section.Body>
          </Section>

          <Section>
            <Section.Header>本人の給与収入以外の収入</Section.Header>
            <Section.Body>
              <Grid colGap>
                <Section>
                  <Section.Header>事業所得</Section.Header>
                  <Section.Body>
                    <FormSection>
                      <AmountField
                        label="収入金額"
                        noteMaxLength={19}
                        note="農業、林業、水産養殖業、製造業、卸売業、小売業、金融業などのサービス業のほか対価を得て継続的に行う事業による所得"
                        error={errors.businessIncome?.message}
                        isError={!!errors.businessIncome?.message}
                        {...register('businessIncome')}
                      />
                      <AmountField
                        label="必要経費"
                        noteMaxLength={19}
                        note="収入を得るために必要な売上原価、販売費・一般管理費その他の費用"
                        error={errors.businessExpense?.message}
                        isError={!!errors.businessExpense?.message}
                        {...register('businessExpense')}
                      />
                      <InputWithText text="円" label="所得金額" value={amountFormat(String(businessEarnings))} disabled />
                    </FormSection>
                  </Section.Body>
                </Section>

                <Section>
                  <Section.Header>雑所得</Section.Header>
                  <Section.Body>
                    <FormSection>
                      <AmountField
                        label="収入金額（公的年金等に係る雑所得）"
                        note="年金、厚生年金、共済年金などの公的年金等"
                        error={errors.miscellaneousIncomeOfficalPension?.message}
                        isError={!!errors.miscellaneousIncomeOfficalPension?.message}
                        {...register('miscellaneousIncomeOfficalPension')}
                      />
                      <AmountField
                        label="収入金額（公的年金等以外の雑所得）"
                        noteMaxLength={19}
                        note="原稿料や印税、講演料、放送出演料、貸金の利子、生命保険契約等に基づく年金など他のいずれの所得にも該当しない所得や恩給（一時恩給を除く）等"
                        error={errors.miscellaneousIncomeOther?.message}
                        isError={!!errors.miscellaneousIncomeOther?.message}
                        {...register('miscellaneousIncomeOther')}
                      />
                      <AmountField
                        label="必要経費"
                        error={errors.miscellaneousExpense?.message}
                        isError={!!errors.miscellaneousExpense?.message}
                        {...register('miscellaneousExpense')}
                      />
                      <InputWithText text="円" label="所得金額" value={amountFormat(String(miscellaneousEarnings))} disabled />
                    </FormSection>
                  </Section.Body>
                </Section>

                <Section>
                  <Section.Header>配当所得</Section.Header>
                  <Section.Body>
                    <FormSection>
                      <AmountField
                        label="収入金額"
                        noteMaxLength={19}
                        note="株主や出資者が法人から受ける剰余金や、利益の配当、剰余金の分配、投資法人からの金銭の分配、投資信託（公社債投資信託及び公募公社債等運用投資信託以外のもの）及び特定受益証券発行信託の収益の分配などに係る所得"
                        error={errors.dividendIncome?.message}
                        isError={!!errors.dividendIncome?.message}
                        {...register('dividendIncome')}
                      />
                      <AmountField
                        label="必要経費"
                        noteMaxLength={19}
                        note="収入金額からその元本を取得するために要した負債の利子（株式等の取得のために借り入れた負債の利子のうち、その株式等の譲渡所得等に係るものを除きます。）"
                        error={errors.dividendExpense?.message}
                        isError={!!errors.dividendExpense?.message}
                        {...register('dividendExpense')}
                      />
                      <InputWithText text="円" label="所得金額" value={amountFormat(String(dividendEarnings))} disabled />
                    </FormSection>
                  </Section.Body>
                </Section>

                <Section>
                  <Section.Header>不動産所得</Section.Header>
                  <Section.Body>
                    <FormSection>
                      <AmountField
                        label="収入金額"
                        noteMaxLength={19}
                        note="不動産の貸付けに際して受け取る権利金や頭金、更新料、名義書換料も不動産所得になります。"
                        error={errors.realEstateIncome?.message}
                        isError={!!errors.realEstateIncome?.message}
                        {...register('realEstateIncome')}
                      />
                      <AmountField
                        label="必要経費"
                        noteMaxLength={19}
                        note="貸し付けた不動産についての修繕費、損害保険料、租税公課、減価償却費、借入金利子等"
                        error={errors.realEstateExpense?.message}
                        isError={!!errors.realEstateExpense?.message}
                        {...register('realEstateExpense')}
                      />
                      <InputWithText text="円" label="所得金額" value={amountFormat(String(realEstateEarnings))} disabled />
                    </FormSection>
                  </Section.Body>
                </Section>

                <Section>
                  <Section.Header>退職所得</Section.Header>
                  <Section.Body>
                    <FormSection>
                      <AmountField
                        label="収入金額"
                        noteMaxLength={19}
                        note="退職手当、一時恩給その他の退職により一時に受ける給与などの所得のほか、社会保険制度等に基づく一時金など"
                        error={errors.retirementIncome?.message}
                        isError={!!errors.retirementIncome?.message}
                        {...register('retirementIncome')}
                      />
                      {retirementIncome && Number(retirementIncome.split(',').join('')) > 0 && (
                        <>
                          <Input
                            label="勤続年数"
                            error={errors.lengthOfService?.message}
                            isError={!!errors.lengthOfService?.message}
                            {...register('lengthOfService')}
                          />
                          <Checkbox {...register('retireForDisablity')}>障害者になったことに直接基因して退職した</Checkbox>
                          <Checkbox {...register('isOfficerRetirementAllowance')}>退職手当等が特定役員退職手当等に該当する</Checkbox>
                        </>
                      )}
                      <InputWithText text="円" label="控除額" value={amountFormat(String(retirementIncomeDeductionAmount))} disabled />
                      <InputWithText text="円" label="所得金額" value={amountFormat(String(retirementEarnings))} disabled />
                    </FormSection>
                  </Section.Body>
                </Section>

                <Section>
                  <Section.Header>その他所得</Section.Header>
                  <Section.Body>
                    <FormSection>
                      <AmountField
                        label="収入金額"
                        noteMaxLength={19}
                        note="⑴ 譲渡所得（土地、建物、機械、ゴルフ会員権、金地金、書画、骨とうなどの資産の譲渡による所得） ⑵ 山林所得（山林（所有期間５年超）の伐採又は譲渡による所得） ⑶ 一時所得（賞金や懸賞当せん金、競馬・競輪の払戻金（営利を目的とする継続的行為から生じたものを除きます。）、生命保険契約等に基づく一時金、損害保険契約等に基づく満期返戻金、遺失物拾得の報労金などによる所得） ⑷ 総合課税又は申告分離課税の対象となる利子所得 （注） 利子所得のうち預貯金の利子などの源泉分離課税の対象となるもの及び特定公社債の利子などの申告分離課税の対象となるもので確定申告しないことを選択したものは、収入金額に含まれません。 ⑸ 申告分離課税の適用を受けた一般株式等に係る譲渡所得等又は上場株式等に係る譲渡所得等（源泉徴収選択口座を通じて行った上場株式等の譲渡による所得等で、確定申告をしないことを選択した所得等は、収入金額に含まれません。） ⑹ 先物取引に係る雑所得等"
                        error={errors.otherIncome?.message}
                        isError={!!errors.otherIncome?.message}
                        {...register('otherIncome')}
                      />
                      <AmountField
                        label="必要経費"
                        error={errors.otherExpense?.message}
                        isError={!!errors.otherExpense?.message}
                        {...register('otherExpense')}
                      />
                      <AmountField
                        label="必要経費 うち特別控除額"
                        error={errors.otherExpenseSpecialDeduction?.message}
                        isError={!!errors.otherExpenseSpecialDeduction?.message}
                        {...register('otherExpenseSpecialDeduction')}
                      />
                      <AmountField
                        label="所得金額"
                        error={errors.otherEarings?.message}
                        isError={!!errors.otherEarings?.message}
                        {...register('otherEarings')}
                      />
                    </FormSection>
                  </Section.Body>
                </Section>
              </Grid>
            </Section.Body>
          </Section>

          <FormSeparator />

          <FloatingButton>
            <ButtonRow>
              <Button onClick={onCancel} disabled={loading}>
                キャンセル
              </Button>
              <Button primary disabled={loading} onClick={handleSubmit(onSubmit)}>
                保存
              </Button>
            </ButtonRow>
          </FloatingButton>
        </FormSection>
      </form>
    </FormProvider>
  );
};
