import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { AnchorTarget } from 'jbc-front/components/ScrollSpy/v2';

import { Section } from '@jbc-year-end-adj/2024/components/ui/Section';
import { FormSection } from '@jbc-year-end-adj/2024/components/form/FormSection';
import { ToggleButton } from '@jbc-year-end-adj/2024/components/form/ToggleButton';

export const EmployeeAddressClassificationSection: FC = () => {
  const { register } = useFormContext();

  return (
    <Section>
      <AnchorTarget name="employee_address_classification" />
      <Section.Header>出力住所設定</Section.Header>
      <Section.Body>
        <FormSection>
          <p>
            給与所得者の扶養控除等（異動）申告書、給与所得者の保険料控除申告書、給与所得者の基礎控除申告書 兼 給与所得者の配偶者控除等申告書
            兼 年末調整に係る定額減税のための申告書 兼
            所得金額調整控除申告書、源泉徴収票、給与支払報告書に出力する従業員住所を選択できます。
            <br />
            給与支払報告書を提出する市区町村を判定するときにもここで選択された住所を使用します。
          </p>
          <ToggleButton.Container>
            <ToggleButton {...register('employeeAddressClassification')} value="resident_card">
              住民票住所を使う
            </ToggleButton>
            <ToggleButton {...register('employeeAddressClassification')} value="profile">
              現住所を使う
            </ToggleButton>
          </ToggleButton.Container>
        </FormSection>
      </Section.Body>
    </Section>
  );
};
