import { FC } from 'react';
import { SpouseLivingStatus as SpouseLivingStatusTemplate } from 'features/questions/templates/SpouseLivingStatus';
import { ButtonContainer } from 'features/questions/components/ButtonContainer';
import Button from 'jbc-front/components/Button';
import { useWizard } from '../WizardProvider';

export const SpouseLivingStatus: FC = () => {
  const { question, request } = useWizard();
  const isSameHousehold = request?.profile?.spouseLivingStatus?.isSameHousehold;

  return (
    <SpouseLivingStatusTemplate question={question}>
      <ButtonContainer>
        <Button huge primary={isSameHousehold} secondary={!isSameHousehold} disabled>
          はい
        </Button>
        <Button huge primary={!isSameHousehold} secondary={isSameHousehold} disabled>
          いいえ
        </Button>
      </ButtonContainer>
    </SpouseLivingStatusTemplate>
  );
};
