import { useCallback } from 'react';
import { useNotifications, Status } from 'reapop';

type Notify = (message: string, status?: Status) => void;

export const useNotify = (): Notify => {
  const { notify } = useNotifications();
  return useCallback(
    (message, status = 'success') => {
      notify({ message, position: 'top-center', status, dismissAfter: 3000 });
    },
    [notify]
  );
};
