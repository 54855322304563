import { useQuery as useQueryOrigin, useMutation as useMutationOrigin } from '@apollo/client';
import _ from 'lodash';
import fp from 'lodash/fp';
import { SubmissionError } from 'redux-form';
import { useNotify } from '@jbc-year-end-adj/common/hooks/useNotify';
import { logError } from '@jbc-year-end-adj/common/telemetry';

const camel = value =>
  _.isArray(value)
    ? value.map(camel)
    : _.isObject(value)
    ? _.mapValues(
        _.mapKeys(value, (v, k) => _.camelCase(k)),
        (v, k) => camel(v)
      )
    : value;

export const useQuery = (...args) => {
  const ret = useQueryOrigin(...args);
  if (ret.error) {
    throw ret.error;
  }
  return ret;
};

export const getBaseError = data => {
  if (_.isArray(data)) {
    let idx = 0;
    for (const v of data) {
      let [base, rest] = getBaseError(v);
      if (base) {
        return [base, fp.set(`[${idx}]`, rest, data)];
      }
      idx++;
    }
    return [];
  }
  if (!_.isObject(data)) {
    return [];
  }
  for (const [key, value] of Object.entries(data)) {
    if (key === 'base') {
      return [value, _.omit(data, 'base')];
    } else {
      let [base, rest] = getBaseError(value);
      if (base) {
        return [base, fp.set(key, rest, data)];
      }
    }
  }
  return [];
};

const validateReadableFiles = async data => {
  if (_.isArray(data)) {
    const ary = await Promise.all(data.map(validateReadableFiles));
    if (ary.length === 0 || ary.every(v => v === undefined)) {
      return undefined;
    } else {
      return ary;
    }
  }
  if (!_.isObject(data)) {
    return undefined;
  }
  const ret = {};
  await Promise.all(
    Object.entries(data).map(([key, value]) => {
      if (value instanceof File) {
        return new Promise(resolve => {
          const reader = new FileReader();
          reader.addEventListener('load', resolve);
          reader.addEventListener('abort', resolve);
          reader.addEventListener('error', () => {
            ret[key] = 'を正常にアップロードできませんでした。再度アップロードしてください。';
            resolve();
          });
          reader.readAsArrayBuffer(value);
        });
      } else {
        return validateReadableFiles(value).then(v => (v ? (ret[key] = v) : undefined));
      }
    })
  );
  return _.size(ret) ? ret : undefined;
};

export const useMutation = (...args) => {
  const query = args[0].loc?.source?.body?.trim();
  const notify = useNotify();
  const [mutation, value] = useMutationOrigin(...args);
  return [
    async (...args) => {
      try {
        const res = await mutation(...args);
        return res;
      } catch (err) {
        const msg = _.get(err, 'graphQLErrors[0].extensions.problems[0].explanation') || _.get(err, 'graphQLErrors[0].message');
        if (msg) {
          if (_.get(err, 'graphQLErrors[0].extensions.code') === 'BROKEN_MULTIPART_REQUEST') {
            const errors = await validateReadableFiles(args[0].variables?.input || {});
            notify(msg, 'error');
            throw new SubmissionError(errors);
          }

          let errors = null;
          try {
            errors = camel(JSON.parse(msg));
          } catch (__) {
            //
          }
          if (errors) {
            const [base, rest] = getBaseError(errors);
            if (base) {
              notify(base, 'error');
              throw new SubmissionError(rest);
            }
            throw new SubmissionError(errors);
          } else {
            if (_.isEmpty(_.get(err, 'graphQLErrors[0].extensions.code'))) {
              logError(err, { query });
            }
            notify(msg, 'error');
            throw err;
          }
        }
        logError(err, { query });
        notify(_.get(err, 'networkError.result.error') || err.message, 'error');
        throw err;
      }
    },
    value
  ];
};
