import { FC } from 'react';
import { Grid } from '../../../../components/Grid';
import { Tag } from '../../../../components/Tag';
import { Diff, FileDiff } from '../../../../components/Diff';
import { Dl, Dt, Dd, FileDd, FileDl } from '../../../../components/DefinitionList';
import { PreviewSubSection } from '../../../../components/PreviewSubSection';
import { useResult } from '../../ResultProvider';
import { Section } from 'components/ui/Section';
import { SocialInsurance } from '../../../../query';
import { amountFormat, displayFormat } from '@jbc-year-end-adj/common/utils/formatter';
import { useYear } from 'hooks/useYear';

type DiffType = {
  type: 'add' | 'update' | 'delete';
  source: SocialInsurance | undefined;
  input: SocialInsurance | undefined;
};

const SOCIAL_INSURANCE_TYPE = {
  national_pension: '国民年金',
  national_insurance: '国民年金基金',
  national_health_insurance: '国民健康保険',
  elderly_medical_insurance: '後期高齢者医療保険',
  national_nursing_medical_insurance: '介護保険',
  voluntary_continued_insurance: '任意継続保険',
  farmers_pension: '農業者年金'
};

export const SocialInsuranceDiff: FC = () => {
  const { employee } = useResult();
  const profile = employee.profile;
  const requestProfile = employee.request?.profile;

  // compareKeyが一致するもの || profileにしかないもの
  const socialInsuranceDiff = profile.socialInsurances.reduce<DiffType[]>((diff, source) => {
    const input = requestProfile?.socialInsurances.find(insurance => insurance.compareKey === source.compareKey);
    const type = input ? 'update' : 'delete';
    diff.push({ type, source, input });

    return diff;
  }, []);
  // requestにしかないもの
  requestProfile?.socialInsurances.forEach(requestInsurance => {
    const index = profile.socialInsurances.findIndex(insurance => insurance.compareKey === requestInsurance.compareKey);
    if (index === -1) {
      socialInsuranceDiff.push({
        type: 'add',
        source: undefined,
        input: requestInsurance
      });
    }
  });

  return (
    <Section>
      <Section.Header>社会保険</Section.Header>
      <Section.Body>
        <Grid colGap>
          {socialInsuranceDiff.length === 0 && <p>社会保険情報はありません</p>}
          {socialInsuranceDiff.map((diff, index) => (
            <>
              {diff.type === 'update' && <UpdateSocialInsuranceDiff key={index} index={index + 1} diff={diff} />}
              {diff.type === 'add' && (
                <CreateOrDeleteSocialInsuranceDiff key={index} index={index + 1} socialInsurance={diff.input} type="add" />
              )}
              {diff.type === 'delete' && (
                <CreateOrDeleteSocialInsuranceDiff key={index} index={index + 1} socialInsurance={diff.source} type="delete" />
              )}
            </>
          ))}
        </Grid>
      </Section.Body>
    </Section>
  );
};

type UpdateSocialInsuranceDiffProps = {
  index: number;
  diff: DiffType;
};

const UpdateSocialInsuranceDiff: FC<UpdateSocialInsuranceDiffProps> = ({ index, diff }) => {
  const year = useYear();
  return (
    <PreviewSubSection>
      <PreviewSubSection.Header>社会保険（{index + 1}）</PreviewSubSection.Header>
      <PreviewSubSection.Body>
        <Diff
          title="社会保険の種類"
          source={diff.source?.insuranceType ? SOCIAL_INSURANCE_TYPE[diff.source.insuranceType] : undefined}
          input={diff.input?.insuranceType ? SOCIAL_INSURANCE_TYPE[diff.input?.insuranceType] : undefined}
        />
        <Diff title="保険料支払先の名称" source={diff.source?.name} input={diff.input?.name} />
        <Diff title="保険料を負担することになっている人 氏名" source={diff.source?.nameOfPayer} input={diff.input?.nameOfPayer} />
        {year <= 2023 && (
          <Diff
            title="保険料を負担することになっている人 続柄"
            source={diff.source?.relationshipOfPayer}
            input={diff.input?.relationshipOfPayer}
          />
        )}
        <Diff title="本年中に支払った金額" source={amountFormat(diff.source?.amount)} input={amountFormat(diff.input?.amount)} />
        <FileDiff title="社会保険の証明書画像" source={diff.source?.image} input={diff.input?.image} />
      </PreviewSubSection.Body>
    </PreviewSubSection>
  );
};

type CreateOrDeleteSocialInsuranceProps = {
  index: number;
  socialInsurance?: SocialInsurance;
  type: 'add' | 'delete';
};

const CreateOrDeleteSocialInsuranceDiff: FC<CreateOrDeleteSocialInsuranceProps> = ({ index, socialInsurance, type }) => {
  const year = useYear();
  const insuranceType = socialInsurance?.insuranceType ? SOCIAL_INSURANCE_TYPE[socialInsurance.insuranceType] : displayFormat();
  return (
    <PreviewSubSection>
      <PreviewSubSection.Header>
        社会保険（{index}）{type === 'add' && <Tag color="blue">追加</Tag>}
        {type === 'delete' && <Tag color="red">削除</Tag>}
      </PreviewSubSection.Header>
      <PreviewSubSection.Body>
        <Dl>
          <Dt>社会保険の種類</Dt>
          <Dd>{insuranceType}</Dd>
        </Dl>
        <Dl>
          <Dt>保険料支払先の名称</Dt>
          <Dd>{displayFormat(socialInsurance?.name)}</Dd>
        </Dl>
        <Dl>
          <Dt>保険料を負担することになっている人 氏名</Dt>
          <Dd>{displayFormat(socialInsurance?.nameOfPayer)}</Dd>
        </Dl>
        {year <= 2023 && (
          <Dl>
            <Dt>保険料を負担することになっている人 続柄</Dt>
            <Dd>{displayFormat(socialInsurance?.relationshipOfPayer)}</Dd>
          </Dl>
        )}
        <Dl>
          <Dt>本年中に支払った金額</Dt>
          <Dd>{amountFormat(socialInsurance?.amount)}</Dd>
        </Dl>
        <FileDl>
          <Dt>社会保険の証明書画像</Dt>
          <FileDd file={socialInsurance?.image} />
        </FileDl>
      </PreviewSubSection.Body>
    </PreviewSubSection>
  );
};
