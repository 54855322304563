import { FC, ReactNode } from 'react';
import { Section } from 'components/ui/Section';

import { Question } from '../type';

import { Lead } from './parts/Lead';
import { Description } from './parts/Description';
import { Content } from './parts/Content';
import { Note } from './parts/Note';

type StartProps = {
  question: Question;
  children: ReactNode;
};

export const SpouseLivingStatus: FC<StartProps> = ({ question, children }) => {
  const description = question.questionDescriptions.find(description => description.field == 'top')?.description;

  return (
    <Section>
      <Section.Header>同一生計配偶者の判定</Section.Header>

      <Section.Body>
        <Content>
          <Lead>あなたの配偶者は同一生計配偶者に該当しますか？</Lead>

          <Description>{description}</Description>

          <Note>
            <ul className="u-mb20">
              <li>・民法上の配偶者であること</li>
              <li>・12月31日時点（年の途中で死亡した場合には、その死亡の日）の現況において、従業員本人と生計を一にしている</li>
              <li>・合計所得金額が48万円以下である</li>
              <li>・青色申告の事業専従者として給与の支払を受けていない</li>
              <li>・白色申告者の事業専従者でない</li>
            </ul>
          </Note>
          {children}
        </Content>
      </Section.Body>
    </Section>
  );
};
