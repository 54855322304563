import React from 'react';
import { gql } from '@apollo/client';
import { reduxForm } from 'redux-form';
import { FileField } from 'jbc-front/components/Form';
import Button from 'jbc-front/components/Button';
import { Upload } from 'jbc-front/components/icons';
import { useMutation, useYear, Hoc } from '../components';
import FileImportDescription from '../result/FileImportDescription';
import { Link } from 'react-router-dom';
import styles from './ImportWithholdingSlips.scss';
import { useNotify } from '@jbc-year-end-adj/common/hooks/useNotify';
import useReactRouter from 'use-react-router';
import { useAsyncTask, ASYNC_TASK_FRAGMENT } from '../components/AsyncTask';
import _ from 'lodash';

const IMPORT = gql`
  mutation importWithholdingSlipsCsv($input: ImportWithholdingSlipsCsvInput!) {
    importWithholdingSlipsCsv(input: $input) {
      clientYearly {
        id
        asyncTask {
          ...AsyncTaskFields
        }
      }
    }
  }
  ${ASYNC_TASK_FRAGMENT}
`;

export const fileEncodings = [
  { value: 'utf8', label: 'UTF-8(推奨)' },
  { value: 'sjis', label: 'Shift-JIS' }
];

const UploadForm = () => {
  const [importWithholdingSlipsCsv] = useMutation(IMPORT);
  const notify = useNotify();
  const { history } = useReactRouter();
  const year = useYear();
  const { taskRunningProps } = useAsyncTask();
  return (
    <Hoc
      makeHoc={reduxForm}
      form="importWithholdingSlipsCsv"
      onSubmit={async values => {
        if (!values.file) {
          notify('ファイルを選択してください', 'error');
          return;
        }
        await importWithholdingSlipsCsv({ variables: { input: { ...values, year } } });
        history.push(`/${year}/result`);
      }}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <p className={styles.text}>
            対応しているファイル形式はCSVとなります。
            <br />
            給与・賞与データを
            <Link to={`/${year}/result`} className="u-txt-link">
              こちら
            </Link>
            からダウンロードし、変更後のファイルをアップロードして下さい。
          </p>
          <FileField name="file" />
          <Button as="button" icon={<Upload size={16} />} primary widthWide {...taskRunningProps}>
            アップロード
          </Button>
        </form>
      )}
    </Hoc>
  );
};

const ImportWithholdingSlips = () => {
  const year = useYear();

  return (
    <div>
      <div className="l-contents-wrap">
        <h1 className="m-title-main">給与・賞与の一括登録</h1>
        <p className="m-title-main-note">
          従業員の給与・賞与金額を一括で登録できます。
          <br />
          各従業員の源泉徴収票編集画面から完了してください。
          <br />※ ジョブカン給与計算から情報を取得する場合には
          <Link to={`/${year}/client_setting`} className="u-txt-link">
            こちら
          </Link>
        </p>
        <div className={styles.wrap}>
          <div className={styles.head}>ファイルを選択してください</div>
          <div className={styles.body}>
            <UploadForm />
          </div>
        </div>
        <div className={styles.description}>
          <FileImportDescription />
        </div>
      </div>
    </div>
  );
};

export default ImportWithholdingSlips;
