import React from 'react';
import { Preview } from 'jbc-front/components/icons';
import Button from 'jbc-front/components/Button';
import { useSession } from '../components';
import styles from '../pages/Result.scss';

const disabledEvent = e => e.stopPropagation();

const DisabledReportButton = () => (
  <span className={styles.disabled}>
    <Preview />
  </span>
);

const ReportButton = ({ children }) => {
  const {
    clientYearly: { fixing }
  } = useSession();

  if (fixing) {
    return <DisabledReportButton />;
  }

  return children;
};

export const Report = ({ onClick, disabled }) => (
  <span className={disabled ? styles.disabled : styles.active}>
    <Preview onClick={disabled ? disabledEvent : onClick} />
  </span>
);

export const DependentDeductionThisYearButton = ({ employee, onClick }) => (
  <ReportButton>
    <Report
      onClick={onClick}
      disabled={(employee.status === 'not_need_adj' && !employee.profile.other) || employee.taxSchedule === 'second'}
    />
  </ReportButton>
);

export const DependentDeductionNextYearButton = ({ employee, onClick, year }) => (
  <ReportButton>
    {year >= 2021 ? (
      <Report
        onClick={onClick}
        disabled={
          (employee.status === 'not_need_adj' &&
            !employee.profile.nextYearOnly &&
            !employee.profile.resignBeforeYearEnd &&
            !employee.salaryGreaterThan20Million &&
            !employee.profile.isDisasterSufferer &&
            !employee.profile.other) ||
          employee.profile.isNotCoveredByNextYear
        }
      />
    ) : (
      <Report onClick={onClick} disabled={employee.status === 'not_need_adj' && !employee.profile.nextYearOnly} />
    )}
  </ReportButton>
);

export const InsuranceDeductionButton = ({ employee, onClick }) => (
  <ReportButton>
    <Report onClick={onClick} disabled={employee.status === 'not_need_adj'} />
  </ReportButton>
);

export const BsiDeductionButton = ({ employee, onClick }) => (
  <ReportButton>
    <Report onClick={onClick} disabled={employee.status === 'not_need_adj'} />
  </ReportButton>
);

export const SpouseDeductionButton = ({ employee, onClick }) => (
  <ReportButton>
    <Report onClick={onClick} disabled={employee.status === 'not_need_adj' || !employee.profile.hasSpouseDeductionThisYear} />
  </ReportButton>
);

export const WithholdingSlipButton = ({ employee, onClick }) => {
  const {
    clientYearly: { fixing }
  } = useSession();
  const isPrimary = !employee.withholdingSlip || employee.hasNoPayment;
  const label = employee.hasNoPayment ? '対象外' : employee.withholdingSlip ? '入力済' : '未入力';

  if (fixing) {
    return (
      <Button primary={isPrimary} widthAuto className={styles.withholdingSlipButton} disabled>
        {label}
      </Button>
    );
  }

  return (
    <Button
      primary={isPrimary}
      widthAuto
      onClick={onClick}
      className={styles.withholdingSlipButton}
      disabled={employee.hasNoPayment}
      disabledReason="今年支払った金額がありません"
    >
      {label}
    </Button>
  );
};
