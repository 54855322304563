import { gql } from '@apollo/client';
import { FileType } from '@jbc-year-end-adj/types';

type EmployeeStatus = 'not_requested' | 'in_progress' | 'applying' | 'rejected' | 'accepted' | 'not_need_adj' | 'fixed';
type ResignType = 'others' | 'passed_away' | 'disability';
type Gender = 'male' | 'female';
type NationalType = 'japanese' | 'foreigner';
type TaxSchedule = 'first' | 'second';
export type ResidentsTaxCollectionMethod = 'from_salary' | 'normally_for_resign' | 'normally_others';
export type EmployeeAddressClassification = 'profile' | 'resident_card';

export type Employee = {
  id: number;
  email: string;
  staffCode: string;
  status: EmployeeStatus;
  joinedOn: string;
  resignedOn: string;
  resignType: ResignType;
  employmentType: string;
  position: string;
  isNonResident: boolean;
  profile: Profile;
  request?: Request;
  taxSchedule: TaxSchedule;
  residentsTaxCollectionMethod: ResidentsTaxCollectionMethod;
  hasNoPayment: boolean;
  salaryGreaterThan20Million: boolean;
  isReductionTarget: boolean;
  isManualTargetCount: boolean;
  reduceTargetCount: number;
  reduceTargetCountFromPayroll: number | null;
};

export type Request = {
  id: number;
  profile?: Profile;
  requestComments: RequestComment[];
};

export type RequestComment = {
  id: string;
  actedUser: {
    id: string;
    name: string;
    email: string;
  };
  commentBy: 'admin' | 'employee';
  comment?: string;
  createdAt: string;
};

export type Profile = {
  lastName: string;
  firstName: string;
  lastNameKana: string;
  firstNameKana: string;
  gender: Gender;
  birthday: string;
  postcode0?: string;
  postcode1?: string;
  city?: string;
  street?: string;
  building?: string;
  fullAddress?: string;
  prefecture?: string;
  prefectureId?: string;
  householder?: Householder;
  nationalType: NationalType;
  isDisasterSufferer: boolean;
  resignBeforeYearEnd: boolean;
  workForMultiCompanies: boolean;
  salaryMoreThanOthers: boolean;
  salaryLessThanOthersNextYear: boolean;
  taxPostponementAmount?: string;
  maritalStatus?: 'married' | 'unmarried' | 'divorced_or_bereavement';
  hasSpouseDeduction: boolean;
  hasSpouseDeductionThisYear: boolean;
  residentCard: ResidentCard;
  formerJobs: FormerJob[];
  income?: Income;
  workingStudentClassification?: WorkingStudentClassification;
  handicapClassification?: HandicapClassification;
  widowClassification?: WidowClassification;
  spouse?: Spouse;
  otherFamilies: OtherFamily[];
  generalLifeInsurances: LifeInsurance[];
  nursingMedicalInsurances: LifeInsurance[];
  individualAnnuityInsurances: LifeInsurance[];
  earthquakeInsurances: EarthquakeInsurance[];
  socialInsurances: SocialInsurance[];
  premium?: Premium;
  housingLoan?: HousingLoan;
  attachments: Attachment[];
  residentTaxCity?: ResidentTaxCity;
  nextYearOnly: boolean;
  isNotCoveredByNextYear: boolean;
  other: boolean;
  otherReason?: string;
  spouseLivingStatus?: SpouseLivingStatus;
};

type ResidentTaxCity = {
  id: string;
  name: string;
  code: string;
};

type Householder = {
  id: number;
  name?: string;
  relationship: string;
};

export type ResidentCard = {
  id: number;
  postcode0?: string;
  postcode1?: string;
  city?: string;
  street?: string;
  building?: string;
  fullAddress?: string;
  prefecture?: string;
  prefectureId?: string;
};

export type FormerJob = {
  id: number;
  companyName: string;
  resignedOn: string;
  lastSalaryOn?: string;
  withholdingSlipStatus: 'submitted' | 'not_submitted' | 'unavailable' | 'in_order';
  deductionAmount?: string;
  paymentAmount?: string;
  taxAmount?: string;
  withholdingSlipImage?: FileType;
  address?: string;
  compareKey: string;
};

export type Income = {
  id: number;
  salaryIncome: string;
  salaryEarnings: string;
  businessIncome: string;
  businessExpense: string;
  businessEarnings: string;
  miscellaneousIncomeOfficalPension: string;
  miscellaneousIncomeOther: string;
  miscellaneousExpense: string;
  miscellaneousEarnings: string;
  dividendIncome: string;
  dividendExpense: string;
  dividendEarnings: string;
  realEstateIncome: string;
  realEstateExpense: string;
  realEstateEarnings: string;
  retirementIncome: string;
  lengthOfService?: string;
  retireForDisablity?: boolean;
  isOfficerRetirementAllowance?: boolean;
  retirementIncomeDeductionAmount: string;
  retirementEarnings: string;
  otherIncome: string;
  otherExpense: string;
  otherExpenseSpecialDeduction: string;
  otherEarings: string;
  totalEarnings: string;
  hasSideJob?: boolean;
  mainJobIncome: string;
  sideJobIncome: string;
  hasBusinessIncome?: boolean;
  hasMiscellaneousIncome?: boolean;
  hasDividendIncome?: boolean;
  hasRealEstateIncome?: boolean;
  hasRetirementIncome?: boolean;
  hasOtherIncome?: boolean;
};

export type WorkingStudentClassification = {
  id: number;
  classification: 'none' | 'working_student';
  detail?: string;
  image?: FileType;
  deductionAmount?: string;
};

export type HandicapClassification = {
  id: number;
  classification: 'none' | 'normal' | 'special';
  detail?: string;
  image?: FileType;
};

export type WidowClassification = {
  id: number;
  classification: 'none' | 'normal' | 'special' | 'single_parent';
  detail?: string;
  deductionAmount?: string;
};

export type Dependent = {
  id: number;
  dependentFrom?: string;
  dependentReason?: string;
  firstName: string;
  firstNameKana?: string;
  lastName: string;
  lastNameKana?: string;
  gender?: Gender;
  isNonResident?: boolean;
  isStudyAbroad?: boolean;
  birthday?: string;
  thisYear?: DependentYearlyInfo;
  nextYear?: DependentYearlyInfo;
  income?: Income;
  diedOn?: string;
  compareKey: string;
  isReductionTarget: boolean;
};

export type Spouse = Dependent;

export type OtherFamily = Dependent & {
  relationOther?: string;
};

export type DependentYearlyInfo = {
  id: number;
  postcode0?: string;
  postcode1?: string;
  city?: string;
  street?: string;
  building?: string;
  fullAddress?: string;
  prefecture?: string;
  prefectureId?: string;
  year?: number;
  addressForeign?: string;
  dependentTaxLaw?: boolean;
  incomeAdjustmentDeduction?: boolean;
  earnings?: string;
  handicapType?: 'none' | 'normal' | 'special';
  handicapDetail?: string;
  handicapImage?: FileType;
  income?: string;
  isNonResident?: boolean;
  isStudyAbroad?: boolean;
  relatedToRelativesDocument?: FileType;
  relatedToRemittanceDocument?: FileType;
  provingStudyAbroadDocument?: FileType;
  remittance?: string;
  residenceStatus?: 'same_address' | 'different_address' | 'different_and_foreign_address';
};

export type LifeInsurance = {
  id: number;
  image: FileType;
  amount: string;
  insuranceDuration: string;
  insuranceType: string;
  name: string;
  nameOfContractor: string;
  nameOfRecipient: string;
  newOrOld: 'new_type' | 'old_type';
  relationshipOfRecipient: string;
  paymentStartDate?: string;
  compareKey: string;
};

export type EarthquakeInsurance = {
  id: number;
  image: FileType;
  amount: string;
  insuranceDuration: string;
  insuranceType: string;
  name: string;
  nameOfContractor: string;
  nameOfRecipient: string;
  newOrOld: 'new_type' | 'old_type';
  relationshipOfRecipient: string;
  compareKey: string;
};

type SocialInsuranceType =
  | 'national_pension'
  | 'national_insurance'
  | 'national_health_insurance'
  | 'national_nursing_medical_insurance'
  | 'voluntary_continued_insurance'
  | 'elderly_medical_insurance'
  | 'farmers_pension';

export type SocialInsurance = {
  id: number;
  amount: string;
  name: string;
  nameOfPayer: string;
  relationshipOfPayer: string;
  insuranceType: SocialInsuranceType;
  image: FileType;
  compareKey: string;
};

export type Premium = {
  id: number;
  corporatePension: string;
  corporatePensionImage?: FileType;
  disabilityMutualAid: string;
  disabilityMutualAidImage?: FileType;
  personalPension: string;
  personalPensionImage?: FileType;
  smallAndMediumSizedEnterprises: string;
  smallAndMediumSizedEnterprisesImage?: FileType;
};

export type HousingLoan = {
  id: number;
  housingLoanDeductionApplicableAmount: string;
  housingLoanDeductionApplicableCount: string;
  housingLoanDeductionAmount: string;
  housingLoanDetails: HousingLoanDetail[];
  image?: FileType;
  certificateImage?: FileType;
};

export type DeductionClassification =
  | 'normal'
  | 'ceritified'
  | 'extension'
  | 'earthquake'
  | 'special_case_house_normal'
  | 'special_case_house_certified'
  | 'special_case_house_earthquake';
export type SpecialDeduction = 'none' | 'applied' | 'special_applied' | 'special_exception_applied';

export type HousingLoanDetail = {
  id: number;
  deductionClassification: DeductionClassification;
  yearEndBalance: string;
  residenceStartOn: string;
  specialDeduction: SpecialDeduction;
  no: 1 | 2;
};

export type Attachment = {
  id: number;
  file: FileType;
  compareKey: string;
};

type SpouseLivingStatus = {
  id: string;
  isSameHousehold: boolean;
};

const EMPLOYEE_BASIC_INFORMATION = gql`
  fragment EmployeeBasicInformation on Employee {
    joinedOn
    resignedOn
    resignType
    employmentType
    position
    isNonResident
  }
`;

const PROFILE_BASIC_INFORMATION = gql`
  fragment ProfileBasicInformation on Profile {
    lastName
    firstName
    lastNameKana
    firstNameKana
    gender
    birthday
    postcode0
    postcode1
    city
    street
    building
    fullAddress
    prefecture
    prefectureId
    householder {
      id
      name
      relationship
    }
    nationalType
    isDisasterSufferer
    taxPostponementAmount
    resignBeforeYearEnd
    workForMultiCompanies
    salaryMoreThanOthers
    salaryLessThanOthersNextYear
    maritalStatus
    hasSpouseDeduction
    hasSpouseDeductionThisYear
  }
`;

const RESIDENT_CARD = gql`
  fragment ResidentCard on Profile {
    residentCard {
      id
      postcode0
      postcode1
      city
      street
      building
      fullAddress
      prefecture
      prefectureId
    }
  }
`;

const FORMER_JOBS = gql`
  fragment FormerJobs on Profile {
    formerJobs {
      id
      companyName
      resignedOn
      lastSalaryOn
      withholdingSlipStatus
      deductionAmount
      paymentAmount
      taxAmount
      withholdingSlipImage {
        ...File
      }
      address
      compareKey
    }
  }
`;

const INCOME = gql`
  fragment Income on Income {
    id
    salaryIncome
    salaryEarnings
    businessIncome
    businessExpense
    businessEarnings
    miscellaneousIncomeOfficalPension
    miscellaneousIncomeOther
    miscellaneousExpense
    miscellaneousEarnings
    dividendIncome
    dividendExpense
    dividendEarnings
    realEstateIncome
    realEstateExpense
    realEstateEarnings
    retirementIncome
    lengthOfService
    retireForDisablity
    isOfficerRetirementAllowance
    retirementIncomeDeductionAmount
    retirementEarnings
    otherIncome
    otherExpense
    otherExpenseSpecialDeduction
    otherEarings
    totalEarnings
    hasSideJob
    mainJobIncome
    sideJobIncome
    hasBusinessIncome
    hasMiscellaneousIncome
    hasDividendIncome
    hasRealEstateIncome
    hasRetirementIncome
    hasOtherIncome
  }
`;

const WORKING_STUDENT_CLASSIFICATION = gql`
  fragment WorkingStudentClassification on Profile {
    workingStudentClassification {
      id
      classification
      detail
      image {
        ...File
      }
      deductionAmount
    }
  }
`;

const HANDICAP_CLASSIFICATION = gql`
  fragment HandicapClassification on Profile {
    handicapClassification {
      id
      classification
      detail
      image {
        ...File
      }
    }
  }
`;

const WIDOW_CLASSIFICATION = gql`
  fragment WidowClassification on Profile {
    widowClassification {
      id
      classification
      detail
      deductionAmount
    }
  }
`;

const SPOUSE = gql`
  fragment Spouse on Profile {
    spouse {
      id
      dependentFrom
      dependentReason
      firstName
      firstNameKana
      lastName
      lastNameKana
      gender
      birthday
      diedOn
      compareKey
      isReductionTarget
      thisYear {
        ...DependentYearlyInfo
      }
      nextYear {
        ...DependentYearlyInfo
      }
      income {
        ...Income
      }
    }
  }
`;

const DEPENDENT = `
  fragment Dependent on Dependent {
    id
    dependentFrom
    dependentReason
    firstName
    firstNameKana
    lastName
    lastNameKana
    gender
    birthday
    diedOn
    compareKey
    relationOther
    isReductionTarget
    thisYear {
      ...DependentYearlyInfo
    }
    nextYear {
      ...DependentYearlyInfo
    }
    income {
      ...Income
    }
  }
`;

const DEPDENT_YEARLY_INFO = `
  fragment DependentYearlyInfo on DependentYearlyInfo {
    id
    postcode0
    postcode1
    city
    street
    building
    fullAddress
    prefectureId
    year
    addressForeign
    dependentTaxLaw
    incomeAdjustmentDeduction
    earnings
    handicapType
    handicapDetail
    handicapImage {
      ...File
    }
    income
    isNonResident
    isStudyAbroad
    relatedToRelativesDocument {
      ...File
    }
    relatedToRemittanceDocument {
      ...File
    }
    provingStudyAbroadDocument{
      ...File
    }
    remittance
    residenceStatus
  }
`;

const LIFE_INSURANCE = gql`
  fragment LifeInsurance on LifeInsurance {
    id
    image {
      ...File
    }
    amount
    insuranceDuration
    insuranceType
    name
    nameOfContractor
    nameOfRecipient
    newOrOld
    relationshipOfRecipient
    paymentStartDate
    compareKey
  }
`;

const EARTHQUAKE_INSURANCE = gql`
  fragment EarthquakeInsurance on EarthquakeInsurance {
    id
    image {
      ...File
    }
    amount
    insuranceDuration
    insuranceType
    name
    nameOfContractor
    nameOfRecipient
    newOrOld
    relationshipOfRecipient
    compareKey
  }
`;

const SOCIAL_INSURANCE = gql`
  fragment SocialInsurance on SocialInsurance {
    id
    amount
    name
    nameOfPayer
    relationshipOfPayer
    insuranceType
    image {
      ...File
    }
    compareKey
  }
`;

const IMAGE = gql`
  fragment File on FileObject {
    url
    filename
    file
  }
`;

const PREMIUM = gql`
  fragment Premium on Premium {
    id
    corporatePension
    corporatePensionImage {
      ...File
    }
    disabilityMutualAid
    disabilityMutualAidImage {
      ...File
    }
    personalPension
    personalPensionImage {
      ...File
    }
    smallAndMediumSizedEnterprises
    smallAndMediumSizedEnterprisesImage {
      ...File
    }
  }
`;

const HOUSING_LOAN = gql`
  fragment HousingLoan on HousingLoan {
    id
    housingLoanDeductionApplicableAmount
    housingLoanDeductionApplicableCount
    housingLoanDeductionAmount
    image {
      ...File
    }
    certificateImage {
      ...File
    }
    housingLoanDetails {
      id
      deductionClassification
      yearEndBalance
      residenceStartOn
      specialDeduction
      no
    }
  }
`;

const ATTACHMENTS = gql`
  fragment Attachment on Attachment {
    id
    file {
      ...File
    }
    compareKey
  }
`;

const MEMO_FRAGMENT = gql`
  fragment Memo on Memo {
    id
    body
    alertAt
    color
    updatedAt
    lastUpdatedUser {
      id
      name
    }
    createdUser {
      id
      name
    }
  }
`;

const SPOUSE_LIVING_STATUS = gql`
  fragment SpouseLivingStatus on SpouseLivingStatus {
    id
    isSameHousehold
  }
`;

export const FETCH_EMPLOYEE = gql`
  query fetchEmployee($id: ID!, $year: Int!) {
    client {
      id
      clientYearly(year: $year) {
        id
        fixed
        clientSetting {
          employeeAddressClassification
          sendAcceptedEmail
        }
        employee(id: $id) {
          id
          email
          staffCode
          status
          taxSchedule
          residentsTaxCollectionMethod
          hasNoPayment
          salaryGreaterThan20Million
          isReductionTarget
          isManualTargetCount
          reduceTargetCount
          reduceTargetCountFromPayroll
          request {
            id
            profile {
              ...ProfileBasicInformation
              ...ResidentCard
              ...FormerJobs
              nextYearOnly
              isNotCoveredByNextYear
              income {
                ...Income
              }
              ...WorkingStudentClassification
              ...HandicapClassification
              ...WidowClassification
              ...Spouse
              otherFamilies {
                ...Dependent
              }
              generalLifeInsurances {
                ...LifeInsurance
              }
              nursingMedicalInsurances {
                ...LifeInsurance
              }
              individualAnnuityInsurances {
                ...LifeInsurance
              }
              earthquakeInsurances {
                ...EarthquakeInsurance
              }
              socialInsurances {
                ...SocialInsurance
              }
              premium {
                ...Premium
              }
              housingLoan {
                ...HousingLoan
              }
              attachments {
                ...Attachment
              }
              spouseLivingStatus {
                ...SpouseLivingStatus
              }
            }
            requestComments {
              id
              actedUser {
                id
                email
                name
              }
              commentBy
              comment
              createdAt
            }
          }
          memos {
            ...Memo
          }
          ...EmployeeBasicInformation
          profile {
            ...ProfileBasicInformation
            ...ResidentCard
            ...FormerJobs
            nextYearOnly
            isNotCoveredByNextYear
            other
            otherReason
            income {
              ...Income
            }
            ...WorkingStudentClassification
            ...HandicapClassification
            ...WidowClassification
            ...Spouse
            otherFamilies {
              ...Dependent
            }
            generalLifeInsurances {
              ...LifeInsurance
            }
            nursingMedicalInsurances {
              ...LifeInsurance
            }
            individualAnnuityInsurances {
              ...LifeInsurance
            }
            earthquakeInsurances {
              ...EarthquakeInsurance
            }
            socialInsurances {
              ...SocialInsurance
            }
            premium {
              ...Premium
            }
            housingLoan {
              ...HousingLoan
            }
            attachments {
              ...Attachment
            }
            spouseLivingStatus {
              ...SpouseLivingStatus
            }
            residentTaxCity {
              id
              name
              code
            }
          }
        }
      }
    }
  }
  ${EMPLOYEE_BASIC_INFORMATION}
  ${PROFILE_BASIC_INFORMATION}
  ${RESIDENT_CARD}
  ${FORMER_JOBS}
  ${INCOME}
  ${WORKING_STUDENT_CLASSIFICATION}
  ${HANDICAP_CLASSIFICATION}
  ${WIDOW_CLASSIFICATION}
  ${SPOUSE}
  ${DEPENDENT}
  ${DEPDENT_YEARLY_INFO}
  ${LIFE_INSURANCE}
  ${EARTHQUAKE_INSURANCE}
  ${SOCIAL_INSURANCE}
  ${PREMIUM}
  ${HOUSING_LOAN}
  ${ATTACHMENTS}
  ${IMAGE}
  ${MEMO_FRAGMENT}
  ${SPOUSE_LIVING_STATUS}
`;
