import { gql } from '@apollo/client';
import { QuestionGroup } from '@jbc-year-end-adj/2024/features/questions/types';

export type QueryResult = {
  questionGroups: QuestionGroup[];
  client: Client;
};

type Client = {
  id: string;
  clientYearly: ClientYearly;
};

type ClientYearly = {
  id: string;
  office: Office;
};

type Office = {
  id: string;
  name: string;
};

export const FETCH_QUESTION_GROUPS = gql`
  query fetchQuestionGroups {
    questionGroups {
      id
      name
      no
      questions {
        id
        name
        no
        code
        questionDescriptions {
          id
          field
          name
          description
          defaultDescription
        }
      }
    }
    client {
      id
      clientYearly {
        id
        office {
          id
          name
        }
      }
    }
  }
`;
