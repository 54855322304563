import { ApolloError } from '@apollo/client';
import { camelCase, isObject, toPath } from 'lodash';
import { ErrorOption } from 'react-hook-form';
import { ISchema, reach } from 'yup';
import { fetchErrorMessage } from './fetchErrorMessage';

const camelize = <T>(value: T): T => {
  if (Array.isArray(value)) {
    return value.map(camelize) as T;
  }
  if (isObject(value)) {
    return Object.fromEntries(Object.entries(value).map(([key, value]): [string, unknown] => [camelCase(key), camelize(value)])) as T;
  }
  return value;
};

interface NestedErrors {
  [key: string]: string[] | NestedErrors | NestedErrors[];
}

const flattenValidationErrors = (errors: NestedErrors): Record<string, string[]> => {
  const result: Record<string, string[]> = {};

  const walk = (errors: NestedErrors, prefix: string = '') => {
    for (const [key, value] of Object.entries(errors)) {
      if (Array.isArray(value)) {
        if (typeof value[0] === 'string') {
          result[`${prefix}${key}`] = value as string[];
        } else if (isObject(value[0])) {
          value.forEach((v, index) => {
            walk(v as NestedErrors, `${prefix}${key}.${index}.`);
          });
        }
      } else {
        walk(value as NestedErrors, `${prefix}${key}.`);
      }
    }
  };
  walk(errors);

  return result;
};

export class ServerValidationError extends Error {
  code: string | undefined;
  validationErrors: Record<string, string[]>;
  notifiable: boolean;

  constructor(error: ApolloError) {
    super(fetchErrorMessage(error), { cause: error });
    this.name = 'ServerValidationError';
    this.code = error.graphQLErrors[0]?.extensions?.code;
    this.validationErrors = flattenValidationErrors(camelize(error.graphQLErrors[0]?.extensions?.validationErrors));
    this.notifiable = error.graphQLErrors[0]?.extensions?.notifiable !== false;
  }

  eachBaseErrors(callback: (path: string, errors: string[]) => void) {
    Object.entries(this.validationErrors).forEach(([path, errors]) => {
      if (toPath(path).at(-1) === 'base') {
        callback(path, errors);
      }
    });
  }
}

export const eachValidationErrorsForHookFrom = <S extends ISchema<T>, T>(
  errors: Record<string, string[]>,
  schema: S,
  value: unknown,
  callback: (path: string, error: ErrorOption) => void
) => {
  Object.entries(errors).forEach(([path, errors]) => {
    const description = reach(schema, path, value).describe({ value });
    const label = 'label' in description ? description.label : '';

    errors.forEach((error, index) => {
      callback(path, {
        type: `server[${index}]`,
        message: `${label}${error}`
      });
    });
  });
};
