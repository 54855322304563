import { FC } from 'react';
import { Modal } from 'jbc-front/components/presenters/ui/Modal';
import Button from 'jbc-front/components/Button';
import { ButtonRow } from 'jbc-front/components/presenters/layout/ButtonRow';

import { useNotify } from '@jbc-year-end-adj/common/hooks/useNotify';
import { useAdminMutation as useMutation } from '@jbc-year-end-adj/2024/hooks/graphql/useMutation';
import { IMPORT_FROM_PREV_YEAR } from './mutation';
import styles from './Cooperation.scss';

type PrevYearImportModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const PrevYearImportModal: FC<PrevYearImportModalProps> = ({ isOpen, onClose }) => {
  const [importFromPrevYear, { loading }] = useMutation(IMPORT_FROM_PREV_YEAR);
  const notify = useNotify();

  const handleSubmit = async () => {
    try {
      await importFromPrevYear();
      onClose();
    } catch (err) {
      const msg = err.graphQLErrors?.[0]?.message;
      notify(msg || err.message, 'error');
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Header onClose={onClose}>昨年の年末調整データから保険・ローン情報を取得</Modal.Header>
      <Modal.Body>
        <p>スタッフコードをもとに、昨年の年末調整から「保険・ローン」の情報を取得します。</p>
        <p>現在入力されている「保険・ローン」の情報は上書きされます。</p>
        <p>※ステータスが「未依頼」のみ取得が出来ます。</p>
        <p>※昨年の添付ファイルは取得されません</p>
        <p className={`${styles.warning} u-mt15`}>本当に取得しますか。</p>
      </Modal.Body>
      <Modal.Footer>
        <ButtonRow>
          <Button onClick={onClose} disabled={loading}>
            キャンセル
          </Button>
          <Button primary onClick={handleSubmit} disabled={loading}>
            取得
          </Button>
        </ButtonRow>
      </Modal.Footer>
    </Modal>
  );
};
