import React from 'react';
import classnames from 'classnames';
import { Notifications } from '@jbc-year-end-adj/common/components/Notifications';
import Footer from './components/Footer';
import Sidebar from './components/Sidebar';
import { Header } from './Header';
import styles from './Layout.scss';

const Layout = ({ children, sidebar, showHeader, showFooter }) => (
  <div>
    <Notifications />
    {showHeader && <Header showNavi />}
    <div className={styles.main}>
      {sidebar && <Sidebar offset={56} />}
      <div className={classnames({ [styles.marginSidebar]: sidebar })}>
        <div className="l-main-contents">{children}</div>
        {showFooter && <Footer />}
      </div>
    </div>
  </div>
);

export default Layout;
