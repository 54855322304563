import React from 'react';
import Button from 'jbc-front/components/Button';
import { useMutation, useQuery } from '../components/Graphql';
import { gql } from '@apollo/client';
import { reduxForm, useFormValue } from '../components/FormName';
import { FileField, AmountField } from '../components/FieldWithDiff';
import { toFormValues } from '../utils/form';
import _ from 'lodash';
import { amount } from '../validators';
import { Panel, Headding, Item, Lead, Buttons } from '../components/PrimaryPanel';
import { parseNumber } from '../utils/income';
import LoadingPage from '../components/LoadingPage';
import useReactRouter from 'use-react-router';
import { CustomDescription } from '../components/QuestionDescription';
import { useNotify } from '@jbc-year-end-adj/common/hooks/useNotify';
import FilePreview from '../components/FilePreview';
import { ToggleDivImage } from '../components/ToggleDivImage';
import image from '../../images/img-Premium_Deduction_Small_Business_Insurance.png';
import { amountFormat } from '@jbc-year-end-adj/common/utils/formatter';
import styles from './Premium.scss';

const amounts = ['corporatePension', 'disabilityMutualAid', 'personalPension', 'smallAndMediumSizedEnterprises'];

export const PREMIUM_FRAGMENT = gql`
  fragment Premium on Profile {
    premium {
      id
      ${amounts.join('\n')}
      smallAndMediumSizedEnterprisesImage {
        url
        filename
      }
      corporatePensionImage {
        url
        filename
      }
      disabilityMutualAidImage {
        url
        filename
      }
      personalPensionImage {
        url
        filename
      }
    }
  }
`;

const PREMIUM = gql`
  query premium {
    request {
      id
      profile {
        id
        ...Premium
      }
    }
    clientSetting {
      id
      requestImageUploadSettings {
        premium
      }
    }
  }
  ${PREMIUM_FRAGMENT}
`;

const ANSWER_PREMIUM = gql`
  mutation answerPremium($input: AnswerPremiumInput!) {
    answerPremium(input: $input) {
      questions {
        id
        status
      }
      profile {
        id
        ...Premium
      }
    }
  }
  ${PREMIUM_FRAGMENT}
`;

export const Premiums = () => {
  const { data, loading } = useQuery(PREMIUM);
  const corporatePension = useFormValue('corporatePension');
  const disabilityMutualAid = useFormValue('disabilityMutualAid');
  const personalPension = useFormValue('personalPension');
  const smallAndMediumSizedEnterprises = useFormValue('smallAndMediumSizedEnterprises');
  if (loading) return <LoadingPage />;
  const {
    clientSetting: {
      requestImageUploadSettings: { premium: premiumImageSetting }
    }
  } = data;
  return (
    <>
      <AmountField
        name="smallAndMediumSizedEnterprises"
        label="独立行政法人中小企業基盤整備機構の共済契約の掛金"
        validate={amount}
        placeholder="掛金がある場合に記入してください　　例：100000"
      />
      {premiumImageSetting !== 'none' && (
        <FileField
          name="smallAndMediumSizedEnterprisesImage"
          label="独立行政法人中小企業基盤整備機構の共済契約の掛金 証明書画像"
          preview={FilePreview}
          disablePreview={false}
          description="対応可能形式：jpg, jpeg, gif, png, pdf"
          required={parseNumber(smallAndMediumSizedEnterprises) > 0 && premiumImageSetting === 'required'}
        />
      )}
      <AmountField
        name="corporatePension"
        label="確定拠出年金法に規定する企業型年金加入者掛金"
        placeholder="企業型年金の掛金がある場合に記入してください　　例：100000"
        validate={amount}
      />
      {premiumImageSetting !== 'none' && (
        <FileField
          name="corporatePensionImage"
          label="確定拠出年金法に規定する企業型年金加入者掛金 証明書画像"
          preview={FilePreview}
          disablePreview={false}
          description="対応可能形式：jpg, jpeg, gif, png, pdf"
          required={parseNumber(corporatePension) > 0 && premiumImageSetting === 'required'}
        />
      )}
      <AmountField
        name="personalPension"
        label="確定拠出年金法に規定する個人型年金加入者掛金"
        placeholder="個人型年金（iDeCo）の掛金がある場合に記入してください　　例：10000"
        validate={amount}
      />
      {premiumImageSetting !== 'none' && (
        <FileField
          name="personalPensionImage"
          label="確定拠出年金法に規定する個人型年金加入者掛金 証明書画像"
          preview={FilePreview}
          disablePreview={false}
          description="対応可能形式：jpg, jpeg, gif, png, pdf"
          required={parseNumber(personalPension) > 0 && premiumImageSetting === 'required'}
        />
      )}
      <AmountField
        name="disabilityMutualAid"
        label="心身障害者扶養共済制度に関する契約の掛金"
        placeholder="掛金がある場合に記入してください　　例：100000"
        validate={amount}
      />
      {premiumImageSetting !== 'none' && (
        <FileField
          name="disabilityMutualAidImage"
          label="心身障害者扶養共済制度に関する契約の掛金 証明書画像"
          preview={FilePreview}
          disablePreview={false}
          description="対応可能形式：jpg, jpeg, gif, png, pdf"
          required={parseNumber(disabilityMutualAid) > 0 && premiumImageSetting === 'required'}
        />
      )}
    </>
  );
};

const QuestionForm =
  (({ dispatch, handleSubmit, classification, gender, maritalStatus }) => (
    <form onSubmit={handleSubmit}>
      <Premiums />
      <Buttons>
        <Button primary onClick={handleSubmit}>
          次ヘ
        </Button>
      </Buttons>
    </form>
  )) |> reduxForm({ form: 'premium' });

const Question = ({ dispatch, handleSubmit }) => {
  const { data, loading } = useQuery(PREMIUM);
  const [answer] = useMutation(ANSWER_PREMIUM);
  const { history } = useReactRouter();
  const notify = useNotify();
  if (loading) return <LoadingPage />;

  const formatValues = premium => {
    return {
      ...premium,
      smallAndMediumSizedEnterprises: amountFormat(premium.smallAndMediumSizedEnterprises),
      corporatePension: amountFormat(premium.corporatePension),
      personalPension: amountFormat(premium.personalPension),
      disabilityMutualAid: amountFormat(premium.disabilityMutualAid)
    };
  };
  return (
    <Panel>
      <Headding className={styles.primaryPanelHeader}>共済契約掛金・個人型企業型年金掛金</Headding>
      <Item>
        <Lead>
          今年支払った共済契約掛金・個人型企業型年金掛金情報を
          <br />
          記入してください。
        </Lead>
        <CustomDescription field="top" />
        <ToggleDivImage title={'※赤枠に関する設問です。'}>
          <img src={image} alt="保険料控除_小規模企業共済" />
        </ToggleDivImage>
        <QuestionForm
          initialValues={toFormValues(formatValues(_.omit(data.request.profile.premium || {}, 'id')))}
          onSubmit={async values => {
            if (amounts.filter(amount => values[amount]).length < 1) {
              notify('今年支払った共済契約掛金・個人型企業型年金掛金情報を記入してください', 'error');
              return;
            }
            await answer({
              variables: {
                input: values
              }
            });
            history.push('/employee_input');
          }}
        />
      </Item>
    </Panel>
  );
};

export default Question;
