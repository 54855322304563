import { FC, ReactNode } from 'react';
import { Notifications } from '@jbc-year-end-adj/common/components/Notifications';
import styles from './Layout.scss';

type SimpleLayoutProps = {
  children: ReactNode;
};

export const SimpleLayout: FC<SimpleLayoutProps> = ({ children }) => {
  return (
    <div>
      <Notifications />
      <div className={styles.main}>
        <div className="l-main-contents">{children}</div>
      </div>
    </div>
  );
};
