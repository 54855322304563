import { FC } from 'react';
import { SocialInsurances as SocialInsurancesTemplate } from 'features/questions/templates/SocialInsurances';
import { useWizard } from '../WizardProvider';
import { SocialInsurance } from '../../../query';
import { Section } from 'components/ui/Section';
import { Input } from 'components/form/Input';
import { InputWithText } from 'components/form/InputWithText';
import { FormSection } from 'components/form/FormSection';
import { FileField } from 'components/form/FileField';
import { SelectField } from 'components/form/SelectField';
import { FilePreview } from 'components/feature/FilePreview';
import { amountFormat } from '@jbc-year-end-adj/common/utils/formatter';
import { useYear } from 'hooks/useYear';

const socialInsuranceTypes = [
  { value: 'national_pension', label: '国民年金' },
  { value: 'national_insurance', label: '国民年金基金' },
  { value: 'national_health_insurance', label: '国民健康保険' },
  { value: 'elderly_medical_insurance', label: '後期高齢者医療保険' },
  { value: 'national_nursing_medical_insurance', label: '介護保険' },
  { value: 'voluntary_continued_insurance', label: '任意継続保険' },
  { value: 'farmers_pension', label: '農業者年金' }
];

export const SocialInsurances: FC = () => {
  const { question, request } = useWizard();
  const year = useYear();

  return (
    <SocialInsurancesTemplate question={question}>
      {request.profile?.socialInsurances.map((insurance, index) => (
        <SocialInsuranceForm key={insurance.id} insurance={insurance} index={index + 1} year={year} />
      ))}
    </SocialInsurancesTemplate>
  );
};

type SocialInsuranceFormProps = {
  insurance: SocialInsurance;
  index: number;
  year: number;
};

const SocialInsuranceForm: FC<SocialInsuranceFormProps> = ({ insurance, index, year }) => {
  const file = insurance.image;

  return (
    <Section>
      <Section.Header>社会保険({index})</Section.Header>
      <Section.Body>
        <FormSection>
          <SelectField
            label="社会保険の種類"
            options={socialInsuranceTypes}
            value={socialInsuranceTypes.find(type => type.value === insurance.insuranceType)}
            required
            disabled
          />
          <Input label="保険料支払先の名称" required value={insurance.name} disabled />
          <Input
            label="保険料を負担することになっている人 氏名"
            required
            note="保険料控除証明書に記載された被保険者の氏名を記入してください。"
            value={insurance.nameOfPayer}
            disabled
          />
          {year <= 2023 && (
            <Input
              label="保険料を負担することになっている人 続柄"
              required
              note="保険料控除証明書に記載された被保険者と本人の関係を記入してください。"
              value={insurance.relationshipOfPayer}
              disabled
            />
          )}
          <InputWithText label="本年中に支払った金額" text="円" required value={amountFormat(insurance.amount)} disabled />

          <FileField
            label="社会保険の証明書画像"
            note="管理者が確認できるよう保険料控除証明書の画像（対応可能形式：jpg, jpeg, gif, png, pdf）をアップロードしてください。原本は必ず会社に提出してください。"
            disabled
          >
            {file && (
              <FileField.FileContainer>
                <FileField.File file={file} />
                <FilePreview file={file} />
              </FileField.FileContainer>
            )}
          </FileField>
        </FormSection>
      </Section.Body>
    </Section>
  );
};
