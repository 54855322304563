import { FC } from 'react';
import { Dl, Dt, Dd, FileDd, FileDl } from '../../../components/DefinitionList';
import { Grid } from '../../../components/Grid';
import { PreviewSubSection } from '../../../components/PreviewSubSection';

import { amountFormat } from '@jbc-year-end-adj/common/utils/formatter';
import { useEmployeeInfo } from '../EmployeeInfoProvider';

export const Preview: FC = () => {
  const { employee } = useEmployeeInfo();
  const premium = employee.profile.premium;

  if (!premium) {
    return <p>小規模企業共済等掛金情報は存在しません。</p>;
  }

  return (
    <Grid colGap col2>
      <PreviewSubSection>
        <PreviewSubSection.Header>独立行政法人中小企業基盤整備機構の共済契約</PreviewSubSection.Header>
        <PreviewSubSection.Body>
          <Dl>
            <Dt>掛金</Dt>
            <Dd>{amountFormat(premium?.smallAndMediumSizedEnterprises)}</Dd>
          </Dl>
          <FileDl>
            <Dt>証明書画像</Dt>
            <FileDd file={premium.smallAndMediumSizedEnterprisesImage} />
          </FileDl>
        </PreviewSubSection.Body>
      </PreviewSubSection>

      <PreviewSubSection>
        <PreviewSubSection.Header>確定拠出年金法に規定する企業型年金加入者</PreviewSubSection.Header>
        <PreviewSubSection.Body>
          <Dl>
            <Dt>掛金</Dt>
            <Dd>{amountFormat(premium?.corporatePension)}</Dd>
          </Dl>
          <FileDl>
            <Dt>証明書画像</Dt>
            <FileDd file={premium.corporatePensionImage} />
          </FileDl>
        </PreviewSubSection.Body>
      </PreviewSubSection>

      <PreviewSubSection>
        <PreviewSubSection.Header>確定拠出年金法に規定する個人型年金加入者</PreviewSubSection.Header>
        <PreviewSubSection.Body>
          <Dl>
            <Dt>掛金</Dt>
            <Dd>{amountFormat(premium?.personalPension)}</Dd>
          </Dl>
          <FileDl>
            <Dt>証明書画像</Dt>
            <FileDd file={premium.personalPensionImage} />
          </FileDl>
        </PreviewSubSection.Body>
      </PreviewSubSection>

      <PreviewSubSection>
        <PreviewSubSection.Header>心身障害者扶養共済制度に関する契約</PreviewSubSection.Header>
        <PreviewSubSection.Body>
          <Dl>
            <Dt>掛金</Dt>
            <Dd>{amountFormat(premium?.disabilityMutualAid)}</Dd>
          </Dl>

          <FileDl>
            <Dt>証明書画像</Dt>
            <FileDd file={premium.disabilityMutualAidImage} />
          </FileDl>
        </PreviewSubSection.Body>
      </PreviewSubSection>
    </Grid>
  );
};
