import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { Input } from 'components/form/Input';
import { SelectField } from 'components/react-hook-form/SelectField';
import { FileField } from 'components/react-hook-form/FileField';
import { DeletableSection } from '../../../../components/DeletableSection';
import { FormSection } from 'components/form/FormSection';
import { Schema } from './schema';
import { FilePreview } from 'components/feature/FilePreview';
import { AmountField } from 'components/react-hook-form/AmountField';
import { useYear } from 'hooks/useYear';

type SocialInsuranceType = {
  index: number;
  onDelete: () => void;
};

const socialInsuranceTypes = [
  { value: 'national_pension', label: '国民年金' },
  { value: 'national_insurance', label: '国民年金基金' },
  { value: 'national_health_insurance', label: '国民健康保険' },
  { value: 'elderly_medical_insurance', label: '後期高齢者医療保険' },
  { value: 'national_nursing_medical_insurance', label: '介護保険' },
  { value: 'voluntary_continued_insurance', label: '任意継続保険' },
  { value: 'farmers_pension', label: '農業者年金' }
];

export const SocialInsurance: FC<SocialInsuranceType> = ({ index, onDelete }) => {
  const year = useYear();
  const {
    formState: { errors },
    watch,
    register,
    setValue
  } = useFormContext<Schema>();

  const image = watch(`socialInsurances.${index}.image`);

  return (
    <DeletableSection>
      <DeletableSection.Header onDelete={onDelete}>社会保険（{index + 1}）</DeletableSection.Header>
      <DeletableSection.Body>
        <FormSection>
          <SelectField
            label="社会保険の種類"
            required
            placeholder="選択して下さい"
            options={socialInsuranceTypes}
            error={errors.socialInsurances && errors.socialInsurances[index]?.insuranceType?.message}
            name={`socialInsurances.${index}.insuranceType`}
          />
          <Input
            label="保険料支払先の名称"
            required
            placeholder="例：日本年金機構　等"
            {...register(`socialInsurances.${index}.name`)}
            error={errors.socialInsurances && errors.socialInsurances[index]?.name?.message}
            isError={errors.socialInsurances && !!errors.socialInsurances[index]?.name?.message}
          />
          <Input
            label="保険料を負担することになっている人 氏名"
            required
            note="保険料控除証明書に記載された被保険者の氏名を記入してください。"
            noteMaxLength={19}
            placeholder="例：ジョブカン　二郎"
            {...register(`socialInsurances.${index}.nameOfPayer`)}
            error={errors.socialInsurances && errors.socialInsurances[index]?.nameOfPayer?.message}
            isError={errors.socialInsurances && !!errors.socialInsurances[index]?.nameOfPayer?.message}
          />
          {year <= 2023 && (
            <Input
              label="保険料を負担することになっている人 続柄"
              required
              note="保険料控除証明書に記載された被保険者と本人の関係を記入してください。"
              noteMaxLength={19}
              placeholder="例：次男"
              {...register(`socialInsurances.${index}.relationshipOfPayer`)}
              error={errors.socialInsurances && errors.socialInsurances[index]?.relationshipOfPayer?.message}
              isError={errors.socialInsurances && !!errors.socialInsurances[index]?.relationshipOfPayer?.message}
            />
          )}
          <AmountField
            label="本年中に支払った金額"
            required
            placeholder="例：100000"
            {...register(`socialInsurances.${index}.amount`)}
            error={errors.socialInsurances && errors.socialInsurances[index]?.amount?.message}
            isError={errors.socialInsurances && !!errors.socialInsurances[index]?.amount?.message}
          />
          <FileField
            label="社会保険の証明書画像"
            note="管理者が確認できるよう保険料控除証明書の画像（対応可能形式：jpg, jpeg, gif, png, pdf）をアップロードしてください。原本は必ず会社に提出してください。"
            noteMaxLength={19}
            name={`socialInsurances.${index}.image`}
          >
            {image && (
              <FileField.FileContainer>
                <FileField.File file={image} onRemove={() => setValue(`socialInsurances.${index}.image`, null)} />
                <FilePreview file={image} />
              </FileField.FileContainer>
            )}
          </FileField>
        </FormSection>
      </DeletableSection.Body>
    </DeletableSection>
  );
};
