import { FC } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Modal } from 'jbc-front/components/presenters/ui/Modal';
import Button from 'jbc-front/components/Button';
import { Spacer } from 'jbc-front/components/presenters/Spacer';
import { Checkbox } from 'jbc-front/components/presenters/form/Checkbox';

import { Textarea } from 'components/form/Textarea';
import { FormSection } from 'components/form/FormSection';
import { useResult } from '../ResultProvider';
import { REFLECT } from './mutation';
import { FETCH_EMPLOYEE } from '../../../query';

import { useNotify } from '@jbc-year-end-adj/common/hooks/useNotify';
// @ts-ignore
import { useMutation } from 'components/Graphql';

type Schema = {
  isSendAcceptEmail: boolean;
  comment: string;
};

type ReflectModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onAfterReflect: () => void;
  sendAcceptedEmail: boolean;
};

export const ReflectModal: FC<ReflectModalProps> = ({ isOpen, onClose, onAfterReflect, sendAcceptedEmail }) => {
  const { employee } = useResult();
  const [reflect, { loading }] = useMutation(REFLECT);
  const notify = useNotify();

  const {
    handleSubmit,
    formState: { errors },
    watch,
    register
  } = useForm<Schema>({
    resolver: yupResolver(
      yup.object({
        isSendAcceptEmail: yup.boolean(),
        comment: yup.string()
      })
    ),
    defaultValues: {
      isSendAcceptEmail: sendAcceptedEmail,
      comment: ''
    }
  });

  const isSendAcceptEmail = watch('isSendAcceptEmail');

  const submit: SubmitHandler<Schema> = async data => {
    await reflect({
      variables: {
        input: {
          employeeId: String(employee.id),
          comment: data.comment,
          isSendAcceptEmail: data.isSendAcceptEmail
        }
      },
      refetchQueries: [FETCH_EMPLOYEE]
    });
    onAfterReflect();
    notify('アンケート結果を承認しました', 'success');
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Header onClose={onClose}>承認</Modal.Header>
      <Modal.Body>
        <FormSection>
          <p>従業員提出情報を承認し、従業員の情報に反映しますか？</p>

          {!sendAcceptedEmail && (
            <Checkbox {...register('isSendAcceptEmail')} checked={isSendAcceptEmail}>
              承認完了メールを送信する
            </Checkbox>
          )}

          {isSendAcceptEmail && (
            <Textarea
              label="コメント"
              placeholder="従業員に通知したい内容などを入力してください"
              {...register('comment')}
              error={errors.comment?.message}
              isError={!!errors.comment?.message}
            />
          )}
        </FormSection>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose} disabled={loading}>
          キャンセル
        </Button>
        <Spacer direction="x" size={20} />
        <Button primary onClick={handleSubmit(submit)} disabled={loading}>
          承認する
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
