import { FC } from 'react';
import { booleanFormat, displayFormat, amountFormat } from '@jbc-year-end-adj/common/utils/formatter';
import { Dl, Dt, Dd, FileDd, FileDl } from '../../../components/DefinitionList';
import { Grid } from '../../../components/Grid';
import { useEmployeeInfo } from '../EmployeeInfoProvider';
import { WORKING_STUDENT_CLASSIFICATION, HANDICAP_CLASSIFICATION, WIDOW_CLASSIFICATION } from '../../../consts';

export const Preview: FC = () => {
  const { employee } = useEmployeeInfo();
  const profile = employee.profile;

  const workingStudentClassification = profile.workingStudentClassification?.classification
    ? WORKING_STUDENT_CLASSIFICATION[profile.workingStudentClassification.classification]
    : displayFormat();

  const handicapClassification = profile.handicapClassification?.classification
    ? HANDICAP_CLASSIFICATION[profile.handicapClassification.classification]
    : displayFormat();

  const widowClassification = profile.widowClassification?.classification
    ? WIDOW_CLASSIFICATION[profile.widowClassification.classification]
    : displayFormat();

  return (
    <Grid>
      <Dl>
        <Dt>勤労学生区分</Dt>
        <Dd>{workingStudentClassification}</Dd>
      </Dl>

      <Dl>
        <Dt>勤労学生詳細</Dt>
        <Dd>{profile.workingStudentClassification?.detail}</Dd>
      </Dl>

      <FileDl>
        <Dt>学生手帳画像</Dt>
        <FileDd file={profile.workingStudentClassification?.image} />
      </FileDl>

      <Dl>
        <Dt>障害者区分</Dt>
        <Dd>{handicapClassification}</Dd>
      </Dl>

      <Dl>
        <Dt>障害者詳細</Dt>
        <Dd>{profile.handicapClassification?.detail}</Dd>
      </Dl>

      <FileDl>
        <Dt>障害者手帳画像</Dt>
        <FileDd file={profile.handicapClassification?.image} />
      </FileDl>

      <Dl>
        <Dt>ひとり親・寡婦区分</Dt>
        <Dd>{widowClassification}</Dd>
      </Dl>

      <Dl>
        <Dt>（源泉徴収票）災害者</Dt>
        <Dd>{booleanFormat({ value: profile.isDisasterSufferer, truthyString: '災害者', falsyString: '対象外' })}</Dd>
      </Dl>

      {profile.isDisasterSufferer && (
        <Dl>
          <Dt isRequired>徴収猶予税額</Dt>
          <Dd>{amountFormat(profile.taxPostponementAmount)}</Dd>
        </Dl>
      )}
    </Grid>
  );
};
