import { gql } from '@apollo/client';

export type Variables = {
  input: {
    employeeId: string;
  };
};

export const convertToVariables = (employeeId: string) => {
  return {
    input: {
      employeeId
    }
  };
};

export const DELETE_FLAT_TAX_REDUCTION = gql`
  mutation DeleteFlatTaxReduction($input: DeleteFlatTaxReductionInput!) {
    deleteFlatTaxReduction(input: $input) {
      employee {
        id
      }
    }
  }
`;
