import { FC } from 'react';
import { Label } from 'jbc-front/components/presenters/form/Label';
import { Note } from 'jbc-front/components/presenters/ui/Note';
import { ReadMoreReadLess } from 'jbc-front/components/presenters/ui/ReadMoreReadLess';

import { Section } from 'components/ui/Section';
import { FormSection } from 'components/form/FormSection';
import { Radio } from 'components/form/Radio';
import { Textarea } from 'components/form/Textarea';
import { InputWithText } from 'components/form/InputWithText';
import { FileField } from 'components/form/FileField';
import { DifferentAddress } from './DifferentAddress';
import { DifferentAndForeignAddress } from './DifferentAndForeignAddress';
import { FormField } from 'components/form/FormField';
import { Row } from 'components/form/Row';

import { gql, useQuery } from '@apollo/client';
import { FilePreview } from 'components/feature/FilePreview';
import { Spouse } from '../../../../query';
import { useYear } from 'hooks/useYear';
import { amountFormat } from '@jbc-year-end-adj/common/utils/formatter';

const IMAGE_SETTINGS = gql`
  query imageSettings($year: Int!) {
    client {
      clientYearly(year: $year) {
        clientSetting {
          id
          requestImageUploadSettings {
            handicap
          }
        }
      }
    }
  }
`;

type NextYearProps = {
  spouse?: Spouse;
};

export const NextYear: FC<NextYearProps> = ({ spouse }) => {
  const year = useYear();

  const { data, loading } = useQuery(IMAGE_SETTINGS, { variables: { year } });

  if (loading) return null;

  const {
    client: {
      clientYearly: {
        clientSetting: {
          requestImageUploadSettings: { handicap: handicapImageSetting }
        }
      }
    }
  } = data;

  const nextYear = spouse?.nextYear;

  return (
    <Section>
      <Section.Header>来年の扶養情報</Section.Header>
      <Section.Body>
        <FormSection>
          <FormField>
            <Radio.Container label="来年の源泉控除対象、または障害者控除対象" required>
              <Radio checked={nextYear && nextYear.dependentTaxLaw} disabled>
                対象
              </Radio>
              <Radio checked={nextYear && !nextYear.dependentTaxLaw} disabled>
                対象外
              </Radio>
            </Radio.Container>

            {year >= 2024 && (
              <FormField.NoteContainer>
                <Note>
                  <ReadMoreReadLess maxLength={19}>
                    税法上の扶養対象については
                    <a className="u-txt-link" href="https://jobcan-lms.zendesk.com/hc/ja/articles/360018787951">
                      こちら
                    </a>
                    をご確認ください。
                    <br />
                    従業員本人の所得が「900万円以下（給与収入のみの場合は1,095万円以下）」で配偶者の所得が「95万以下（給与収入のみの場合は150万円以下）の場合に源泉控除対象配偶者となります。
                    <br />
                    年金収入のみの場合は108万円（65歳未満）、158万円（65歳以上）を超える場合は源泉控除対象配偶者に該当しません。
                  </ReadMoreReadLess>
                </Note>
              </FormField.NoteContainer>
            )}
          </FormField>

          {nextYear && nextYear.dependentTaxLaw && (
            <>
              <Radio.Container label="同居・別居" required>
                <Radio checked={nextYear.residenceStatus === 'same_address'} disabled>
                  同居
                </Radio>
                <Radio checked={nextYear.residenceStatus === 'different_address'} disabled>
                  別居（国内）
                </Radio>
                <Radio checked={nextYear.residenceStatus === 'different_and_foreign_address'} disabled>
                  別居（国外）
                </Radio>
              </Radio.Container>

              {nextYear.residenceStatus === 'different_address' && <DifferentAddress yearlyInfo={nextYear} />}
              {nextYear.residenceStatus === 'different_and_foreign_address' && <DifferentAndForeignAddress yearlyInfo={nextYear} />}

              <Radio.Container label="障害者区分" required>
                <Radio checked={nextYear.handicapType === 'none'} disabled>
                  対象外
                </Radio>
                <Radio checked={nextYear.handicapType === 'normal'} disabled>
                  一般障害者
                </Radio>
                <Radio checked={nextYear.handicapType === 'special'} disabled>
                  特別障害者
                </Radio>
              </Radio.Container>

              {(nextYear.handicapType === 'normal' || nextYear.handicapType === 'special') && (
                <>
                  <Textarea
                    label="詳細"
                    note="障害の状態又は交付を受けている手帳などの種類と交付年月日、障害の等級等を記入してください。"
                    value={nextYear.handicapDetail}
                    disabled
                  />

                  {handicapImageSetting !== 'none' && (
                    <FileField label="障害者手帳画像" required={handicapImageSetting === 'required'} disabled>
                      {nextYear.handicapImage && (
                        <FileField.FileContainer>
                          <FileField.File file={nextYear.handicapImage} />
                          <FilePreview file={nextYear.handicapImage} />
                        </FileField.FileContainer>
                      )}
                    </FileField>
                  )}
                </>
              )}

              <FormField>
                {year >= 2024 ? (
                  <>
                    <Row>
                      <InputWithText label="給与収入等" text="円" noteMaxLength={19} value={amountFormat(nextYear.income)} disabled />
                      <InputWithText label="所得見積額" text="円" value={amountFormat(nextYear.earnings)} disabled />
                    </Row>

                    <FormField.NoteContainer>
                      <Note>
                        <ReadMoreReadLess maxLength={19}>
                          「今年の扶養情報をコピーする」ボタンを使用した場合、給与収入金額と給与所得金額が
                          「給与収入等」に反映されます。また、「給与収入等」に手入力された金額は、すべて給与
                          収入と判定し、「所得見積額」が自動計算されます。給与収入以外の収入がある場合は、
                          「給与収入等」と「所得見積額」を手入力してください。
                        </ReadMoreReadLess>
                      </Note>
                    </FormField.NoteContainer>
                  </>
                ) : (
                  <>
                    <FormField.LabelContainer>
                      <Label>所得見積額</Label>
                    </FormField.LabelContainer>
                    <InputWithText value={amountFormat(nextYear.earnings)} text="円" disabled />
                    <FormField.NoteContainer>
                      <Note>
                        税法上の扶養対象については
                        <a className="u-txt-link" href="https://jobcan-lms.zendesk.com/hc/ja/articles/360018787951">
                          こちら
                        </a>
                        をご確認ください。
                        <br />
                        従業員本人の所得が「900万円以下（給与収入のみの場合は1,095万円以下）」で配偶者の所得が「95万以下（給与収入のみの場合は150万円以下）の場合に源泉控除対象配偶者となります。
                        <br />
                        年金収入のみの場合は108万円（65歳未満）、158万円（65歳以上）を超える場合は源泉控除対象配偶者に該当しません。
                      </Note>
                    </FormField.NoteContainer>
                  </>
                )}
              </FormField>
            </>
          )}
        </FormSection>
      </Section.Body>
    </Section>
  );
};
