import { FC } from 'react';
import { Grid } from '../../../../components/Grid';
import { Diff, FileDiff } from '../../../../components/Diff';
import { Dl, Dt, Dd, FileDd, FileDl } from '../../../../components/DefinitionList';
import { Tag } from '../../../../components/Tag';
import { PreviewSubSection } from '../../../../components/PreviewSubSection';
import { useResult } from '../../ResultProvider';
import { Section } from 'components/ui/Section';
import { EarthquakeInsurance } from '../../../../query';
import { amountFormat, displayFormat } from '@jbc-year-end-adj/common/utils/formatter';
import { useYear } from 'hooks/useYear';

type DiffType = {
  type: 'add' | 'update' | 'delete';
  source: EarthquakeInsurance | undefined;
  input: EarthquakeInsurance | undefined;
};

const NEW_OR_OLD = {
  new_type: '地震',
  old_type: '旧長期'
};

export const EarthquakeInsuranceDiff: FC = () => {
  const { employee } = useResult();
  const profile = employee.profile;
  const requestProfile = employee.request?.profile;

  // compareKeyが一致するもの || profileにしかないもの
  const earthquakeInsuranceDiff = profile.earthquakeInsurances.reduce<DiffType[]>((diff, source) => {
    const input = requestProfile?.earthquakeInsurances.find(insurance => insurance.compareKey === source.compareKey);
    const type = input ? 'update' : 'delete';
    diff.push({ type, source, input });

    return diff;
  }, []);
  // requestにしかないもの
  requestProfile?.earthquakeInsurances.forEach(requestInsurance => {
    const index = profile.earthquakeInsurances.findIndex(insurance => insurance.compareKey === requestInsurance.compareKey);
    if (index === -1) {
      earthquakeInsuranceDiff.push({
        type: 'add',
        source: undefined,
        input: requestInsurance
      });
    }
  });

  return (
    <Section>
      <Section.Header>地震保険</Section.Header>
      <Section.Body>
        <Grid colGap>
          {earthquakeInsuranceDiff.length === 0 && <p>地震保険情報はありません</p>}
          {earthquakeInsuranceDiff.map((diff, index) => (
            <>
              {diff.type === 'update' && <UpdateEarthquakeInsuranceDiff key={index} index={index + 1} diff={diff} />}
              {diff.type === 'add' && (
                <CreateOrDeleteEarthquakeInsuranceDiff key={index} index={index + 1} earthquakeInsurance={diff.input} type="add" />
              )}
              {diff.type === 'delete' && (
                <CreateOrDeleteEarthquakeInsuranceDiff key={index} index={index + 1} earthquakeInsurance={diff.source} type="delete" />
              )}
            </>
          ))}
        </Grid>
      </Section.Body>
    </Section>
  );
};

type CreateOrDeleteEarthquakeInsuranceDiffProps = {
  index: number;
  earthquakeInsurance: EarthquakeInsurance | undefined;
  type: 'add' | 'delete';
};

const CreateOrDeleteEarthquakeInsuranceDiff: FC<CreateOrDeleteEarthquakeInsuranceDiffProps> = ({ index, earthquakeInsurance, type }) => {
  const year = useYear();
  const newOrOld = earthquakeInsurance?.newOrOld ? NEW_OR_OLD[earthquakeInsurance.newOrOld] : undefined;

  return (
    <PreviewSubSection>
      <PreviewSubSection.Header>
        地震保険（{index}）{type === 'add' && <Tag color="blue">追加</Tag>}
        {type === 'delete' && <Tag color="red">削除</Tag>}
      </PreviewSubSection.Header>
      <PreviewSubSection.Body>
        <Dl>
          <Dt>保険会社等の名称</Dt>
          <Dd>{displayFormat(earthquakeInsurance?.name)}</Dd>
        </Dl>
        <Dl>
          <Dt>保険等の種類（目的）</Dt>
          <Dd>{displayFormat(earthquakeInsurance?.insuranceType)}</Dd>
        </Dl>
        <Dl>
          <Dt>保険期間</Dt>
          <Dd>{displayFormat(earthquakeInsurance?.insuranceDuration)}</Dd>
        </Dl>
        <Dl>
          <Dt>保険等の契約者の氏名</Dt>
          <Dd>{displayFormat(earthquakeInsurance?.nameOfContractor)}</Dd>
        </Dl>
        <Dl>
          <Dt>保険等の受取人 氏名</Dt>
          <Dd>{displayFormat(earthquakeInsurance?.nameOfRecipient)}</Dd>
        </Dl>
        {year <= 2023 && (
          <Dl>
            <Dt>保険等の受取人 続柄</Dt>
            <Dd>{displayFormat(earthquakeInsurance?.relationshipOfRecipient)}</Dd>
          </Dl>
        )}
        <Dl>
          <Dt>保険料区分</Dt>
          <Dd>{displayFormat(newOrOld)}</Dd>
        </Dl>
        <Dl>
          <Dt>保険料金額</Dt>
          <Dd>{amountFormat(earthquakeInsurance?.amount)}</Dd>
        </Dl>
        <FileDl>
          <Dt>証明書画像</Dt>
          <FileDd file={earthquakeInsurance?.image} />
        </FileDl>
      </PreviewSubSection.Body>
    </PreviewSubSection>
  );
};

type UpdateEarthquakeInsuranceDiffProps = {
  index: number;
  diff: DiffType;
};

const UpdateEarthquakeInsuranceDiff: FC<UpdateEarthquakeInsuranceDiffProps> = ({ index, diff }) => {
  const year = useYear();
  const sourceNewOrOld = diff.source ? NEW_OR_OLD[diff.source?.newOrOld] : undefined;
  const inputNewOrOld = diff.input ? NEW_OR_OLD[diff.input?.newOrOld] : undefined;

  return (
    <PreviewSubSection>
      <PreviewSubSection.Header>地震保険（{index}）</PreviewSubSection.Header>
      <PreviewSubSection.Body>
        <Diff title="保険会社等の名称" source={diff.source?.name} input={diff.input?.name} />
        <Diff title="保険等の種類（目的）" source={diff.source?.insuranceType} input={diff.input?.insuranceType} />
        <Diff title="保険期間" source={diff.source?.insuranceDuration} input={diff.input?.insuranceDuration} />
        <Diff title="保険等の契約者の氏名" source={diff.source?.nameOfContractor} input={diff.input?.nameOfContractor} />
        <Diff title="保険等の受取人 氏名" source={diff.source?.nameOfRecipient} input={diff.input?.nameOfRecipient} />
        {year <= 2023 && (
          <Diff title="保険等の受取人 続柄" source={diff.source?.relationshipOfRecipient} input={diff.input?.relationshipOfRecipient} />
        )}
        <Diff title="保険料区分" source={sourceNewOrOld} input={inputNewOrOld} />
        <Diff title="保険料金額" source={amountFormat(diff.source?.amount)} input={amountFormat(diff.input?.amount)} />
        <FileDiff title="証明書画像" source={diff.source?.image} input={diff.input?.image} />
      </PreviewSubSection.Body>
    </PreviewSubSection>
  );
};
