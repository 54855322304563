import { isApolloError, ServerError } from '@apollo/client';

export const isServerError = (error: Error): error is ServerError => error.name === 'ServerError';

export const isNotifiableError = (error: Error): boolean => {
  if ('notifiable' in error) {
    return !!error.notifiable;
  }
  if (isApolloError(error)) {
    return error.graphQLErrors[0]?.extensions?.notifiable !== false;
  }
  return true;
};
