import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'whatwg-fetch';
import 'font-awesome/scss/font-awesome.scss';
import 'jbc-front/styles/default.scss';
import 'normalize.css';
import 'nprogress/nprogress.css';
import '@jbc-year-end-adj/common/telemetry/init';
import React from 'react';
import { Provider } from 'react-redux';
import configureStore from './configureStore';
import { BrowserRouter } from 'react-router-dom';
import * as _pages from './pages';
import { ErrorBoundary } from './components';
import { Routes } from 'routes/AdminRoutes';
import { ApolloProvider } from '@apollo/client';
import configureApollo from './configureApollo';
import { ScrollToTopOnAnyNavigation } from '@jbc-year-end-adj/common/components/ScrollToTopOnNavigation';
import { NotificationsProvider } from '@jbc-year-end-adj/common/components/Notifications';

const store = configureStore();
const client = configureApollo('/graphql');

const App = () => (
  <ApolloProvider client={client}>
    <Provider store={store}>
      <NotificationsProvider>
        <ErrorBoundary>
          <BrowserRouter>
            <ScrollToTopOnAnyNavigation />
            <Routes />
          </BrowserRouter>
        </ErrorBoundary>
      </NotificationsProvider>
    </Provider>
  </ApolloProvider>
);

export default App;
