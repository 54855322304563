import { reset, reduxForm, FieldArray } from 'redux-form';
import React, { useState } from 'react';
import _, { isArray } from 'lodash';
import { Section, TextField } from 'jbc-front/components/Form';
import Delete from 'jbc-front/components/icons/Delete';
import { Edit, Strage, MinusCircle } from 'jbc-front/components/icons';
import ActionButton from 'jbc-front/components/ActionButton';
import styles from './ResidentTaxPaymentAddressForm.scss';
import { gql } from '@apollo/client';
import { useMutation, useQuery, useYear } from '../components';
import compose from 'lodash/fp/compose';
import { useNotify } from '@jbc-year-end-adj/common/hooks/useNotify';
import { useDispatch } from 'react-redux';
import { designationNumber, maxLength } from '../validators';
import FormErrors, { LabelMapper, onSubmitFail } from 'jbc-front/components/FormErrors';

const RESIDENT_TAX_PAYMENT_ADDRESS_FORM = 'residentTaxPaymentAddressForm';

const ALL_CLIENT_CITIES_FRAGMENT = gql`
  fragment AllClientCities on ClientCity {
    id
    designationNumber
    totalCount
    city {
      id
      code
      name
      prefecture {
        id
        name
      }
    }
  }
`;

const ALL_CLIENT_CITIES = gql`
  query allClientCities($year: Int!) {
    client {
      id
      clientYearly(year: $year) {
        id
        allClientCities {
          ...AllClientCities
        }
      }
    }
  }
  ${ALL_CLIENT_CITIES_FRAGMENT}
`;

const UPDATE_CLIENT_CITY = gql`
  mutation updateClientCity($input: UpdateClientCityInput!, $year: Int!) {
    updateClientCity(input: $input) {
      client {
        id
        clientYearly(year: $year) {
          allClientCities {
            ...AllClientCities
          }
        }
      }
    }
  }
  ${ALL_CLIENT_CITIES_FRAGMENT}
`;

const MODES = { SHOW: 'show', EDIT: 'edit' };

const ResidentTaxPaymentAddressRows = ({ fields, mode }) => (
  <tbody>
    {fields.length === 0 ? (
      <tr>
        <td colSpan={4}>データがありません。</td>
      </tr>
    ) : (
      fields.map((item, index) => {
        const row = fields.get(index);
        const disabled = row.totalCount > 0;

        return (
          <tr key={item}>
            <td>{row.code}</td>
            <td>{`${row.prefectureName} ${row.cityName}`}</td>
            <td>
              {mode === MODES.EDIT ? (
                <div className={styles.designationNumber}>
                  <TextField name={`${item}.designationNumber`} maxLength="15" validate={[designationNumber, maxLength(15)]} />
                </div>
              ) : (
                row.designationNumber
              )}
            </td>
            <td>
              {mode === MODES.EDIT && (
                <ActionButton className={styles.default} onClick={() => fields.remove(index)} disabled={disabled}>
                  <div className={styles.noteContainer}>
                    {disabled && (
                      <div className={styles.note}>
                        従業員情報に登録されている
                        <br />
                        市区町村は削除できません
                      </div>
                    )}
                    <Delete size={20} />
                  </div>
                </ActionButton>
              )}
            </td>
          </tr>
        );
      })
    )}
  </tbody>
);

const ResidentTaxPaymentAddressList = compose(
  Child => props => {
    const dispatch = useDispatch();
    const notify = useNotify();
    const year = useYear();
    const [mode, setMode] = useState(MODES.SHOW);
    const [updateClientCity] = useMutation(UPDATE_CLIENT_CITY);
    const { data, loading } = useQuery(ALL_CLIENT_CITIES, {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
      variables: { year }
    });
    const clientCities = _.get(data, 'client.clientYearly.allClientCities', []);
    const residentTaxPaymentAddresses = clientCities.map(
      ({
        id,
        designationNumber,
        totalCount,
        city: {
          code,
          name: cityName,
          prefecture: { name: prefectureName }
        }
      }) => ({
        id,
        designationNumber,
        totalCount,
        code,
        cityName,
        prefectureName
      })
    );

    return (
      !loading && (
        <Child
          {...props}
          initialValues={{ residentTaxPaymentAddresses }}
          onSubmit={async values => {
            await updateClientCity({
              variables: {
                input: {
                  clientCities: values.residentTaxPaymentAddresses.map(({ id, designationNumber }) => ({
                    id,
                    designationNumber
                  })),
                  year
                },
                year
              }
            });
            setMode(MODES.SHOW);
            notify('保存が完了しました', 'success');
          }}
          mode={mode}
          setMode={setMode}
          havingData={clientCities.length > 0}
          dispatch={dispatch}
        />
      )
    );
  },
  reduxForm({
    form: RESIDENT_TAX_PAYMENT_ADDRESS_FORM,
    enableReinitialize: true,
    onSubmitFail: (errors, ...rest) => {
      if (isArray(errors?.residentTaxPaymentAddresses)) {
        errors.residentTaxPaymentAddresses = [];
      }
      return onSubmitFail(errors, ...rest);
    }
  })
)(({ handleSubmit, submitting, pristine, mode, setMode, havingData, dispatch }) => (
  <Section title="住民税納付先市区町村" className={styles.box}>
    <FormErrors />
    <div className="u-mb30">
      「特別徴収税額の決定・変更通知書」に記載されている「指定番号」を入力してください。
      <br />
    </div>
    <form onSubmit={handleSubmit}>
      <LabelMapper name="residentTaxPaymentAddresses" label={[]} />
      {havingData && mode === MODES.SHOW && (
        <div className={styles.action}>
          <ActionButton onClick={() => setMode(MODES.EDIT)} icon={<Edit size={16} />}>
            編集
          </ActionButton>
        </div>
      )}
      {havingData && mode === MODES.EDIT && (
        <div className={styles.action}>
          <ActionButton disabled={submitting || pristine} onClick={handleSubmit} icon={<Strage size={16} />}>
            保存
          </ActionButton>
          <ActionButton
            onClick={() => {
              dispatch(reset(RESIDENT_TAX_PAYMENT_ADDRESS_FORM));
              setMode(MODES.SHOW);
            }}
            icon={<MinusCircle size={16} />}
          >
            キャンセル
          </ActionButton>
        </div>
      )}
      <div className="l-overflow-scroll">
        <table className={`${styles.table} m-table-list m-table-fixed`}>
          <thead>
            <tr>
              <th width={95}>団体コード</th>
              <th width={170}>市区町村名</th>
              <th width={213}>指定番号</th>
              <th width={60} />
            </tr>
          </thead>
          <FieldArray name="residentTaxPaymentAddresses" component={ResidentTaxPaymentAddressRows} props={{ mode }} />
        </table>
      </div>
    </form>
  </Section>
));

export default ResidentTaxPaymentAddressList;
