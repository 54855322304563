import { FC, ReactNode, createContext, useContext, useEffect } from 'react';
import { FileType } from '@jbc-year-end-adj/types';
import { displayFormat } from '@jbc-year-end-adj/common/utils/formatter';
import { FileLink } from 'components/ui/FileLink';
import styles from './DefinitionList.scss';
import { FilePreview } from 'components/feature/FilePreview';
import classnames from 'classnames';
import { useDlState } from 'hooks/useDlState';

type DtDdContextType = {
  isEmpty: boolean;
  isRequired: boolean;
  registerDt: (isRequiredField: boolean) => void;
  registerDd: (value: ReactNode) => void;
};

const noop = () => undefined;

export const DtDdContext = createContext<DtDdContextType>({
  isEmpty: false,
  isRequired: false,
  registerDt: noop,
  registerDd: noop
});

type DlProps = {
  children: ReactNode;
  className?: string;
} & JSX.IntrinsicElements['dl'];

export const Dl: FC<DlProps> = ({ children, className }) => {
  const { isEmpty, isRequired, registerDt, registerDd } = useDlState();

  const contextValue = {
    isEmpty,
    isRequired,
    registerDt,
    registerDd
  };

  return (
    <DtDdContext.Provider value={contextValue}>
      <dl className={classnames(styles.dl, className)}>{children}</dl>
    </DtDdContext.Provider>
  );
};

type FileDlProps = {
  children: ReactNode;
};

export const FileDl: FC<FileDlProps> = ({ children }) => {
  return <Dl className={styles.fileDl}>{children}</Dl>;
};

type DtProps = {
  children: ReactNode;
  className?: string;
  isRequired?: boolean;
} & JSX.IntrinsicElements['dt'];

export const Dt: FC<DtProps> = ({ children, className, isRequired }) => {
  const { isEmpty, registerDt } = useContext(DtDdContext);

  useEffect(() => {
    registerDt(!!isRequired);
  }, [isRequired, registerDt]);

  const shouldApplyRequiredClass = isRequired && isEmpty;
  return <dt className={classnames(styles.dt, className, { [styles.required]: shouldApplyRequiredClass })}>{children}</dt>;
};

type DdProps = {
  children: ReactNode;
  className?: string;
} & JSX.IntrinsicElements['dd'];

export const Dd: FC<DdProps> = ({ children, className }) => {
  const { registerDd } = useContext(DtDdContext);

  useEffect(() => {
    registerDd(children);
  }, [children, registerDd]);
  return <dd className={classnames(styles.dd, className)}>{children}</dd>;
};

type FileProps = {
  file: FileType | undefined;
};

export const FileDd: FC<FileProps> = ({ file }) => {
  if (!file) return <Dd>{displayFormat()}</Dd>;

  if ((file.url && file.filename) || file.file) {
    return (
      <Dd>
        <FileLink file={file} />
        <FilePreview file={file} />
      </Dd>
    );
  }

  return <Dd>{displayFormat(file.filename || file.file)}</Dd>;
};
