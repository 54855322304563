import { useYearMasters } from '../components';
import _ from 'lodash';
import { gql } from '@apollo/client';

class Report {
  constructor({ title, name, hasMyNumber }) {
    this.name = name;
    this.title = title;
    this.hasMyNumber = hasMyNumber;
    this.query = gql`
      query report($employeeId: ID!, $showMyNumber: Boolean, $year: Int!) {
        client {
          id
          clientYearly(year: $year) {
            id
            employee(id: $employeeId) {
              reports(showMyNumber: $showMyNumber) {
                ${name}
                ${name}Url
              }
            }
          }
        }
      }
    `;
    this.loadReport = async ({ client, employeeId, showMyNumber, notify, setPdf, year, ...rest }) => {
      try {
        const {
          data: {
            client: {
              clientYearly: {
                employee: { reports }
              }
            }
          }
        } = await client.query({
          query: this.query,
          variables: { employeeId, showMyNumber, year },
          fetchPolicy: 'no-cache'
        });
        setPdf(
          reports[this.name],
          reports[`${this.name}Url`],
          this.hasMyNumber &&
            !showMyNumber && {
              reloadWithMyNumber: showMyNumber => this.loadReport({ client, employeeId, setPdf, notify, showMyNumber, year, ...rest })
            }
        );
      } catch (error) {
        const msg = _.get(error, 'graphQLErrors[0].message') || _.get(error, 'networkError.result.error');
        notify(msg || error.message, 'error');
      }
    };
    this.show = async ({ client, employeeId, show, setPdf, notify, showMyNumber, hasMyNumberPermission, year, ...rest }) => {
      show({ title: this.title, hasMyNumber: hasMyNumberPermission && this.hasMyNumber, ...rest });
      this.loadReport({ client, employeeId, showMyNumber, notify, setPdf, year, ...rest });
    };
  }
}

const reportList = {};

export const useReportList = year => {
  const [yearMaster] = useYearMasters({
    year,
    toJpFirstYear: true
  });
  const yearMastersOfR = useYearMasters({
    year,
    toHeiseiEra: true,
    toJpFirstYear: true
  });
  if (reportList[year]) {
    return reportList[year];
  }
  if (!yearMaster || _.isEmpty(yearMastersOfR)) return null;
  const [thisYear, nextYear] = yearMastersOfR;
  const bsiDeductionFileName =
    year === 2024
      ? `${yearMaster.eraName}${yearMaster.eraNumber}年分 給与所得者の基礎控除申告書 兼 給与所得者の配偶者控除等申告書 兼 年末調整に係る定額減税のための申告書 兼 所得金額調整控除申告書`
      : `${yearMaster.eraName}${yearMaster.eraNumber}年分 給与所得者の基礎控除申告書 兼 給与所得者の配偶者控除等申告書 兼 所得金額調整控除申告書`;

  reportList[year] = [
    ['withholdingBook', '源泉徴収簿'],
    ['withholdingSlip', '源泉徴収票'],
    ['withholdingSlipEmployee', '源泉徴収票'],
    ['insuranceDeduction', `${yearMaster.eraName}${yearMaster.eraNumber}年分 給与所得者の保険料控除申告書`],
    ['spouseDeduction', `${yearMaster.eraName}${yearMaster.eraNumber}年分 給与所得者の配偶者控除等申告書`, true],
    ['bsiDeduction', bsiDeductionFileName, true],
    ['dependentDeductionThisYear', `${thisYear.eraName}${thisYear.eraNumber}年分 給与所得者の扶養控除等（異動）申告書`, true],
    ['dependentDeductionNextYear', `${nextYear.eraName}${nextYear.eraNumber}年分 給与所得者の扶養控除等（異動）申告書`, true],
    ['fileMount', '添付書類台紙']
  ].reduce((obj, [name, title, hasMyNumber]) => {
    obj[name] = new Report({ name, title, hasMyNumber });
    return obj;
  }, {});
  return reportList[year];
};
