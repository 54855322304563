import React from 'react';
import Button from 'jbc-front/components/Button';
import ActionButton from 'jbc-front/components/ActionButton';
import { useMutation, useQuery, useYear } from '../components';
import { gql } from '@apollo/client';
import { FieldArray } from 'redux-form';
import { BoxDouble, Section } from 'jbc-front/components/Form';
import { Copy } from 'jbc-front/components/icons';
import {
  CheckboxField,
  TextAreaField,
  SelectField,
  TextField,
  DateField,
  RadioField,
  FormSection,
  FileField,
  AmountField
} from '../components/FieldWithDiff';

import _ from 'lodash';
import { toFormValues } from '../utils/form';
import Name from '../components/Name';
import Address from '../components/Address';
import { genderOptions } from './BasicInfos';
import { handicapClassifications } from './Handicap';
import { amount, maxTenThousandYen, maxLength, maxLengthAmount } from '../validators';
import { useAutofill, useFormValue, reduxForm } from '../components/FormName';
import { Panel, Headding, Item, Buttons, Label } from '../components/PrimaryPanel';
import { DeleteSquare } from 'jbc-front/components/icons';
import { LabelMapper } from 'jbc-front/components/FormErrors';
import FormAdd from '../components/FormAdd';
import { salaryEarnings } from '../utils/income';
import LoadingPage from '../components/LoadingPage';
import useReactRouter from 'use-react-router';
import { CustomDescription } from '../components/QuestionDescription';
import { useNotify } from '@jbc-year-end-adj/common/hooks/useNotify';
import { applicableForOtherFamily } from '@jbc-year-end-adj/ancient/utils/incomeAdjustment';
import { ToggleDivImage } from '../components/ToggleDivImage';
import image from '../../images/img-Dependent_Exemption_Form_BC.png';
import image2 from '../../images/img-Basic_Consignment_Spouse2.png';

export const YEARLY_INFO_FRAGMENT = gql`
  fragment YearlyInfo on DependentYearlyInfo {
    id
    residenceStatus
    prefectureId
    postcode0
    postcode1
    city
    street
    building
    addressForeign
    income
    earnings
    isNonResident
    isStudyAbroad
    remittance
    handicapType
    handicapDetail
    handicapImage {
      url
      filename
    }
    relatedToRelativesDocument {
      url
      filename
    }
    relatedToRemittanceDocument {
      url
      filename
    }
    provingStudyAbroadDocument {
      url
      filename
    }
    dependentTaxLaw
    incomeAdjustmentDeduction
  }
`;
export const DEPENDENT_FRAGMENT = gql`
  fragment Dependent on Dependent {
    id
    firstName
    lastName
    firstNameKana
    lastNameKana
    relationOther
    gender
    birthday
    dependentFrom
    dependentReason
    diedOn
    compareKey
  }
`;
export const OTHER_FAMILIES_FRAGMENT = gql`
  fragment OtherFamilies on Profile {
    id
    otherFamilies {
      ...Dependent
      thisYear {
        ...YearlyInfo
      }
      nextYear {
        ...YearlyInfo
      }
    }
  }
  ${DEPENDENT_FRAGMENT}
  ${YEARLY_INFO_FRAGMENT}
`;

const DEPENDENTS = gql`
  query dependents {
    request {
      id
      employee {
        id
        year
      }
      profile {
        ...OtherFamilies
        nextYearOnly
      }
    }
  }
  ${OTHER_FAMILIES_FRAGMENT}
`;

const EARNINGS = gql`
  query earnings {
    yearMasters {
      id
      year
      earnings
      spouseEarnings
    }
    clientSetting {
      id
      requestImageUploadSettings {
        nonResidentAndStudyAbroad
        handicap
      }
    }
  }
`;

const ANSWER_DEPENDENTS = gql`
  mutation answerDependents($dependents: [DependentInput!]!) {
    answerDependents(input: { dependents: $dependents }) {
      questions {
        id
        status
      }
      profile {
        ...OtherFamilies
      }
    }
  }
  ${OTHER_FAMILIES_FRAGMENT}
`;

export const relationTypes = [
  { label: '配偶者', value: 'Spouse' },
  { label: 'その他', value: 'OtherFamily' }
];

export const relationOthers = [
  '実父',
  '実母',
  '義父',
  '義母',
  '長男',
  '長女',
  '次男',
  '次女',
  '三男',
  '三女',
  '兄',
  '姉',
  '弟',
  '妹',
  '祖父',
  '祖母',
  '曽祖父母',
  '孫息子',
  '孫娘',
  '叔父',
  '叔母',
  '甥',
  '姪'
].map(val => ({ value: val, label: val }));

export const residenceStatuses = [
  { value: 'same_address', label: '同居' },
  { value: 'different_address', label: '別居（国内）' },
  { value: 'different_and_foreign_address', label: '別居（国外）' }
];

export const DependentsYearlyInfo = ({ withCopy, field, hideIncome, target, isSpouse, thisYearField, year, isNextYear }) => {
  const { data, loading } = useQuery(EARNINGS);
  const residenceStatus = useFormValue(`${field}.residenceStatus`);
  const isNonResidentStatus = useFormValue(`${field}.isNonResident`);
  const isStudyAbroadStatus = useFormValue(`${field}.isStudyAbroad`);
  const handicapType = useFormValue(`${field}.handicapType`);
  const { autofill, withSelector } = useAutofill();
  const yearForAddressLookup = useYear();
  if (loading) return null;
  const {
    yearMasters,
    clientSetting: {
      requestImageUploadSettings: { nonResidentAndStudyAbroad: nonResidentAndStudyAbroadImageSetting, handicap: handicapImageSetting }
    }
  } = data;
  const { earnings: maxEarnings, spouseEarnings: maxSpouseEarnings } = yearMasters.find(master => master.year === year);
  return (
    <>
      {withCopy && (
        <ActionButton
          icon={<Copy size={13} />}
          className="u-mb20"
          onClick={() => {
            withSelector(selector => {
              const thisYear = selector(thisYearField || field.replace(/nextYear/, 'thisYear'));
              [
                'residenceStatus',
                'prefectureId',
                'postcode0',
                'postcode1',
                'city',
                'street',
                'building',
                'addressForeign',
                'income',
                'earnings',
                'isNonResident',
                'remittance',
                'handicapType',
                'handicapDetail',
                'isStudyAbroad'
              ].forEach(name => autofill(`${field}.${name}`, thisYear[name]));
            });
          }}
        >
          今年の情報をコピーする
        </ActionButton>
      )}
      <RadioField name="residenceStatus" options={residenceStatuses} label="同居・別居" required />
      {residenceStatus === 'different_address' && <Address withKana={false} year={yearForAddressLookup} required />}
      {residenceStatus === 'different_and_foreign_address' && (
        <>
          <TextField name="addressForeign" label="Address" />
          <CheckboxField
            name="isNonResident"
            label="非居住者"
            description={`${target}が国内に住所を有せず、かつ、現在まで引き続き1年以上居所を有しない場合チェックしてください。`}
          />
        </>
      )}
      {residenceStatus === 'different_and_foreign_address' && isNonResidentStatus && (
        <>
          {nonResidentAndStudyAbroadImageSetting !== 'none' && (
            <CheckboxField name="isStudyAbroad" label="留学" description={`${target}が非居住者のうち留学の場合はチェックしてください。`} />
          )}
          <AmountField
            name="remittance"
            label="国外居住親族への送金額"
            validate={amount}
            description={`${target}が非居住者である場合、本年中にその被扶養者に送金等をした金額の合計を記入してください。`}
          />
          {nonResidentAndStudyAbroadImageSetting !== 'none' && (
            <>
              <Label>添付ファイル</Label>
              <p className="u-mb20">
                国外居住親族に係る扶養控除等を受けようとする場合は関係書類（例：親族関係書類、送金関係書類、留学証明書類）を添付してください。
              </p>
              <FileField
                name="relatedToRelativesDocument"
                label="親族関係書類"
                required={nonResidentAndStudyAbroadImageSetting === 'required'}
              />
              <FileField
                name="relatedToRemittanceDocument"
                label="送金関係書類"
                required={nonResidentAndStudyAbroadImageSetting === 'required'}
              />
              {isNonResidentStatus && residenceStatus === 'different_and_foreign_address' && isStudyAbroadStatus && (
                <FileField
                  name="provingStudyAbroadDocument"
                  label="留学証明書類"
                  required={nonResidentAndStudyAbroadImageSetting === 'required'}
                />
              )}
            </>
          )}
        </>
      )}

      <RadioField name="handicapType" label="障害者区分" options={handicapClassifications} required />
      {handicapType && handicapType !== 'none' && (
        <>
          <TextAreaField name="handicapDetail" label="詳細" />
          {handicapImageSetting !== 'none' && (
            <FileField name="handicapImage" label="障害者手帳画像" required={handicapImageSetting === 'required'} />
          )}
        </>
      )}
      {!hideIncome && (
        <BoxDouble>
          <AmountField
            name="income"
            label="給与収入等"
            validate={[maxLengthAmount(7)]}
            description="給与収入が103万円を超える場合被扶養者に該当しません。（配偶者は150万円を超えてはいけません。また、従業員本人の給与収入が1,095万円を超える場合配偶者控除を受けることができません。）"
            onChange={(e, value) => {
              autofill(`${field}.earnings`, salaryEarnings(value, year));
            }}
          />
          <AmountField
            name="earnings"
            label="所得見積額"
            validate={[amount, maxTenThousandYen(isSpouse ? maxSpouseEarnings : maxEarnings)]}
            description={`所得が${maxEarnings / 10_000}万円を超える場合被扶養者に該当しません。（配偶者は${maxSpouseEarnings /
              10_000}万円）`}
          />
        </BoxDouble>
      )}
    </>
  );
};

export const DependentBasicInfo = () => (
  <>
    <Name required />
    <RadioField name="gender" label="性別" options={genderOptions} required />
    <DateField name="birthday" label="生年月日" viewMode="years" example="例）1981/01/23、S56.1.23" required />
  </>
);

const Dependent = ({ field }) => {
  const dependentThisYear = useFormValue(`${field}.thisYear.dependentTaxLaw`);
  const dependentNextYear = useFormValue(`${field}.nextYear.dependentTaxLaw`);
  const incomeAdjustmentDeduction = useFormValue(`${field}.thisYear.incomeAdjustmentDeduction`);
  const { data, loading } = useQuery(DEPENDENTS);
  if (loading) return <LoadingPage />;
  const {
    request: {
      employee: { year },
      profile: { nextYearOnly }
    }
  } = data;
  return (
    <>
      <DependentBasicInfo />
      <SelectField
        options={relationOthers}
        name="relationOther"
        label="続柄詳細"
        creatable
        required
        note="リストにない続柄は直接入力して追加できます"
      />
      <Section title="今年の税の扶養" style={{ maxWidth: '760px' }}>
        <FormSection name="thisYear">
          <LabelMapper name="thisYear" label="今年の情報の" />
          <CheckboxField
            name="dependentTaxLaw"
            label="今年の税の扶養対象"
            description="今年の給与所得者の扶養控除等(異動)申告書に記載されます。"
            disabled={nextYearOnly || incomeAdjustmentDeduction}
          />
          <CheckboxField
            name="incomeAdjustmentDeduction"
            label="今年の税の扶養対象ではないが、所得金額調整控除の対象"
            note={
              <div>
                同一生計内の他の所得者に扶養される年齢23歳未満、もしくは特別障害者に該当する扶養親族は、本人の収入が850万円を超えている場合に所得金額調整控除の対象になります。詳しくは
                <a href="https://www.nta.go.jp/taxes/shiraberu/taxanswer/shotoku/1411.htm" target="_blank" rel="noopener noreferrer">
                  国税庁HP
                </a>
                をご覧ください。
              </div>
            }
            disabled={nextYearOnly || dependentThisYear}
          />
          {(dependentThisYear || incomeAdjustmentDeduction) && (
            <DependentsYearlyInfo field={`${field}.thisYear`} target="被扶養者" year={year} />
          )}
        </FormSection>
      </Section>
      <Section title="来年の税の扶養" style={{ maxWidth: '760px' }}>
        <FormSection name="nextYear">
          <LabelMapper name="thisYear" label="来年の情報の" />
          <CheckboxField
            name="dependentTaxLaw"
            label="来年の税の扶養対象"
            description="来年の給与所得者の扶養控除等(異動)申告書に記載されます。"
          />
          {dependentNextYear && (
            <DependentsYearlyInfo field={`${field}.nextYear`} withCopy={dependentThisYear} target="被扶養者" year={year + 1} isNextYear />
          )}
        </FormSection>
      </Section>
      {(dependentThisYear || dependentNextYear) && (
        <>
          <DateField name="dependentFrom" label="税法上の扶養家族になった日" />
          <TextField name="dependentReason" label="税法上の扶養に追加された理由" validate={maxLength(24)} />
          <DateField name="diedOn" label="死亡日" description={`扶養親族が今年（${year}年）亡くなられた場合、死亡日をご記入ください。`} />
        </>
      )}
    </>
  );
};

const renderDependents = ({ fields, handleSubmit, submitting }) => (
  <Panel>
    <Headding>扶養親族情報</Headding>
    <Item>
      <CustomDescription field="top" />
      <p className="u-mb20">被扶養者（16歳未満も含む）の情報を入力してください。</p>
      <ToggleDivImage
        title={'主に「扶養控除等(異動)申告書」のB欄、C欄、「住民税に関する事項」、「所得金額調整控除等申告書」の判定に関する設問です。'}
      >
        <img src={image} alt="扶養控除申告書_BC住" />
        <img src={image2} alt="R3基配所_所得金額調整控除申告書" />
      </ToggleDivImage>
      {fields.map((field, index) => (
        <div key={index}>
          <Section
            title="被扶養者"
            style={{ maxWidth: '760px' }}
            icon={
              <DeleteSquare
                onClick={() => {
                  fields.remove(index);
                }}
              />
            }
          >
            <FormSection name={field}>
              <Dependent field={field} />
            </FormSection>
          </Section>
        </div>
      ))}
      <div>
        {fields.length < 10 && (
          <div className="u-ta-r u-mt20 u-mb20">
            <FormAdd
              onClick={() => {
                fields.push({});
              }}
              name="被扶養者"
            />
          </div>
        )}
      </div>
      <Buttons>
        <Button primary onClick={handleSubmit} disabled={submitting}>
          次ヘ
        </Button>
      </Buttons>
    </Item>
  </Panel>
);

const QuestionForm =
  (({ dispatch, handleSubmit, submitting }) => (
    <form onSubmit={handleSubmit}>
      <LabelMapper name="dependents" label="被扶養者" />
      <FieldArray name="dependents" component={renderDependents} handleSubmit={handleSubmit} submitting={submitting} />
    </form>
  )) |> reduxForm({ form: 'dependents' });

const Question = ({ dispatch, handleSubmit }) => {
  const { data, loading } = useQuery(DEPENDENTS);
  const [answer] = useMutation(ANSWER_DEPENDENTS);
  const { history } = useReactRouter();
  const notify = useNotify();
  if (loading) return <LoadingPage />;
  return (
    <QuestionForm
      initialValues={{
        dependents: makeInitialValues(data.request.profile?.otherFamilies |> (dependents => (_.isEmpty(dependents) ? [{}] : dependents)))
      }}
      onSubmit={async values => {
        const errorDependents = values.dependents.filter(
          dependent => dependent.thisYear?.incomeAdjustmentDeduction && !applicableForOtherFamily(dependent, data.request.employee.year)
        );
        if (!_.isEmpty(errorDependents)) {
          await errorDependents.forEach(dependent => {
            notify(`被扶養者${dependent.lastName} ${dependent.firstName}は所得金額調整控除の対象となる条件を満たしていません`, 'error');
          });
          return;
        }
        await answer({
          variables: {
            dependents:
              values.dependents.map(dependent => {
                const { thisYear, nextYear, ...value } = dependent;
                if (!_.isEmpty(thisYear)) {
                  value.thisYear = thisYear;
                }
                if (!_.isEmpty(nextYear)) {
                  value.nextYear = nextYear;
                }
                return value;
              }) || []
          }
        });
        history.push('/employee_input');
      }}
    />
  );
};

export const makeInitialValues = dependents =>
  dependents.map(dependent => ({
    ...toFormValues(dependent),
    thisYear: toFormValues(dependent.thisYear, true),
    nextYear: toFormValues(dependent.nextYear, true)
  }));

export default Question;
