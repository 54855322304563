import { FC, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';

import Button from 'jbc-front/components/Button';
import { ButtonRow } from 'jbc-front/components/presenters/layout/ButtonRow';
import { Modal } from 'jbc-front/components/presenters/ui/Modal';
import { FormSection } from 'components/form/FormSection';
import { FormSeparator } from 'adminPages/employees/show/components/FormSeparator';
import { FloatingButton } from 'adminPages/employees/show/components/FloatingButton';

import { ReductionTarget } from '../Preview/ReductionTarget';
import { ReductionAmount } from './ReductionAmount';

import { FETCH_EMPLOYEE } from 'adminPages/employees/show/query';
import { useEmployeeInfo } from '../../EmployeeInfoProvider';
import { schema, Schema, generateDefaultValues } from './schema';
import { Variables, UPDATE_FLAT_TAX_REDUCTION, convertToVariables } from './mutation';

import styles from './Form.scss';

// @ts-ignore
import { useNotify } from 'actions';
// @ts-ignore
import { useMutation } from 'components/Graphql';

type FormProps = {
  onCancel: () => void;
};

export const Form: FC<FormProps> = ({ onCancel }) => {
  const { employee } = useEmployeeInfo();
  const shouldCalculate = ['accepted', 'not_need_adj'].includes(employee.status) || employee.isManualTargetCount;
  const reduceTargetCount = shouldCalculate ? employee.reduceTargetCount : 0;
  const [update, { loading }] = useMutation(UPDATE_FLAT_TAX_REDUCTION);
  const notify = useNotify();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const methods = useForm<Schema>({
    defaultValues: generateDefaultValues(reduceTargetCount),
    resolver: yupResolver(schema),
    mode: 'onBlur'
  });

  const { handleSubmit, watch } = methods;
  const targetCountValue = watch('reduceTargetCount');

  const onSubmit: SubmitHandler<Schema> = async (data: Schema) => {
    const variables: Variables = convertToVariables(data, String(employee.id));

    await update({
      variables,
      refetchQueries: [FETCH_EMPLOYEE]
    });
    onCancel();
    notify('定額減税情報を保存しました。', 'success');
  };

  const handleClick = () => {
    if (String(targetCountValue) === '0') {
      setIsOpen(true);
    } else {
      handleSubmit(onSubmit)();
    }
  };
  return (
    <FormProvider {...methods}>
      <form>
        <FormSection>
          <div className={styles.form}>
            <ReductionTarget employee={employee} />
            <ReductionAmount employee={employee} />
          </div>

          <FormSeparator />

          <FloatingButton>
            <ButtonRow>
              <Button onClick={onCancel} disabled={loading}>
                キャンセル
              </Button>
              <Button primary onClick={handleClick} disabled={loading}>
                保存
              </Button>
            </ButtonRow>
          </FloatingButton>
        </FormSection>

        {isOpen && (
          <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
            <Modal.Header onClose={() => setIsOpen(false)}>確認</Modal.Header>
            <Modal.Body>
              年末調整時点の減税対象人数を「0」人で登録すると、定額減税対象外となります。
              <br />
              <br />
              当該従業員を定額減税の対象外としますか？
            </Modal.Body>
            <Modal.Footer>
              <ButtonRow>
                <Button onClick={() => setIsOpen(false)}>キャンセル</Button>
                <Button primary onClick={handleSubmit(onSubmit)} disabled={loading}>
                  保存
                </Button>
              </ButtonRow>
            </Modal.Footer>
          </Modal>
        )}
      </form>
    </FormProvider>
  );
};
