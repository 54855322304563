import React from 'react';
import Button from 'jbc-front/components/Button';
import { useMutation, useQuery } from '../components/Graphql';
import { gql } from '@apollo/client';
import { reduxForm, FieldArray } from 'redux-form';
import Hint from 'jbc-front/components/Hint';
import { BoxDouble, Section } from 'jbc-front/components/Form';
import { FormSection, RadioField, TextField, DateField, FileField, AmountField } from '../components/FieldWithDiff';
import { toFormValues } from '../utils/form';
import { amount } from '../validators';
import FormAdd from '../components/FormAdd';
import { DeleteSquare } from 'jbc-front/components/icons';
import { Panel, Headding, Item, Buttons, Lead } from '../components/PrimaryPanel';
import { LabelMapper } from 'jbc-front/components/FormErrors';
import LoadingPage from '../components/LoadingPage';
import useReactRouter from 'use-react-router';
import { CustomDescription } from '../components/QuestionDescription';
import FilePreview from '../components/FilePreview';
import { useNotify } from '@jbc-year-end-adj/common/hooks/useNotify';
import { ToggleDivImage } from '../components/ToggleDivImage';
import image from '../../images/img-Premium_Deduction_Life_Insurance.png';

const MAX_COUNT = 10;

export const LIFE_INSURANCES_FRAGMENT = gql`
  fragment LifeInsuranceFields on LifeInsurance {
    id
    amount
    image {
      url
      filename
    }
    insuranceDuration
    insuranceType
    name
    nameOfContractor
    nameOfRecipient
    newOrOld
    paymentStartDate
    relationshipOfRecipient
    compareKey
  }
  fragment LifeInsurances on Profile {
    generalLifeInsurances {
      ...LifeInsuranceFields
    }
    nursingMedicalInsurances {
      ...LifeInsuranceFields
    }
    individualAnnuityInsurances {
      ...LifeInsuranceFields
    }
  }
`;

const LIFE_INSURANCES = gql`
  query lifeInsrances {
    request {
      id
      profile {
        id
        ...LifeInsurances
      }
    }
    clientSetting {
      id
      requestImageUploadSettings {
        lifeInsurance
      }
    }
  }
  ${LIFE_INSURANCES_FRAGMENT}
`;

const ANSWER_LIFE_INSURANCES = gql`
  mutation answerLifeInsurances($input: AnswerLifeInsurancesInput!) {
    answerLifeInsurances(input: $input) {
      questions {
        id
        status
      }
      profile {
        id
        ...LifeInsurances
      }
    }
  }
  ${LIFE_INSURANCES_FRAGMENT}
`;

const formName = 'lifeInsurances';

const newOrOlds = [
  { value: 'new_type', label: '新' },
  { value: 'old_type', label: '旧' }
];

const placeholderName = {
  generalLifeInsurances: '○○生命',
  nursingMedicalInsurances: '□□生命',
  individualAnnuityInsurances: '△△生命'
};

const placeholderInsuranceType = {
  generalLifeInsurances: '例：終身、定期　等',
  nursingMedicalInsurances: '例：介護、医療　等',
  individualAnnuityInsurances: '例：▲▲年金　等'
};

export const renderLifeInsurances = ({ fields, handleSubmit, insuranceName, newOnly, isPension, requestImageUpload, fixed }) => (
  <div>
    {fields.map((field, index) => {
      return (
        <Section
          key={index}
          title={`${insuranceName}(${index + 1})`}
          style={{ maxWidth: '100%', marginTop: '30px' }}
          icon={
            fixed ? (
              undefined
            ) : (
              <DeleteSquare
                onClick={() => {
                  fields.remove(index);
                }}
              />
            )
          }
        >
          <FormSection name={field}>
            <BoxDouble>
              <TextField name="name" label="保険会社等の名称" required placeholder={placeholderName[fields.name]} />
              <TextField
                name="insuranceType"
                label="保険等の種類"
                description="保険料控除証明書に記載された保険種類を記入してください"
                placeholder={placeholderInsuranceType[fields.name]}
                required
              />
              <TextField
                name="insuranceDuration"
                label={isPension ? '年金支払期間' : '保険期間'}
                description="保険料控除証明書に記載された保険期間を記入してください"
                placeholder="例：終身、10年　等"
                required
              />
              <TextField
                name="nameOfContractor"
                label="契約者氏名"
                description="保険料控除証明書に記載された契約者の氏名を記入してください"
                placeholder="例：ジョブカン　太朗"
                required
              />
              <TextField
                name="nameOfRecipient"
                label="受取人・氏名"
                description="保険料控除証明書に記載された受給人の氏名を記入してください"
                placeholder="例：ジョブカン　花子"
                required
              />
              <TextField
                name="relationshipOfRecipient"
                label="受取人・続柄"
                description={
                  <div>
                    保険料控除証明書に記載された受給人と本人の関係を記入してください。
                    <Hint text={<div>受給人が本人の場合、「本人」を記入してください。</div>} />
                  </div>
                }
                placeholder="例：本人、妻　等"
                required
              />
            </BoxDouble>
            {isPension && <DateField name="paymentStartDate" label="支払開始日" required />}
            {!newOnly && (
              <RadioField
                name="newOrOld"
                label="新・旧の区分"
                options={newOrOlds}
                description="保険料控除証明書の表記をご確認ください"
                required
              />
            )}
            <AmountField name="amount" label="保険料金額" required validate={amount} placeholder="例：100000" />
            {requestImageUpload !== 'none' && (
              <FileField
                name="image"
                label="証明書画像"
                description="管理者が確認できるよう保険料控除証明書の画像（対応可能形式：jpg, jpeg, gif, png, pdf）をアップロードしてください。原本は必ず会社に提出してください。"
                required={requestImageUpload === 'required'}
                preview={FilePreview}
                disablePreview={false}
              />
            )}
          </FormSection>
        </Section>
      );
    })}
    <div>
      {fields.length < MAX_COUNT && (
        <FormAdd
          onClick={() => {
            fields.push(newOnly ? { newOrOld: 'new_type' } : {});
          }}
          name={insuranceName}
        />
      )}
    </div>
  </div>
);

const QuestionForm =
  (({ handleSubmit }) => {
    const { data, loading } = useQuery(LIFE_INSURANCES);
    if (loading) return <LoadingPage />;
    const {
      clientSetting: {
        requestImageUploadSettings: { lifeInsurance: lifeInsuranceImageSetting }
      }
    } = data;
    return (
      <form onSubmit={handleSubmit}>
        <LabelMapper name="generalLifeInsurances" label="一般の生命保険" />
        <LabelMapper name="nursingMedicalInsurances" label="介護医療保険" />
        <LabelMapper name="individualAnnuityInsurances" label="個人年金保険" />
        <FieldArray
          name="generalLifeInsurances"
          component={renderLifeInsurances}
          insuranceName="一般の生命保険"
          requestImageUpload={lifeInsuranceImageSetting}
        />
        <FieldArray
          name="nursingMedicalInsurances"
          component={renderLifeInsurances}
          insuranceName="介護医療保険"
          newOnly
          requestImageUpload={lifeInsuranceImageSetting}
        />
        <FieldArray
          name="individualAnnuityInsurances"
          component={renderLifeInsurances}
          insuranceName="個人年金保険"
          isPension
          requestImageUpload={lifeInsuranceImageSetting}
        />
        <Buttons>
          <Button primary onClick={handleSubmit}>
            次ヘ
          </Button>
        </Buttons>
      </form>
    );
  }) |> reduxForm({ form: formName });

export const makeInitialValues = profile => ({
  generalLifeInsurances: (profile.generalLifeInsurances || []).map(v => toFormValues(v)),
  nursingMedicalInsurances: (profile.nursingMedicalInsurances || []).map(v => toFormValues(v)),
  individualAnnuityInsurances: (profile.individualAnnuityInsurances || []).map(v => toFormValues(v))
});

const Question = ({ handleSubmit }) => {
  const { data, loading } = useQuery(LIFE_INSURANCES);
  const [answer] = useMutation(ANSWER_LIFE_INSURANCES);
  const { history } = useReactRouter();
  const notify = useNotify();
  if (loading) return <LoadingPage />;
  const { request } = data;
  return (
    <Panel>
      <Headding>生命保険</Headding>
      <Item>
        <Lead>今年支払った生命保険料情報を記入して下さい</Lead>
        <CustomDescription field="top" />
        <ToggleDivImage title={'※赤枠に関する設問です。'}>
          <img src={image} alt="保険料控除_生命保険" />
        </ToggleDivImage>
        <QuestionForm
          initialValues={makeInitialValues(request.profile)}
          onSubmit={async values => {
            if (
              values.generalLifeInsurances.length < 1 &&
              values.nursingMedicalInsurances.length < 1 &&
              values.individualAnnuityInsurances.length < 1
            ) {
              notify('今年支払った生命保険料情報を記入して下さい', 'error');
              return;
            }
            await answer({ variables: { input: values } });
            history.push('/employee_input');
          }}
        />
      </Item>
    </Panel>
  );
};

export default Question;
