import { FC } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler, useFieldArray, FormProvider } from 'react-hook-form';

import Button from 'jbc-front/components/Button';
import { ButtonRow } from 'jbc-front/components/presenters/layout/ButtonRow';

import { Input } from 'components/form/Input';
import { DateField } from 'components/react-hook-form/DateField';
import { FileField } from 'components/react-hook-form/FileField';
import { SelectField } from 'components/react-hook-form/SelectField';
import { FormSection } from 'components/form/FormSection';
import { FormAddButton } from 'components/form/FormAddButton';

import { Grid } from '../../../../components/Grid';
import { DeletableSection } from '../../../../components/DeletableSection';
import { FormSeparator } from '../../../../components/FormSeparator';
import { FETCH_EMPLOYEE } from '../../../../query';
import { FloatingButton } from '../../../../components/FloatingButton';
import { useResult } from '../../ResultProvider';
import { schema, Schema, generateDefaultValues, emptyFormerJob } from './schema';
import { UPDATE_REQUEST_FORMER_JOBS, convertFormDataToVariables } from './mutation';
import { useNotify } from '@jbc-year-end-adj/common/hooks/useNotify';
// @ts-ignore
import { useMutation } from 'components/Graphql';
import { FilePreview } from 'components/feature/FilePreview';
import { AmountField } from 'components/react-hook-form/AmountField';

type FormProps = {
  onCancel: () => void;
};

const withholdingSlipStatuses = [
  { value: 'submitted', label: '提出済み' },
  { value: 'not_submitted', label: '手元にある' },
  { value: 'in_order', label: '取り寄せ中' },
  { value: 'unavailable', label: '入手できない' }
];

export const FormerJobForm: FC<FormProps> = ({ onCancel }) => {
  const { employee } = useResult();
  const requestProfile = employee.request?.profile;
  const [update, { loading }] = useMutation(UPDATE_REQUEST_FORMER_JOBS);
  const notify = useNotify();

  const methods = useForm<Schema>({
    defaultValues: generateDefaultValues(requestProfile?.formerJobs),
    resolver: yupResolver(schema)
  });

  const {
    handleSubmit,
    formState: { errors },
    register,
    watch,
    setValue,
    control
  } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'formerJobs'
  });

  const onSubmit: SubmitHandler<Schema> = async (data: Schema) => {
    const variables = convertFormDataToVariables(String(employee.id), data);
    await update({
      variables,
      refetchQueries: [FETCH_EMPLOYEE]
    });
    onCancel();
    notify('前職情報を保存しました。', 'success');
  };

  return (
    <FormProvider {...methods}>
      <form>
        <FormSection>
          <Grid colGap>
            {fields.map((field, index) => {
              const withholdingSlipImage = watch(`formerJobs.${index}.withholdingSlipImage`);

              return (
                <DeletableSection key={field.id}>
                  <DeletableSection.Header onDelete={() => remove(index)}>前職（{index + 1}）</DeletableSection.Header>
                  <DeletableSection.Body>
                    <FormSection>
                      <Input
                        label="会社名"
                        required
                        error={errors.formerJobs && errors.formerJobs[index]?.companyName?.message}
                        isError={errors.formerJobs && !!errors.formerJobs[index]?.companyName?.message}
                        {...register(`formerJobs.${index}.companyName`)}
                      />

                      <Input
                        label="住所"
                        error={errors.formerJobs && errors.formerJobs[index]?.address?.message}
                        isError={errors.formerJobs && !!errors.formerJobs[index]?.address?.message}
                        {...register(`formerJobs.${index}.address`)}
                      />

                      <DateField
                        label="退職日"
                        required
                        error={errors.formerJobs && errors.formerJobs[index]?.resignedOn?.message}
                        name={`formerJobs.${index}.resignedOn`}
                      />

                      <DateField label="最後の給与支給日" name={`formerJobs.${index}.lastSalaryOn`} />

                      <SelectField
                        label="源泉徴収票"
                        required
                        placeholder="選択して下さい"
                        options={withholdingSlipStatuses}
                        error={errors.formerJobs && errors.formerJobs[index]?.withholdingSlipStatus?.message}
                        name={`formerJobs.${index}.withholdingSlipStatus`}
                      />
                      <>
                        <FileField label="源泉徴収票画像" name={`formerJobs.${index}.withholdingSlipImage`}>
                          {withholdingSlipImage && (
                            <FileField.FileContainer>
                              <FileField.File
                                file={withholdingSlipImage}
                                onRemove={() => setValue(`formerJobs.${index}.withholdingSlipImage`, null)}
                              />
                              <FilePreview file={withholdingSlipImage} />
                            </FileField.FileContainer>
                          )}
                        </FileField>

                        <AmountField
                          label="支払金額"
                          hint={
                            <>
                              この項目に入力した金額は源泉徴収票に反映されます。
                              <br />
                              源泉徴収票編集画面で前職分の課税支払額の情報を登録した場合はこの項目に反映されません。
                            </>
                          }
                          error={errors.formerJobs && errors.formerJobs[index]?.paymentAmount?.message}
                          isError={errors.formerJobs && !!errors.formerJobs[index]?.paymentAmount?.message}
                          {...register(`formerJobs.${index}.paymentAmount`)}
                        />

                        <AmountField
                          label="社会保険料等の金額"
                          hint={
                            <>
                              この項目に入力した金額は源泉徴収票に反映されます。
                              <br />
                              源泉徴収票編集画面で前職分の社会保険料控除額の情報を登録した場合はこの項目に反映されません。
                            </>
                          }
                          error={errors.formerJobs && errors.formerJobs[index]?.deductionAmount?.message}
                          isError={errors.formerJobs && !!errors.formerJobs[index]?.deductionAmount?.message}
                          {...register(`formerJobs.${index}.deductionAmount`)}
                        />

                        <AmountField
                          label="源泉徴収税額"
                          hint={
                            <>
                              この項目に入力した金額は源泉徴収票に反映されます。
                              <br />
                              源泉徴収票編集画面で前職分の源泉徴収額（所得税）の情報を登録した場合はこの項目に反映されません。
                            </>
                          }
                          error={errors.formerJobs && errors.formerJobs[index]?.taxAmount?.message}
                          isError={errors.formerJobs && !!errors.formerJobs[index]?.taxAmount?.message}
                          {...register(`formerJobs.${index}.taxAmount`)}
                        />
                      </>
                    </FormSection>
                  </DeletableSection.Body>
                </DeletableSection>
              );
            })}

            <FormAddButton onClick={() => append(emptyFormerJob)}>前職情報を追加</FormAddButton>
          </Grid>

          <FormSeparator />

          <FloatingButton>
            <ButtonRow>
              <Button onClick={onCancel} disabled={loading}>
                キャンセル
              </Button>
              <Button primary onClick={handleSubmit(onSubmit)} disabled={loading}>
                保存
              </Button>
            </ButtonRow>
          </FloatingButton>
        </FormSection>
      </form>
    </FormProvider>
  );
};
