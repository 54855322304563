import { FC } from 'react';
import { Modal } from 'jbc-front/components/presenters/ui/Modal';
import Button from 'jbc-front/components/Button';
import { Spacer } from 'jbc-front/components/presenters/Spacer';
import { useEmployeeInfo } from '../EmployeeInfoProvider';
import { ACCEPT } from './mutation';
import { FETCH_EMPLOYEE } from '../../../query';
import styles from './AcceptModal.scss';

import { useNotify } from '@jbc-year-end-adj/common/hooks/useNotify';
// @ts-ignore
import { useMutation } from 'components/Graphql';

type AcceptModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const AcceptModal: FC<AcceptModalProps> = ({ isOpen, onClose }) => {
  const { employee } = useEmployeeInfo();
  const [accept, { loading }] = useMutation(ACCEPT);
  const notify = useNotify();

  const handleAccept = async () => {
    try {
      await accept({
        variables: {
          input: {
            employeeId: String(employee.id)
          }
        },
        refetchQueries: [FETCH_EMPLOYEE]
      });
      notify('完了しました', 'success');
    } finally {
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Header onClose={onClose}>完了</Modal.Header>
      <Modal.Body>
        {['in_progress', 'rejected'].includes(employee.status) && (
          <p>
            <span className={styles.warning}>完了すると、従業員が入力中の情報が削除されます。</span>
          </p>
        )}
        {['applying', 'fixed'].includes(employee.status) && (
          <p>
            <span className={styles.warning}>完了すると、入力済みの従業員提出情報が削除されます（従業員提出情報は反映されません）。</span>
            <br />
            従業員提出情報を反映するには結果承認タブの「承認する」を選択してください。
          </p>
        )}
        <p>完了しますか？</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose} disabled={loading}>
          キャンセル
        </Button>
        <Spacer direction="x" size={20} />
        <Button primary onClick={handleAccept} disabled={loading}>
          完了
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
