import React from 'react';
import Button from 'jbc-front/components/Button';
import { useMutation, useQuery } from '../components/Graphql';
import { gql } from '@apollo/client';
import { TextField, FormSection, SelectField, FileField, AmountField } from '../components/FieldWithDiff';
import { Section } from 'jbc-front/components/Form';
import { toFormValues } from '../utils/form';
import { amount } from '../validators';
import FormAdd from '../components/FormAdd';
import { reduxForm, useFormValue } from '../components/FormName';
import { DeleteSquare } from 'jbc-front/components/icons';
import { FieldArray } from 'redux-form';
import { Panel, Headding, Item, Buttons, Lead } from '../components/PrimaryPanel';
import { LabelMapper } from 'jbc-front/components/FormErrors';
import LoadingPage from '../components/LoadingPage';
import useReactRouter from 'use-react-router';
import { CustomDescription } from '../components/QuestionDescription';
import FilePreview from '../components/FilePreview';
import { useNotify } from '@jbc-year-end-adj/common/hooks/useNotify';
import { ToggleDivImage } from '../components/ToggleDivImage';
import image from '../../images/img-Premium_Deduction_Social_Insurance.png';
import { amountFormat } from '@jbc-year-end-adj/common/utils/formatter';
import styles from './SocialInsurances.scss';

const MAX_COUNT = 10;

export const SOCIAL_INSURANCES_FRAGMENT = gql`
  fragment SocialInsuranceFields on SocialInsurance {
    amount
    name
    nameOfPayer
    relationshipOfPayer
    insuranceType
    compareKey
    image {
      url
      filename
    }
  }
  fragment SocialInsurances on Profile {
    socialInsurances {
      id
      ...SocialInsuranceFields
    }
  }
`;

const SOCIAL_INSURANCES = gql`
  query socialInsurances {
    request {
      id
      profile {
        id
        ...SocialInsurances
      }
    }
    clientSetting {
      id
      requestImageUploadSettings {
        pensionInsurance
        socialInsurance
      }
    }
  }
  ${SOCIAL_INSURANCES_FRAGMENT}
`;

const ANSWER_SOCIAL_INSURANCES = gql`
  mutation answerSocialInsurances($input: AnswerSocialInsurancesInput!) {
    answerSocialInsurances(input: $input) {
      questions {
        id
        status
      }
      profile {
        id
        ...SocialInsurances
      }
    }
  }
  ${SOCIAL_INSURANCES_FRAGMENT}
`;

const formName = 'socialInsurances';

const socialInsuranceTypes = [
  { value: 'national_pension', label: '国民年金' },
  { value: 'national_insurance', label: '国民年金基金' },
  { value: 'national_health_insurance', label: '国民健康保険' },
  { value: 'elderly_medical_insurance', label: '後期高齢者医療保険' },
  { value: 'national_nursing_medical_insurance', label: '介護保険' },
  { value: 'voluntary_continued_insurance', label: '任意継続保険' },
  { value: 'farmers_pension', label: '農業者年金' }
];

const getRequestImageUploadSetting = (insuranceType, requestImageUploadSettings) => {
  if (requestImageUploadSettings === undefined || insuranceType === undefined) {
    return null;
  }

  const requestImageUpload =
    insuranceType === 'national_pension' || insuranceType === 'national_insurance'
      ? requestImageUploadSettings.pensionInsurance
      : requestImageUploadSettings.socialInsurance;
  return requestImageUpload;
};

const SocialInsurance = ({ name, title, deleteInsurance, requestImageUploadSettings, fixed }) => {
  const insuranceType = useFormValue(`${name}.insuranceType`);
  const requestImageUpload = getRequestImageUploadSetting(insuranceType, requestImageUploadSettings);
  return (
    <Section
      title={title}
      style={{ maxWidth: '100%', marginTop: '30px' }}
      icon={fixed ? undefined : <DeleteSquare onClick={deleteInsurance} />}
    >
      <FormSection name={name}>
        <SelectField name="insuranceType" options={socialInsuranceTypes} label="社会保険の種類" required />
        <TextField name="name" label="保険料支払先の名称" placeholder="例：日本年金機構　等" required />
        <TextField
          name="nameOfPayer"
          label="保険料を負担することになっている人 氏名"
          description="保険料控除証明書に記載された被保険者の氏名を記入してください。"
          placeholder="例：ジョブカン　二郎"
          required
        />
        <TextField
          name="relationshipOfPayer"
          label="保険料を負担することになっている人 続柄"
          description="保険料控除証明書に記載された被保険者と本人の関係を記入してください。"
          placeholder="例：次男"
          required
        />
        <AmountField name="amount" label="本年中に支払った金額" validate={amount} placeholder="例：100000" required />
        {requestImageUpload !== 'none' && (
          <FileField
            name="image"
            label="社会保険の証明書画像"
            description="管理者が確認できるよう保険料控除証明書の画像（対応可能形式：jpg, jpeg, gif, png, pdf）をアップロードしてください。原本は必ず会社に提出してください。"
            required={requestImageUpload === 'required'}
            preview={FilePreview}
            disablePreview={false}
          />
        )}
      </FormSection>
    </Section>
  );
};

export const renderSocialInsurances = ({ fields, requestImageUploadSettings, fixed }) => (
  <div>
    {fields.map((field, index) => (
      <SocialInsurance
        deleteInsurance={() => fields.remove(index)}
        name={field}
        key={index}
        title={`社会保険(${index + 1})`}
        requestImageUploadSettings={requestImageUploadSettings}
        fixed={fixed}
      />
    ))}
    {fields.length < MAX_COUNT && (
      <FormAdd
        name="社会保険"
        onClick={() => {
          fields.push({});
        }}
      />
    )}
  </div>
);

const QuestionForm =
  (({ handleSubmit, anwser, classification, gender }) => {
    const { data, loading } = useQuery(SOCIAL_INSURANCES);
    if (loading) return <LoadingPage />;
    const {
      clientSetting: { requestImageUploadSettings }
    } = data;
    return (
      <form>
        <LabelMapper name="socialInsurances" label="社会保険" />
        <FieldArray component={renderSocialInsurances} name="socialInsurances" requestImageUploadSettings={requestImageUploadSettings} />
        <Buttons>
          <Button primary onClick={handleSubmit}>
            次ヘ
          </Button>
        </Buttons>
      </form>
    );
  }) |> reduxForm({ form: formName });

const Question = ({ dispatch, handleSubmit }) => {
  const { data, loading } = useQuery(SOCIAL_INSURANCES);
  const [answer] = useMutation(ANSWER_SOCIAL_INSURANCES);
  const { history } = useReactRouter();
  const notify = useNotify();
  if (loading) return <LoadingPage />;
  const formatValues = profile => {
    return (profile.socialInsurances || []).map(v => {
      const formattedSocialInsurances = toFormValues(v);

      return {
        ...formattedSocialInsurances,
        amount: formattedSocialInsurances.amount ? amountFormat(formattedSocialInsurances.amount) : formattedSocialInsurances.amount
      };
    });
  };

  return (
    <Panel>
      <Headding className={styles.primaryPanelHeader}>国民年金・国民健康保険料</Headding>
      <Item>
        <Lead>今年支払った社会保険料情報を記入してください。</Lead>
        <CustomDescription field="top" />
        <ToggleDivImage title={'※赤枠に関する設問です。'}>
          <img src={image} alt="保険料控除_社会保険" />
        </ToggleDivImage>
        <QuestionForm
          initialValues={{
            socialInsurances: formatValues(data.request.profile)
          }}
          onSubmit={async values => {
            if (values.socialInsurances.length < 1) {
              notify('今年支払った社会保険料情報を記入してください', 'error');
              return;
            }
            await answer({
              variables: {
                input: values
              }
            });
            history.push('/employee_input');
          }}
        />
      </Item>
    </Panel>
  );
};

export default Question;
