import { FC } from 'react';
import { Widow as WidowTemplate } from 'features/questions/templates/Widow';
import { ButtonContainer } from 'features/questions/components/ButtonContainer';
import Button from 'jbc-front/components/Button';
import { useWizard } from '../WizardProvider';

export const Widow: FC = () => {
  const { question, request } = useWizard();

  return (
    <WidowTemplate>
      <WidowTemplate.CheckSpouse question={question}>
        <ButtonContainer>
          <Button
            huge
            primary={request.profile?.maritalStatus === 'married'}
            secondary={request.profile?.maritalStatus !== 'married'}
            disabled
          >
            配偶者あり
          </Button>
          <Button
            huge
            primary={request.profile?.maritalStatus === 'unmarried'}
            secondary={request.profile?.maritalStatus !== 'unmarried'}
            disabled
          >
            配偶者なし
            <br />
            （未婚・寡婦対象外）
          </Button>
          <Button
            huge
            primary={request.profile?.maritalStatus === 'divorced_or_bereavement'}
            secondary={request.profile?.maritalStatus !== 'divorced_or_bereavement'}
            disabled
          >
            配偶者なし
            <br />
            （その他）
          </Button>
        </ButtonContainer>
      </WidowTemplate.CheckSpouse>
      {request.profile?.maritalStatus !== 'married' && (
        <WidowTemplate.CheckWidow isFemale={true} isDivorcedOrBereavement={true}>
          <ButtonContainer>
            <Button
              huge
              primary={request.profile?.widowClassification?.classification === 'none'}
              secondary={request.profile?.widowClassification?.classification !== 'none'}
              disabled
            >
              対象外
            </Button>
            <Button
              huge
              primary={request.profile?.widowClassification?.classification === 'single_parent'}
              secondary={request.profile?.widowClassification?.classification !== 'single_parent'}
              disabled
            >
              ひとり親
            </Button>
            {request.profile?.gender === 'female' && request.profile?.maritalStatus === 'divorced_or_bereavement' && (
              <Button
                huge
                primary={request.profile?.widowClassification?.classification === 'normal'}
                secondary={request.profile?.widowClassification?.classification !== 'normal'}
                disabled
              >
                寡婦
              </Button>
            )}
          </ButtonContainer>
        </WidowTemplate.CheckWidow>
      )}
    </WidowTemplate>
  );
};
