import { FC } from 'react';
import ActionButton from 'jbc-front/components/ActionButton';
import { Revert } from 'jbc-front/components/icons';

type RollbackActionButtonProps = {
  onClick: () => void;
  label?: string;
};

const RollbackActionButton: FC<RollbackActionButtonProps> = ({ onClick, label = '手入力前に戻す', ...rest }) => (
  <ActionButton as="button" icon={<Revert size={15} />} onClick={onClick} {...rest}>
    {label}
  </ActionButton>
);

export default RollbackActionButton;
