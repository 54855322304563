import { gql } from '@apollo/client';
import { MaritalStatus, ResidenceStatus, HandicapType, Gender } from '@jbc-year-end-adj/types';
import { Schema, YearlyInfoSchema, YearlyInfoNextYearSchema } from './schema';
import { FileType } from '@jbc-year-end-adj/types';

type Spouse = {
  lastName: string;
  firstName: string;
  lastNameKana: string;
  firstNameKana: string;
  birthday: string;
  gender: Gender;
  dependentFrom?: string;
  dependentReason?: string;
  diedOn?: string;
  thisYear?: YearlyInfo;
  nextYear?: YearlyInfo;
  income?: Income;
  spouseLivingStatus?: SpouseLivingStatus;
};

type Income = {
  salaryIncome?: string;
  businessIncome?: string;
  businessExpense?: string;
  miscellaneousIncomeOfficialPension?: string;
  miscellaneousIncomeOther?: string;
  miscellaneousExpense?: string;
  dividendIncome?: string;
  dividendExpense?: string;
  realEstateIncome?: string;
  realEstateExpense?: string;
  retirementIncome?: string;
  lengthOfService?: string;
  retireForDisablity?: boolean;
  isOfficerRetirementAllowance?: boolean;
  otherIncome?: string;
  otherExpense?: string;
  otherExpenseSpecialDeduction?: string;
  otherEarings?: string;
};

type YearlyInfo = {
  dependentTaxLaw?: boolean;
  hasSpouseRetirementIncome?: boolean;
  residenceStatus?: ResidenceStatus;
  postcode0?: string;
  postcode1?: string;
  prefectureId?: string;
  city?: string;
  street?: string;
  building?: string;
  addressForeign?: string;
  isNonResident?: boolean;
  relatedToRelativesDocument?: File | FileType;
  relatedToRemittanceDocument?: File | FileType;
  remittance?: string;
  handicapType?: HandicapType;
  handicapDetail?: string;
  handicapImage?: File | FileType;
  earnings?: string;
  income?: string;
};

type SpouseLivingStatus = {
  isSameHousehold?: boolean;
};

export type QueryVariables = {
  input: {
    employeeId: string;
    attributes: {
      maritalStatus: MaritalStatus;
      spouse?: Spouse;
    };
  };
};

export const UPDATE_REQUEST_SPOUSE = gql`
  mutation UpdateSpouse($input: UpdateSpouseInput!) {
    updateSpouse(input: $input) {
      employee {
        id
      }
    }
  }
`;

const buildSpouseIncomeAttributes = (income?: Income) => ({
  salaryIncome: income?.salaryIncome || '0',
  businessIncome: income?.businessIncome || '0',
  businessExpense: income?.businessExpense || '0',
  miscellaneousIncomeOfficialPension: income?.miscellaneousIncomeOfficialPension || '0',
  miscellaneousIncomeOther: income?.miscellaneousIncomeOther || '0',
  miscellaneousExpense: income?.miscellaneousExpense || '0',
  dividendIncome: income?.dividendIncome || '0',
  dividendExpense: income?.dividendExpense || '0',
  realEstateIncome: income?.realEstateIncome || '0',
  realEstateExpense: income?.realEstateExpense || '0',
  retirementIncome: income?.retirementIncome || '0',
  lengthOfService: income?.lengthOfService || '0',
  retireForDisablity: income?.retireForDisablity,
  isOfficerRetirementAllowance: income?.isOfficerRetirementAllowance,
  otherIncome: income?.otherIncome || '0',
  otherExpense: income?.otherExpense || '0',
  otherExpenseSpecialDeduction: income?.otherExpenseSpecialDeduction || '0',
  otherEarings: income?.otherEarings || '0'
});

export const convertFormDataToQueryVariables = (employeeId: string, data: Schema): QueryVariables => {
  const input: QueryVariables['input'] = {
    employeeId,
    attributes: {
      maritalStatus: data.maritalStatus,
      spouse: undefined
    }
  };

  if (data.maritalStatus !== 'unmarried' && data.spouse) {
    input.attributes.spouse = {
      lastName: data.spouse.lastName,
      firstName: data.spouse.firstName,
      lastNameKana: data.spouse.lastNameKana,
      firstNameKana: data.spouse.firstNameKana,
      birthday: data.spouse.birthday,
      gender: data.spouse.gender
    };
    const isSameHousehold = data.spouse.thisYear?.spouseLivingStatus?.isSameHousehold;
    input.attributes.spouse.spouseLivingStatus = {
      isSameHousehold: !isSameHousehold ? undefined : isSameHousehold === 'true'
    };

    const thisYearDependentTaxLaw = data.spouse.thisYear?.dependentTaxLaw === 'true';
    const thisYearHasRetirementIncome = data.spouse.thisYear?.hasSpouseRetirementIncome === 'true';
    const nextYearDependentTaxLaw = data.spouse.nextYear?.dependentTaxLaw === 'true';

    if (thisYearDependentTaxLaw || thisYearHasRetirementIncome || nextYearDependentTaxLaw) {
      input.attributes.spouse.dependentFrom = optionalFormatter('', data.spouse.dependentFrom);
      input.attributes.spouse.dependentReason = data.spouse.dependentReason;
      input.attributes.spouse.diedOn = optionalFormatter('', data.spouse.diedOn);
    }

    if (data.spouse.thisYear && (isSameHousehold === 'true' || thisYearDependentTaxLaw || thisYearHasRetirementIncome)) {
      input.attributes.spouse.thisYear = {
        dependentTaxLaw: thisYearDependentTaxLaw,
        hasSpouseRetirementIncome: thisYearHasRetirementIncome,
        residenceStatus: data.spouse.thisYear.yearlyInfo.residenceStatus,
        ...convertYearlyInfoByResidenceStatus(data.spouse.thisYear),
        handicapType: data.spouse.thisYear.yearlyInfo.handicapType,
        handicapDetail: data.spouse.thisYear.yearlyInfo.handicapDetail,
        handicapImage: data.spouse.thisYear.yearlyInfo.handicapImage
      };
      input.attributes.spouse.income = buildSpouseIncomeAttributes(data.spouse.thisYear.yearlyInfo.income);
    } else {
      input.attributes.spouse.thisYear = {
        dependentTaxLaw: false
      };
    }

    if (data.spouse.nextYear && nextYearDependentTaxLaw) {
      input.attributes.spouse.nextYear = {
        dependentTaxLaw: nextYearDependentTaxLaw,
        residenceStatus: data.spouse.nextYear.yearlyInfo.residenceStatus,
        ...convertYearlyInfoByResidenceStatus(data.spouse.nextYear),
        handicapType: data.spouse.nextYear.yearlyInfo.handicapType,
        handicapDetail: data.spouse.nextYear.yearlyInfo.handicapDetail,
        handicapImage: data.spouse.nextYear.yearlyInfo.handicapImage,
        income: optionalFormatter('', data.spouse.nextYear.yearlyInfo.income),
        earnings: optionalFormatter('', data.spouse.nextYear.yearlyInfo.earnings)
      };
    } else {
      input.attributes.spouse.nextYear = {
        dependentTaxLaw: false
      };
    }
  }

  return { input };
};

const optionalFormatter = <T>(target: T, value: T) => {
  return target === value ? undefined : value;
};

const convertYearlyInfoByResidenceStatus = (yearlyInfo: YearlyInfoSchema | YearlyInfoNextYearSchema) => {
  switch (yearlyInfo.yearlyInfo.residenceStatus) {
    case 'same_address':
      return {};
    case 'different_address':
      return {
        postcode0: yearlyInfo.yearlyInfo.postcode0,
        postcode1: yearlyInfo.yearlyInfo.postcode1,
        prefectureId: yearlyInfo.yearlyInfo.prefectureId,
        city: yearlyInfo.yearlyInfo.city,
        street: yearlyInfo.yearlyInfo.street,
        building: yearlyInfo.yearlyInfo.building,
        relatedToRelativesDocument: undefined,
        relatedToRemittanceDocument: undefined
      };
    case 'different_and_foreign_address':
      return {
        addressForeign: yearlyInfo.yearlyInfo.addressForeign,
        isNonResident: yearlyInfo.yearlyInfo.isNonResident,
        remittance: yearlyInfo.yearlyInfo.remittance || '0',
        relatedToRelativesDocument: yearlyInfo.yearlyInfo.relatedToRelativesDocument,
        relatedToRemittanceDocument: yearlyInfo.yearlyInfo.relatedToRemittanceDocument
      };
  }
};
