import { FC, ReactNode } from 'react';
import { Section } from 'components/ui/Section';

import { Question } from '../type';

import { ToggleImageContainer } from './parts/ToggleImageContainer';
import { Lead } from './parts/Lead';
import { Description } from './parts/Description';
import { Content } from './parts/Content';

// @ts-ignore
import image from '../../../../images/img-Premium_Deduction_Mortgage_Loan_Insurance.png';

type HousingLoanProps = {
  question: Question;
  children: ReactNode;
  initiallyOpen?: boolean;
};

export const HousingLoan: FC<HousingLoanProps> = ({ question, children, initiallyOpen = false }) => {
  const description = question.questionDescriptions.find(description => description.field == 'top')?.description;

  return (
    <Section>
      <Section.Header>住宅ローン控除</Section.Header>

      <Section.Body>
        <Content>
          <Lead>住宅ローン控除情報を記入してください。</Lead>

          <Description>{description}</Description>

          <ToggleImageContainer initiallyOpen={initiallyOpen}>
            <ToggleImageContainer.Header>※下図の書類に関する設問です。</ToggleImageContainer.Header>
            <ToggleImageContainer.Body>
              <img src={image} alt="保険料控除_住宅ローン" />
            </ToggleImageContainer.Body>
          </ToggleImageContainer>

          {children}
        </Content>
      </Section.Body>
    </Section>
  );
};
