import { FC, useState, useContext } from 'react';
import { Loading } from 'jbc-front/components/presenters/ui/Loading';
import Button from 'jbc-front/components/Button';
import { Import } from 'jbc-front/components/icons';
import { AnchorTarget } from 'jbc-front/components/ScrollSpy/v2';
import { useAsyncTask } from '@jbc-year-end-adj/2024/features/AsyncTask';
import { useAdminQuery as useQuery } from '@jbc-year-end-adj/2024/hooks/graphql/useQuery';
import { useSession } from '@jbc-year-end-adj/2024/features/AdminSessionProvider';
import { Section } from '@jbc-year-end-adj/2024/components/ui/Section';
import { useNotify } from '@jbc-year-end-adj/2024/hooks/useNotify';
import styles from '../Form.scss';
import { FETCH_APPLICATIONS, QueryResult } from './query';
import { LmsImportModal } from './LmsImportModal';
import { PayrollImportModal } from './PayrollImportModal';
import { FormContext } from '../Form';

const LMS_APPLICATION_ID = '1';
const PAYROLL_APPLICATION_ID = '2';

export const CooperationSection: FC = () => {
  const notify = useNotify();
  const { submit } = useContext(FormContext);
  const [isOpenModalId, setIsOpenModalId] = useState<string | null>(null);
  const { isCurrentYear, clientYearly } = useSession();
  const {
    taskRunningProps: { disabled, disabledReason }
  } = useAsyncTask();
  const { data, loading } = useQuery<QueryResult>(FETCH_APPLICATIONS);

  if (loading) return <Loading />;

  const disabledMessage = () => {
    if (disabledReason) {
      return disabledReason;
    } else if (clientYearly?.fixed) {
      return <DisabledMessage />;
    } else {
      return undefined;
    }
  };

  const applications = data?.client?.applications;

  const hasConnectedApplication = (applicationId: string) => {
    const application = applications?.find(application => {
      return application.id === applicationId && application.connected;
    });

    return !!application;
  };

  const openModal = async (applicationId: string) => {
    switch (await submit()) {
      case 'success':
        setIsOpenModalId(applicationId);
        return;
      case 'invalid':
        notify('インポート前に設定を修正してください。', 'error');
        return;
      case 'error':
        notify('エラーが発生しました。', 'error');
        return;
    }
  };

  return (
    <Section>
      <AnchorTarget name="request_data" />
      <Section.Header>データ取得</Section.Header>
      <Section.Body>
        {!isCurrentYear && (
          <div className={styles.wrap}>
            <p>過去の年末調整には、ジョブカン労務HR・給与計算の事業所情報・従業員情報・給与データを反映させることはできません。</p>
          </div>
        )}

        {isCurrentYear && (
          <>
            <p className="u-mb20">
              ジョブカン労務HR・給与計算の事業所情報・従業員情報・給与データを取得し、最新の年末調整に反映します。
              <br />
              年末調整開始後の再インポートは依頼一覧の「連携（取得）」ボタンより行うことができます。
            </p>
            <p className={styles.red}>
              スタッフコードを持たない従業員は取得できません。
              <br />
              スタッフコードを変更しますと別従業員として認識されますのでご注意ください。
              <br />
              <br />
              年末調整の確定前に再度データのインポートを行うと、従業員のステータスに関わらず、従業員情報を上書きできます。
              <br />
              上書きを望まない従業員に対しては、データ取得先（ジョブカン労務HR・ジョブカン給与計算）の対象従業員選択（年末調整）から当該従業員を対象外に設定の上、インポート対象選択で「インポート設定されている従業員」の選択をお願いします。
              <br />
              <br />
              ※結果承認タブに表示されている、従業員から提出された情報は上書きされません。
              <br />
              <br />
              労務HR・給与計算を両方利用されている場合、労務HRと給与計算の間のデータ連携を行ってデータを一致させた後、両方の取得ボタンを押してください。
            </p>

            <div className={styles.buttonContainer}>
              {applications?.map(application => (
                <Button
                  key={application.id}
                  className={styles.button}
                  disabled={disabled || loading || !application.connected || clientYearly?.fixed}
                  disabledReason={disabledMessage()}
                  onClick={() => openModal(application.id)}
                >
                  <div className={styles.icon}>
                    <Import size={21} />
                  </div>
                  {application.name}
                  から
                  <br />
                  インポート
                </Button>
              ))}
            </div>

            {hasConnectedApplication(LMS_APPLICATION_ID) && (
              <LmsImportModal isOpen={isOpenModalId === LMS_APPLICATION_ID} onClose={() => setIsOpenModalId(null)} />
            )}

            {hasConnectedApplication(PAYROLL_APPLICATION_ID) && (
              <PayrollImportModal isOpen={isOpenModalId === PAYROLL_APPLICATION_ID} onClose={() => setIsOpenModalId(null)} />
            )}
          </>
        )}
      </Section.Body>
    </Section>
  );
};

const DisabledMessage = () => {
  return (
    <p className="u-ta-l">
      年末調整情報が確定されています。
      <br />
      確定を解除後行ってください。
    </p>
  );
};
