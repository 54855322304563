import { isApolloError } from '@apollo/client';
import { isServerError } from './predicate';

export const fetchErrorMessage = (error: Error) => {
  if (isApolloError(error)) {
    const graphQLErrorMessage = error.graphQLErrors[0]?.message;
    if (graphQLErrorMessage) {
      return graphQLErrorMessage;
    }

    if (error.networkError && isServerError(error.networkError)) {
      const networkErrorMessage = error.networkError.result['error']?.message;
      if (networkErrorMessage) {
        return networkErrorMessage;
      }
    }
  }

  return error.message;
};
