import { FC } from 'react';
import { Description, List, ToggleList } from '@jbc-year-end-adj/2024/components/ui/FileImportDescription';
import styles from './FileImportDescription.scss';

import {
  NOT_AVAILABLE_TEXT,
  AVAILABLE_LIST,
  RESIDECESTATUS_LIST,
  GENDER,
  HANDICAP_CLASSIFICATION,
  RELATIONSHIP,
  DEPENDENT_RELATIONSHIP,
  SOCIAL_INSURANCE,
  COLORS,
  NATIONAL_TYPE
} from './consts';

export const FileImportDescription: FC = () => {
  return (
    <div className={styles.description}>
      <Description descriptions={descriptions} />
    </div>
  );
};

const NotAvailableList = <List items={AVAILABLE_LIST} />;
const GenderList = <List items={GENDER} />;
const NonResidentTypeList = <List items={['対象外', '非居住者']} />;
const RelationShipList = <ToggleList items={RELATIONSHIP} />;
const NationalTypeList = <List items={NATIONAL_TYPE} notice="※ 空欄の場合は日本人となります" />;
const ResidentsTaxCollectionMethodList = <List items={['特別徴収', '普通徴収（退職）', '普通徴収（その他）']} />;
const TaxScheduleList = <List items={['甲欄', '乙欄']} />;
const WorkingStudentClassificationList = <List items={['対象外', '勤労学生']} notice={NOT_AVAILABLE_TEXT} />;
const HandicapClassificationsList = <List items={HANDICAP_CLASSIFICATION} notice={NOT_AVAILABLE_TEXT} />;
const WidowClassificationList = <List items={['対象外', 'ひとり親', '寡婦']} notice={NOT_AVAILABLE_TEXT} />;
const DisastererList = <List items={['対象外', '災害者']} notice={NOT_AVAILABLE_TEXT} />;
// 従業員基本項目
const basicInfoDescriptions = [
  { name: 'スタッフコード', required: true },
  { name: '来年度年末調整対象外', description: NotAvailableList, example: '対象' },
  { name: '入社日', description: '半角数字 (yyyy/mm/dd)', example: '2010/4/1' },
  { name: '退職日', description: '半角数字 (yyyy/mm/dd)', example: '2015/4/1' },
  { name: '姓', example: '山田' },
  { name: '名', example: '太郎' },
  { name: '姓カナ', description: '全角カタカナ', example: 'ヤマダ' },
  { name: '名カナ', description: '全角カタカナ', example: 'タロウ' },
  { name: '生年月日', description: '半角数字 (yyyy/mm/dd)', example: '1980/1/1' },
  { name: '性別', description: GenderList, example: '男' },
  { name: '非居住者区分', description: NonResidentTypeList, example: '対象外' },
  { name: '住所 郵便番号', description: '半角数字7桁', example: '999-9999' },
  { name: '住所 都道府県', example: '東京都' },
  { name: '住所 市区町村', example: '渋谷区' },
  { name: '住所 丁目番地', example: '9-99-99' },
  { name: '住所 建物名・部屋番号', example: 'ジョブカンアパート' },
  { name: '世帯主の続柄', description: RelationShipList, example: '本人' },
  { name: '世帯主の氏名', example: '山田太郎' },
  { name: '外国人区分', description: NationalTypeList, example: '外国人' },
  { name: '住民票住所 郵便番号', description: '半角数字7桁', example: '999-9999' },
  { name: '住民票住所 都道府県', example: '東京都' },
  { name: '住民票住所 市区町村', example: '渋谷区' },
  { name: '住民票住所 丁目番地', example: '9-99-99' },
  { name: '住民票住所 建物名・部屋番号', example: 'ジョブカンアパート' },
  { name: '給与支払報告書提出先（市区町村コード）', description: '半角数字', example: '131130' },
  { name: '住民税徴収方法', description: ResidentsTaxCollectionMethodList, example: '特別徴収' },
  { name: '税額表区分', description: TaxScheduleList, example: '甲欄' },
  { name: '勤労学生区分', description: WorkingStudentClassificationList, example: '勤労学生' },
  {
    name: '勤労学生詳細情報',
    description: '勤労学生の場合は学校名や入学年月日を入力してください',
    example: 'ジョブカン大学（平成30年4月1日入学）'
  },
  { name: '障害者区分', description: HandicapClassificationsList, example: '一般障害者' },
  {
    name: '障害者詳細情報',
    description: '障害者手帳の種類と交付年月日、障害の等級などを入力してください',
    example: '身体障害者手帳（平成25年4月1日交付）・身体障害者2級'
  },
  { name: 'ひとり親・寡婦区分', description: WidowClassificationList, example: 'ひとり親' },
  { name: '（源泉徴収票）災害者', description: DisastererList, example: '災害者' },
  { name: '徴収猶予税額', description: '半角数字' }
];

const FormerJobWithholdingSlipList = <List items={['提出済み', '手元にある', '取り寄せ中', '入手できない']} />;
// 前職の項目
const formerJobDescriptions = [
  { name: '前職1 ID', example: '1' },
  { name: '前職1 会社名', example: 'ジョブカン株式会社' },
  { name: '前職1 住所', example: '東京都渋谷区9-99-99' },
  { name: '前職1 退職日', description: '半角数字 (yyyy/mm/dd)', example: '2011/6/1' },
  { name: '前職1 最後の給与支給日', description: '半角数字 (yyyy/mm/dd)', example: '2011/6/1' },
  { name: '前職1 源泉徴収票', description: FormerJobWithholdingSlipList, example: '提出済み' },
  { name: '前職1 支払金額', description: '半角数字', example: '100000' },
  { name: '前職1 社会保険料等の金額', description: '半角数字', example: '10000' },
  { name: '前職1 源泉徴収税額', description: '半角数字', example: '10000' }
];

const YesNotList = <List items={['はい', 'いいえ']} />;
// 従業員の収入項目
const incomeDescription = [
  { name: '本人収入 給与所得 収入金額（当社)', description: '半角数字', example: 900000 },
  { name: '本人収入 給与所得 収入金額（他社)', description: '半角数字', example: 100000 },
  { name: '本人収入 事業所得 収入金額', description: '半角数字' },
  { name: '本人収入 事業所得 必要経費', description: '半角数字' },
  { name: '本人収入 雑所得 収入金額（公的年金等に係る雑所得）', description: '半角数字' },
  { name: '本人収入 雑所得 収入金額（公的年金等以外の雑所得）', description: '半角数字' },
  { name: '本人収入 雑所得 必要経費', description: '半角数字' },
  { name: '本人収入 配当所得 収入金額', description: '半角数字' },
  { name: '本人収入 配当所得 必要経費', description: '半角数字' },
  { name: '本人収入 不動産所得 収入金額', description: '半角数字' },
  { name: '本人収入 不動産所得 必要経費', description: '半角数字' },
  { name: '本人収入 退職所得 収入金額', description: '半角数字' },
  { name: '勤続年数', description: '半角数字' },
  { name: '障害者になったことに直接起因して退職した', description: YesNotList, example: 'いいえ' },
  { name: '退職手当等が特定役員退職手当等に該当する', description: YesNotList, example: 'はい' },
  { name: '本人収入 その他所得 収入金額', description: '半角数字' },
  { name: '本人収入 その他所得 必要経費', description: '半角数字' },
  { name: '本人収入 その他所得 必要経費 うち特別控除額', description: '半角数字' },
  { name: '本人収入 その他所得 所得金額', description: '半角数字' }
];

const MaritalStatusList = <List items={['有', '無（配偶者情報不要）', '無（配偶者情報必須）']} />;
const ResidenceStatusesList = <List items={RESIDECESTATUS_LIST} />;
const StudyAbroadList = <List items={['対象外', '留学']} />;
const TargetList = <List items={['対象', '対象外']} />;

// 配偶者
const spouseDescriptions = [
  { name: '配偶者の有無', description: MaritalStatusList, example: '有' },
  { name: '配偶者 姓', example: '山田' },
  { name: '配偶者 名', example: '花子' },
  { name: '配偶者 姓カナ', description: '全角カタカナ', example: 'ヤマダ' },
  { name: '配偶者 名カナ', description: '全角カタカナ', example: 'ハナコ' },
  { name: '配偶者 性別', description: GenderList, example: '女' },
  { name: '配偶者 生年月日', description: '半角数字 (yyyy/mm/dd)', example: '1980/1/1' },
  { name: '配偶者 同一生計配偶者', description: TargetList, example: '対象' },
  { name: '配偶者 今年の源泉控除対象、配偶者特別控除対象、または障害者控除対象', description: NotAvailableList, example: '対象' },
  { name: '配偶者 今年の同居・別居', description: ResidenceStatusesList, example: '同居' },
  { name: '配偶者 今年の住所 郵便番号', description: '半角数字7桁', example: '999-9999' },
  { name: '配偶者 今年の住所 都道府県', example: '東京都' },
  { name: '配偶者 今年の住所 市区町村', example: '渋谷区' },
  { name: '配偶者 今年の住所 丁目番地', example: '9-99-99' },
  { name: '配偶者 今年の住所 建物名・部屋番号', example: 'ジョブカンアパート' },
  { name: '配偶者 今年の海外住所', description: '半角英数字', example: '9999PennsylvaniaAvenueN.W. Washington,DC20004' },
  { name: '配偶者 今年の非居住者', description: NonResidentTypeList, example: '非居住者' },
  { name: '配偶者 今年の国外居住親族への送金額', description: '半角数字' },
  { name: '配偶者 今年の障害者区分', description: HandicapClassificationsList, example: '対象外' },
  {
    name: '配偶者 今年の障害者区分詳細',
    description: '障害者手帳の種類と交付年月日、障害の等級などを入力してください',
    example: '身体障害者手帳（平成25年4月1日交付）・身体障害者2級'
  },
  { name: '配偶者 来年の源泉控除対象または障害者控除対象', description: NotAvailableList, example: '対象' },
  { name: '配偶者 来年の同居・別居', description: ResidenceStatusesList, example: '同居' },
  { name: '配偶者 来年の住所 郵便番号', description: '半角数字7桁', example: '999-9999' },
  { name: '配偶者 来年の住所 都道府県', example: '東京都' },
  { name: '配偶者 来年の住所 市区町村', example: '渋谷区' },
  { name: '配偶者 来年の住所 丁目番地', example: '9-99-99' },
  { name: '配偶者 来年の住所 建物名・部屋番号', example: 'ジョブカンアパート' },
  { name: '配偶者 来年の海外住所', description: '半角英数字', example: '9999PennsylvaniaAvenueN.W. Washington,DC20004' },
  { name: '配偶者 来年の非居住者', description: NonResidentTypeList, example: '非居住者' },
  { name: '配偶者 来年の国外居住親族への送金額', description: '半角数字' },
  { name: '配偶者 来年の障害者区分', description: HandicapClassificationsList, example: '対象外' },
  {
    name: '配偶者 来年の障害者区分詳細',
    description: '障害者手帳の種類と交付年月日、障害の等級などを入力してください',
    example: '身体障害者手帳（平成25年4月1日交付）・身体障害者2級'
  },
  { name: '配偶者 来年の給与収入等', description: '半角数字' },
  { name: '配偶者 来年の所得見積額', description: '半角数字' },
  { name: '配偶者 源泉控除対象配偶者になった日', description: '半角数字（yyyy/mm/dd）', example: '2011/4/1' },
  { name: '配偶者 源泉控除対象配偶者になった理由', example: '結婚' },
  { name: '配偶者 死亡日', description: '半角数字 (yyyy/mm/dd)' },
  { name: '配偶者 給与所得 収入金額', description: '半角数字' },
  { name: '配偶者 事業所得 収入金額', description: '半角数字' },
  { name: '配偶者 事業所得 必要経費', description: '半角数字' },
  { name: '配偶者 雑所得 収入金額（公的年金等に係る雑所得）', description: '半角数字' },
  { name: '配偶者 雑所得 収入金額（公的年金等以外の雑所得）', description: '半角数字' },
  { name: '配偶者 雑所得 必要経費', description: '半角数字' },
  { name: '配偶者 配当所得 収入金額', description: '半角数字' },
  { name: '配偶者 配当所得 必要経費', description: '半角数字' },
  { name: '配偶者 不動産所得 収入金額', description: '半角数字' },
  { name: '配偶者 不動産所得 必要経費', description: '半角数字' },
  { name: '配偶者 退職所得 収入金額', description: '半角数字' },
  { name: '配偶者 勤続年数', description: '半角数字' },
  { name: '配偶者 障害者になったことに直接起因して退職した', description: YesNotList, example: 'いいえ' },
  { name: '配偶者 退職手当等が特定役員退職手当等に該当する', description: YesNotList, example: 'はい' },
  { name: '配偶者 その他所得 収入金額', description: '半角数字' },
  { name: '配偶者 その他所得 必要経費', description: '半角数字' },
  { name: '配偶者 その他所得 必要経費 うち特別控除額', description: '半角数字' },
  { name: '配偶者 その他所得 所得金額', description: '半角数字' }
];

const DependentRelationShipList = <ToggleList items={DEPENDENT_RELATIONSHIP} />;

// 扶養親族
const otherFamilyDescriptions = [
  { name: '扶養親族1 ID', example: '1' },
  { name: '扶養親族1 姓', example: '山田' },
  { name: '扶養親族1 名', example: '花子' },
  { name: '扶養親族1 姓カナ', description: '全角カタカナ', example: 'ヤマダ' },
  { name: '扶養親族1 名カナ', description: '全角カタカナ', example: 'ハナコ' },
  { name: '扶養親族1 性別', description: GenderList, example: '女' },
  { name: '扶養親族1 生年月日', description: '半角数字 (yyyy/mm/dd)', example: '1980/1/1' },
  { name: '扶養親族1 続柄', description: DependentRelationShipList, example: '実父' },
  { name: '扶養親族1 今年の税の扶養対象', description: NotAvailableList, example: '対象' },
  {
    name: '扶養親族1 今年の税の扶養対象ではないが、所得金額調整控除の対象、退職手当等を有する扶養親族',
    description: TargetList,
    example: '対象'
  },
  { name: '扶養親族1 今年の同居・別居', description: ResidenceStatusesList, example: '同居' },
  { name: '扶養親族1 今年の住所 郵便番号', description: '半角数字7桁', example: '999-9999' },
  { name: '扶養親族1 今年の住所 都道府県', example: '東京都' },
  { name: '扶養親族1 今年の住所 市区町村', example: '渋谷区' },
  { name: '扶養親族1 今年の住所 丁目番地', example: '9-99-99' },
  { name: '扶養親族1 今年の住所 建物名・部屋番号', example: 'ジョブカンアパート' },
  { name: '扶養親族1 今年の海外住所', description: '半角英数字', example: '9999PennsylvaniaAvenueN.W. Washington,DC20004' },
  { name: '扶養親族1 今年の非居住者', description: NonResidentTypeList, example: '非居住者' },
  { name: '扶養親族1 今年の留学', description: StudyAbroadList, example: '留学' },
  { name: '扶養親族1 今年の国外居住親族への送金額', description: '半角数字' },
  { name: '扶養親族1 今年の障害者区分', description: HandicapClassificationsList, example: '対象外' },
  {
    name: '扶養親族1 今年の障害者区分詳細',
    description: '障害者手帳の種類と交付年月日、障害の等級などを入力してください',
    example: '身体障害者手帳（平成25年4月1日交付）・身体障害者2級'
  },
  { name: '扶養親族1 給与所得 収入金額', description: '半角数字' },
  { name: '扶養親族1 来年の税の扶養対象', description: NotAvailableList, example: '対象' },
  { name: '扶養親族1 来年の同居・別居', description: ResidenceStatusesList, example: '同居' },
  { name: '扶養親族1 来年の住所 郵便番号', description: '半角数字7桁', example: '999-9999' },
  { name: '扶養親族1 来年の住所 都道府県', example: '東京都' },
  { name: '扶養親族1 来年の住所 市区町村', example: '渋谷区' },
  { name: '扶養親族1 来年の住所 丁目番地', example: '9-99-99' },
  { name: '扶養親族1 来年の住所 建物名・部屋番号', example: 'ジョブカンアパート' },
  { name: '扶養親族1 来年の海外住所', description: '半角英数字', example: '9999PennsylvaniaAvenueN.W. Washington,DC20004' },
  { name: '扶養親族1 来年の非居住者', description: NonResidentTypeList, example: '非居住者' },
  { name: '扶養親族1 来年の留学', description: StudyAbroadList, example: '留学' },
  { name: '扶養親族1 来年の国外居住親族への送金額', description: '半角数字' },
  { name: '扶養親族1 来年の障害者区分', description: HandicapClassificationsList, example: '対象外' },
  {
    name: '扶養親族1 来年の障害者区分詳細',
    description: '障害者手帳の種類と交付年月日、障害の等級などを入力してください',
    example: '身体障害者手帳（平成25年4月1日交付）・身体障害者2級'
  },
  { name: '扶養親族1 来年の給与収入等', description: '半角数字' },
  { name: '扶養親族1 来年の所得見積額', description: '半角数字', example: 350000 },
  { name: '扶養親族1 税法上の扶養家族になった日', description: '半角数字（yyyy/mm/dd）', example: '2011/4/1' },
  { name: '扶養親族1 税法上の扶養に追加された理由' },
  { name: '扶養親族1 死亡日', description: '半角数字 (yyyy/mm/dd)', example: '2000/1/1' }
];

// 定額減税
const FlatTaxReductionDescription = [{ name: '年末調整時点減税対象人数', description: '半角数字(0~99)', example: '2' }];

const SocialInsuranceTypeList = <List items={SOCIAL_INSURANCE} />;
const NewOrOldList = <List items={['新', '旧']} />;
const EarthquakeInsuranceList = <List items={['地震', '旧長期']} />;

const insuranceDescriptions = [
  { name: '一般の生命保険1 ID', example: 1 },
  { name: '一般の生命保険1 保険会社等の名称', example: 'ジョブカン生命' },
  { name: '一般の生命保険1 保険等の種類', example: '養老' },
  { name: '一般の生命保険1 保険期間', example: '10年' },
  { name: '一般の生命保険1 契約者氏名', example: '山田花子' },
  { name: '一般の生命保険1 受取人の氏名', example: '山田花子' },
  { name: '一般の生命保険1 新・旧の区分', description: NewOrOldList, example: '新' },
  { name: '一般の生命保険1 保険料金額', description: '半角数字', example: '10000' },
  { name: '介護医療保険1 ID', example: 1 },
  { name: '介護医療保険1 保険会社等の名称', example: 'ジョブカン生命' },
  { name: '介護医療保険1 保険等の種類', example: '介護' },
  { name: '介護医療保険1 保険期間', example: '10年' },
  { name: '介護医療保険1 契約者氏名', example: '山田花子' },
  { name: '介護医療保険1 受取人の氏名', example: '山田太郎' },
  { name: '介護医療保険1 保険料金額', example: '10000' },
  { name: '個人年金保険1 ID', example: 1 },
  { name: '個人年金保険1 保険会社等の名称', example: 'ジョブカン生命' },
  { name: '個人年金保険1 保険等の種類', example: '確定年金' },
  { name: '個人年金保険1 年金支払期間', example: '30年' },
  { name: '個人年金保険1 契約者氏名', example: '山田花子' },
  { name: '個人年金保険1 受取人の氏名', example: '山田花子' },
  { name: '個人年金保険1 支払開始日', description: '半角数字 (yyyy/mm/dd)', example: '2000/1/1' },
  { name: '個人年金保険1 新・旧の区分', description: NewOrOldList, example: '新' },
  { name: '個人年金保険1 保険料金額', description: '半角数字', example: '10000' },
  { name: '地震保険1 ID', example: 1 },
  { name: '地震保険1 保険会社等の名称', example: 'ジョブカン火災' },
  { name: '地震保険1 保険等の種類（目的）', example: '地震（建物）' },
  { name: '地震保険1 保険期間', example: '5年' },
  { name: '地震保険1 保険等の契約者の氏名', example: '山田花子' },
  { name: '地震保険1 保険等の受取人 氏名', example: '山田花子' },
  { name: '地震保険1 保険料区分（地震・旧長期）', description: EarthquakeInsuranceList, example: '地震' },
  { name: '地震保険1 保険料金額', description: '半角数字', example: '10000' },
  { name: '社会保険1 ID', example: 1 },
  { name: '社会保険1 社会保険の種類', description: SocialInsuranceTypeList, example: '国民年金' },
  { name: '社会保険1 保険料支払先の名称', example: '新宿区' },
  { name: '社会保険1 保険料を負担することになっている人 氏名', example: '山田花子' },
  { name: '社会保険1 本年中に支払った金額', description: '半角数字', example: '10000' }
];

const premiumDescriptions = [
  { name: '独立行政法人中小企業基盤整備機構の共済契約の掛金', description: '半角数字', example: '10000' },
  { name: '確定拠出年金法に規定する企業型年金加入者掛金', description: '半角数字', example: '10000' },
  { name: '確定拠出年金法に規定する個人型年金加入者掛金', description: '半角数字', example: '10000' },
  { name: '心身障害者扶養共済制度に関する契約の掛金', description: '半角数字', example: '10000' }
];

const HousingLoanNoList = <List items={['1', '2']} />;
const HousingLoanSpecialDeductionList = <List items={['住', '認', '増', '震', '住(特家)', '認(特家)', '震(特家)']} />;
const HousingLoanDeductionClassificationList = <List items={['対象外', '特定取得', '特別特定取得', '特例特別特例取得']} />;
// 住宅ローン項目
const housingLoanDescriptions = [
  { name: '住宅借入金等 特別控除適用数', description: HousingLoanNoList, example: '1' },
  { name: '住宅借入金等特別控除額', description: '半角数字', example: '10000' },
  { name: '居住開始年月日（1回目）', description: '半角数字 (yyyy/mm/dd)', example: '2000/1/1' },
  { name: '住宅借入金等特別控除区分（1回目）', description: HousingLoanSpecialDeductionList, example: '住' },
  { name: '住宅借入金等特別控除区分（1回目）特定', description: HousingLoanDeductionClassificationList, example: '特定取得' },
  { name: '住宅借入金等 年末残高（1回目）', description: '半角数字', example: '10000' },
  { name: '居住開始年月日（2回目）', description: '半角数字 (yyyy/mm/dd)', example: '2000/1/1' },
  { name: '住宅借入金等特別控除区分（2回目）', description: HousingLoanSpecialDeductionList, example: '住' },
  { name: '住宅借入金等特別控除区分（2回目）特定', description: HousingLoanDeductionClassificationList, example: '特定取得' },
  { name: '住宅借入金等 年末残高（2回目）', description: '半角数字', example: '10000' }
];

const MemoColorList = <List items={COLORS} />;
// メモ項目
const memoDescriptions = [
  { name: 'メモ1 ID', example: 1 },
  { name: 'メモ1 色', description: MemoColorList, example: '青' },
  { name: 'メモ1 アラート予定日', description: '半角数字（yyyy/mm/dd）', example: '2022/12/1' },
  { name: 'メモ1 内容', description: 'メモの内容', example: 'メモ１です。' }
];

const descriptions = [
  ...basicInfoDescriptions,
  ...formerJobDescriptions,
  ...incomeDescription,
  ...spouseDescriptions,
  ...otherFamilyDescriptions,
  ...FlatTaxReductionDescription,
  ...insuranceDescriptions,
  ...premiumDescriptions,
  ...housingLoanDescriptions,
  ...memoDescriptions
];
