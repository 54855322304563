import React from 'react';
import _ from 'lodash';
import Button from 'jbc-front/components/Button';
import { useNotify } from '@jbc-year-end-adj/common/hooks/useNotify';
import { useAsyncTask, useMutation, useSession, useYear, BulkDelete, convertSelectedIds } from '../components';
import FloatingButton from '../components/FloatingButton';
import { BulkStatusUpdateModal } from './BulkStatusUpdateModal';
import { REQUEST, REQUEST_ASYNC } from './query';
import { DELETE_MODE, REQUEST_MODE, CHANGE_STATUS_MODE } from '../pages/Top';
import styles from '../pages/Top.scss';

const MAX_REQUEST_COUNT = 100;

const convertRequestMutation = (selectedTotalCount, mutation, asyncMutation) => {
  const isExcessive = selectedTotalCount > MAX_REQUEST_COUNT;

  return {
    requestMutation: isExcessive ? asyncMutation : mutation,
    message: isExcessive ? '一括依頼を開始しました' : '依頼しました'
  };
};

const DeleteModeButton = ({
  variables,
  selected,
  allSelectMode,
  isDisabledSubmit,
  selectedTotalCount,
  selectedInfo,
  onSuccess,
  onCancel
}) => {
  return (
    <>
      <BulkDelete
        variables={variables}
        selected={selected}
        allSelectMode={allSelectMode}
        isDisabledSubmit={isDisabledSubmit}
        selectedTotalCount={selectedTotalCount}
        selectedInfo={selectedInfo}
        onSuccess={onSuccess}
      />
      <Button onClick={onCancel}>キャンセル</Button>
    </>
  );
};

const RequestModeButton = ({
  isCurrentYear,
  selectedTotalCount,
  allSelectMode,
  selected,
  year,
  taskRunningProps,
  isDisabledSubmit,
  reset
}) => {
  const [request, { loading: loadingRequest }] = useMutation(REQUEST);
  const [requestAsync, { loading: loadingRequestAsync }] = useMutation(REQUEST_ASYNC);
  const notify = useNotify();
  const handleSubmitRequest = async () => {
    const { requestMutation, message } = convertRequestMutation(selectedTotalCount, request, requestAsync);
    const { employeeIds, exceptEmployeeIds } = convertSelectedIds(allSelectMode, Object.keys(_.pickBy(selected)));

    await requestMutation({
      variables: {
        input: {
          year,
          allSelectMode,
          employeeIds,
          exceptEmployeeIds
        }
      }
    });
    notify(message);
    reset();
  };

  return (
    <>
      {!isCurrentYear && <p className={styles.errorMessage}>※過去の年末調整の依頼を送ることはできません</p>}
      <Button
        onClick={handleSubmitRequest}
        disabled={!isCurrentYear || isDisabledSubmit() || loadingRequest || loadingRequestAsync}
        {...taskRunningProps}
        primary
      >
        年末調整を依頼する
      </Button>
    </>
  );
};

const ChangeStatusModeButton = ({
  isCurrentYear,
  selectedTotalCount,
  allSelectMode,
  selected,
  year,
  taskRunningProps,
  isDisabledSubmit,
  reset,
  onCancel
}) => {
  return (
    <>
      <BulkStatusUpdateModal
        selectedTotalCount={selectedTotalCount}
        allSelectMode={allSelectMode}
        selected={selected}
        isDisabledSubmit={isDisabledSubmit}
        isCurrentYear={isCurrentYear}
        taskRunningProps={taskRunningProps}
        reset={reset}
        year={year}
      />
      <Button onClick={onCancel}>キャンセル</Button>
    </>
  );
};

const FloatingButtonArea = ({
  mode,
  onChangeMode,
  variables,
  selected,
  allSelectMode,
  isDisabledSubmit,
  selectedTotalCount,
  selectedInfo,
  reset
}) => {
  const year = useYear();
  const { isCurrentYear } = useSession();
  const { taskRunningProps } = useAsyncTask();
  const hidden = !isCurrentYear || isDisabledSubmit();

  return (
    <>
      {mode === DELETE_MODE && (
        <FloatingButton>
          <DeleteModeButton
            variables={variables}
            selected={selected}
            allSelectMode={allSelectMode}
            isDisabledSubmit={isDisabledSubmit}
            selectedTotalCount={selectedTotalCount}
            selectedInfo={selectedInfo}
            onSuccess={() => onChangeMode(REQUEST_MODE)}
            onCancel={() => onChangeMode(REQUEST_MODE)}
          />
        </FloatingButton>
      )}
      {mode === REQUEST_MODE && (
        <FloatingButton className={hidden ? styles.transitionOff : styles.transitionOn}>
          <RequestModeButton
            isCurrentYear={isCurrentYear}
            selectedTotalCount={selectedTotalCount}
            allSelectMode={allSelectMode}
            isDisabledSubmit={isDisabledSubmit}
            selected={selected}
            year={year}
            taskRunningProps={taskRunningProps}
            reset={reset}
          />
        </FloatingButton>
      )}
      {mode === CHANGE_STATUS_MODE && (
        <FloatingButton className={hidden ? styles.transitionOff : styles.transitionOn}>
          <ChangeStatusModeButton
            isCurrentYear={isCurrentYear}
            selectedTotalCount={selectedTotalCount}
            allSelectMode={allSelectMode}
            isDisabledSubmit={isDisabledSubmit}
            selected={selected}
            year={year}
            taskRunningProps={taskRunningProps}
            reset={reset}
            onCancel={() => onChangeMode(REQUEST_MODE)}
          />
        </FloatingButton>
      )}
    </>
  );
};

export default FloatingButtonArea;
