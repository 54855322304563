import { FC } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';

import { Modal } from 'jbc-front/components/presenters/ui/Modal';
import { ButtonRow } from 'jbc-front/components/presenters/layout/ButtonRow';
import Button from 'jbc-front/components/Button';
import { Spacer } from 'jbc-front/components/presenters/Spacer';
import { Radio, RadioContainer } from 'jbc-front/components/presenters/form/Radio';
import { Note } from 'jbc-front/components/presenters/ui/Note';

import { yup } from '@jbc-year-end-adj/common/yup';
import { FormSection } from '@jbc-year-end-adj/2024/components/form/FormSection';
import { FormField } from '@jbc-year-end-adj/2024/components/form/FormField';
import { Label } from '@jbc-year-end-adj/2024/components/form/Label';
import { useAdminMutation as useMutation } from '@jbc-year-end-adj/2024/hooks/graphql/useMutation';
import { IMPORT_EMPLOYEES } from './mutation';

import styles from './Cooperation.scss';
import { yupResolver } from '@hookform/resolvers/yup';

type LmsImportModalProps = {
  isOpen: boolean;
  onClose: () => void;
  refetch: () => void;
};

type Schema = {
  target: 'all' | 'selection';
};

const schema = yup.object({
  target: yup
    .mixed<'all' | 'selection'>()
    .oneOf(['all', 'selection'])
    .required()
});

export const PayrollDataImportModal: FC<LmsImportModalProps> = ({ isOpen, onClose, refetch }) => {
  const { register, handleSubmit } = useForm<Schema>({
    resolver: yupResolver(schema),
    defaultValues: {
      target: 'selection'
    }
  });

  const [importEmployees, { loading }] = useMutation(IMPORT_EMPLOYEES);

  const submit: SubmitHandler<Schema> = async data => {
    await importEmployees({
      variables: {
        service: 'ジョブカン給与計算',
        filterBySelection: data.target === 'selection',
        needsOffice: false,
        needsEmployee: false,
        needsWithholdingSlip: true
      }
    });
    refetch();
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Header onClose={onClose}>ジョブカン給与計算から取得</Modal.Header>
      <Modal.Body>
        スタッフコードを持たない従業員は取得できません。
        <br />
        スタッフコードを変更しますと別従業員として認識されますのでご注意ください。
        <br />
        <br />
        また、給与額・賞与額の連携は、年内支給の全ての給与・賞与が確定されている場合にのみ実行されます。
        <br />
        従業員の支給金額が連携されない場合は給与・賞与の確定状態をご確認ください。
        <Spacer direction="y" size={30} />
        <form>
          <FormSection>
            <FormField>
              <FormField.LabelContainer>
                <Label className={styles.label}>インポート対象選択</Label>
              </FormField.LabelContainer>
              <RadioContainer className={styles.radioContainer}>
                <Radio {...register('target')} value="all">
                  全従業員
                </Radio>
                <Radio {...register('target')} value="selection">
                  インポート設定されている従業員
                </Radio>
              </RadioContainer>
              <FormField.NoteContainer>
                <Note>
                  全従業員：ジョブカン給与計算に登録されている従業員の情報を取得します。（インポートされる従業員の条件は
                  <a
                    className="u-txt-link"
                    href="https://jobcan-payroll.zendesk.com/hc/ja/articles/900002422883"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    こちら
                  </a>
                  ）
                  <br />
                  インポート設定されている従業員：
                  <a
                    className="u-txt-link"
                    href="https://payroll.jobcan.jp/employees/choose_coordination_yea"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    対象従業員選択（年末調整）
                  </a>
                  で選択されている従業員の情報を取得します。
                </Note>
              </FormField.NoteContainer>
            </FormField>
          </FormSection>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <ButtonRow>
          <Button onClick={() => onClose()}>キャンセル</Button>
          <Button primary onClick={handleSubmit(submit)} disabled={loading}>
            取得
          </Button>
        </ButtonRow>
      </Modal.Footer>
    </Modal>
  );
};
